import React from 'react';
import ReactPaginate from 'react-paginate';
import Styles from './Pagination.module.css'

const Pagination = ({ onPageChange, rowsPerPage, pageNumber, totalPageSize }) => {


  return (
      <ReactPaginate
        nextClassName={''}
        previousLabel={'<'}
        nextLabel={'>'}
        pageCount={totalPageSize}
        onPageChange={onPageChange}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        containerClassName={Styles.paginationBttns}
        previousLinkClassName={Styles.previousBttn}
        nextLinkClassName={Styles.nextBttn}
        disabledClassName={Styles.paginationDisabled}
        activeClassName={Styles.paginationActive}
        // forcePage={pageNumber - 1} 
        initialPage={pageNumber - 1}
      />
    )
};

export default Pagination;
