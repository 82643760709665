import React from 'react';

const Slider = () => {
	return (
		<div className="slider1">
			<ul className="slides">
				<input type="radio" name="radio-btn" id="img-1" checked />
				<li className="slide-container">
					<div className="slide">
						<div className="testemonials__data">
							<div className="testimonial">
								<img
									src="./images/testimonial-image.png"
									alt="testimonial 1"
									className="testimonial__image"
								/>
								<p className="testimonial__text">
									The culture of Mountain West is a fun, engaging, collaborative, involved,
									family-oriented organization which leads to elevating employee engagement and makes
									for a great working environment.
								</p>
								<hr className="testimonial__hr" />
								<h6 className="testimonial__name heading_3">
									<b>Amanda Olaveson</b>
								</h6>
								<p className="testimonial__subtext heading_3">
									<b>Underwriter II</b>
								</p>
								<hr className="testimonial__hr1" />
							</div>
						</div>
					</div>
					{/* <div className="nav">
						<label for="img-6" className="prev">
							&#x2039;
						</label>
						<label for="img-2" className="next">
							&#x203a;
						</label>
					</div> */}
				</li>

				<input type="radio" name="radio-btn" id="img-2" />
				<li className="slide-container">
					<div className="slide">
						<div className="testemonials__data">
							<div className="testimonial">
								<img
									src="./images/Suazo-Esther.jpg"
									alt="testimonial 2"
									className="testimonial__image"
								/>
								<p className="testimonial__text">
									What I like about Mountain West is it is very family orientated and very
									accommodating to our family needs.
								</p>
								<hr className="testimonial__hr" />
								<h6 className="testimonial__name heading_3">
									<b>Esther Suazo</b>
								</h6>
								<p className="testimonial__subtext heading_3">
									<b>Underwriting Trainee</b>
								</p>
								<hr className="testimonial__hr1" />
							</div>
						</div>
					</div>

					{/* <div className="nav">
						<label for="img-1" className="prev">
							&#x2039;
						</label>
						<label for="img-3" className="next">
							&#x203a;
						</label>
					</div> */}
				</li>
				<li className="nav-dots">
					<label for="img-1" className="nav-dot" id="img-dot-1" />
					<label for="img-2" className="nav-dot" id="img-dot-2" />
				</li>
			</ul>
		</div>
	);
};

export default Slider;
