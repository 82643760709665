import { DISCOUNT_LIST_REQUEST, DISCOUNT_LIST_SUCCESS, DISCOUNT_LIST_FAIL } from '../constants/userConstants';

export const discountListReducer = (state = {}, action) => {
	switch (action.type) {
		case DISCOUNT_LIST_REQUEST:
			return { loading: true };

		case DISCOUNT_LIST_SUCCESS:
			return { agentList: action.payload, loading: false };

		case DISCOUNT_LIST_FAIL:
			return { loading: false, error: action.payload };


		default:
			return state;
	}
};
