import React, { Fragment, useState, useEffect, useCallback } from 'react'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'

const EditAnnuity = ({
  setShowModal,
  showModal,
  handleYes,
  section1,
  setSection1,
}) => {
  const [loading, setLoading] = useState(false)

  const [title, setTitle] = useState({ value: '', error: '' })
  const [description, setDescription] = useState({ value: '', error: '' })
  const [agentSearchPlaceholder, setAgentSearchPlaceholder] = useState({
    value: '',
    error: '',
  })
  const [locationPlaceholder, setLocationPlaceholder] = useState({
    value: '',
    error: '',
  })

  useEffect(() => {}, [])
  const submit = (e) => {
    e.preventDefault()
    if (section1.title.value == '') {
      let temp = { ...section1 }
      temp.title.error = 'Please enter title!'
      setSection1(temp)
      return
    } else {
      let temp = { ...section1 }
      temp.title.error = ''
      setSection1(temp)
    }
    if (section1.description.value == '') {
      let temp = { ...section1 }
      temp.description.error = 'Please enter description!'
      setSection1(temp)
      return
    } else {
      let temp = { ...section1 }
      temp.description.error = ''
      setSection1(temp)
    }

    handleYes()
    setShowModal(false)
  }

  return (
    <Modal show={showModal}>
      <form>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Annuity Info</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '100%' }}
                    required={true}
                    value={section1.title.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.title.value = e.target.value
                      setSection1(temp)
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section1.title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Description:'
                    style={{ width: '100%' }}
                    type={'textarea'}
                    required={true}
                    value={section1.description.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.description.value = e.target.value
                      setSection1(temp)
                      setDescription({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                    errorMessage={section1.description.error}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right'></div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditAnnuity
