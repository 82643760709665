import React from "react";

import Tooltip from "../Tooltip/Tooltip";
import styles from './LoginTooltip.module.css'
import { NavLink } from "react-router-dom";

const LoginTooltip = ({ onClick, setShowTooltip }) => {
    return (
        <Tooltip>
            <div style={{ display: 'block', textAlign: 'end' }}>
                <svg onClick={() => setShowTooltip(false)} className={styles.modal_close_icon}>
                    <use xlinkHref="/sprite.svg#icon-close" />
                </svg>
            </div>
            <div className={styles.mainView}>

                <div className={styles.infoContainer}>
                    <span className={styles.mainTitle}>Customer Hub</span>
                    <p className={styles.loginDescription}>
                        Customer Hub is the central location to conveniently access and manage your account. You can pay bills, update information, review policies and stay informed.
                    </p>
                    <NavLink
                        to="/360/customer-login"
                        style={{ color: 'white', textDecoration: 'none' }}
                    >
                        <button className={styles.loginButton}>
                            Sign In
                        </button>
                    </NavLink>
                </div>
                <div className={styles.dash} />
                <div className={styles.infoContainer}>
                    <span className={styles.mainTitle}>360 Insurance Hub</span>
                    <p className={styles.loginDescription}>
                        360 Insurance Hub is a convenient information page for all 360 Insurance. Access tools, documents, and more.
                    </p>
                    <NavLink
                        to="/360/agent-login"
                        style={{ color: 'white', textDecoration: 'none' }}
                    >
                        <button className={styles.loginButton}>

                            Login
                        </button>
                    </NavLink>
                </div>
            </div>
        </Tooltip>
    )
}

export default LoginTooltip;