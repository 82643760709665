import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import { Link } from 'react-router-dom'
import CheckBox from '../../../../components/Inputs/Checkbox'

const EditAbout = ({ setShowModal, showModal, handleYes, section3, setSection3 }) => {
  const [loading, setLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(null)
  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState('')

  const [title, setTitle] = useState({ value: '', error: '' })
  const [description, setDescription] = useState({ value: '', error: '' })
  const [buttonTitle, setButtonTitle] = useState({ value: '', error: '' })
  const [showButton, setShowButton] = useState(true)

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles);
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0]
    setUploadImage(file)
    var reader = new FileReader()
    reader.onloadend = function () {
      console.log('RESULT', reader.result)
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })

  const [aboutList, setAboutList] = useState([
    {
      title: 'About',
      description: `Whether you are a new home owner, or starting your business - you can rely on our experience to help you cover what matters most.
    Mountain West is a multi-line insurance company, serving the needs of individuals, families, and businesses throughout Wyoming and Montana for over 70 years. Our mission is to provide financial peace of mind of our customers with competitive, quality products and services.`,
      buttonTitle: 'About us',
      showButton: true,
    },
  ])

  const resetHandler = () => {
    setTitle({ value: '', error: '' })
    setButtonTitle({ value: '', error: '' })
    setDescription({ value: '', error: '' })
    setIsUpdate(false)
    setCurrentIndex(null)
    setUploadImage(null)
    setShowButton(true)
  }

  const onAddhandler = () => {
    let validated = true

    if (title.value === '' && title.value.trim() === '') {
      setTitle({
        ...title,
        error: 'Please enter tab title',
      })
      validated = false
    }
    if (description.value === '' && description.value.trim() === '') {
      setDescription({
        ...description,
        error: 'Please enter description',
      })
      validated = false
    }
    if (buttonTitle.value === '' && buttonTitle.value.trim() === '') {
      setButtonTitle({
        ...buttonTitle,
        error: 'Please enter button title',
      })
      validated = false
    }

    if (validated) {
      let array = aboutList
      array.push({
        title: title.value,
        description: description.value,
        buttonTitle: buttonTitle.value,
        showButton: showButton,
      })

      setAboutList(array)
      resetHandler()
    }
  }

  const editHandler = (data, index) => {
    setTitle({ value: data.title, error: '' })
    setButtonTitle({ value: data.buttonTitle, error: '' })
    setDescription({ value: data.description, error: '' })
    setIsUpdate(true)
    setCurrentIndex(index)
  }
  const deleteHandler = (data) => {
    const filtered = aboutList.filter((item, i) => {
      return item.title !== data.title
    })

    setAboutList(filtered)
    resetHandler()
  }

  const saveHandler = () => {
    let validated = true

    if (title.value === '' && title.value.trim() === '') {
      setTitle({
        ...title,
        error: 'Please enter tab title',
      })
      validated = false
    }
    if (description.value === '' && description.value.trim() === '') {
      setDescription({
        ...description,
        error: 'Please enter description',
      })
      validated = false
    }
    if (buttonTitle.value === '' && buttonTitle.value.trim() === '') {
      setButtonTitle({
        ...buttonTitle,
        error: 'Please enter button title',
      })
      validated = false
    }

    if (validated) {
      let array = aboutList
      let item = array[currentIndex]

      array[currentIndex] = {
        title: title.value,
        description: description.value,
        buttonTitle: buttonTitle.value,
        showButton: showButton,
      }
      setAboutList(array)
      resetHandler()
    }
  }

  console.log('show button', showButton)
  const submit = (e) => {
    e.preventDefault();

    if (section3.title.value == '') {
      let temp = { ...section3 };
      temp.title.error = 'Please enter title!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.title.error = ''
      setSection3(temp)
    }
    if (section3.description.value == '') {
      let temp = { ...section3 };
      temp.description.error = 'Please enter description!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.description.error = ''
      setSection3(temp)
    }
    if (section3.button.value == '') {
      let temp = { ...section3 };
      temp.button.error = 'Please enter button title!'
      setSection3(temp)
      return
    } else {
      let temp = { ...section3 };
      temp.button.error = ''
      setSection3(temp)
    }

    handleYes(uploadImage)
  }

  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit About</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => {
                resetHandler()
                setShowModal(false)
              }}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    value={section3.title.value}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.title.value = e.target.value
                      setSection3(temp)
                      // setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section3.title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Description:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    required={true}
                    value={section3.description.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.description.value = e.target.value
                      setSection3(temp)
                      // setDescription({ error: '', value: e.target.value })
                    }}
                    errorMessage={section3.description.error}
                  />
                </div>
                <div
                  className='pre_heading'
                  style={{
                    gap: '1rem',
                    alignItems: 'center',
                  }}>
                  <FormInputComponent
                    heading='Button Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section3.button.value}
                    onChange={(e) => {
                      let temp = { ...section3 }
                      temp.button.value = e.target.value
                      setSection3(temp)
                      // setButtonTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section3.button.error}
                  />

                </div>
              </div>
              <div className='modal_inner_content--right3'>
                <label>About Image</label>
                <div className='upload_border' {...getRootProps()}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  <svg className='modal_upload__icon-dark'>
                    <use xlinkHref='/sprite.svg#icon-cloud' />
                  </svg>
                </div>
                {/* <div className='upload_preview_modal'>
                  <img height={200} src={uploadImagePreview}></img>
                </div> */}
                <div
                  className='modal_dark_buttons'
                  style={{ paddingRight: '0rem' }}>

                </div>
              </div>
            </div>

          </div>

          <div className='modal_dark_buttons'>
            <button
              className='modal__button-reset-dark'
              onClick={() => {
                resetHandler()
                setShowModal(false)
              }}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditAbout
