import React from 'react';
import './SimpleButton.css';

const SimpleButton = ({ onClick, varient, children, style }) => {
	return (
		<button
			className={`simpleButton ${varient === 'black' ? 'simpleButton__black' : 'simpleButton__red'}`}
			style={style}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

export default SimpleButton;
