import React from 'react';
import './Button.css';

const Button = ({ onClick, label, icon, style, varient, href }) => {
	return (
		<button
			className={`button ${varient === 'black' ? 'button__black' : 'button__red'}`}
			onClick={onClick}
			style={style}
		>
			<div className="button__icon">
				<svg className="button__icon--icon">
					<use xlinkHref={icon} />
				</svg>
			</div>
			{label && <p className="button__text heading_3">{label}</p>}
		</button>
	);
};

export default Button;
