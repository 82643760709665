import React, { Fragment, useState, useEffect, useCallback } from "react";
// import { useHistory } from 'react-router-dom'
import Modal from "../../../../components/DarkModal/index";
import FormInputComponent from "../../../../components/FormInputComponent/FormInputComponent";
import { useDropzone } from "react-dropzone";
import { imageUpload } from "../../../../redux/actions/contentAction";

const EditInsuranceInfo = ({
  setShowModal,
  showModal,
  handleYes,
  section1,
  setSection1,
  handleSection2Submit,
  pointsArr,
  setPointsArr,
}) => {
  const [loading, setLoading] = useState(false);

  const [uploadImagePreview, setUploadImagePreview] = useState("");
  const [uploadImage, setUploadImage] = useState(null);

  const [editIndex, setEditIndex] = useState(-1);

  const [title, setTitle] = useState({ value: "", error: "" });
  const [subTitle, setSubTitle] = useState({ value: "", error: "" });
  const [point, setPoint] = useState({ value: "", error: "" });
  const [para, setPara] = useState({ value: "", error: "" });


  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles);
    encodeImageFileAsURL(acceptedFiles);
  }, []);
  const encodeImageFileAsURL = (files) => {
    var file = files[0];
    setUploadImage(file);
    var reader = new FileReader();
    reader.onloadend = function () {
      console.log("RESULT", reader.result);
      if (reader.result) {
        setUploadImagePreview(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg,image/png",
    onDrop,
  });

  console.log(uploadImage, 'uploadImage')

  const submit = (e) => {
    e.preventDefault();
    if (section1.title.value === "") {
      let temp = { ...section1 };
      temp.title.error = "Please enter title!";
      setSection1(temp);
      return;
    } 
    if (section1.subTitle.value === "") {
      let temp = { ...section1 };
      temp.subTitle.error = "Please enter Subtitle!";
      setSection1(temp);
      return;
    } 

    if (uploadImage) {
      console.log('hi')
      imageUpload(uploadImage).then((res) => {
        console.log(res, 'res')
        if (res.data.status) {
          setUploadImagePreview(res.data.path);

          let content = {
            ...section1,
            imageUrl: {
              value: res.data.path,
            },
          };

          console.log("content for online", content);

          handleYes(content);
          setShowModal(false);
        }
      });
    } else {
      let content = {
        ...section1,
      };

      console.log("content", content);

      handleYes(content);
      setShowModal(false);
    }
  };


  const onEdit = (item, index) => {
    setEditIndex(index);
    setPoint({
      ...point,
      value: item.point.value,
    });
    setPara({
      ...para,
      value: item.para.value,
    });
  };

  const onDelete = (item, index) => {
    if (window.confirm("Are You Sure to Delete?")) {
      let temp = [...pointsArr];
      temp.splice(index, 1);
      setPointsArr(temp);
      handleSection2Submit(temp);
    }
  };

  const onAdd = () => {
    let valid = true;

    if (point.value === "" && point.value.trim() === "") {
      setPoint({
        ...point,
        error: "Please enter point",
      });
      valid = false;
    }
    if (para.value === "" && para.value.trim() === "") {
      setPara({
        ...para,
        error: "Please enter Description",
      });
      valid = false;
    }

    if (valid) {
      let content = {
        point: { value: point.value, action: "", type: "" },
        para: { value: para.value, action: "", type: "" },
      };

      if (editIndex > -1) {
        const temp = [...pointsArr];
        temp[editIndex] = content;

        console.log("updated", temp);
        setPointsArr(temp);
        handleSection2Submit(temp)
      } else {
        const temp = [...pointsArr];
        temp.push(content);
        setPointsArr(temp);
        handleSection2Submit(temp)  
        console.log("added", temp);
      }
    }
  };

  const resetAll = () => {
    setPoint({ value: '', error: '' })
    setPara({ value: '', error: '' })
    setEditIndex(-1)
  }
  const closeModal = () => {
    resetAll()
    setShowModal(false)
  }

  return (
    <Modal show={showModal}>
      <form>
        <div className="modal__container-dark">
          <div className="modal_heading__container-dark">
            <h1 className="modal_heading__text-dark">Edit Insurance Info</h1>
            <button
              className="modal__heading--button-dark"
              onClick={() => closeModal()}>
              <svg className="modal_heading__icon-dark">
                <use xlinkHref="/sprite.svg#icon-cross" />
              </svg>
            </button>
          </div>
          <div className="modal__form-dark">
            <div className="modal_inner_content">
              <div className="modal_inner_content--left">
                <div className="pre_heading">
                  <FormInputComponent
                    heading="Title:"
                    style={{ width: "80%" }}
                    required={true}
                    value={section1.title.value}
                    onChange={(e) => {
                      let temp = { ...section1 };
                      temp.title.value = e.target.value;
                      setSection1(temp);
                    }}
                    errorMessage={section1.title.error}
                  />
                </div>
                <div className="pre_heading">
                  <FormInputComponent
                    heading="Subtitle:"
                    style={{ width: "80%" }}
                    type={"textarea"}
                    required={true}
                    value={section1.subTitle.value}
                    onChange={(e) => {
                      let temp = { ...section1 };
                      temp.subTitle.value = e.target.value;
                      setSection1(temp);
                    }}
                  />
                </div>
                {/* <div className='pre_heading'>
                  <FormInputComponent
                    heading='Point 1:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section1.point1.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.subTitle1.value = e.target.value
                      setSection1(temp)
                      setPoint1({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                  />
                </div>

                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Point 2'
                    style={{ width: '80%' }}
                    required={true}
                    value={section1.point2.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.contact.value = e.target.value
                      setSection1(temp)
                      setPoint2({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Point 3:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section1.point3.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.contact.value = e.target.value
                      setSection1(temp)
                      setPoint3({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Para 1:'
                    type={'textarea'}
                    style={{ width: '80%' }}
                    required={true}
                    value={section1.para1.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.subTitle2.value = e.target.value
                      setSection1(temp)
                      setPara1({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Para 2:'
                    type={'textarea'}
                    style={{ width: '80%' }}
                    required={true}
                    value={section1.para2.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.contact.value = e.target.value
                      setSection1(temp)
                      setPara2({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                  />
                </div>

                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Para 3:'
                    type={'textarea'}
                    style={{ width: '80%' }}
                    required={true}
                    value={section1.para3.value}
                    onChange={(e) => {
                      let temp = { ...section1 }
                      temp.contact.value = e.target.value
                      setSection1(temp)
                      setPara3({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                  />
                </div> */}
                <div className="pre_heading" style={{ display: "flex" }}>
                  <FormInputComponent
                    heading="Button1 label"
                    style={{ width: "47%" }}
                    required={true}
                    value={section1.button1label.value}
                    onChange={(e) => {
                      let temp = { ...section1 };
                      temp.button1label.value = e.target.value;
                      setSection1(temp);
                      // setPara3({
                      //   error: '',
                      //   value: e.target.value,
                      // })
                    }}
                  />
                  <FormInputComponent
                    heading="Button1 link"
                    style={{ width: "47%" }}
                    required={true}
                    value={section1.button1link.value}
                    onChange={(e) => {
                      let temp = { ...section1 };
                      temp.button1link.value = e.target.value;
                      setSection1(temp);
                      // setPara3({
                      //   error: '',
                      //   value: e.target.value,
                      // })
                    }}
                  />
                </div>
                <div className="pre_heading" style={{ display: "flex" }}>
                  <FormInputComponent
                    heading="Button2 label"
                    style={{ width: "47%" }}
                    required={true}
                    value={section1.button2label.value}
                    onChange={(e) => {
                      let temp = { ...section1 };
                      temp.button2label.value = e.target.value;
                      setSection1(temp);
                      // setPara3({
                      //   error: '',
                      //   value: e.target.value,
                      // })
                    }}
                  />
                  <FormInputComponent
                    heading="Button2 link"
                    style={{ width: "47%" }}
                    required={true}
                    value={section1.button2link.value}
                    onChange={(e) => {
                      let temp = { ...section1 };
                      temp.button2link.value = e.target.value;
                      setSection1(temp);
                      // setPara3({
                      //   error: '',
                      //   value: e.target.value,
                      // })
                    }}
                  />
                </div>

                <div
            style={{
              width: '94.5%',
              marginTop: '4px',
              height: '0.5px',
              left:18,
              top:'42rem',
              position: 'absolute',
              backgroundColor: '#9e8d8d',
              alignSelf: 'center',
            }}></div>
                <label style={{ fontSize: "1.4rem", fontWeight: "600", marginTop: '1rem' }}>
                  Add Points
                </label>
                <div style={{ display: "flex", justifyContent: 'space-between', width: '100%' }}>
                  <div style={{width: '100%'}}>
                <div className="pre_heading" style={{ display: "flex" }}>
                  <FormInputComponent
                    heading="Point"
                    style={{ width: "40%" }}
                    required={true}
                    value={point.value}
                    onChange={(e) => {
                      setPoint({ error: "", value: e.target.value });
                    }}
                    errorMessage={point.error}
                  />
                </div>
                <div className="pre_heading" style={{ display: "flex" }}>
                  <FormInputComponent
                    heading="Description"
                    type={'textarea'}
                    style={{ width: "80%" }}
                    required={true}
                    value={para.value}
                    onChange={(e) => {
                      setPara({ error: "", value: e.target.value });
                    }}
                    errorMessage={para.error}
                  />
                </div>
                </div>
              </div>
              </div>
              <div className="modal_inner_content--right">
                <label>Upload Image</label>
                <div
                  className="upload_border"
                  {...getRootProps()}
                  style={{ marginTop: "-1rem" }}
                >
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  {uploadImagePreview === "" ? (
                    <svg className="modal_upload__icon-dark">
                      <use xlinkHref="/sprite.svg#icon-cloud" />
                    </svg>
                  ) : (
                    <img
                      height={60}
                      width={60}
                      src={uploadImagePreview}
                      style={{
                        marginTop: "0rem",
                        alignSelf: "center",
                      }}
                    />
                  )}
                </div>
                <div className="modal_dark_buttons" style={{marginTop: '15rem'}}>
            <button
              type="button"
              className="modal__button-reset-dark"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            {loading ? (
              <button className="modal__button-dark">Please wait...</button>
            ) : (
              <button className="modal__button-dark" onClick={submit}>
                Submit
              </button>
            )}
          </div>
                <div
                  style={{
                    marginTop: '15rem',
                    marginLeft: '20rem',
                    display: 'flex',
                    gap: 34,
                  }}>
                  <button
                    type='button'
                    className='modal__button-reset-dark'
                    onClick={resetAll}
                    >
                    Reset
                  </button>
                  <button
                    type='button'
                    className='modal__button-dark'
                    onClick={onAdd}>
                    {loading ? 'Saving..' : editIndex > -1 ? 'Save' : 'Add'}
                  </button>
                </div>
              </div>
            </div>
            <div className="modal_inner_content">
              <div className="modal_form_container">
                <table
                  className="modal__form-table"
                  cellSpacing="0"
                  cellPadding="0"
                >
                  <thead className="modal__form-sticky">
                    <tr className="modal__form-tr">
                      <th>No.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {pointsArr &&
                      pointsArr.length > 0 &&
                      pointsArr.map((item, i) => {
                        return (
                          <tr
                            style={{ height: "60px" }}
                            className="modal__form-tr2"
                            key={i}
                          >
                            <td>{i + 1}</td>
                            <td>{item?.point?.value}</td>
                            <td style={{ width: "360px" }}>
                              {item?.para?.value}
                            </td>
                            <td className="modal__form-buttons" style={{justifyContent: 'center', paddingTop: '1.1rem'}}>
                              <button
                                type="button"
                                className="modal__button-edit"
                                onClick={(e) => onEdit(item, i)}
                              >
                                edit
                              </button>
                              <button
                                type="button"
                                className="modal__button-delete"
                                onClick={(e) => onDelete(item, i)}
                              >
                                delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </form>
    </Modal>
  );
};

export default EditInsuranceInfo;
