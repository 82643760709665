import React from "react";
import styles from "./LinkChip.module.css";
import { Link } from "react-router-dom";

const LinkChip = ({ title, link }) => {
  return (
    <div onClick={() => link && window.open(link)} target={'_blank'} className={styles.chipContainer} rel="noreferrer" style={{ cursor: `${link ? 'pointer' : ''}` }}>
      <label style={{ fontSize: "1.6rem", cursor: "pointer" }}>{`» ${title}`}</label>
      <div style={{ marginBottom: "-2rem" }}>
        <svg className={styles.link_button}>
          <use style={{ marginBottom: "-2rem" }} xlinkHref="/sprite.svg#icon-link-close-button" />
        </svg>
      </div>
    </div>
  );
};

export default LinkChip;
