import React from 'react'
import Banner from '../../../components/Banner/Banner'
import Content from '../../../components/Content/Content'
import FormInputComponent from '../../../components/FormInputComponent/FormInputComponent'
import Button from '../../../components/Inputs/Button'
import TextInput from '../../../components/Inputs/TextInput'
import SimpleTextBox from '../../../components/SimpleTextBox'
import Image1 from '../../../components/images/farm-insurance.png'
import './SendMessage.css'

const Index = () => {
  return (
    <Content headerFooterMenu="mountain-west">
        <Banner
			backgroundImage="url(/images/denver_map.png)"
			//title="360 Insurance."
			//subTitle="A multiline insurance company, serving the needs of individuals, families and businesses throughout wyoming and Montana."
		/>
        <div className='send_message-contact--maincontact--main'>
            <div className='send_message-contact--container'>
                <div
                // style={{ height: '60vh' ,backgroundImage:'url(/images/farm-insurance.png)'}}
                >
                    <img className='send_message-contact--image' src={Image1}/>
                    <div style={{padding: '2rem'}}>
                        <h1>Contact</h1>
                    <p style={{marginTop: '2rem', fontSize: '1.1rem'}}>If you have questions or comments regarding your policy, or its products or services, we first request you contact your 360 insurance agent. Otherwise, fill out the form to direct your inquiry to Customer Service.</p>
                    <p style={{marginTop: '2rem', fontSize: '1.4rem'}}>931 Boulder Drive, Laramie, WY 82070</p>
                    <p style={{marginTop: '2rem', fontSize: '1.4rem'}}>
                        (307) 745-4835
                    </p>
                    </div>

                </div>
            </div>
            <div className='send_message--container'>
                <h1>Send us a message</h1>
                <div className='message--form'>
                    <div className='send_message-flex--container'>
                        <FormInputComponent
                        style={{width: '100%'}}
                        heading='Name'
                        subheading='First' />

                        <FormInputComponent
                        // heading='Name'
                        style={{width: '100%'}}
                        subheading='Last' />
                    </div>
                    <div className='send_message-flex--container'>
                        <FormInputComponent
                        style={{width: '100%'}}
                        heading='Phone'
                         />

                        <FormInputComponent
                        style={{width: '100%'}}
                        heading='Name'
                         />
                    </div>
                    <div className='send_message-flex--container'>
                        <FormInputComponent
                        style={{width: '100%'}}
                        heading='Policy Number'
                         />
                        <div style={{width: '100%'}}>
                            <label className='send_message-input--checkbox--label'>Prefered Method of Contact</label>
                            <div className='checkbox--container'>
                                 <div className='actual--box'>
                                     <input
                                        className='checkbox-round'
                                        type='checkbox'
                                        name="vehicle1" value="Bike"
                                        />
                                        <label>Email</label>
                                 </div>
                                 <div className='actual--box'>
                                     <input
                                        className='checkbox-round'
                                        type='checkbox'
                                        name="vehicle1" value="Bike"
                                        />
                                        <label>Phone</label>
                                 </div>
                            </div>
                        </div>
                    </div>
                    <div className='send_message-commentBox'>
                        <label className='send_message-input--checkbox--label'>Comment or Message</label>
                        <textarea
                        className='send_message-input--text--area'/>
                    </div>
                    <button className='submit--button'>Submit</button>
                </div>
            </div>
        </div>
    </Content>
  )
}

export default Index