import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Content from '../../../components/Content/Content';
import Button from '../../../components/Inputs/Button';
import { HashLink } from 'react-router-hash-link';

import './HomeScreen.css';
import PaymentModal from './PaymentModal';
import AccountModal from './AccountDetail';
import BillingModal from './BillingDetails';
import AgentMessageAlertModal from './AgentMessageAlert';
import axios from 'axios';
import { ADMIN_USERS, PAYMENT_BASE_URL } from '../../../redux/actions/ip';
import { useSelector } from 'react-redux';
import { addContent, getPageContent, imageUpload } from '../../../redux/actions/contentAction';

import EditHomeModal from './edit/EditHome'
import EditProductModal from './edit/EditProduct'
import EditAboutModal from './edit/EditAbout'
import EditServicesModal from './edit/EditServices'
import EditContactModal from './edit/EditContact'

const HomeScreen = ({ history }) => {
	const [checked, setChecked] = useState('checked1');
	const [paymentModal, setPaymentModal] = useState(false);
	const [accountModal, setAccountModal] = useState(false);
	const [billingModal, setBillingModal] = useState(false);
	const [paymentMessageModal, setPaymentMessageModal] = useState(false);
	const [accountDetails, setAccountDetails] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState('quick');

	const azureLogin = useSelector((state) => state.azureLogin);
	const [showEdit, setShowEdit] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [showProductModal, setProductModal] = useState(false)
	const [showAboutModal, setShowAboutModal] = useState(false)
	const [showServiceModal, setShowServiceModal] = useState(false)
	const [showContactModal, setShowContactModal] = useState(false)
	// const login = localStorage.getItem(az) ? JSON.parse(localStorage.getItem(az)) : null
	const [section1, setSection1] = useState({
		title: { value: 'SEASONS CHANGE. TIME CHANGES.', action: '', type: '' },
		image: { value: '/images/header-img.jpg', action: '', type: '' },
		subTitle: {
			value:
				'Your needs change.',
			action: '',
			type: '',
		},
		subTitle2: {
			value:
				'CONTACT YOUR LOCAL AGENT TODAY.',
			action: '',
			type: '',
		},
		tagLine: {
			value: 'We take pride in our client relationships.',
			action: '',
			type: '',
		},
		button1: {
			value: 'FIND YOUR LOCAL AGENT',
			action: '360/find-an-agent',
			type: '',
		},
		button2: {
			value: 'START A QUOTE',
			action: '360/start-a-quote',
			type: '',
		},
		button1Link: {
			value: '360/find-an-agent',
			action: '360/find-an-agent',
			type: '',
		},
		button2Link: {
			value: '360/start-a-quote',
			action: '360/start-a-quote',
			type: '',
		},
	})
	const [section2, setSection2] = useState({
		title: { value: 'Choose a product', action: '', type: '' },
		subTitle: { value: 'thats right for you', action: '', type: '' },
		productList: [
			{
				title: 'Farm & Ranch',
				action: '/360/farm-&-ranch-insurance',
				iconName: '/images/product-1.png',
			},
			{
				title: 'Auto',
				action: '/360/auto-insurance',
				iconName: '/images/product-4.png',
			},
			{
				title: 'Home',
				action: '/360/home-owners-insurance',
				iconName: '/images/product-3.png',
			},
			{
				title: 'Business',
				action: '/360/business-insurance',
				iconName: '/images/product-2.png',
			},
		],
	})
	const [section3, setSection3] = useState({
		image: { value: '/images/farm-insurance.png', action: '', type: '' },
		title: { value: 'About', action: '', type: '' },
		description: {
			value: `Whether you are a new home owner, or starting your business -
	  you can rely on our experience to help you cover what matters
	  most. <br /> Mountain West is a multi-line insurance company,
	  serving the needs of individuals, families, and businesses
	  throughout Wyoming and Montana for over 70 years. Our mission
	  is to provide financial peace of mind of our customers with
	  competitive, quality products and services.`,
			action: '',
			type: '',
		},
		button: { value: 'About Us', action: '/360/about-us', type: '' },
	})
	const [section4, setSection4] = useState({
		serviceImage: { value: '/images/fallen-tree2.jpg', action: '', type: '' },
		serviceTitle: { value: 'Claims Service', action: '', type: '' },
		serviceSubTitle: {
			value: `Taking care of our customers`,
			action: '',
			type: '',
		},
		serviceButton: { value: 'CLAIMS', action: '/360/about-us', type: '' },
		serviceButtonLink: { value: '/360/how-to-claim-file', action: '/360/about-us', type: '' },
		packageImage: { value: '/images/house-car.jpg', action: '', type: '' },
		packageTitle: { value: 'PACKAGE POLICIES', action: '', type: '' },
		packageSubTitle: {
			value: `Bundle & Save Auto + Home`,
			action: '',
			type: '',
		},
		packageButton: { value: 'DISCOUNTS', action: '/360/about-us', type: '' },
		packageButtonLink: { value: '/360/home-owners-insurance#home_discounts', action: '/360/about-us', type: '' },
	})
	const [section5, setSection5] = useState({
		title: { value: 'Nobody wants to be stranded', action: '', type: '' },
		image: { value: '/images/car-sunrise.jpg', action: '', type: '' },
		subTitle: {
			value: 'Thats why we have Emergency Roadside Service',
			action: '',
			type: '',
		},
		tagLine: { value: 'Need ERS? 1-888-292-3308', action: '', type: '' },
		button1: {
			value: 'AUTO INSURANCE',
			action: '360/find-an-agent',
			type: '',
		},
		button1Link: {
			value: '/360/auto-insurance#roadside_service',
			action: '360/find-an-agent',
			type: '',
		},
	})
	useEffect(
		() => {
			// console.log('Account', accountDetails);
			if (accountDetails) {
				setAccountModal(false)
				setBillingModal(true)
			}
		},
		[accountDetails]
	);
	const schedulePayment = async (data, callback) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			// const accountDetail = await axios.post(`http://72.167.37.148:3002/auth/get-token/${data.referenceNumber}/${data.policyHolderName}`, {}, config);
			// const accountDetail = await axios.post(`http://localhost:3002/auth/get-token/${data.referenceNumber}/${data.policyHolderName}/${data.minAmountDue}`, { data1: "hi", data }, config);
			const accountDetail = await axios.post(`${PAYMENT_BASE_URL}/auth/get-token/${data.referenceNumber}/${data.policyHolderName}/${data.minAmountDue}`, { data1: "hi", data }, config);
			console.log('==>>', accountDetail.data);
			if (accountDetail.data.status) {
				let container = window.$('#portalOneContainer');
				//instantiate modal
				container.portalOne();
				let portalOne = container.data('portalOne');
				portalOne.run({
					sessionId: accountDetail.data.sessionKey,
					accessTokenId: accountDetail.data.accessToken,
					displayMode: 'Modal',
					allowClosing: 'true'
				});
				container
					.addEventListener("portalOne.scheduledPaymentCreated", function () {
						console.log(new Date() + ' portalOne.scheduledPaymentCreated');
					});
				container
					.addEventListener("portalOne.scheduledPaymentCanceled", function () {
						console.log(new Date() + ' portalOne.scheduledPaymentCanceled');
					});
				// callback(true)
				return Promise.resolve(true)
			} else {
				// callback(false)
				return Promise.resolve(false)
			}
		} catch (error) {

			return Promise.reject(error)

		}
	}
	const quickPay = async (data) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json'
				}
			};
			const accountDetail = await axios.post(`${PAYMENT_BASE_URL}/auth/quick-pay-token/${data}`, { data1: "hi", accountNumber: data }, config);
			console.log('==>>', accountDetail.data);
			if (accountDetail.data.status) {
				// let container = window.$('#portalOneContainer');
				// container.portalOne();
				// let portalOne = container.data('portalOne');
				// portalOne.run({
				// 	sessionId: accountDetail.data.sessionKey,
				// 	accessTokenId: accountDetail.data.accessToken,
				// 	displayMode: 'Modal',
				// 	allowClosing: 'true'
				// });
				setPaymentMessageModal(true)
				// callback(true)
				return Promise.resolve(true)
			} else {
				// callback(false)
				if (accountDetail.data.redirect) {
					const win = window.open("https://ipn.paymentus.com/rotp/i360", '_blank');
					if (win != null) {
						win.focus();
					}
					return Promise.resolve(true)
				} else
					return Promise.resolve(false)
			}
		} catch (error) {

			return Promise.reject(error)

		}
	}

	const handleHeaderSubmit = (file) => {
		if (file) {
			imageUpload(file).then((res) => {
				if (res.data.status) {
					let temp = { ...section1 }
					if (!temp.image) temp.image = { value: '' }
					temp.image.value = res.data.path
					setSection1(temp)
					addContent({
						pageName: '360_Home',
						section: 'Section_1',
						content: temp,
					}).then((result) => {
						console.log(result.data)
						if (result.data.status) {
							alert('Content Saved')
						}
					})
				}
			})
		} else {
			addContent({
				pageName: '360_Home',
				section: 'Section_1',
				content: section1,
			}).then((result) => {
				if (result.data.status) {
					alert('Content Saved')
				}
			})
		}
		setShowModal(false)
	}
	const handleSection2Submit = () => {
		let temp = {
			...section2,
			productList: {
				value: JSON.stringify(section2.productList),
				type: 'json',
			},
		}
		addContent({
			pageName: '360_Home',
			section: 'Section_2_Update',
			content: temp,
		}).then((result) => {
			if (result.data.status) {
				alert('Content Saved')
			}
		})
		setProductModal(false)
	}
	const handleSection3Submit = (file) => {
		if (file) {
			imageUpload(file).then((res) => {
				if (res.data.status) {
					let temp = { ...section3 }
					if (!temp.image) temp.image = { value: '' }
					temp.image.value = res.data.path
					setSection3(temp)
					addContent({
						pageName: '360_Home',
						section: 'Section_3',
						content: temp,
					}).then((result) => {
						console.log(result.data)
						if (result.data.status) {
							alert('Content Saved')
						}
					})
				}
			})
		} else {
			addContent({
				pageName: '360_Home',
				section: 'Section_3',
				content: section3,
			}).then((result) => {
				console.log(result.data)
				if (result.data.status) {
					alert('Content Saved')
				}
			})
		}
		setShowAboutModal(false)
	}
	const submit4Async = async (file, claim) => {
		let serviceFile = null,
			claimFile = null
		try {
			if (file) {
				let data = await imageUpload(file)
				if (data && data.data && data.data.status) serviceFile = data.data.path
			}
			if (claim) {
				let data = await imageUpload(claim)
				if (data && data.data && data.data.status) claimFile = data.data.path
			}
			let temp = { ...section4 }
			if (serviceFile) {
				temp.serviceImage.value = serviceFile
			}
			if (claimFile) {
				temp.packageImage.value = claimFile
			}
			setSection4(temp)
			addContent({
				pageName: '360_Home',
				section: 'Section_4',
				content: temp,
			}).then((result) => {
				setShowServiceModal(false)
				if (result.data.status) {
					alert('Content Saved')
				}
			})
		} catch (error) { }
	}
	const handleSection4Submit = (file, packageImage) => {
		submit4Async(file, packageImage).then((res) => { })
	}
	const handleSection5Submit = (file) => {
		if (file) {
			imageUpload(file).then((res) => {
				if (res.data.status) {
					let temp = { ...section4 }
					if (!temp.image) temp.image = { value: '' }
					temp.image.value = res.data.path
					setSection5(temp)
					addContent({
						pageName: '360_Home',
						section: 'Section_5',
						content: temp,
					}).then((result) => {
						console.log(result.data)
						if (result.data.status) {
							alert('Content Saved')
						}
					})
				}
			})
		} else {
			addContent({
				pageName: '360_Home',
				section: 'Section_5',
				content: section5,
			}).then((result) => {
				console.log(result.data)
				if (result.data.status) {
					alert('Content Saved')
				}
			})
		}

		setShowContactModal(false)
	}
	useEffect(() => {
		if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
			if (
				ADMIN_USERS.includes(azureLogin.userInfo.account.username)
			) {
				setShowEdit(true)
			} else setShowEdit(false)
		} else setShowEdit(false)
	}, [azureLogin])
	useEffect(() => {
		getPageContent({ pageName: '360_Home' }).then((res) => {
			if (res.data.status) {
				if (res.data.result.Section_1) setSection1(res.data.result.Section_1)
				if (res.data.result.Section_2_Update) {
					let s2 = res.data.result.Section_2_Update
					s2.productList = JSON.parse(s2.productList.value)
					setSection2(s2)
				}
				if (res.data.result.Section_3) setSection3(res.data.result.Section_3)
				if (res.data.result.Section_4) setSection4(res.data.result.Section_4)
				if (res.data.result.Section_5) setSection5(res.data.result.Section_5)
			}
		})
	}, [])


	return (
		<Content headerFooterMenu="mountain-west">
			<div
				className="mw_slider"
				style={{
					// backgroundImage: 'url(/images/Home-Page.jpg) ',
					// backgroundImage: "linear-gradient(rgba(47,35,98,0.4), rgba(47,35,98,0.4)),url(/images/Home-Page.jpg)"
					backgroundImage: `linear-gradient(rgba(47,35,98,0.4), rgba(47,35,98,0.4)),url(${section1.image ? section1.image.value : '/images/Home-Page.jpg'}`
					// backgroundPosition: "10% 65%"
				}}
			>
				<div className="mw_slider__left">
					{/* <h3 className="mw_slider__subheading">
						SEASON CHANGE. TIME CHANGE.
					</h3>
					<h1 className="mw_slider__heading">Your needs change.</h1>
					<h3 className="mw_slider__subheading">
						CONTACT YOU LOCAL AGENT TODAY.
					</h3> */}
				</div>
				<div className="mw_slider__right">
					<h3 className="mw_slider__subheading">
						{section1.title.value}
					</h3>
					<h1 className="mw_slider__heading">{section1.subTitle.value}</h1>
					<h3 className="mw_slider__subheading">
						{section1.subTitle2.value}
					</h3>
					<Link to={section1.button1Link.value} className="mw_button" style={{ width: '26rem', textAlign: 'center' }}>
						{section1.button1.value}
					</Link>
				</div>
				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						id='admin-edit'
						onClick={() => setShowModal(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
			</div>

			<div className="quote">
				<div className="flex">
					<img src="/images/sponsers/sponsers.png" alt="Hand Shake" className="quote__image" />
					<h3 className="quote__text">{section1.tagLine.value}</h3>
				</div>

				<div className="header_buttons">
					<Link to={section1.button2Link.value} className="mw_button">
						{section1.button2.value}
					</Link>

				</div>

				{/* <PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} /> */}
			</div>

			<div className="mw_products edit-feature">
				<div className="mw_products__left">
					<h3 className="mw_products__heading">
						{/* Choose a product <span> that's right for you.</span> */}
						{section2.title.value} <span> {section2.subTitle.value}</span>
					</h3>
					<br />
					{/* <div className="hide_button">
						<Button label="view all products" varient="red" icon="/sprite.svg#icon-search" />
					</div> */}
					{/* <Link to='/' className='mw_button'>
						View All Products
					</Link> */}
				</div>
				<div className="mw_products__right">
					{section2.productList &&
						section2.productList.map((item) => (
							<Link to={item.action} className='mw_products__item'>
								<img
									src={item.iconName}
									alt={item.iconName}
									className='mw_products__item--image'
								/>
								<p className='mw_products__item--name'>{item.title}</p>
							</Link>
						))}
					{/* <Link to="/360/farm-&-ranch-insurance" className="mw_products__item">
						<img src="/images/product-1.png" alt="Product 1" className="mw_products__item--image" />
						<p className="mw_products__item--name">Farm & Ranch</p>
					</Link>
					<Link to="/360/auto-insurance" className="mw_products__item">
						<img src="/images/product-4.png" alt="Product 2" className="mw_products__item--image" />
						<p className="mw_products__item--name">Auto</p>
					</Link>
					<Link to="/360/home-owners-insurance" className="mw_products__item">
						<img src="/images/product-3.png" alt="Product 3" className="mw_products__item--image" />
						<p className="mw_products__item--name">Home</p>
					</Link>
					<Link to="/360/business-insurance" className="mw_products__item">
						<img src="/images/product-2.png" alt="Product 4" className="mw_products__item--image" />
						<p className="mw_products__item--name">Business</p>
					</Link> */}
				</div>
				{showEdit && (
					<div
						className='mwfbi_banner__edit2'
						onClick={() => setProductModal(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
				<div className="hide_button1">
					<Button label="view all products" varient="red" icon="/sprite.svg#icon-search" />
				</div>

			</div>

			<div className="mw_services">
				<PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} />
				<AgentMessageAlertModal setPaymentMessageModal={setPaymentMessageModal} paymentMessageModal={paymentMessageModal} />

				<AccountModal onQuickPayment={quickPay} setPaymentModal={setAccountModal} paymentMethod={paymentMethod} setAccountDetails={setAccountDetails} paymentModal={accountModal} onAccountDetails={(data) => {
					setAccountDetails(data);

				}} />
			</div>
			<BillingModal setPaymentModal={setBillingModal} paymentModal={billingModal} accountDetail={accountDetails} schedulePayment={schedulePayment} />
			<div id="portalOneContainer" key="portalOneContainer" />
			<div className="mw_services edit-feature">
				<div className="mw_services__item">
					<div className="mw_services__item--info">
						<div>
							<div className="mw_services__item--name">  {section4.serviceTitle.value}</div>
							<p className="mw_services__item--data"> {section4.serviceSubTitle.value}</p>
						</div>
						<Link to={section4.serviceButtonLink.value} className="mw_button_small">
							{section4.serviceButton.value}
						</Link>
					</div>
					<img src={section4.serviceImage.value} alt="Calims Service" className="mw_services__item--image" />
				</div>
				<div className="mw_services__item">
					<div className="mw_services__item--info">
						<div>
							<div className="mw_services__item--name"> {section4.packageTitle.value}</div>
							<p className="mw_services__item--data">{section4.packageSubTitle.value}</p>
						</div>
						<HashLink smooth to={section4.packageButtonLink.value} className="mw_button_small">
							{section4.packageButton.value}
						</HashLink>
					</div>
					<img src={section4.packageImage.value} alt="Package Policies" className="mw_services__item--image" />
				</div>
				{showEdit && (
					<div
						className='mwfbi_banner__edit2'
						onClick={() => setShowServiceModal(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
			</div>

			<div
				className="contact_us edit-feature"
				style={{
					backgroundImage: `url(${section5.image.value}) `
				}}
			>
				<div className="contact_us__box ">
					<h4 className="contact_us__heading">{section5.title.value}</h4>
					<p className="contact_us__subheading">{section5.subTitle.value}</p>
					<p className="contact_us__tag">{section5.tagLine.value}</p>
					{/* <Button label="view all products" varient="red" icon="/sprite.svg#icon-search" /> */}
					<div className="mw_button2">
						<HashLink smooth to={section5.button1Link.value} className="mw_button">
							{section5.button1.value}
						</HashLink>
					</div>

					{/* <Link to="/" className="mw_button">
						Auto Insurance
					</Link> */}
					{showEdit && (
						<div
							className='mwfbi_banner__edit2'
							onClick={() => setShowContactModal(true)}>
							<svg>
								<use xlinkHref='/sprite.svg#icon-edit' />
							</svg>
						</div>
					)}
				</div>
			</div>
			<EditHomeModal
				showModal={showModal}
				setShowModal={setShowModal}
				handleYes={handleHeaderSubmit}
				section1={section1}
				setSection1={setSection1}
			/>
			<EditProductModal
				showModal={showProductModal}
				setShowModal={setProductModal}
				handleYes={handleSection2Submit}
				section2={section2}
				setSection2={setSection2}
			/>
			<EditAboutModal
				showModal={showAboutModal}
				setShowModal={setShowAboutModal}
				handleYes={handleSection3Submit}
				section3={section3}
				setSection3={setSection3}
			/>
			<EditServicesModal
				showModal={showServiceModal}
				setShowModal={setShowServiceModal}
				handleYes={handleSection4Submit}
				section4={section4}
				setSection4={setSection4}
			/>
			<EditContactModal
				showModal={showContactModal}
				setShowModal={setShowContactModal}
				handleYes={handleSection5Submit}
				section5={section5}
				setSection5={setSection5}
			/>
		</Content >
	);
};

export default HomeScreen;
