import React, { useEffect, useState } from "react";
import styles from "./PdfCard.module.css";
import { Link } from "react-router-dom";
import axios from "axios";

const GenricPdfCard = ({ title, pdfPath }) => {
  const [pdfData, setPdfData] = useState('');

  useEffect(() => {
    if (pdfPath) {
      convertPdfToBase64(pdfPath)
    }
  }, [pdfPath])

  const convertPdfToBase64 = (url) => {
    axios.get(url, { responseType: "blob" })
      .then(function (response) {

        var baseFile = new Blob([response.data], { type: 'application/pdf' })
        setPdfData(URL.createObjectURL(baseFile));
      });
  }
  return (
    <div style={{paddingBottom: '2rem', justifyContent: 'center'}}>
        <div style={{justifyContent: 'center'}}>
        <label style={{ fontSize: '16px' }} className={styles.title}>{title}</label>
        <iframe onClick={() => console.log('hi')} style={{ overflow: 'hidden' }} draggable='true' title="pdfimage" src={pdfData} height="350" width="320" ></iframe>
    </div>
    </div>
  );
};

export default GenricPdfCard;
