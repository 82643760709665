import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';

const GoogleMap = ({ defaultProps, setGoogleMapRef, children }) => {

    const [cordinates, setCordinates] = useState(null)
    useEffect(() => {
        setCordinates(defaultProps.center);
    }, [defaultProps.center])
    return (
        <GoogleMapReact
            defaultCenter={cordinates}
            center={cordinates}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => setGoogleMapRef(map, maps)}
            options={{
                mapTypeControl: false,
                streetViewControl: false,
                mapTypeId: 'roadmap',
            }}
        >
            {children}
        </GoogleMapReact>
    );
};

export default GoogleMap;
