import React, { useEffect, useState } from 'react';
import './Banner.css';
import ShowModal from './edit/EditGeniricBanner'

const Banner = ({ backgroundImage, title, subTitle, subTitleto, pageName, bannerData, setBannerData, showEdit = false }) => {

	const [showModal, setShowModal] = useState(false);
	const handleSubmit = () => {

	}
	useEffect(() => {
		let edit = document.getElementById("admin-edit");
		if (edit) {
			edit.addEventListener("mouseover", function (event) {
				let edit = document.getElementById("carrier-banner");
				edit.style.border = "1px solid red";
			}, false);
			edit.addEventListener("mouseleave", function (event) {
				let edit = document.getElementById("carrier-banner");
				edit.style.border = "";
			}, false);
		}
	}, [])
	return (
		<div
			id="carrier-banner"
			className="mwfbi_banner main-content-padding "
			style={{
				backgroundImage: `${bannerData ? `linear-gradient(rgba(47,35,98,0.4), rgba(47,35,98,0.4)),url(${bannerData.imageUrl.value})` : backgroundImage}`,
				width: '100%',
				position: 'relative'
			}}
		>
			<h3 className="mwfbi_banner__heading heading_1 edit-effect">{bannerData ? bannerData.title.value : title}</h3>
			<h1 className="mwfbi_banner__text">{bannerData ? bannerData.subTitle.value : subTitle}</h1>
			<h1 className="mwfbi_banner__text">{subTitleto}</h1>
			{/* <div className="mwfbi_banner__edit" id="admin-edit" onClick={() => setShowModal(true)}>
				<svg>
					<use xlinkHref="/sprite.svg#icon-edit" />
				</svg>
			</div> */}
			<ShowModal
				showModal={showModal}
				setShowModal={setShowModal}
				handleYes={handleSubmit}
				bannerData={bannerData}
				setBannerData={setBannerData}
				pageName={pageName}
			></ShowModal>
			{showEdit && bannerData && (
				<div
					className='mwfbi_banner__edit'
					style={{ top: '1rem' }}
					id='admin-edit'
					onClick={() => setShowModal(true)}>
					<svg>
						<use xlinkHref='/sprite.svg#icon-edit' />
					</svg>
				</div>
			)}
		</div>
	);
};

export default Banner;
