import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import ReactPaginate from 'react-paginate'
import {
  deleteContentItem,
  imageUpload,
  addContentItem,
} from '../../../../redux/actions/contentAction'

const itemsPerPage = 10

const InsurancePropertiesModel = ({
  setShowModal,
  showModal,
  handleYes,
  section7,
  setSection7,
}) => {
  const [loading, setLoading] = useState(false)

  const [title, setTitle] = useState({ value: '', error: '' })

  const [editIndex, setEditIndex] = useState(-1)
  const [editData, setEditData] = useState('')

  const handleOnSubmit = () => {
    if (title.value == '') {
      setTitle({ ...title, error: 'Please enter title!' })
      return
    }

    clearErrors()
    let content = title.value
    setLoading(true)

    if (editIndex > -1) {
      const temp = [...section7]
      temp[editIndex] = content
      setSection7(temp)
      handleYes(temp)

      clear()
      // setShowModal(false)
    } else {
      setSection7([...section7, content])
      handleYes([...section7, content])
      clear()
      // setShowModal(false)
    }
    setLoading(false)
  }

  const onEdit = (item, index) => {
    // e.preventDefault();
    // setLoading(true);
    setEditData(item)
    setEditIndex(index)
    setTitle({ ...title, value: item })
  }
  const clear = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: '', error: '' },
    })
    setTitle({ value: '', error: '' })
    setEditIndex(-1)
    // setShowModal(false)
    // handleOnSubmit()
  }

  const closeModal = () => {
    resetAll()
    setShowModal(false)
  }
  const resetAll = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: '', error: '' },
    })
    setEditIndex(-1)
    setTitle({ ...title, value: '', error: '' })
  }
  const clearErrors = () => {
    setTitle({ ...title, error: '' })
  }

  const onItemDelete = (item, index) => {
    if (window.confirm('Are You Sure to Delete?')) {
      const temp = [...section7]
      temp.splice(index, 1)
      setSection7(temp)
      handleYes(temp)
    }
  }
  return (
    <Modal show={showModal}>
      <form onSubmit={handleOnSubmit}>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Personal Property</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => closeModal()}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '50%' }}
                    required={true}
                    value={title.value}
                    onChange={(e) => {
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={title.error}
                  />
                </div>

                <div
                  className='modal_dark_buttons'
                  style={{ padding: '0.5rem' }}>
                  <button
                    type='button'
                    className='modal__button-reset-dark'
                    onClick={resetAll}>
                    Reset
                  </button>
                  <button
                    type='button'
                    className='modal__button-dark'
                    onClick={handleOnSubmit}>
                    {loading ? 'Saving..' : editIndex > -1 ? 'Save' : 'Add'}
                  </button>
                </div>
              </div>
            </div>
            <div className='modal_inner_content'>
              <div className='modal_form_container'>
                <table
                  className='modal__form-table'
                  cellspacing='0'
                  cellpadding='0'>
                  <thead className='modal__form-sticky'>
                    <tr className='modal__form-tr'>
                      <th>No.</th>
                      <th>Property Name</th>
                      <th className='modal__form-buttons'>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {section7 &&
                      section7.length &&
                      section7.map((item, i) => {
                        return (
                          <tr className='modal__form-tr2' key={i}>
                            <td>{i + 1}</td>
                            <td>{item}</td>
                            <td className='modal__form-buttons'>
                              <button
                                type='button'
                                className='modal__button-edit'
                                onClick={(e) => onEdit(item, i)}>
                                edit
                              </button>
                              <button
                                type='button'
                                className='modal__button-delete'
                                onClick={(e) => onItemDelete(item, i)}>
                                delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default InsurancePropertiesModel
