import React, { useState, useCallback } from "react";
import Modal from "../../../DarkModal";

import Styles from '../Section1/EditFirstSection.module.css';

let timeout;
const EditSecondSection = ({ pointList = [], onSave, setPointList, showSection1Modal, close, onEdit }) => {

    const reposition = (index, item, direction) => {
        const tempArr = pointList.filter(
            (element, position) => index !== position
        );
        if (direction === "up" && index > 0) {
            tempArr.splice(index - 1, 0, item);
            clearTimeout(timeout);
        } else if (direction === "down" && index < pointList.length) {
            tempArr.splice(index + 1, 0, item);
            clearTimeout(timeout);
        } else return;
        setPointList(tempArr);
        timeout = setTimeout(() => {
            onSave(tempArr);
        }, 2000);
    }

    return (
        <Modal show={showSection1Modal}>
            <form>
                <div className="modal__container-dark">
                    <div className="modal_heading__container-dark">
                        <h1 className="modal_heading__text-dark">
                            Points
                        </h1>
                        <button
                            className="modal__heading--button-dark"
                            onClick={() => close()}
                        >
                            <svg className="modal_heading__icon-dark">
                                <use xlinkHref="/sprite.svg#icon-cross" />
                            </svg>
                        </button>
                    </div>
                    <div className={"modal__form-dark"} style={{ display: 'flex' }}>
                        <div className="modal_inner_content">
                            <div className={Styles.modalContainer} style={{ height: '30rem' }}>

                                {pointList && <div className='modal_form_container' style={{ height: '30rem' }}>
                                    <table
                                        className='modal__form-table'
                                        cellSpacing='0'
                                        cellPadding='0'>
                                        <thead className='modal__form-sticky'>
                                            <tr className='modal__form-tr'>
                                                <th>No.</th>
                                                <th>Title</th>
                                                <th>Sort</th>
                                                <th style={{ textAlign: 'end', paddingRight: '5rem' }}>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {pointList.map((item, i) => {
                                                return (
                                                    <tr
                                                        style={{ height: '60px' }}
                                                        className='modal__form-tr2'
                                                        key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{item.title}</td>
                                                        <td>
                                                            <button type="button" onClick={(e) => reposition(i, item, 'up')}>
                                                                <svg className="modal_heading__icon-dark">
                                                                    <use xlinkHref="/sprite.svg#icon-up" />
                                                                </svg>
                                                            </button>
                                                            &nbsp;
                                                            <button type="button" onClick={(e) => reposition(i, item, 'down')}>
                                                                <svg className="modal_heading__icon-dark">
                                                                    <use xlinkHref="/sprite.svg#icon-down" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                        <td
                                                            style={{ marginTop: '12px' }}
                                                            className='modal__form-buttons'>
                                                            <button
                                                                type='button'
                                                                className='modal__button-edit'
                                                                onClick={(e) => onEdit(item, i)}>
                                                                edit
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default EditSecondSection;