import React, { useState } from "react";
import styles from "./ImageCard.module.css";
import CryptoJS from 'crypto-js';
import { useSelector } from "react-redux";

const ImageCard = ({ title, imagePath, link = "#", hasToken = false }) => {

  const azureLogin = useSelector(state => state.azureLogin);
  const generateLoginToken = () => {
    // Mountain West Farm Bureau
    // Frontline Cyber Solutions
    const token = CryptoJS.AES.encrypt(
      JSON.stringify({ clientName: 'Mountain West Farm Bureau', email: azureLogin && azureLogin.userInfo && azureLogin.userInfo.account ? azureLogin.userInfo.account.username : '' }),
      process.env.REACT_APP_SECURE_KEY
    ).toString();

    return token;
  };
  return (
    <div style={{ paddingBottom: '5rem', justifyContent: 'center' }}>
      <label style={{ fontSize: '20px', paddingBottom: '2rem', textAlign: 'center' }} className={styles.title}>{title}</label>
      <div style={{ justifyContent: 'center', display: 'flex' }}>
        {link ? <a href={`${link}${hasToken ? '?token=' + generateLoginToken() : ''}`} target="_blank" rel="noreferrer">
          <img style={{ width: '140px', height: '140px', justifyContent: 'center', display: 'block' }} src={imagePath} alt={title} />
        </a> :
          <img style={{ width: '140px', height: '140px', justifyContent: 'center', display: 'block' }} src={imagePath} alt={title} />

        }
      </div>
    </div>
  );
};

export default ImageCard;
