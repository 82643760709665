import React, { useEffect, useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./PointAddForm.module.css";
import Modal from "../../../DarkModal";
import FormInputComponent from "../../../FormInputComponent/FormInputComponent";
const PointAddForm = ({
  onPointSave,
  showModal,
  setShowModal,
  onClose,
  points
}) => {
  const [title, setTitle] = useState({ value: '', error: '' });
  const [sectionType, setSectionType] = useState("Link");

  return (
    <Modal show={showModal}>
      <div className="modal__container-dark">
        <div className="modal_heading__container-dark">
          <h1 className="modal_heading__text-dark">
            Edit Points
          </h1>
          <button
            className="modal__heading--button-dark"
            onClick={onClose}
          >
            <svg className="modal_heading__icon-dark">
              <use xlinkHref="/sprite.svg#icon-cross" />
            </svg>
          </button>
        </div>
        <div className={"modal__form-dark"} style={{ display: 'flex' }}>
          <div className="modal_inner_content">
            <div className={styles.mainContainer}>

              <div className="leftConatiner" style={{ width: '100%' }}>
                <div className={'inline-form-view' + " " + styles.mainTitle}>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '30%' }}
                    required={true}
                    value={title.value}
                    onChange={(e) => {
                      setTitle({ error: '', value: e.target.value });
                    }}
                    errorMessage={title.error}
                  />
                </div>
                <div className="select_state" >
                  <label style={{ fontSize: "1.4rem" }} className="label_heading_quote">
                    Select Option type:   <span className="red_star">&#42;</span>
                  </label>
                  <br />
                  <select
                    id="selectstate"
                    name="selectstate"
                    className="landing_selectbox select__state__textbox"
                    style={{ width: '30%' }}
                    onChange={(e) => setSectionType(e.target.value)}
                    value={sectionType}
                  >
                    <option
                      value={'Link'}
                      id="Link">
                      Link
                    </option>
                    <option
                      value={'Pdf'}
                      id="Pdf">
                      PDF
                    </option>
                    <option
                      value={'Image'}
                      id="Image">
                      Image
                    </option>
                  </select>{" "}
                  <br />
                  {/* <p className="form_input__error">{'selectAgentError'}</p> */}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                  <button
                    className='modal__button-reset-dark'
                    type='button'
                    onClick={() => {
                      setTitle({ value: "", error: "" })
                      setSectionType({ value: "Link", error: "" })
                    }}>
                    Reset
                  </button>
                  <button
                    className='modal__button-dark'
                    type='button'
                    onClick={() => {
                      if (title.value === "") {
                        setTitle({ ...title, error: "Please enter title!" })
                        return
                      }
                      if (points.find(item => item.title.toLowerCase() === title.value.toLowerCase())) {
                        setTitle({ ...title, error: "Title already present." })
                        return
                      }
                      onPointSave({ title: title.value, type: sectionType, points: [] })
                      setTitle({ value: "", error: "" })
                      setSectionType({ value: "Link", error: "" })
                    }}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal >
  );
};

export default PointAddForm;
