import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HtmlParser from "html-react-parser";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import Banner from "../../../components/Banner/Banner";
import Content from "../../../components/Content/Content";
import Button from "../../../components/Inputs/Button";
import {
  addContent,
  getPageContent,
  updateFieldOrder,
} from "../../../redux/actions/contentAction";
import { ADMIN_USERS } from "../../../redux/actions/ip";

import "./AboutUs.css";
import EditInsuranceInfo from "./edit/EditInsuranceInfo";
import EditInsuranceMessage from "./edit/EditInsuranceMessage";
import EditTimeline from "./edit/EditTimeline";
import EditTimelineInfo from "./edit/EditTimelineInfo";
const AboutUs = ({ history }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showTimelineModal, setShowTimelineModal] = useState(false);
  const [showTimelineInfoModal, setShowTimelineInfoModal] = useState(false);
  const [showInsuranceMessageModal, setShowInsuranceMessageModal] =
    useState(false);
  const azureLogin = useSelector((state) => state.azureLogin);
  const [section1, setSection1] = useState({
    title: {
      value: "LOCAL INSURANCE FOR LOCAL FAMILIES",
      action: "",
      type: "",
    },
    subTitle: { value: "What We Value", action: "", type: "" },
    imageUrl: { value: "/images/sponsers/sponsers.png" },
    button1label: { value: "Find Your Local Agent" },
    button1link: { value: "/mwfbi/find-an-agent" },
    button2label: { value: "Community Involvement" },
    button2link: { value: "/mwfbi/about/our-community" },
  });
  const [section3, setSection3] = useState({
    title: {
      value:
        "Our mission is to provide financial peace of mind for our customers with competative, quality products and services.",
      action: "",
      type: "",
    },
  });
  const [section4, setSection4] = useState({
    title: {
      value: "OUR HISTORY",
      action: "",
      type: "",
    },
    subtitle: {
      value:
        "From the very begining, our company has its roots in Western soil and Mountain-West region",
      action: "",
      type: "",
    },
  });
  const [bannerData, setBannerData] = useState({
    title: { value: "About Mountain West Farm Bureau", error: "" },
    subTitle: {
      value:
        "a multi-line insurance company, serving the needs of individuals, families,",
      error: "",
    },
    tagline: {
      value: "WE TAKE PRIDE IN OUR CLIENT RELATIONSHIPS",
      error: "",
    },
    button1: { value: "Find Your Agent", error: "" },
    button1Link: { value: "/images/farm-insurance.png", error: "" },
    button1Status: { value: true, error: "" },
    button2: { value: "", error: "" },
    button2Link: { value: "", error: "" },
    button2Status: { value: false, error: "" },
    imageUrl: { value: "/images/start-a-quote.png", error: "" },
  });
  const [contractList, setContractList] = useState([
    {
      year: "1948",
      title: "Company Founded",
      subTitle:
        "Agricultural Insurance Company of Wyoming and agricultural Indemnity Company of Wyoming are organized to provide Wyoming Farm Bureau members with insurance.",
    },
    {
      title: "Farm & Ranch Coverage Added",
      year: "1952",
      subTitle:
        "Ranchers and farmers coverage is added to the service program.",
    },
    {
      title: "Expansion Continues",
      year: "1954",
      subTitle:
        "As expansion continues, fire insurance is made available and the company moves to a larger facility.",
    },
    {
      title: "Wyoming & Montana Partner Up",
      year: "1958",
      subTitle:
        "Wyoming Farm Bureau Mutual Insurance Company and the Montana Farm Bureau Federation form a partnership.",
    },
    {
      title: "Company Name Changes",
      year: "1973",
      subTitle: `The companies name is changed to the current Mountain West Farm Bureau Mutual Insurance Company to better reflect the Wyoming / Montana partnership.`,
    },
    {
      title: "Company Brand Changes",
      year: "1987",
      subTitle: `Mountain West adopts the new national logo of the American Farm Bureau Federation.`,
    },
    {
      title: "Building Relocates",
      year: "2003",
      subTitle: `Wyoming Farm Bureau Federation and Mountain West relocate to the current building at 931 Boulder Drive.`,
    },
    {
      title: "Founded New Company",
      year: "2011",
      subTitle: `360 Insurance Company is founded as a wholly owned subsidiary of Mountain West Farm Bureau Mutual Insurance Company and is located in Western Colorado.`,
    },
  ]);
  const [pointList, setPointsList] = useState([
    {
      point: { value: "Our Stewardship", action: "", type: "" },
      para: {
        value:
          "We take to heart our responsibility to provide protection of what our clients’ value.",
        action: "",
        type: "",
      },
    },
    {
      point: { value: "Our Relationships", action: "", type: "" },
      para: {
        value:
          "We keep the promises we make. Integrity, professionalism, respect and personal accountability is ingrained in our company fabric.",
        action: "",
        type: "",
      },
    },
    {
      point: { value: "Our Action", action: "", type: "" },
      para: {
        value:
          "We take pride in our work. We challenge ourselves to personal growth, to be proactive and continually improve on the delivery of our products and services.",
        action: "",
        type: "",
      },
    },
  ]);

  const HistoryCard = ({ data, index, length }) => {
    const className = `history-card  ${index !== length - 1 && index % 2 === 0
      ? "card-left"
      : index === length - 1
        ? index % 2 === 0
          ? "card-left-end"
          : "card-right-end"
        : "card-right"
      }`;
    return (
      <div className={className}>
        {index % 2 === 0 && (
          <div className="history-year-left">{data.year}</div>
        )}
        {index % 2 === 0 && (
          <div className="about-inner-card-content-left">
            <label className="title heading_2">{data.title}</label>
            <label className="desc heading_3">
              {HtmlParser(data.subTitle)}
            </label>
          </div>
        )}
        {index % 2 !== 0 && (
          <div className="about-inner-card-content-right">
            <label className="title heading_2">{data.title}</label>
            <label className="desc heading_3">
              {HtmlParser(data.subTitle)}
            </label>
          </div>
        )}
        {index % 2 !== 0 && (
          <div className="history-year-right">{data.year}</div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true);
      } else setShowEdit(false);
    } else setShowEdit(false);
  }, [azureLogin]);

  useEffect(() => {
    getPageContent({ pageName: "AboutUs" }).then((res) => {
      if (res.data.status) {
        if (res.data.result.Section_3) setSection3(res.data.result.Section_3);
        if (res.data.result.Section_4) setSection4(res.data.result.Section_4);
        if (res.data.result.TopBanner)
          setBannerData({
            ...res.data.result.TopBanner,
            button1Status: {
              value: res.data.result.TopBanner.button1Status.value === "true",
            },
            button2Status: {
              value: res.data.result.TopBanner.button2Status.value === "true",
            },
          });
        if (res.data.result.Section_5_v6) {
          setContractList(JSON.parse(res.data.result.Section_5_v6.list.value));
        }
        if (res?.data?.result?.Section_2_v2) {
          setPointsList(JSON.parse(res.data.result.Section_2_v2.list.value));
        }
        if (res?.data?.result?.Section_1) {
          setSection1(JSON.parse(res.data.result.Section_1.list.value));
        }
      }
    });
  }, []);

  const handleMessageSubmit = (content) => {
    addContent({
      pageName: "AboutUs",
      section: "Section_3",
      content: section3,
    }).then((result) => {
      if (result.data.status) {
        setSection3(content)
        alert("Content Saved");
      }
    });
  };

  const handleSection1Submit = (content) => {
    addContent({
      pageName: "AboutUs",
      section: "Section_1",
      content: {
        list: { value: JSON.stringify(content) },
      },
    }).then((result) => {
      if (result.data.status) {
        setSection1(content)
        alert("Content Saved");
      }
    });
  };

  const handleSection2Submit = (content) => {
    addContent({
      pageName: "AboutUs",
      section: "Section_2_v2",
      content: {
        list: { value: JSON.stringify(content) },
      },
    }).then((result) => {
      if (result.data.status) {
        setPointsList(content);
        alert("Content Saved");
      }
    });
  };

  const handleTimelineSubmit = (content) => {
    addContent({
      pageName: "AboutUs",
      section: "Section_5_v6",
      content: {
        list: { value: JSON.stringify(content) },
      },
    }).then((result) => {
      if (result.data.status) {
        setContractList(content)
        alert("Content Saved");
      }
    });
  };

  const handleTimelineInfoSubmit = (content) => {
    addContent({
      pageName: "AboutUs",
      section: "Section_4",
      content: section4,
    }).then((result) => {
      if (result.data.status) {
        setSection4(content)
        alert("Content Saved");
      }
    });
  };

  return (
    <Content headerFooterMenu="mountain-west">
      <Banner
        backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/farm-insurance.png)"
        title="About Mountain West Farm Bureau"
        subTitle="a multi-line insurance company, serving the needs of individuals, families,"
        bannerData={bannerData}
        showEdit={showEdit}
        setBannerData={setBannerData}
        pageName={'AboutUs'}
      />
      {bannerData &&
        bannerData.button1 &&
        (bannerData.button1Status.value || bannerData.button2Status.value) &&
        (<div className='quote2'>
          <div className='flex2'>
            <img
              src={"/images/sponsers/sponsers.png"}
              alt="Hand Shake"
              className="quote__image"
            />
            <h3 className='quote__text2'>
              {bannerData && bannerData.tagline
                ? bannerData.tagline.value
                : "We take pride in our client relationships"}
            </h3>
          </div>
          <div className='header_buttons'>
            {bannerData &&
              bannerData.button1 &&
              bannerData.button1Status.value && (
                <Link to={bannerData.button1Link.value} className='mw_button'>
                  {bannerData.button1.value}
                </Link>
              )}
            {bannerData &&
              bannerData.button2 &&
              bannerData.button2.value &&
              bannerData.button2Status.value && (
                <Link to={bannerData.button2Link.value} className='mw_button'>
                  {bannerData.button2.value}
                </Link>
              )}
          </div>
        </div>)}
      <div
        className="our-mission-main"
        style={{ width: "100%", position: "relative" }}
      >
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "1rem" }}
            id="admin-edit"
            onClick={() => setShowInfoModal(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
        <EditInsuranceInfo
          section1={section1}
          pointsArr={pointList}
          setPointsArr={setPointsList}
          setSection1={setSection1}
          showModal={showInfoModal}
          setShowModal={setShowInfoModal}
          handleYes={handleSection1Submit}
          handleSection2Submit={handleSection2Submit}
        />
        <EditTimeline
          handleYes={handleTimelineSubmit}
          contractList={contractList}
          setContractList={setContractList}
          showModal={showTimelineModal}
          setShowModal={setShowTimelineModal}
        />
        <EditInsuranceMessage
          section1={section3}
          setSection1={setSection3}
          handleYes={handleMessageSubmit}
          showModal={showInsuranceMessageModal}
          setShowModal={setShowInsuranceMessageModal}
        />
        <EditTimelineInfo
          section1={section4}
          setSection1={setSection4}
          handleYes={handleTimelineInfoSubmit}
          showModal={showTimelineInfoModal}
          setShowModal={setShowTimelineInfoModal}
        />
        <p className="our-mission-main-heading">{section1?.title?.value}</p>
      </div>
      <div className="about-how-we-work-desc">
        <div className="homework_image">
          <img
            src={section1.imageUrl.value}
            className="about_howwework_image"
          />
        </div>
        <div className="about-how-we-work-desc-heading1">
          {section1?.subTitle?.value}
          {pointList &&
            pointList.length > 0 &&
            pointList.map((item, i) => (
              <p key={i} className="about-how-we-work-desc-heading2">
                <b className="subheading">{item.point.value}:</b>{" "}
                {item.para.value}
              </p>
            ))}
          <div className="mwfbi__buttons" style={{ marginTop: "3rem" }}>
            <Button
              label={section1.button1label.value}
              varient="red"
              icon="/sprite.svg#icon-user"
              onClick={() => history.push(section1.button1link.value)}
            />
            <Button
              label={section1.button2label.value}
              varient="black"
              icon="/sprite.svg#icon-search"
              onClick={() => history.push(section1.button2link.value)}
            />
          </div>
        </div>
      </div>
      <div
        className="history-main"
        style={{ width: "100%", position: "relative" }}
      >
        <div
          className="history_begining"
          style={{ width: "100%", position: "relative" }}
        >
          {showEdit && (
            <div
              className="mwfbi_banner__edit"
              style={{ top: "2rem" }}
              id="admin-edit"
              onClick={() => setShowInsuranceMessageModal(true)}
            >
              <svg>
                <use xlinkHref="/sprite.svg#icon-edit" />
              </svg>
            </div>
          )}

          <h3 className="heading_history">{section3.title.value}</h3>
        </div>
      </div>

      <div
        className="history-main1"
        style={{ width: "100%", position: "relative" }}
      >
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "3rem" }}
            id="admin-edit"
            onClick={() => setShowTimelineInfoModal(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "12rem" }}
            id="admin-edit"
            onClick={() => setShowTimelineModal(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
        <div className="history-content">
          <div className="head">
            <div className="label_heading">{section4.title.value}</div>
            <div className="label_heading_1">{section4.subtitle.value}</div>
          </div>

          <div className="history-card-list">
            {contractList.map((item, index) => (
              <HistoryCard
                key={index}
                data={item}
                length={contractList.length}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </Content>
  );
};

export default AboutUs;
