import React, { useState, useEffect } from 'react';
import Banner from '../../../components/Banner/Banner';
import Content from '../../../components/Content/Content';
import Button from '../../../components/Inputs/Button';
import PaymentModal from '../HomeScreen/PaymentModal';

import { Link } from 'react-router-dom';
import './Disclosure.css';

const Disclosure = () => {
	const [paymentModal, setPaymentModal] = useState(false);

	useEffect(() => {
		let acc = document.getElementsByClassName('accordion');

		acc[0].classList.toggle('active');
		acc[0].nextElementSibling.classList.toggle('show');

		for (let i = 0; i < acc.length; i++) {
			acc[i].onclick = function () {
				this.classList.toggle('active');
				this.nextElementSibling.classList.toggle('show');
			};
		}
	});
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);
	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/hat-and-rope.jpg)"
				title="COLORADO PRIVATE PASSENGER AUTOMOBILE INSURANCE"
			// subTitle="Look for answers to your questions form the list below."
			/>
			<div className="quote2">
				{/* <div className="flex2">
					<h3 className="quote__text2">Start your retirement solution planning with your local agent. </h3>
				</div>
				<div className="header_buttons">
					<Link to="/mwfbi/start-a-quote" className="mw_button">
						Start a Quote
					</Link>
					<Link to="/mwfbi/find-an-agent" className="mw_button">
						find an agent
					</Link>
				</div> */}
			</div>

			<div className="faqs">
				<div className="faq">
					<div>
						<p className="accordion">
							SUMMARY DISCLOSURE FORM
							{/* <svg className="accordion_icon">
								<use xlinkHref="/sprite.svg#icon-plus" />
							</svg> */}
						</p>
						<div className="panel">
							<p className="phase2_subheading">
								This summary disclosure form is a basic guide to the major coverages and exclusions in your policy. It is a general
								description. It is not a policy of any kind. All coverage is subject to the terms, conditions, and exclusions of your
								policy and all applicable endorsements.

							</p>
						</div>
						<PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} />
						{/* <Button label="Make Payment" icon="/sprite.svg#icon-arrow_right" /> */}
					</div>
				</div>
				<div className="faq">
					<div>
						<p className="accordion">PLEASE READ YOUR POLICY FOR COMPLETE DETAILS. THIS SUMMARYDISCLOSURE FORM SHALL NOT
							BE CONSTRUED TO REPLACE ANY PROVISION OF THE POLICY ITSELF.</p>
						<div className="panel">
							<p className="phase2_subheading">
								Complete details include, but are not limited to, information on the method we use to calculate your unearned
								premium (e.g., pro rata or short rate), if you should cancel your policy before the next renewal. This summary
								disclosure form also provides some of the factors considered for cancellation, nonrenewal and increase-inpremium. These factors are general in nature. They do not represent the only reasons a policy may be cancelled or
								changed. Please contact us or your agent for further information.<br /><br />
								Your policy excludes livery. If you are a driver for a transportation network company, please verify with that
								company that you have purchased the appropriate coverage through them.
							</p>
						</div>
					</div>
				</div>

				<div className="faq">
					<div>
						<p className="accordion">
							REQUIRED COVERAGE - Liability
						</p>
						<div className="panel">
							<p className="phase2_subheading">
								Colorado law requires you to have liability coverage on your automobile. This coverage pays bodily injury
								to another person and property damage to another’s property that are the result of an accident in which you
								are found to be at fault.<br /><br />
								Coverage is not provided for any automobile owned by you or a resident relative that is not insured for
								liability under your policy. There is no coverage for intentional acts.<br /><br />
								Please read your policy for other conditions and exclusions.
							</p>
						</div>
					</div>
				</div>
				<div className="faq">
					<div>
						<p className="accordion">
							OTHER COVERAGES
						</p>
						<div className="panel">
							<p className="phase2_subheading">
								<label className='faq_sub_title'>A.&emsp; Uninsured and Underinsured Motorist Coverage</label><br /><br />
								Uninsured and underinsured motorist coverage will be included in your policy unless you reject it in writing.<br /><br />
								Uninsured Motorist coverage pays for your bodily injury damages caused by an at-fault owner of an
								Uninsured Motorist automobile.<br /><br />
								Underinsured Motorist coverage pays for your bodily injury damages caused by an at-fault owner of an
								Underinsured Motorist automobile. A motorist is considered underinsured if his or her liability coverage is
								not enough to pay the full amount you are legally allowed to recover as damages.<br /><br />
								Please read your policy for other conditions and exclusions.<br /><br />
								<label className='faq_sub_title'>B.&emsp; Physical Damage Coverage – Collision and Comprehensive</label><br /><br />
								You must be offered collision coverage.<br /><br />
								Collision coverage pays for damage to your own automobile when it collides with another automobile or
								object. It also pays if your automobile overturns.<br /><br />
								Comprehensive coverage pays for damage to your automobile from causes such as fire, theft, vandalism,
								hail, and falling objects.<br /><br />
								Collision and comprehensive coverage may be written with a deductible. A deductible is that part of a loss
								you will pay. We will pay the balance of covered repairs subject to your policy provisions. A lender may
								require you purchase both collision and comprehensive coverage.<br /><br />
								We cannot provide coverage for autos with losses that occur while your automobile is rented or leased to
								others.<br /><br />
								Please read your policy for other conditions and exclusions.<br /><br />

								<label className='faq_sub_title'>C. &emsp;Medical Payments Coverage<br /><br /></label>
								Medical payments coverage of $5,000 will be included in your policy unless you reject it. You may reject
								the coverage in writing or in the same method in which you applied for the policy.<br /><br />
								Medical payments coverage is not required to be offered on motorcycles, low-powered scooters, off-road
								vehicles or other miscellaneous vehicles.<br /><br />
								Medical payments coverage pays for you and your passengers reasonable health care expenses incurred
								for bodily injury caused by an automobile accident.<br /><br />
								If you are in an automobile accident, your medical payments coverage will pay before your health
								insurance coverage.<br /><br />
								We must prioritize the payment of your benefits in a manner consistent with Colorado insurance law.<br /><br />
								Injuries to you or any passenger that are the result of any accident are not covered unless medical
								payments coverage is purchased.<br /><br />
								Please read your policy for other conditions and exclusions.<br /><br />

								<label className='faq_sub_title'>D. &emsp;Uninsured Motorist Property Damage Coverage</label><br /><br />
								This coverage pays for damages to your automobile caused by an at-fault owner of an uninsured
								automobile.<br /><br />
								This is an optional coverage you can request if you do not have collision coverage on your automobile.<br /><br />
								This coverage will not apply if the automobiles do not make physical contact.<br /><br />
								This coverage only pays actual cash value of your automobile or cost of repair or replacement, whichever
								is less.<br /><br />
								Please read your policy for other conditions and exclusions.
							</p>
						</div>
					</div>
				</div>
				<div className="faq">
					<div>
						<p className="accordion">
							CANCELLATION, NONRENEWAL AND INCREASE IN PREMIUM
						</p>
						<div className="panel">
							<p className="phase2_subheading">
								<label className='faq_sub_title'>	A. Cancellation</label><br /><br />
								During the first 59 days we may cancel your policy for any reason not prohibited by law. After your policy
								has been in effect for more than 59 days, we may cancel your policy for any of the following reasons:<br /><br />
								<ol style={{ marginLeft: '2rem' }}>
									<li>&emsp;Nonpayment of policy premium; or</li>
									<li>&emsp;Knowingly making a false statement on your application for automobile insurance; or</li>
									<li>&emsp;A driver’s license suspension or revocation; or</li>
									<li>&emsp;Knowingly and willfully making a false material statement on a claim under the policy.</li>
								</ol>
							</p>
							<p className="phase2_subheading">
								<label className='faq_sub_title'>	B. Nonrenewal</label><br /><br />
								We may choose to non-renew your policy. Some examples of reasons for nonrenewal include, but are not
								limited to:<br /><br />
								<ol style={{ marginLeft: '2rem' }}>
									<li>&emsp;An unacceptable number of traffic convictions;</li>
									<li>&emsp;An unacceptable number of at-fault accidents; or</li>
									<li>&emsp;Conviction of a major violation such as drunk driving or reckless driving.</li>
								</ol>
							</p>
							<p className="phase2_subheading">
								<label className='faq_sub_title'>	C. Increase in Premium</label><br /><br />
								We may increase your premiums. Some examples of reasons for increased premium include, but are not
								limited to:<br /><br />
								<ol style={{ marginLeft: '2rem' }}>
									<li>&emsp;Change of garage location of the automobile;</li>
									<li>&emsp;Change of automobile(s) insured;</li>
									<li>&emsp;Addition of a driver;</li>
									<li>&emsp;Change in use of your automobile;									</li>
									<li>&emsp;A general rate increase. This results from the loss experience of a large group of policyholders
										rather than from a single policyholder. A general rate increase applies to everyone in the group, not just
										those who had a loss.<br /><br />

									</li>
								</ol>
								The above list of reasons is not all inclusive. There may be other changes that result in an increased
								premium.<br /><br />
								<label className='faq_sub_title'>We may add a surcharge or remove a discount because of an at-fault accident or traffic conviction.
									Under this circumstance you will receive a notice of your statutory right to file a complaint with the
									Colorado Division of Insurance.</label><br /><br />

							</p>


						</div>
					</div>
				</div>

				<div className="faq">
					<div>
						<p className="accordion">
							PLEASE READ THE DISCLOSURE THAT APPLIES TO YOUR POLICY.
						</p>
						<div className="panel">
							<p className="phase2_subheading">
								<label className='faq_sub_title'>SUMMARY OF COVERAGE
									PROPERTY POLICY</label>
							</p>
							<hr className='hr_height' />
							<label className='faq_sub_title'>THIS DOCUMENT IS A SUMMARY OF YOUR PROPERTY POLICY COVERAGE. THE INFORMATION IN
								THIS DOCUMENT DOES NOT REPLACE ANY POLICY PROVISION. COVERAGE IS SUBJECT TO THE
								TERMS, CONDITIONS, SPECIAL LIMITS AND EXCLUSIONS OF THE POLICY AND APPLICABLE
								ENDORSEMENTS. PLEASE REVIEW YOUR DECLARATIONS FOR APPLICABLE COVERAGE AND READ
								YOUR POLICY FOR DETAILS! IN THE EVEN OF A CONFLICT BETWEEN THE POLICY AND THIS
								SUMMARY DISCLOSURE FORM, YOUR POLICY PROVISIONS SHALL PREVAIL.</label>
							<hr className='hr_height' />

							<table className='dis_table'>
								<tr>
									<td className='title'>General
										Information:</td>
									<td className='desc'>The coverage amount listed on your attached declaration page is only an estimate of the
										replacement cost value of your insured property. It may not be sufficient to replace your property
										in the event of a total loss. If you have concerns about the coverage amount, you should take an
										opportunity to discuss this with us to ensure your property has enough coverage in the event of
										a total loss.<br /><br />
										It is important that you review and discuss your coverage with your agent or company
										representative on an annual basis. Any changes to your insured property, i.e. remodeling or
										building code updates, may require an increased coverage amount for your insured property</td>
								</tr>

								<tr>
									<td className='title'>Your declaration
										may list the
										specific limits of
										your policy for
										each of these
										coverages, if
										applicable:</td>
									<td className='desc'>
										<b>Dwelling:</b> We offer different types of policies with different coverage amounts. You may want
										to consider a replacement cost policy or an actual cash value policy. If you insure your
										dwelling on a replacement cost basis, we will encourage you tochoose a coverage limit
										equal to the estimated cost to rebuild it. Your policy may also include a feature providing
										additional coverage in case the cost to rebuild exceeds the insurance amount purchased. Your
										policy also includes Ordinance or Law Coverage which covers increased costs of demolition,
										construction, renovation, or repair associated with the enforcement of building ordinances or
										law. See below for more information or contact us with any questions about these additional
										coverages.<br />
										&ensp;&ensp;<b>Replacement</b> Cost is the amount it takes to replace your damaged or destroyed property,
										subject to the limits shown in your declaration page and policy. Please refer to your policy
										for additional information.<br />
										&ensp;&ensp;<b>Actual Cash Value</b> is the cost of repairing or replacing damaged or destroyed property
										with property of same kind and quality less depreciation, subject to the limits shown in your
										declaration page and policy.<br />
										<b>Other Structures:</b> Buildings and other structures not attached to the dwelling such as fences,
										sheds, and detached garages. These are subject to the “other structures” limit identified in your
										declaration page. If additional coverage is needed, discuss it with your agent.<br />
										<b>Personal Property:</b> Personal items and household goods; valuable items, such as jewelry
										and art, are subject to limitations. If you own valuable items, you should consider purchasing
										additional coverage through a Scheduled Personal Articles Policy.<br />
										<b>Loss of Use / Additional Living Expense: </b>Covers increased living expenses during the time
										required to repair or replace the damage to the dwelling following an insured loss, or if you
										permanently relocate, the time required to move your household to a new location. This coverage
										is subject to time limitations. Please review your policy.<br />
										<b>Personal Liability:</b> Provides protection if you or a resident insured causes bodily injury or
										property damage to another, on a per occurrence basis.<br />
										<b>Medical Payments for Others: </b>If a person, other that you or a resident of your household, is
										injured on the insured premises, this coverage will pay medical expenses subject to the policy
										limit.<br />

									</td>
								</tr>

								<tr>
									<td className='title'>Additional
										coverages you
										might want to
										consider, for an
										additional
										premium:</td>
									<td className='desc'>
										<b>Scheduled Personal Property:</b> Your policy may provide limited coverage for certain types of
										property,such as jewelry, fine arts, furs, electronic or musical equipment, etc. Purchasing a
										scheduled personal articles policy, will provide additional coverages or limits for these items.
										Water and Sewer Back-up: Pays up to the limit specified in the coverage form for damage
										caused by overflow or sump pump discharge.<br />
										<b>Earthquake: </b>Provides coverage for earthquake, which is typically excluded from a property
										insurance policy.<br />
										<b>Home Cyber Protection: </b>This coverage pays for losses resulting from computer and home
										system attacks, cyber extortion, online fraud and data breach.<br />
										<b>Home Systems Protection:</b> This coverage pays for direct physical damage to covered
										equipment caused by a sudden and accidental mechanical or electrical breakdown.<br />
										<b>Service Line Coverage:</b> This coverage pays for a covered service line resulting in a leak, tear,
										rupture or collapse of an underground pipe or powerline.<br />
										<b>Umbrella Policy:</b> Provides additional liability coverage to supplement the protection provided
										by property and automobile insurance policies.<br />
									</td>
								</tr>

							</table>
							<hr className='hr_height' />
							<label className='faq_sub_title'><b>IMPORTANT:</b> In Colorado, there is potential for large and even total losses due to fires, tornadoes, other natural
								disasters, or other causes of loss. It is extremely important to conduct an annual review of your property coverage to
								ensure you are adequately insured. If you have questions or concerns regarding your insurance coverage, be sure
								to discuss them with your insurance agent or company representative. Please maintain a copy of this document and your
								entire policy in a safe and secure location away from your property.
							</label>
							<hr className='hr_height' />
							<table className='dis_table'>
								<tr>
									<td className='title'>Items that may
										affectyour
										premium):</td>
									<td className='desc'>
										<b>The following are examples of some of the items that may affect your premium that may
											discount or surcharge your policy premium:</b>

										<ul style={{ marginLeft: '2rem', listStyleType: "square", fontWeight: 'normal' }}>
											<li>&emsp;Deductible: That part of the covered loss for which you are responsible for paying. Please
												review your policy declaration page. Deductibles may be a fixed amount, a percentage
												of the dwelling limit, or a combination of both;</li>
											<li>&emsp;Multiple policy discounts;</li>
											<li>&emsp;Claim history;</li>
											<li>&emsp;Age of home;</li>
											<li>&emsp;Roof age or type; or</li>
											<li>&emsp;Smoke/fire/burglar alarms.</li>
										</ul>

									</td>
								</tr>
								<tr>
									<td className='title'>General
										Exclusions:</td>
									<td className='desc'>
										<b>Property Exclusions:</b>
										<ol style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
											<li>&emsp;Loss or damage that you or a resident of your house intentionally causes;</li>
											<li>&emsp;Flooding*, including surface water</li>
											<li>&emsp;Earth movement, settling, cracking, bulging, shrinkage, or expansion of the structure, other
												structures, or of pavements, driveways, or sidewalks;</li>
											<li>&emsp;Pollution and contamination;</li>
											<li>&emsp;Land;											</li>
											<li>&emsp;Mold or fungi;</li>
											<li>&emsp;Wear and tear.</li>
										</ol>
										*Flood insurance may be purchased through the National Flood Insurance Program
										(www.floodsmart.gov)
										<b>Liability Exclusions:</b>
										<ol style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
											<li>&emsp;Any loss that an insured intends or should expect to happen;</li>
											<li>&emsp;Bodily injury to an insured person or property damage to an insured person’s property;</li>

										</ol>
										Damage which results from the ownership or use of an automobile and other types of motorized
										landvehicles, aircraft, or certain watercraft.

									</td>
								</tr>
							</table>
							<hr className='hr_height' />
							<label className='faq_sub_title'>IMPORTANT: This document is a summary of coverage available under your property policy. The property policy is
								a contract between you and us. Each of us has duties, rights, and responsibilities under this contract. Please review
								your policy carefully. If you have any questions or concerns, you may also contact the Colorado Division of Insurance.</label>
							<hr className='hr_height' />
							<table className='dis_table'>
								<tr>
									<td className='title'>Reasons for
										cancellation, non-renewal,or
										increase in premium: </td>
									<td className='desc'>
										<b>Cancellation and Nonrenewal:</b><br />
										You may cancel your policy at any time by writing to us or your agent and indicating the
										date the cancellation is to take effect.<br />
										We may choose to cancel or non-renew your policy. If your policy is cancelled or non-renewed,
										we will send you advance notice. Some examples of reasons for cancellation and non-renewal
										include, but are not limited to:
										<ol style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
											<li> Failure to pay your premium when it is due;</li>
											<li> Knowingly making a false statement or a material misrepresentation on your
												application for your policy;</li>
											<li> Knowingly making a false statement or material misrepresentation regarding a claim;</li>
											<li> Frequency or type of claims;</li>
											<li> A substantial change in the use or occupancy of the premises.</li>
											<b>Increase in Premium:</b><br />
											Conditions that may increase your premium include, but are not limited to:
											<li> A loss resulting in a paid claim;</li>
											<li> A general rate increase. This results from the loss experience of a large group of
												policyholders rather than from a loss suffered by an individual policyholder. A general rate
												increase applies to many persons in the group, not just those who had losses.</li>
											<li> Adjustment for inflation. We include inflation coverage in your policy. This coverage may
												automatically increase the amount of your insurance coverage as inflation pushes up the
												cost of replacing your home. The increases may be based on a construction cost index and
												may be reflected in the premium on each renewal date.</li>
										</ol>
									</td>
								</tr>
							</table>
							<hr className='hr_height' />
							<label className='faq_sub_title'><i>IMPORTANT: NOTE: It is important that you review and discuss your coverages with your agent or company representative on
								an annual basis. Any changes to your home, i.e. remodeling or county code updates, may require an increased
								coverage amount for your insured property</i></label>
							<hr className='hr_height' />
						</div>
					</div>
				</div>
				<div className="faq">
					<div>
						<p className="accordion">
							PLEASE READ THE DISCLOSURE THAT APPLIES TO YOUR POLICY.
						</p>
						<div className="panel">
							<p className="phase2_subheading">
								<label className='faq_sub_title'>SUMMARY OF COVERAGE
									CONDOMINIUM POLICY</label>
							</p>
							<hr className='hr_height' />
							<label className='faq_sub_title'>THIS DOCUMENT IS A SUMMARY OF YOUR CONDOMINIUM COVERAGE. THE INFORMATION IN THIS
								DOCUMENT DOES NOT REPLACE ANY POLICY PROVISION. COVERAGE IS SUBJECT TO THE TERMS,
								CONDITIONS, SPECIAL LIMITS AND EXCLUSIONS OF THE POLICY AND APPLICABLE
								ENDORSEMENTS. PLEASE REVIEW YOUR DECLARATIONS FOR APPLICABLE COVERAGE AND READ
								YOUR POLICY FOR DETAILS! IN THE EVEN OF A CONFLICT BETWEEN THE POLICY AND THIS
								SUMMARY DISCLOSURE FORM, YOUR POLICY PROVISIONS SHALL PREVAIL. </label>
							<hr className='hr_height' />

							<table className='dis_table'>
								<tr>
									<td className='title'>General
										Information:</td>
									<td className='desc'>The coverage amount listed on your attached declaration page is only an estimate of the value
										of your insured property. It may not be sufficient to replace your property in the event of a total
										loss. If you have concerns about the coverage amount, you should take an opportunity to discuss
										this with us to ensure your property has enough coverage in the event of a total loss<br /><br />
										It is important that you review and discuss your coverage with your agent or company
										representative on an annual basis. You should also review your homeowner association bylaws
										and covenants, conditions and restrictions (CC&R) to identify real property you are responsible
										for insuring. Any changes to your insured property, i.e. remodeling or building code updates
										may require an increased coverage amount for your insured property. </td>
								</tr>

								<tr>
									<td className='title'>Your declaration
										may list the
										specific limits of
										your policy for
										each of these
										coverages, if
										applicable:</td>
									<td className='desc'>
										<b>Dwelling:</b> Your condominium policy will cover the property that is permanently installed in your
										unit that is not otherwise covered by a homeowner association insurance policy, as outlined in
										your homeowner association CC&R. You may want to consider a replacement cost policy or an
										actual cash value policy. Your policy also includes Ordinance or Law Coverage which covers
										increased costs of demolition, construction, renovation, or repair associated with the enforcement
										of building ordinances or law.<br />
										&ensp;&ensp;<b>Replacement Cost</b> iis the amount it takes to replace your damaged or destroyed property,
										subject to the limits shown in your declaration page and policy. Please refer to your policy
										for additional information.<br />
										&ensp;&ensp;<b>Actual Cash Value</b> is the cost of repairing or replacing damaged or destroyed property
										with property of same kind and quality less depreciation, subject to the limits shown in your
										declaration page and policy.<br />
										<b>Personal Property:</b> Personal items and household good; valuable items, such as jewelry and
										art are subject to limitations. You may have the option of purchasing replacement cost coverage
										on your personal property or your personal property may be valued at actual cash value. If you
										own valuable items you should consider purchasing a Schedule Personal Articles Policy.<br />

										<b>Loss of Use / Additional Living Expense:</b> Covers increased living expenses during the time
										required to repair or replace the damage to the dwelling following an insured loss, or if you
										permanently relocate, the time required to move your household to a new location. This coverage
										is subject to time limitations. Please review your policy.<br />

										<b>Personal Liability: </b> Provides protection if you or a resident insured causes bodily injury or
										property damage to another, on a per occurrence basis.<br />

										<b>Medical Payments for Others:</b> If a person, other that you or a resident of your household, is
										injured on the insured premises, this coverage will pay medical expenses subject to the policy
										limit.<br />


									</td>
								</tr>

								<tr>
									<td className='title'>Additional
										coverages you
										might want to
										consider, for an
										additional
										premium:</td>
									<td className='desc'>
										<b>Building Items Other Than Structure:</b> Covered permanently attached items such as cabinets
										and light fixtures. <br />

										<b>Scheduled Personal Property: </b>Your policy may provide limited coverage for certain types of
										property,such as jewelry, fine arts, furs, electronic or musical equipment, etc. Purchasing a
										scheduled personal articles policy, will provide additional coverages or limits for these items.<br />

										<b>Water and Sewer Back-up:</b>Pays up to the limit specified in the coverage form for damage
										caused by overflow or sump pump discharge.<br />

										<b>Earthquake:</b> TProvides coverage for earthquake, which is typically excluded from a property
										insurance policy.<br />

										<b>Home Cyber Protection: </b> This coverage pays for losses resulting from computer and home
										system attacks, cyber extortion, online fraud and data breach. <br />

										<b>Home Systems Protection: </b> This coverage pays for direct physical damage to covered
										equipment caused by a sudden and accidental mechanical or electrical breakdown. <br />

										<b>Umbrella Policy: </b> Provides additional liability coverage to supplement the protection provided
										by property and automobile insurance policies.
										<br />
									</td>
								</tr>

							</table>
							<hr className='hr_height' />
							<label className='faq_sub_title'><b>IMPORTANT:</b> In Colorado, there is potential for large and even total losses due to fires, tornadoes, other natural
								disasters, or other causes of loss. It is extremely important to conduct an annual review of your property coverage to
								ensure you are adequately insured. If you have questions or concerns regarding your insurance coverage, be sure
								to discuss them with your insurance agent or company representative. Please maintain a copy of this document and your
								entire policy in a safe and secure location away from your property.

							</label>
							<hr className='hr_height' />
							<table className='dis_table'>
								<tr>
									<td className='title'>Items that may
										affect your
										premium:</td>
									<td className='desc'>
										<b>The following are examples of some of the items that may affect your premium that may
											discount or surcharge your policy premium:</b>

										<ul style={{ marginLeft: '2rem', listStyleType: "square", fontWeight: 'normal' }}>
											<li>&emsp;Deductible: That part of the covered loss for which you are responsible for paying.
												Please review your policy declaration page. Deductibles may be a fixed amount, a
												percentage of the dwelling limit, or a combination of both;
											</li>
											<li>&emsp;Multiple policy discounts;</li>
											<li>&emsp;Claim history;</li>
											<li>&emsp;Central Sprinkler;</li>
											<li>&emsp;Age of home;</li>
											<li>&emsp;Smoke/fire/burglar alarms.</li>
										</ul>

									</td>
								</tr>
								<tr>
									<td className='title'>General
										Exclusions:</td>
									<td className='desc'>
										<b>Property Exclusions:</b>
										<ol style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
											<li>&emsp;Loss or damage that you or a resident of your house intentionally causes;</li>
											<li>&emsp;Flooding*, including surface water</li>
											<li>&emsp;Earth movement, settling, cracking, bulging, shrinkage, or expansion of the structure,
												other structures, or of pavements, driveways, or sidewalks;</li>
											<li>&emsp;Pollution and contamination;</li>
											<li>&emsp;Land;											</li>
											<li>&emsp;Mold or fungi;</li>
											<li>&emsp;Wear and tear.</li>
										</ol>
										*Flood insurance may be purchased through the National Flood Insurance Program
										(www.floodsmart.gov)
										<b>Liability Exclusions:</b>
										<ol style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
											<li>&emsp;Any loss that an insured intends or should expect to happen;</li>
											<li>&emsp;Bodily injury to an insured person or property damage to an insured person’s property;</li>

										</ol>
										Damage which results from the ownership or use of an automobile and other types of motorized
										landvehicles, aircraft, or certain watercraft.

									</td>
								</tr>
							</table>
							<hr className='hr_height' />
							<label className='faq_sub_title'><i>IMPORTANT: This document is a summary of coverage available under your condominium policy. The policy is a
								contract between you and us. Each of us has duties, rights, and responsibilities under this contract. Please review
								your policy carefully. If you have any questions or concerns, you may also contact the Colorado Division of Insurance.</i></label>
							<hr className='hr_height' />
							<table className='dis_table'>
								<tr>
									<td className='title'>Reasons for
										cancellation, non-renewal,or
										increase in premium: </td>
									<td className='desc'>
										<b>Cancellation and Nonrenewal:</b><br />
										You may cancel your policy at any time by writing to us or your agent and indicating the
										date the cancellation is to take effect.
										<br />
										We may choose to cancel or non-renew your policy. If your policy is cancelled or non-renewed,
										we will send you advance notice. Some examples of reasons for cancellation and non-renewal
										include, but are not limited to:

										<ol style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
											<li> Failure to pay your premium when it is due;</li>
											<li> Knowingly making a false statement or a material misrepresentation on your
												application for your policy;
											</li>
											<li> Knowingly making a false statement or material misrepresentation regarding a claim;
											</li>
											<li> Frequency or type of claims;</li>
											<li> A substantial change in the use or occupancy of the premises.</li>
											<b>Increase in Premium:</b><br />
											Conditions that may increase your premium include, but are not limited to:
											<li> A loss resulting in a paid claim;</li>
											<li> A general rate increase. This results from the loss experience of a large group of
												policyholders rather than from a loss suffered by an individual policyholder. A general rate
												increase applies to many persons in the group, not just those who had losses.</li>
											<li> Adjustment for inflation. We include inflation coverage in your policy. This coverage may
												automatically increase the amount of your insurance coverage as inflation pushes up the
												cost of replacing your home. The increases may be based on a construction cost index and
												may be reflected in the premium on each renewal date.
											</li>
										</ol>
									</td>
								</tr>
							</table>
							<hr className='hr_height' />
							<label className='faq_sub_title'><i> NOTE:  It is important that you review and discuss your coverages with your agent or company representative on
								an annual basis. Any changes to your home, i.e. remodeling or county code updates, may require an increased
								coverage amount for your insured property.</i></label>
							<hr className='hr_height' />
						</div>
					</div>
				</div>
				<div className="faq">
					<div>
						<p className="accordion">
							PLEASE READ THE DISCLOSURE THAT APPLIES TO YOUR POLICY
						</p>
						<div className="panel">
							<p className="phase2_subheading">
								<label className='faq_sub_title'>SUMMARY OF COVERAGE
									RENTERS POLICY</label>
							</p>
							<hr className='hr_height' />
							<label className='faq_sub_title'>THIS DOCUMENT IS A SUMMARY OF YOUR RENTERS COVERAGE. THE INFORMATION IN THIS
								DOCUMENT DOES NOT REPLACE ANY POLICY PROVISION. COVERAGE IS SUBJECT TO THE TERMS,
								CONDITIONS, SPECIAL LIMITS AND EXCLUSIONS OF THE POLICY AND APPLICABLE
								ENDORSEMENTS. PLEASE REVIEW YOUR DECLARATIONS FOR APPLICABLE COVERAGE AND READ
								YOUR POLICY FOR DETAILS! IN THE EVEN OF A CONFLICT BETWEEN THE POLICY AND THIS
								SUMMARY DISCLOSURE FORM, YOUR POLICY PROVISIONS SHALL PREVAIL. </label>
							<hr className='hr_height' />

							<table className='dis_table'>
								<tr>
									<td className='title'>General
										Information:</td>
									<td className='desc'>The coverage amount listed on your attached declaration page is only an estimate of the value
										of your insured property. It may not be sufficient to replace your property in the event of a total
										loss. If you have concerns about the coverage amount, you should take an opportunity to discuss
										this with us to ensure your property has enough coverage in the event of a total loss.<br /><br />

										It is important that you review and discuss your coverage with your agent or company
										representative on an annual basis. You should also review your homeowner association bylaws
										and covenants, conditions and restrictions (CC&R) to identify real property you are responsible
										for insuring. Any changes to your insured property, i.e. remodeling or building code updates
										may require an increased coverage amount for your insured property.  </td>
								</tr>

								<tr>
									<td className='title'>Your declaration
										may list the
										specific limits of
										your policy for
										each of these
										coverages, if
										applicable:</td>
									<td className='desc'>
										<b>Personal Property: </b> Personal items and household good; valuable items, such as jewelry and
										art are subject to limitations. You may have the option of purchasing replacement cost coverage
										on your personal property or your personal property may be valued at actual cash value. If you
										own valuable items you should consider purchasing a Schedule Personal Articles Policy. <br />
										&ensp;&ensp;<b>Replacement Cost</b> s the amount it takes to replace your damaged or destroyed property,
										subject to the limits shown in your declaration page and policy. Please refer to your policy
										for additional information.
										<br />
										&ensp;&ensp;<b>Actual Cash Value</b> e is the cost of repairing or replacing damaged or destroyed property
										with property of same kind and quality less depreciation, subject to the limits shown in your
										declaration page and policy.<br />

										<b>Loss of Use / Additional Living Expense:</b> Covers increased living expenses during the time
										required to repair or replace the damage to the dwelling following an insured loss, or if you
										permanently relocate, the time required to move your household to a new location. This coverage
										is subject to time limitations. Please review your policy.<br />

										<b>Personal Liability:</b> : Provides protection if you or a resident insured causes bodily injury or
										property damage to another, on a per occurrence basis.<br />

										<b>Medical Payments for Others:</b> If a person, other that you or a resident of your household, is
										injured on the insured premises, this coverage will pay medical expenses subject to the policy
										limit.
										<br />


									</td>
								</tr>

								<tr>
									<td className='title'>Additional
										coverages you
										might want to
										consider, for an
										additional
										premium:</td>
									<td className='desc'>

										<b>Scheduled Personal Property: </b>Your policy may provide limited coverage for certain types of
										property,such as jewelry, fine arts, furs, electronic or musical equipment, etc. Purchasing a
										scheduled personal articles policy, will provide additional coverages or limits for these items.<br />

										<b>Water and Sewer Back-up:</b> Pays up to the limit specified in the coverage form for damage
										caused by overflow or sump pump discharge.<br />

										<b>Earthquake:</b> Provides coverage for earthquake, which is typically excluded from a property
										insurance policy<br />

										<b>Home Cyber Protection: </b> This coverage pays for losses resulting from computer and home
										system attacks, cyber extortion, online fraud and data breach.
										<br />

										<b>Home Systems Protection: </b> This coverage pays for direct physical damage to covered
										equipment caused by a sudden and accidental mechanical or electrical breakdown.  <br />

										<b>Umbrella Policy: </b> Provides additional liability coverage to supplement the protection provided
										by property and automobile insurance policies.
										<br />
									</td>
								</tr>

							</table>
							<hr className='hr_height' />
							<label className='faq_sub_title'><b>IMPORTANT:</b>  In Colorado, there is potential for large and even total losses due to fires, tornadoes, other natural
								disasters, or other causes of loss. It is extremely important to conduct an annual review of your property coverage to
								ensure you are adequately insured. If you have questions or concerns regarding your insurance coverage, be sure
								to discuss them with your insurance agent or company representative. Please maintain a copy of this document and your
								entire policy in a safe and secure location away from your property.


							</label>
							<hr className='hr_height' />
							<table className='dis_table'>
								<tr>
									<td className='title'>Items that may
										affect your
										premium:</td>
									<td className='desc'>
										<b>The following are examples of some of the items that may affect your premium that may
											discount or surcharge your policy premium:
										</b>

										<ul style={{ marginLeft: '2rem', listStyleType: "square", fontWeight: 'normal' }}>
											<li>&emsp;Deductible: That part of the covered loss for which you are responsible for paying.
												Please review your policy declaration page. Deductibles may be a fixed amount, a
												percentage of the dwelling limit, or a combination of both;
											</li>
											<li>&emsp;Multiple policy discounts;</li>
											<li>&emsp;Central Sprinkler;</li>
											<li>&emsp;Claim history;</li>
											<li>&emsp;Smoke/fire/burglar alarms.</li>
										</ul>

									</td>
								</tr>
								<tr>
									<td className='title'>General
										Exclusions:</td>
									<td className='desc'>
										<b>Property Exclusions:</b>
										<ol style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
											<li>&emsp;Loss or damage that you or a resident of your house intentionally causes;</li>
											<li>&emsp;Flooding*, including surface water</li>
											<li>&emsp;Earth movement, settling, cracking, bulging, shrinkage, or expansion of the structure,
												other structures, or of pavements, driveways, or sidewalks;</li>
											<li>&emsp;Pollution and contamination;</li>
											<li>&emsp;Land;											</li>
											<li>&emsp;Mold or fungi;</li>
											<li>&emsp;Wear and tear.</li>
										</ol>
										*Flood insurance may be purchased through the National Flood Insurance Program
										(www.floodsmart.gov)

										<b>Liability Exclusions:</b>
										<ol style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
											<li>&emsp;Any loss that an insured intends or should expect to happen;</li>
											<li>&emsp;Bodily injury to an insured person or property damage to an insured person’s property;
											</li>

										</ol>
										Damage which results from the ownership or use of an automobile and other types of motorized
										landvehicles, aircraft, or certain watercraft.


									</td>
								</tr>
							</table>
							<hr className='hr_height' />
							<label className='faq_sub_title'><i>IMPORTANT: This document is a summary of coverage available under your renter policy. The policy is a contract
								between you and us. Each of us has duties, rights, and responsibilities under this contract. Please review your policy
								carefully. If you have any questions or concerns, you may also contact the Colorado Division of Insurance.</i></label>
							<hr className='hr_height' />
							<table className='dis_table'>
								<tr>
									<td className='title'>Reasons for
										cancellation, non-renewal,or
										increase in
										premium: </td>
									<td className='desc'>
										<b>Cancellation and Nonrenewal:</b><br />
										You may cancel your policy at any time by writing to us or your agent and indicating the
										date the cancellation is to take effect.
										<br />
										We may choose to cancel or non-renew your policy. If your policy is cancelled or non-renewed,
										we will send you advance notice. Some examples of reasons for cancellation and non-renewal
										include, but are not limited to:


										<ol style={{ marginLeft: '2rem', fontWeight: 'normal' }}>
											<li> Failure to pay your premium when it is due;</li>
											<li> Knowingly making a false statement or a material misrepresentation on your
												application for your policy;
											</li>
											<li> Knowingly making a false statement or material misrepresentation regarding a claim;
											</li>
											<li> Frequency or type of claims;</li>
											<li> A substantial change in the use or occupancy of the premises.</li>
											<b>Increase in Premium:</b><br />
											Conditions that may increase your premium include, but are not limited to:
											<li> A loss resulting in a paid claim;</li>
											<li>  A general rate increase. This results from the loss experience of a large group of
												policyholders rather than from a loss suffered by an individual policyholder. A general rate
												increase applies to many persons in the group, not just those who had losses.</li>
											<li> Adjustment for inflation. We include inflation coverage in your policy. This coverage may
												automatically increase the amount of your insurance coverage as inflation pushes up the
												cost of replacing your home. The increases may be based on a construction cost index and
												may be reflected in the premium on each renewal date.
											</li>
										</ol>
									</td>
								</tr>
							</table>
							<hr className='hr_height' />
							<label className='faq_sub_title'><i> NOTE:   It is important that you review and discuss your coverages with your agent or company representative on
								an annual basis. Any changes to your home, i.e. remodeling or county code updates, may require an increased
								coverage amount for your insured property</i></label>
							<hr className='hr_height' />
						</div>
					</div>
				</div>

			</div>
		</Content >
	);
};

export default Disclosure;
