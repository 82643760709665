import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import { addContent, imageUpload } from '../../../../redux/actions/contentAction'

const EditInfo = ({ setShowModal, showModal, pageName, section2, setSection2 }) => {
  const [loading, setLoading] = useState(false)

  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(null)

  const [title, setTitle] = useState({ value: section2?.title.value, error: '' })
  const [description1, setDescription1] = useState({ value: section2?.description1.value, error: '' })
  const [description2, setDescription2] = useState({ value: section2?.description2.value, error: '' })
  const [contact, setContact] = useState({ value: section2?.contact.value, error: '' })

  const onDrop = useCallback((acceptedFiles) => {
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0]
    setUploadImage(file)
    var reader = new FileReader()
    reader.onloadend = function () {
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })

  const submit = (e) => {
    e.preventDefault()
    let temp = { ...section2 };
    if (section2.title.value == '') {
      temp.title.error = 'Please enter title!'
      setSection2(temp)
      return
    } else {
      temp.title.error = ''
      setSection2(temp)
    }
    if (section2.description1.value == '') {
      temp.description1.error = 'Please enter description1!'
      setSection2(temp)
      return
    } else {
      temp.description1.error = ''
      setSection2(temp)
    }
    if (section2.description2.value == '') {
      temp.description2.error = 'Please enter description2'
      setSection2(temp)
      return
    } else {
      temp.description2.error = ''
      setSection2(temp)
    }

    if (uploadImage) {
      imageUpload(uploadImage).then(upload => {
        temp.image.value = upload.data.path;
        addContent({ pageName: pageName, section: "Section_2", content: temp })
          .then(result => {
            if (result.data.status) {
              setSection2(temp)
              alert("Content Saved")
            }
          })
      })

    } else {
      addContent({ pageName: pageName, section: "Section_2", content: temp })
        .then(result => {
          if (result.data.status) {
            setSection2(temp)
            alert("Content Saved")
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
  }
  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Section 2</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section2?.title.value}
                    onChange={(e) => {
                      let temp = { ...section2 }
                      temp.title.value = e.target.value
                      setSection2(temp)
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section2?.title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Description:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    required={true}
                    value={section2?.description1.value}
                    onChange={(e) => {
                      let temp = { ...section2 }
                      temp.description1.value = e.target.value
                      setSection2(temp)
                      setDescription1({ error: '', value: e.target.value })
                    }}
                    errorMessage={section2?.description1.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Address:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    required={true}
                    value={section2?.description2.value}
                    onChange={(e) => {
                      let temp = { ...section2 }
                      temp.description2.value = e.target.value
                      setSection2(temp)
                      setDescription2({ error: '', value: e.target.value })
                    }}
                    errorMessage={section2?.description2.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Contact:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section2?.contact.value}
                    onChange={(e) => {
                      let temp = { ...section2 }
                      temp.contact.value = e.target.value
                      setSection2(temp)
                      setContact({ error: '', value: e.target.value })
                    }}
                    errorMessage={section2?.contact.error}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right'>
                <label>About background Image</label>
                <div className='upload_border' {...getRootProps()}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  <svg className='modal_upload__icon-dark'>
                    <use xlinkHref='/sprite.svg#icon-cloud' />
                  </svg>
                </div>
                <div className='upload_preview_modal'>
                  <img style={{ backgroundColor: "#f2f2f2" }} height={140} src={uploadImagePreview ? uploadImagePreview : section2?.image.value}></img>
                </div>
              </div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditInfo
