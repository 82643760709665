import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import './ERS.css';
import Content from "../../../components/Content/Content";
import Banner from "../../../components/Banner/Banner";
import TextInputPrefix from "../../../components/Inputs/TextInputPrefix";
import FormInputComponent from "../../../components/FormInputComponent/FormInputComponent";
import { sendERSAction } from "../../../redux/actions/mailActions";
import { useDispatch, useSelector } from "react-redux";
import { inputPhoneMasking } from "../utils/phonemask";
import { useDropzone } from "react-dropzone";
import CheckBox from "../../../components/Inputs/Checkbox";
import MultiSelectDropdown from "../../../components/MultiSeclectDropdown/MultiSelectDropdown";

import EditEmailAddressesModal from "../StartAQuote/edit/EditEmailAddresses"
import { addFormContent, deleteFormField, getFormContent, getPageContent, updateFieldOrder, updateFormContent } from "../../../redux/actions/contentAction";
import { commaSeprator, splitArrayTo2D } from "../../../redux/constants/utils";
import EditFieldTypeModal from "../StartAQuote/edit/EditFieldTypeModal";
import {
  validateEmail,
  validateOnlyChacters,
  validateOnlyNumber,
  validatePhone,
  validateZipCode,
} from "../utils/validators";
import { ADMIN_USERS } from "../../../redux/actions/ip";

let fileIndex = [-1, -1];

const ERS = ({ match }) => {

  const dispatch = useDispatch();

  const azureLogin = useSelector((state) => state.azureLogin)
  const [showEmailEdit, setShowEmailEdit] = useState(false);
  const [showEdit, setShowEdit] = useState(true);

  const [fieldTypeArr, setFieldTypeArr] = useState([]);
  const [resultArr, setResultArr] = useState([]);
  const [currentInsuranceError, setCurrentInsuranceError] = useState("");
  const onDrop = (files) => {
    // Do something with the files
    var file = files[0];
    if (file) {
      if (fileIndex.length === 2) {
        if (fileIndex[0] > -1 && fileIndex[1] > -1 && resultArr.length) {
          const temp = [...resultArr];
          temp[fileIndex[0]][fileIndex[1]].selectedValue = file;
          setResultArr(temp)
        }
      }
    }
  }
  const onDropRejected = (file) => {
    if (file.length && fileIndex.length === 2) {
      if (fileIndex[0] > -1 && fileIndex[1] > -1 && resultArr.length) {
        const temp = [...resultArr];
        temp[fileIndex[0]][fileIndex[1]].error = file[0].errors.map(item => item.code === 'file-too-large' ? 'File is larger than 5MB' : item.message).join(',');
        setResultArr(temp)
      }
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png,application/pdf', minSize: 0,
    maxSize: 5242880, onDrop, onDropRejected
  })
  const [agentFirstName, setAgentFirstName] = useState('');
  const [agentFirstNameError, setAgentFirstNameError] = useState('');

  const [agentLastName, setAgentLastName] = useState("");
  const [agentLastNameError, setAgentLastNameError] = useState("");

  const [agentEmail, setAgentEmail] = useState("");
  const [agentEmailError, setAgentEmailError] = useState("");

  const [agentPhone, setAgentPhone] = useState("");
  const [agentPhoneError, setAgentPhoneError] = useState("");

  const [state, setState] = useState("");
  const [showFieldTypeModal, setShowFieldTypeModal] = useState(false);
  const [editFormData, setEditFormData] = useState([]);
  const [bannerData, setBannerData] = useState({
    title: { value: "ERS", error: "" },
    subTitle: {
      value:
        "Please use the form below and fill out as much information as possible. Any relevant invoices, photos or information can be uploaded at 			the end.",
      error: "",
    },
    tagline: {
      value: "WE TAKE PRIDE IN OUR CLIENT RELATIONSHIPS",
      error: "",
    },
    button1: { value: "Find Your Agent", error: "" },
    button1Link: { value: "/mwfbi/find-an-agent", error: "" },
    button1Status: { value: true, error: "" },
    button2: { value: "", error: "" },
    button2Link: { value: "", error: "" },
    button2Status: { value: false, error: "" },
    imageUrl: { value: "/images/start-a-quote.png", error: "" },
  });

  const getFieldList = () => {
    getFormContent("ERS").then((res) => {
      setFieldTypeArr(
        res.data.result.map((item) => ({
          ...item,
          selectedValue: item.FieldType === "Multi-Value" ? [] : "",
          error: "",
        }))
      );
      setResultArr(
        splitArrayTo2D(
          res.data.result.map((item) => ({
            ...item,
            selectedValue: item.FieldType === "Multi-Value" ? [] : "",
            error: "",
          }))
        )
      );
    });
  };


  useEffect(() => {
    getFieldList();
  }, []);

  const handleChangeFieldOrder = (fieldId, position) => {
    updateFieldOrder("ERS", fieldId, position).then((result) => {
      if (result.data.status) {
        alert("Field Updated");
      }
      getFieldList();
    });
  };

  const onHandleForm = async (e) => {
    e.preventDefault();
    let formValid = true;
    if (agentFirstName === "" && agentFirstName.trim() === "") {
      formValid = false
      setAgentFirstNameError("Please Enter Agent First Name");
    } else if (agentLastName === "" && agentLastName.trim() === "") {
      formValid = false
      setAgentLastNameError("Please Enter Agent Last Name");
    } else if (!validateEmail(agentEmail)) {
      formValid = false
      setAgentEmailError("Please enter valid email");
    } else if (!validatePhone(agentPhone)) {
      formValid = false
      setAgentPhoneError("Please enter valid phone number.");
    }

    const temp = resultArr.map((field) => {
      let fieldTemp = [...field];
      fieldTemp = fieldTemp.map((element) => {
        let item = { ...element };
        if (item.RequiredStatus) {
          if (item.selectedValue === "") {
            formValid = false;
            item.error = `Please enter the ${item.FieldLabel}`;
          } else item.error = "";
          if (item.selectedValue.length === 0) {
            formValid = false;
            item.error = `Please enter the ${item.FieldLabel}`;
          } else item.error = "";

          switch (item.ValidationType) {
            case "Only Text":
              if (item.selectedValue.length > 0 && validateOnlyChacters(item.selectedValue) === false) {
                formValid = false;
                item.error = `Please enter valid ${item.FieldLabel}`;
              }
              return item;

            case "Only Number":
              if (
                item.selectedValue.length > 0 &&
                validateOnlyNumber(item.selectedValue) === false
              ) {
                formValid = false;
                item.error = `Please enter valid ${item.FieldLabel}`;
              }
              return item;
            case "Email":
              if (
                item.selectedValue.length > 0 &&
                validateEmail(item.selectedValue) === false
              ) {
                formValid = false;
                item.error = `Please enter valid ${item.FieldLabel}`;
              }
              return item;
            case "Phone":
              if (
                item.selectedValue.length > 0 &&
                validatePhone(item.selectedValue) === false
              ) {
                formValid = false;
                item.error = `Please enter valid ${item.FieldLabel}`;
              }
              return item;
            case "Zip":
              if (
                item.selectedValue.length > 0 &&
                validateZipCode(item.selectedValue) === false
              ) {
                formValid = false;
                item.error = `Please enter valid ${item.FieldLabel}`;
              }
              return item
            default:
              return item
          }
        }
        return item;
      });
      return fieldTemp;
    });
    setResultArr(temp);

    let formData = new FormData();

    if (formValid) {
      let body = [];
      temp.forEach((field) => {
        field.forEach((item) => {
          if (item.FieldInputType === "FileUpload" && item.selectedValue) {
            formData.append('files', item.selectedValue);
            body = [...body, { key: item.FieldLabel, value: item.selectedValue.name, isFile: true }];
          } else
            body = [...body, { key: item.FieldLabel, value: item.selectedValue }];
        });
      });
      formData.append("agentEmail", agentEmail);
      formData.append("agentFirstName", agentFirstName);
      formData.append("agentLastName", agentLastName);
      formData.append("agentPhone", agentPhone);
      formData.append("body", JSON.stringify(body));

      sendERSAction(formData).then((res) => {
        alert(
          "Thank you for submitting your ERS Incident Report. A representative will be in touch shortly to go over your submission."
        );
        window.location.reload()
      });
    }
  }
  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true)
      } else setShowEdit(false)
    } else setShowEdit(false)
  }, [azureLogin])

  const handleDynamicFormSubmit = (content) => {
    addFormContent({
      fieldType: content.fieldType,
      fieldInputType: content.fieldInputType,
      fieldLabel: content.fieldLabel,
      fieldValue: content.fieldValue,
      placeHolder: content.placeHolder,
      validationType: content.validationType,
      pageName: "ERS",
      requiredStatus: content.requiredStatus === "True" ? 1 : 0,
    }).then((res) => {
      if (res.data.status) {
        getFormContent("ERS").then((res) => {
          setFieldTypeArr(res.data.result);
          setResultArr(splitArrayTo2D(res.data.result));
          alert("Field has been added.")
        });
      }
    });
  };

  const handleDynamicFormUpdate = (id, content) => {
    updateFormContent(id, {
      fieldType: content.fieldType,
      fieldInputType: content.fieldInputType,
      fieldLabel: content.fieldLabel,
      fieldValue: content.fieldValue,
      placeHolder: content.placeHolder,
      validationType: content.validationType,
      pageName: "StartAQuote",
      requiredStatus: content.requiredStatus === "True" ? 1 : 0,
    }).then((res) => {
      if (res.data.status) {
        getFormContent("ERS").then((res) => {
          setFieldTypeArr(res.data.result);
          setResultArr(splitArrayTo2D(res.data.result));
          alert("Field has been saved.")
        });
      }
    });
  };

  const onFieldDelete = (e, item, index) => {
    e.preventDefault();
    if (window.confirm("Are You Sure to Delete?")) {
      deleteFormField(item.ID).then((res) => {
        if (res.data.status) {
          getFormContent("ERS").then((res) => {
            setResultArr(splitArrayTo2D(res.data.result));
            setFieldTypeArr(res.data.result);
            alert("Field has been deleted.")
          });
        }
      });
    }
  };

  const dropDownValues = (item) => {
    let arr_obj = [];
    commaSeprator(item.FieldValue).forEach((data) => {
      let obj = { label: data, value: data };
      arr_obj.push(obj);
    });
    return arr_obj;
  };

  return (
    <Content headerFooterMenu="mountain-west">
      <Banner
        backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(/images/start-a-quote.png)"
        title="Emergency Roadside Service Incident Report"
        subTitle="Please use the form below and fill out as much information as possible.
				Any relevant invoices, photos or information can be uploaded at the end."
        bannerData={bannerData}
        pageName={"ERS"}
        showEdit={showEdit}
        setBannerData={setBannerData}
      />
      <form>
        <div className="main_outer_component">
          <div className="start_a_quote-your-info">
            <div className="pre_heading">
              <FormInputComponent
                heading="Agent Name"
                subheading={"First"}
                style={{ width: '48%' }}
                value={agentFirstName}
                onChange={(e) => {
                  setAgentFirstName(e.target.value);
                  setAgentFirstNameError('');
                }}
                required={true}
                errorMessage={agentFirstNameError}
              />
              <FormInputComponent
                heading=" "
                subheading={"Last"}
                style={{ width: '48%' }}
                value={agentLastName}
                onChange={(e) => {
                  setAgentLastName(e.target.value);
                  setAgentLastNameError('');
                }}
                errorMessage={agentLastNameError}
              />
            </div>
            <div className="pre_heading">
              <FormInputComponent
                heading="Agent Email"
                style={{ width: '48%' }}
                value={agentEmail}
                subheading={"Email"}
                onChange={(e) => {
                  setAgentEmail(e.target.value);
                  setAgentEmailError('');
                }}
                required={true}
                errorMessage={agentEmailError}
              />
              <FormInputComponent
                heading="Agent Phone"
                subheading={"Phone"}
                style={{ width: '48%' }}
                value={agentPhone}
                onChange={(e) => {
                  setAgentPhone(inputPhoneMasking(e.target.value));
                  setAgentPhoneError('');
                }}
                required={true}
                errorMessage={agentPhoneError}
              />
            </div>

          </div>
          <div className="dash_heading_2" style={{ width: "100%", position: "relative" }}>
            <label>Case Information</label>
            {showEdit && (
              <div
                className="mwfbi_banner__edit"
                style={{ top: "2rem", right: "-52rem" }}
                id="admin-edit"
                onClick={() => setShowFieldTypeModal(true)}
              >
                <svg>
                  <use xlinkHref="/sprite.svg#icon-edit" />
                </svg>
              </div>
            )}

          </div>
          {resultArr && resultArr.map((field, parentIndex) => {
            return (
              <div
                key={'parent' + parentIndex}
                id={'parent' + parentIndex}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                {field && field.map((item, index) => {
                  switch (item?.FieldInputType) {
                    case "Textbox":
                      return (
                        <div
                          key={'text' + index + parentIndex + item.FieldLabel}
                          id={'text' + index + parentIndex + item.FieldLabel}
                          style={{ width: "48%", marginBottom: "3rem" }}
                        >
                          <FormInputComponent
                            heading={
                              item.FieldLabel !== "undefined"
                                ? item.FieldLabel
                                : null
                            }
                            placeholder={
                              item.PlaceHolder ? item.PlaceHolder : null
                            }
                            style={{ width: "100%" }}
                            requiredStar={
                              item.FieldLabel !== "undefined"
                                ? item.RequiredStatus
                                : false
                            }
                            value={item.selectedValue}
                            onChange={(e) => {
                              let temp = [...resultArr];
                              temp[parentIndex][index].selectedValue =
                                e.target.value;
                              setResultArr(temp);
                            }}
                            errorMessage={currentInsuranceError}
                          />
                          <p key={'error' + parentIndex + index} className="form_input__error">{item.error}</p>
                        </div>
                      );
                    case "TextboxSingle":
                      return (
                        <div
                          key={'text' + index + parentIndex + item.FieldLabel}
                          id={'text' + index + parentIndex + item.FieldLabel}
                          style={{ width: "100%", marginBottom: "3rem" }}
                        >
                          <FormInputComponent
                            heading={
                              item.FieldLabel !== "undefined"
                                ? item.FieldLabel
                                : null
                            }
                            placeholder={
                              item.PlaceHolder ? item.PlaceHolder : null
                            }
                            style={{ width: "100%" }}
                            requiredStar={
                              item.FieldLabel !== "undefined"
                                ? item.RequiredStatus
                                : false
                            }
                            value={item.selectedValue}
                            onChange={(e) => {
                              let temp = [...resultArr];
                              temp[parentIndex][index].selectedValue =
                                e.target.value;
                              setResultArr(temp);
                            }}
                            errorMessage={currentInsuranceError}
                          />
                          <p key={'error' + parentIndex + index} className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Date":
                      return (
                        <div
                          key={'datediv' + parentIndex + index + item.FieldLabel}
                          id={'datediv' + parentIndex + index + item.FieldLabel}
                          style={{ width: "48%", marginBottom: "6rem" }}
                        >
                          <FormInputComponent
                            key={index}
                            heading={item.FieldLabel !== "undefined" ? item.FieldLabel : null}
                            type={"date"}
                            placeholder={item.PlaceHolder ? item.PlaceHolder : ''}
                            requiredStar={
                              item.FieldLabel !== "undefined"
                                ? item.RequiredStatus
                                : false
                            }
                            value={item.selectedValue}
                            onChange={(e) => {
                              let temp = [...resultArr];
                              temp[parentIndex][index].selectedValue = e.target.value;
                              setResultArr(temp);
                            }}
                            errorMessage={item.error}
                          />
                          <p key={'errordate' + parentIndex + index} className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Textarea":
                      return (
                        <div
                          key={'areadiv' + parentIndex + index + item.FieldLabel}
                          id={'areadiv' + parentIndex + index + item.FieldLabel}
                          style={{ width: "100%", marginBottom: "2rem" }}
                        >
                          <FormInputComponent
                            height="140px"
                            type={"textarea"}
                            heading={
                              item.FieldLabel !== "undefined"
                                ? item.FieldLabel
                                : null
                            }
                            placeholder={
                              item.PlaceHolder ? item.PlaceHolder : null
                            }
                            style={{ width: "100%" }}
                            // required={
                            //   item.FieldLabel !== "undefined"
                            //     ? item.RequiredStatus
                            //     : false
                            // }
                            value={item.selectedValue}
                            onChange={(e) => {
                              let temp = [...resultArr];
                              temp[parentIndex][index].selectedValue =
                                e.target.value;
                              setResultArr(temp);
                            }}
                            errorMessage={currentInsuranceError}
                          />
                          <p key={'errorarea' + parentIndex + index} className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Select":
                      return (
                        <div
                          key={'select' + parentIndex + index + item.FieldLabel}
                          id={'select' + parentIndex + index + item.FieldLabel}
                          style={{ width: "48%" }}>
                          <label
                            key={'select-lable' + parentIndex + index}
                            htmlFor="State"
                            className="label_heading_quote"
                          >
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <div>
                            <select
                              key={'state' + parentIndex + index}
                              id={'state' + parentIndex + index}
                              name="State"
                              style={{ width: "100%", marginBottom: 0 }}
                              className="select__state__textbox1"
                              value={state}
                              onChange={(e) => {
                                setState(e.target.value);
                                let temp = [...resultArr];
                                temp[parentIndex][index].selectedValue =
                                  e.target.value;
                              }}
                            >
                              <option key={'stateoptiono' + parentIndex + index} value="">Select</option>
                              {commaSeprator(item.FieldValue).map((value, child) => (
                                <option key={'stateoption' + parentIndex + index + child} value={value}>{value}</option>
                              ))}
                            </select>
                          </div>
                          <p key={'stateperror' + parentIndex + index} className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Checkbox":
                      return (
                        <div
                          style={{ marginBottom: "2rem" }}
                          key={'checkdiv' + parentIndex + index + item.FieldLabel}
                          id={'checkdiv' + parentIndex + index + item.FieldLabel}
                          className="label_heading_quote"
                        >
                          <label key={'checklable' + parentIndex + index} className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <br />
                          {commaSeprator(item.FieldValue).map((value, childIndex) => (
                            <Fragment key={'frag' + parentIndex + index + childIndex}>
                              <input
                                type="checkbox"
                                id={value + "checkbox" + parentIndex + index + childIndex}
                                key={value + "checkbox" + parentIndex + index + childIndex}
                                // name={item.title}
                                style={{ marginRight: "2rem" }}
                                // checked={item.selectedValue.indexOf(value) > 0}
                                onChange={(e) => {
                                  let temp = [...resultArr];
                                  if (e.target.checked)
                                    temp[parentIndex][index].selectedValue.push(
                                      value
                                    );
                                  else {
                                    temp[parentIndex][
                                      index
                                    ].selectedValue.splice(
                                      temp[parentIndex][
                                        index
                                      ].selectedValue.indexOf(value),
                                      1
                                    );
                                  }
                                  setResultArr(temp);
                                }}
                              />
                              <label
                                key={'checklbll' + parentIndex + index}
                                className="label_heading_quote"
                                htmlFor="phone"
                                style={{ marginRight: "2rem" }}
                              >
                                {value}
                              </label>
                            </Fragment>
                          ))}
                          <p key={'checklablelerror' + parentIndex + index} className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Chips":
                      return (
                        <div
                          key={'chips' + parentIndex + index + item.FieldLabel}
                          id={'chips' + parentIndex + index + item.FieldLabel}
                          style={{ marginBottom: "2rem" }}
                          className="Insurance__list"
                        >
                          <label key={'chips-head' + parentIndex + index} className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <div key={'lsisscoc' + parentIndex + index} className="list__of__contents">
                            <div key={'listiis2' + parentIndex + index} className="list__of__contents2">
                              <ul>
                                {commaSeprator(item.FieldValue).map(
                                  (value, childIndex) => (
                                    <li
                                      key={'chipsitem' + parentIndex + index + childIndex}
                                      className={`list__of__contents1 ${item.selectedValue?.includes(value)
                                        ? "list__of__contents1_active"
                                        : ""
                                        }`}
                                      onClick={() => {
                                        let temp = [...resultArr];

                                        if (
                                          item.selectedValue?.includes(value)
                                        ) {
                                          temp[parentIndex][
                                            index
                                          ].selectedValue.splice(
                                            item.selectedValue.indexOf(value),
                                            1
                                          );
                                        } else {
                                          temp[parentIndex][
                                            index
                                          ].selectedValue = [
                                              ...temp[parentIndex][index]
                                                .selectedValue,
                                              value,
                                            ];
                                        }
                                        setResultArr(temp);
                                      }}
                                    >
                                      {value}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>

                          <p key={'ergtgsfvror' + parentIndex + index} className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Switch":
                      return (
                        <div
                          key={'switch' + parentIndex + index + item.FieldLabel}
                          id={'id' + parentIndex + index + item.FieldLabel}
                          style={{ marginTop: "2.4rem" }}>
                          <label key={'swithclabledl' + parentIndex + index} className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <CheckBox
                            text={
                              item.selectedValue ===
                                commaSeprator(item.FieldValue)[1]
                                ? commaSeprator(item.FieldValue)[1]
                                : commaSeprator(item.FieldValue)[0]
                            }
                            key={'checkoxx' + parentIndex + index}
                            /* checked={button2Status.value} */
                            onChange={(e) => {
                              let temp = [...resultArr];
                              if (e.target.checked) {
                                console.log(e.target.value, "check");
                                temp[parentIndex][index].selectedValue =
                                  commaSeprator(item.FieldValue)[1];
                              } else {
                                console.log("anil");
                                temp[parentIndex][index].selectedValue =
                                  commaSeprator(item.FieldValue)[0];
                              }
                              setResultArr(temp);
                            }}
                          />
                          <p key={'checbcoserror' + parentIndex + index} className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "Radio":
                      return (
                        <div
                          key={'radio' + index + parentIndex + item.FieldLabel}
                          id={'radio' + index + parentIndex + item.FieldLabel}
                          style={{ marginTop: "2.4rem" }}>
                          <label key={'radiohead' + parentIndex + index} className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <br />
                          <fieldset id={'gp' + item + parentIndex + index} style={{ border: 'none' }}>
                            {commaSeprator(item.FieldValue).map((elem) => (
                              <>
                                <input
                                  type="radio"
                                  name={'gp' + item + parentIndex + index}
                                  id={'gp' + item + parentIndex + index}
                                  value={elem}
                                  onChange={(e) => {
                                    /* setRadioValue(e.target.value) */
                                    console.log(e.target.value);
                                    let temp = [...resultArr];
                                    // eslint-disable-next-line eqeqeq
                                    if (e.target.checked == true) {
                                      console.log(e.target.checked, "check");
                                      temp[parentIndex][index].selectedValue =
                                        elem;
                                    } else {
                                      console.log(
                                        temp[parentIndex][index].selectedValue
                                      );
                                      temp[parentIndex][index].selectedValue = "";
                                    }
                                    setResultArr(temp);
                                  }}
                                />
                                <label
                                  className="label_heading_quote"
                                  htmlFor="phone"
                                  style={{
                                    marginLeft: "2rem",
                                    marginRight: "2rem",
                                  }}
                                >
                                  {elem}
                                </label>
                              </>
                            ))}
                          </fieldset>
                          <p key={'erroreerss' + parentIndex + index} className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "MultiSelect":
                      return (
                        <div
                          key={'multiselect' + parentIndex + index + item.FieldLabel}
                          id={'multiselect' + parentIndex + index + item.FieldLabel}
                          style={{
                            marginTop: "2.4rem",
                            marginBottom: "3.2rem",
                          }}
                        >
                          <label key={'multihead' + parentIndex + index} className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <br />

                          <MultiSelectDropdown
                            /* className="multi_dropdown_start_a_quote" */
                            options={dropDownValues(item)}
                            placeHolder={item.PlaceHolder && item.PlaceHolder}
                            key={'multiselectdrop' + parentIndex + index}
                            onChange={(e) => {
                              let vals = [];
                              e.map((val) => {
                                vals = [...vals, val.value];
                              });
                              let temp = [...resultArr];
                              temp[parentIndex][index].selectedValue = vals;
                              setResultArr(temp);
                            }}
                          />

                          <p key={'multiselcterror' + parentIndex + index} className="form_input__error">{item.error}</p>
                        </div>
                      );

                    case "FileUpload":
                      return (
                        <div
                          key={'upload' + parentIndex + index + item.FieldLabel}
                          id={'upload' + parentIndex + index + item.FieldLabel}
                          style={{
                            width: "100%",
                            marginTop: "2.4rem",
                            marginBottom: "3.2rem",
                          }}
                        >
                          <label key={'uploadhead' + parentIndex + index} className="label_heading_quote">
                            {item.FieldLabel}
                            {item.RequiredStatus ? (
                              <span className="red_star">&#42;</span>
                            ) : null}
                          </label>
                          <div key={'uploadClick' + parentIndex + index} className="upload_border" {...getRootProps({
                            onClick: (e) => {
                              fileIndex = [parentIndex, index]
                            }
                          })}>
                            <input key={'uploadKey' + parentIndex + index} {...getInputProps()} />
                            <label key={'uploadplca' + parentIndex + index}>{item.PlaceHolder ? item.PlaceHolder : 'Click or Drag a file to this area to upload'}</label>
                          </div>
                          {(
                            item &&
                            item.selectedValue && <label>{item.selectedValue.name}</label>
                          )}
                          {(
                            item &&
                            item.error && <p key={'upliaderror' + parentIndex + index} className="form_input__error">{item.error}</p>
                          )}
                        </div>)
                    default:
                      return null
                  }

                })}

              </ div >

            )
          })}


          <div style={{ width: "100%", position: "relative" }}>
            <button
              className="main__form_button"
              type="submit"
              onClick={onHandleForm}
            >
              Submit
            </button>
            {showEdit && (
              <div
                className="mwfbi_banner__edit"
                style={{ top: "2rem", right: "-52rem" }}
                id="admin-edit"
                onClick={() => setShowEmailEdit(true)}
              >
                <svg>
                  <use xlinkHref="/sprite.svg#icon-edit" />
                </svg>
              </div>
            )}
          </div>
        </div>
      </form>
      <EditFieldTypeModal
        handleYes={handleDynamicFormSubmit}
        handleYes1={handleDynamicFormUpdate}
        showModal={showFieldTypeModal}
        setShowModal={setShowFieldTypeModal}
        editFormData={editFormData}
        setEditFormData={setEditFormData}
        fieldTypeArr={fieldTypeArr}
        onFieldDelete={onFieldDelete}
        setFieldTypeArr={setFieldTypeArr}
        handleChangeFieldOrder={handleChangeFieldOrder}
      />
      <EditEmailAddressesModal
        showModal={showEmailEdit}
        setShowModal={setShowEmailEdit}
        page={"ERS"}
      />
    </Content >
  );

}
export default ERS;
