
import moment from 'moment-timezone';
export const TOPIC_LIST = [
    'Auto',
    'Business',
    'Events',
    'Farm & Ranch',
    'Life',
    'Home',
    'Financial',
    'Community',
    'Cyber',
    'Renters',
    'Holidays',
    'Umbrella',
    'Insurance Basics',
    'Claims'
].sort()

export const getCustomLocalDate = (date) => {
    try {
        const offset = new Date().getTimezoneOffset()
        const localDate = new Date(date);
        localDate.setMinutes(localDate.getMinutes() + offset)
        return localDate;
    } catch (error) {
        throw error
    }

}
export const getTimeZoneLocalDate = (dt) => {
    try {
        // let date = new Date(dt)
        return moment.utc(dt).local()
        // return moment.tz(date, moment.tz.guess()).add(1,'days')
        // return moment.tz(date, moment.tz.guess()).subtract(new Date().getTimezoneOffset(), 'minutes')
        // var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        // var offset = date.getTimezoneOffset() / 60;
        // var hours = date.getHours();

        // newDate.setHours(hours - offset);
        // console.log(dt + moment.tz.guess(), newDate)
        // return moment(newDate)
    } catch (error) {
        throw error
    }

}