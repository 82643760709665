import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'

const EditInsuranceSpecialType = ({
  setShowModal,
  showModal,
  handleYes,
  section10,
  setSection10,
  handleYes2,
  section11,
  setSection11,
  handleYes3,
  section12,
  setSection12,
}) => {
  const [loading, setLoading] = useState(false)

  const [title, setTitle] = useState({ value: '', error: '' })

  const [editIndex, setEditIndex] = useState(-1)

  useEffect(() => {}, [])

  const resetAll = () => {
    setTitle({ value: '', error: '' })
    setEditIndex(-1)
  }

  const onEdit = (item, index) => {
    setEditIndex(index)
    setTitle({
      ...title,
      value: item.title.value,
    })
  }

  const onDelete = (item, index) => {
    if (window.confirm('Are You Sure to Delete?')) {
      let temp = [...section10]
      temp.splice(index, 1)
      setSection10(temp)
      handleYes(temp)
    }
  }

  const onAdd = () => {
    let valid = true

    if (title.value === '' && title.value.trim() === '') {
      setTitle({
        ...title,
        error: 'Please enter title',
      })
      valid = false
    }

    if (valid) {
      let content = {
        title: { value: title.value, action: '', type: '' },
      }

      if (editIndex > -1) {
        const temp = [...section10]
        temp[editIndex] = content

        setSection10(temp)
        handleYes(temp)
      } else {
        const temp = [...section10]
        temp.push(content)
        setSection10(temp)
        handleYes(temp)

      }
    }
  }

  const submit = (e) => {
    e.preventDefault()

    if (section11.title.value === '' && section11.title.value.trim() === '') {
      let temp = { ...section11 }
      temp.title.error = 'Please enter title!'
      setSection11(temp)
      return
    } else {
      let temp = { ...section11 }
      temp.title.error = ''
      setSection11(temp)
    }
    if (
      section11.subTitle1.value === '' &&
      section11.subTitle1.value.trim() === ''
    ) {
      let temp = { ...section11 }
      temp.subTitle1.error = 'Please enter sub title 1!'
      setSection11(temp)
      return
    } else {
      let temp = { ...section11 }
      temp.subTitle1.error = ''
      setSection11(temp)
    }
    if (
      section11.subTitle2.value === '' &&
      section11.subTitle2.value.trim() === ''
    ) {
      let temp = { ...section11 }
      temp.subTitle2.error = 'Please enter sub title 2!'
      setSection11(temp)
      return
    } else {
      let temp = { ...section11 }
      temp.subTitle2.error = ''
      setSection11(temp)
    }
    if (
      section11.subTitle3.value === '' &&
      section11.subTitle3.value.trim() === ''
    ) {
      let temp = { ...section11 }
      temp.subTitle3.error = 'Please enter sub title 3!'
      setSection11(temp)
      return
    } else {
      let temp = { ...section11 }
      temp.subTitle3.error = ''
      setSection11(temp)
    }

    if (section12.title.value === '' && section12.title.value.trim() === '') {
      let temp = { ...section12 }
      temp.title.error = 'Please enter title!'
      setSection12(temp)
      return
    } else {
      let temp = { ...section12 }
      temp.title.error = ''
      setSection12(temp)
    }
    if (
      section12.subTitle1.value === '' &&
      section12.subTitle1.value.trim() === ''
    ) {
      let temp = { ...section12 }
      temp.subTitle1.error = 'Please enter sub title 1!'
      setSection12(temp)
      return
    } else {
      let temp = { ...section12 }
      temp.subTitle1.error = ''
      setSection12(temp)
    }
    if (
      section12.subTitle2.value === '' &&
      section12.subTitle2.value.trim() === ''
    ) {
      let temp = { ...section12 }
      temp.subTitle2.error = 'Please enter sub title 2!'
      setSection12(temp)
      return
    } else {
      let temp = { ...section12 }
      temp.subTitle2.error = ''
      setSection12(temp)
    }

    handleYes2()
    handleYes3()

    setShowModal(false)
  }

  return (
    <Modal show={showModal}>
      <form>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>
              Edit Auto Insurance Special Type
            </h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={title.value}
                    onChange={(e) => {
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={title.error}
                  />
                </div>
              </div>
              <div
                className='modal_inner_content--right'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}>
                <div
                  className='modal_dark_buttons'
                  style={{ padding: '0.5rem' }}></div>
                <div
                  style={{
                    display: 'flex',
                    gap: 30,
                  }}>
                  <button
                    type='button'
                    className='modal__button-reset-dark'
                    onClick={resetAll}>
                    Reset
                  </button>
                  <button
                    type='button'
                    className='modal__button-dark'
                    onClick={onAdd}>
                    {loading ? 'Saving..' : editIndex > -1 ? 'Save' : 'Add'}
                  </button>
                </div>
              </div>
            </div>
            <div className='modal_inner_content'>
              <div
                className='modal_form_container'
                style={{
                  height: '10rem',
                }}>
                <table
                  className='modal__form-table'
                  cellSpacing='0'
                  cellPadding='0'>
                  <thead className='modal__form-sticky'>
                    <tr className='modal__form-tr'>
                      <th>No.</th>
                      <th>Title</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {section10 &&
                      section10.length > 0 &&
                      section10.map((item, i) => {
                        return (
                          <tr className='modal__form-tr2' key={i}>
                            <td>{i + 1}</td>
                            <td>{item.title.value}</td>
                            <td className='modal__form-buttons'>
                              <button
                                type='button'
                                className='modal__button-edit'
                                onClick={(e) => onEdit(item, i)}>
                                edit
                              </button>
                              <button
                                type='button'
                                className='modal__button-delete'
                                onClick={(e) => onDelete(item, i)}>
                                delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            style={{
              width: '96%',
              marginTop: '4px',
              height: '0.5px',
              backgroundColor: '#9e8d8d',
              alignSelf: 'center',
            }}></div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section11.title.value}
                    onChange={(e) => {
                      let temp = { ...section11 }
                      temp.title.value = e.target.value
                      setSection11(temp)
                    }}
                    errorMessage={section11.title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title 1:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section11.subTitle1.value}
                    onChange={(e) => {
                      let temp = { ...section11 }
                      temp.subTitle1.value = e.target.value
                      setSection11(temp)
                    }}
                    errorMessage={section11.subTitle1.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title 2:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section11.subTitle2.value}
                    onChange={(e) => {
                      let temp = { ...section11 }
                      temp.subTitle2.value = e.target.value
                      setSection11(temp)
                    }}
                    errorMessage={section11.subTitle2.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title 3:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section11.subTitle3.value}
                    onChange={(e) => {
                      let temp = { ...section11 }
                      temp.subTitle3.value = e.target.value
                      setSection11(temp)
                    }}
                    errorMessage={section11.subTitle3.error}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right2'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section12.title.value}
                    onChange={(e) => {
                      let temp = { ...section12 }
                      temp.title.value = e.target.value
                      setSection12(temp)
                    }}
                    errorMessage={section12.title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title 1:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section12.subTitle1.value}
                    onChange={(e) => {
                      let temp = { ...section12 }
                      temp.subTitle1.value = e.target.value
                      setSection12(temp)
                    }}
                    errorMessage={section12.subTitle1.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title 2:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section12.subTitle2.value}
                    onChange={(e) => {
                      let temp = { ...section12 }
                      temp.subTitle2.value = e.target.value
                      setSection12(temp)
                    }}
                    errorMessage={section12.subTitle2.error}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className='modal_dark_buttons'
            style={{
              paddingTop: '0',
            }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditInsuranceSpecialType
