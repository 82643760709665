import React, { useState, useEffect } from 'react';
import Content from '../../components/Content/Content';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormInputComponent from '../../components/FormInputComponent/FormInputComponent';

import Button from '../../components/Inputs/Button';
// import TextInput from '../../components/Inputs/TextInput';

import './LoginScreen.css';
import { loginAction, loginAzureAction, azureLogin } from '../../redux/actions/userActions';

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

const LoginScreen = ({ history }) => {
	const dispatch = useDispatch();
	const { instance } = useMsal();
	const [checked, setChecked] = useState('checked1');

	const [userName, setUserName] = useState({ value: '', error: '' });
	const [password, setPassword] = useState({ value: '', error: '' });

	const userLogin = useSelector((state) => state.userLogin);

	useEffect(
		() => {
			if (userLogin && userLogin.userInfo && userLogin.userInfo.result && userLogin.userInfo.result.accessToken) {
				history.push('/');
			}
		},
		[userLogin]
	);

	const handleLoginSubmit = (e) => {
		e.preventDefault();
		dispatch(loginAction({ username: userName.value, password: password.value }));
	};
	const handleAzureLoginSubmit = (e) => {
		e.preventDefault();
		// loginAzureAction()
		// 	.then(res => {
		// 		console.log(res);
		// 	}, error => {
		// 		console.log(error);
		// 	})
		instance.loginPopup(loginRequest)
			.then(res => {
				if (res.account) {
					localStorage.setItem('az', JSON.stringify(res))
					dispatch(azureLogin(res))
				}
			})
			.catch(e => {
				console.log(e);
			});
	};

	return (
		<Content headerFooterMenu="mountain-west" history={history}>
			<div
				className="mw_slider"
				style={{
					backgroundImage: 'url(/images/header-img.jpg) '
				}}
			>
				<div className="mw_slider__left">
					<h1 className="mw_slider__heading">Local Insurance</h1>
					<h3 className="mw_slider__subheading">
						For 360 Insurance Farm Owners, Ranchers, Families and Business Owners.
					</h3>
				</div>
			</div>
			<div className="login_main_content">
				{/* <div div class="tabset">
					<input
						type="radio"
						name="tabset"
						id="tab1"
						aria-controls="tab-about"
						checked={checked === 'checked1'}
						onChange={() => setChecked('checked1')}
					/>
					<label for="tab1">About</label>

					<input
						type="radio"
						name="tabset"
						id="tab2"
						aria-controls="tab-blog"
						checked={checked === 'checked2'}
						onChange={() => setChecked('checked2')}
					/>
					<label for="tab2">Blog</label>


					<div class="tab-panels">
						<section id="tab-about" class="tab-panel">
							<p className="tab_about__data">
								Whether you are a new home owner, or starting your business - you can rely on our
								experience to help you cover what matters most. <br /> Mountain West is a multi-line
								insurance company, serving the needs of individuals, families, and businesses throughout
								Wyoming and Montana for over 70 years. Our mission is to provide financial peace of mind
								of our customers with competitive, quality products and services.
							</p>
							<div className="mw_button2">
								<Link to="/mwfbi/about-us" className="mw_button">
									About Us
								</Link>
							</div>
						</section>
						<section id="tab-blog" class="tab-panel">
							<h3 className="tab_blog__data">Strengthen Your Mind - September 27th</h3>
							<h3 className="tab_blog__data">Traveling on Budget - October 13th</h3>
							<h3 className="tab_blog__data">Taking Steps to have a Better day - August 27th</h3>
						</section>
						
					</div>
				</div> */}

				<div className="login_container">
					<h1 className="phase2_heading1">LOGIN TO YOUR ACCOUNT</h1>
					<br />
					{/* <FormInputComponent
						heading="Username/Email"
						placeholder="Enter your username or email address"
						style={{ width: '100%' }}
						required={true}
						value={userName.value}
						onChange={(e) => setUserName({ value: e.target.value, error: '' })}
					/>
					<br />

					<FormInputComponent
						heading="Password"
						placeholder="•••••••••••••••••••"
						type="password"
						style={{ width: '100%' }}
						required={true}
						value={password.value}
						onChange={(e) => setPassword({ value: e.target.value, error: '' })}
					/>
					<div className="login_button">
						{userLogin && userLogin.loading ? (
							<Button label="loggin in" varient="red" icon="/sprite.svg#icon-login" />
						) : (
							<Button
								label="login"
								varient="red"
								icon="/sprite.svg#icon-login"
								onClick={handleLoginSubmit}
							/>
						)}
					</div> */}
					<div className="login_button">
						{userLogin && userLogin.loading ? (
							<Button label="loggin in" varient="red" icon="/sprite.svg#icon-login" />
						) : (
							<Button
								label="Azure Login"
								varient="red"
								icon="/sprite.svg#icon-login"
								onClick={handleAzureLoginSubmit}
							/>
						)}
					</div>
				</div>
			</div>
			<div className="mw_products">
				<div className="mw_products__left">
					<h3 className="mw_products__heading">
						Choose a product <span> that's right for you.</span>
					</h3>
					<div className="hide_button">
						<Button label="view all products" varient="red" icon="/sprite.svg#icon-search" />
					</div>
				</div>
				<div className="mw_products__right">
					<Link to="#" className="mw_products__item">
						<img src="/images/product-1.png" alt="Product 1" className="mw_products__item--image" />
						<p className="mw_products__item--name">Farm & Ranch</p>
					</Link>
					<Link to="#" className="mw_products__item">
						<img src="/images/product-4.png" alt="Product 2" className="mw_products__item--image" />
						<p className="mw_products__item--name">Auto</p>
					</Link>
					<Link to="#" className="mw_products__item">
						<img src="/images/product-3.png" alt="Product 3" className="mw_products__item--image" />
						<p className="mw_products__item--name">Home</p>
					</Link>
					<Link to="#" className="mw_products__item">
						<img src="/images/product-2.png" alt="Product 4" className="mw_products__item--image" />
						<p className="mw_products__item--name">Business</p>
					</Link>
				</div>
				<div className="hide_button1">
					<Button label="view all products" varient="red" icon="/sprite.svg#icon-search" />
				</div>
			</div>
		</Content>
	);
};

export default LoginScreen;
