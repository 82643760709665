import React, { Fragment, useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom'
import axios from 'axios';
import Modal from '../../../components/Modal';
import { BASE_URL, PAYMENT_BASE_URL } from '../../../redux/actions/ip';
const qs = require('qs');
// const BASE_URL = 'http://localhost:8080';

const API_BASE_URL = 'https://stgportalone.processonepayments.com';

const PaymentModal = ({ setPaymentModal, paymentModal }) => {
	const [accountNumber, setAccountNumber] = useState({ value: '', error: '' });
	const [name, setName] = useState({ value: '', error: '' });
	const [loading, setLoading] = useState(false);
	const [sessionId, setSessionId] = useState('');
	const [accessTokenId, setAccessTokenId] = useState('');
	const [currentStatus, setCurrentStatus] = useState([]);

	useEffect(
		() => {
			if (sessionId !== '' && accessTokenId !== '') {
				setPaymentModal(false);
				setAccountNumber({ value: '', error: '' });
				setName({ value: '', error: '' });
				// let container = window.$('#portalOneContainer');
				// //instantiate modal
				// container.portalOne();
				// let portalOne = container.data('portalOne');

				// portalOne.run({
				// 	sessionId,
				// 	accessTokenId,
				// 	displayMode: 'Modal',
				// 	allowClosing: 'true'
				// });

				// portalOne.makePayment({
				// 	paymentCategory: 'CreditCard',
				// 	feeContext: 'PaymentWithFee',
				// 	amountContext: 'SelectOrEnterAmount',
				// 	minAmountDue: '12.00',
				// 	accountBalance: '120.00',
				// 	billingZip: '95630',
				// 	billingAddressStreet: '602 Coolidge Dr., Folsom, CA',
				// 	policyHolderName: 'John Smith',
				// 	clientReferenceData1: 'POL330701-02',
				// 	confirmationDisplay: 'true',
				// 	saveOption: 'Save',
				// 	accountGroupCode: 'FallsLake',
				// 	token: accessTokenId,
				// 	acknowledgmentRequired: 'true',
				// 	acceptCreditCards: 'true',
				// 	acceptPrepaidCards: 'true',
				// 	customerId: 'e3a6fa12-d448-4686-a5ff-7f821f0b0c5d',
				// 	sessionId: sessionId,
				// 	isScheduledPayEnabled: true,
				// 	finalPayDate: '2022-03-05',
				// 	email: 'example@example.com',
				// 	convenienceFeeType: 'Default',
				// 	scheduledPaymentEngineType: 'ScheduledPaymentEngine'
				// });
			}
		},
		[sessionId, accessTokenId]
	);

	const handlePayment = async () => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		if (accountNumber.value === '' || accountNumber.value.trim() === '') {
			setAccountNumber({ ...accountNumber, error: 'Please enter your account number' });
			// 	 else if (name.value === '' || name.value.trim() === '') {
			// 	setName({ ...name, error: 'Please enter your name' });
			// }
		} else {
			try {
				setLoading(true);
				const tokenResponse = await axios.post(`${PAYMENT_BASE_URL}/auth/signin-microsoft/${accountNumber.value}/test`, { accountNumber: accountNumber.value }, config);
				console.log('Res', tokenResponse.data);
				if (tokenResponse && tokenResponse.data.status) {
					setSessionId(tokenResponse.data.sessionKey);
					setTimeout(() => {
						setAccessTokenId(tokenResponse.data.accessToken);
						setLoading(false);
						setCurrentStatus([]);
					}, 5000);
					// setCurrentStatus([...currentStatus, { id: 1, status: 'Creating Account...' }]);
					let container = window.$('#portalOneContainer');
					//instantiate modal
					container.portalOne();
					let portalOne = container.data('portalOne');
				
					portalOne.run({
						sessionId: tokenResponse.data.sessionKey,
						accessTokenId: tokenResponse.data.accessToken,
						displayMode: 'Modal',
						allowClosing: 'true'
					});


				} else {
					window.open('https://ipn.paymentus.com/rotp/i360', '_blank');
					setPaymentModal(false);
					setAccountNumber({ value: '', error: '' });
					setName({ value: '', error: '' });
					setLoading(false);
				}
			} catch (error) {
				setName({ ...name, error: 'Something went wrong' });
				setLoading(false);
			}
		}
	};


	return (
		<Modal show={paymentModal}>
			<div className='modal__container'>
				<div className='modal_heading__container'>
					<h1 className='modal_heading__text'>Manage Payment Method</h1>
					<button className='modal__heading--button' onClick={() => setPaymentModal(false)}>
						<svg className='modal_heading__icon'>
							<use xlinkHref='/sprite.svg#icon-close' />
						</svg>
					</button>
				</div>
				<div className='modal__form'>
					<label htmlFor='accno' className='modal__form--label'>
						Account Number
					</label>
					<input
						type='text'
						id='accno'
						value={accountNumber.value}
						onChange={(e) => setAccountNumber({ value: e.target.value })}
						placeholder='Your account number'
						className='modal__form--input'
					/>
					{accountNumber.error && <p className='modal__form--error'>{accountNumber.error}</p>}
				</div>
				{/* <div className='modal__form'>
					<label htmlFor='accno' className='modal__form--label'>
						Name
					</label>
					<input
						type='text'
						id='accno'
						value={name.value}
						onChange={(e) => setName({ value: e.target.value })}
						placeholder='Your name'
						className='modal__form--input'
					/>
					{name.error && <p className='modal__form--error'>{name.error}</p>}
				</div> */}
				{loading ? (
					<button className='modal__button'>Please wait...</button>
				) : (
					<button className='modal__button' onClick={handlePayment}>
						Make Payment
					</button>
				)}
				{currentStatus && currentStatus.length > 0 && currentStatus.map((status, i) => <p key={i}>{status.status}</p>)}
			</div>
		</Modal>
	);
};

export default PaymentModal;
