import axios from 'axios';
import { AGENT_LIST_REQUEST, AGENT_LIST_SUCCESS, AGENT_LIST_FAIL } from '../constants/userConstants';
import { BASE_URL } from './ip';

export const getAgentList = () => async (dispatch) => {
	try {
		dispatch({ type: AGENT_LIST_REQUEST });

		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/cms/get-page-content-item-list`, { key: "Agent", client: "360insurance" }, config);

		dispatch({
			type: AGENT_LIST_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: AGENT_LIST_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
