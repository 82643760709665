import React, { Fragment, useState, useEffect, useCallback } from "react";
// import { useHistory } from 'react-router-dom'
import Modal from "../../../components/DarkModal";
import FormInputComponent from "../../../components/FormInputComponent/FormInputComponent";
import Styles from './blogSettings.module.css'
import { deleteTopicById, editStatusByTopicId, getAllMainTopicsAPI, getAllTopics } from "../../../redux/actions/blogListAction";
import { useDispatch } from "react-redux";
import { TOPIC_LIST } from "../../../constants";

const EditFieldTypeModal = ({
  setShowModal,
  showModal,
  handleYes,
  topicList,
  getAllBlogs,
  editFormData,
  setEditFormData,
  handleYes1,
  addNewTopic,
  topicsData
}) => {
  const [loading, setLoading] = useState(false);
  const [fieldType, setFieldType] = useState({
    value: "Select Field Type",
    error: "",
  });
  const [fieldInputType, setFieldInputType] = useState({
    value: "Select Input Type",
    error: "",
  });
  const [topic, setTopic] = useState({
    value: "Select Topic",
    error: "",
  });
  const [label, setLabel] = useState({ value: "", error: "" });
  const [tagTitle, setTagTitle] = useState({ value: "", error: "" });
  const [blogListData, setBlogListData] = useState()
  // const [topicsData, setTopicsData] = useState([])
  const [fieldValue, setFieldValue] = useState({ value: "", error: "" });
  const [placeholder, setPlaceholder] = useState({ value: "", error: "" });
  const [validationType, setValidationType] = useState({ value: "None", error: "" });
  const [requiredType, setRequiredType] = useState({
    value: "False",
    error: "",
    checked: false
  });
  const [mainTopics, setMainTopics] = useState([]);

  const [fieldId, setFieldId] = useState('');
  const [currentStatus, setCurrentStatus] = useState();




  const [editIndex, setEditIndex] = useState(-1);
  const [editData, setEditData] = useState({
    tagTitle: { value: "", error: "" },
    topic: { value: "", error: "" },
  });

  const dispatch = useDispatch()

  const tagStatusChange = (e, item) => {
    editStatusByTopicId({ id: item.topicId, mainTopicId: item.mainTopicId, tag: item.tag, title: item.title, status: e.target.checked ? 1 : 0 }).then((res) => {
      if (res.data.status) {
        dispatch(getAllTopics());
      }
    })
    // console.log(e.target.checked, topicId)
  }



  const handleDiscountSubmit = (e) => {
    e.preventDefault();
    if (tagTitle.value === "") {
      setTagTitle({ ...tagTitle, error: "Please enter the tag title !" });
      return
    }
    if (topic.value === "Select Topic") {
      setTopic({
        ...topic,
        error: "Please select a Topic !",
      });
      return
    }
    clearErrors();
    let content = {
      tag: tagTitle.value,
      mainTopicId: parseInt(topic.value),
      status: 1,
    };
    if (editIndex > -1) {
      console.log(editFormData, 'asas')
      let editContent = {
        tag: tagTitle.value,
        mainTopicId: parseInt(topic.value),
        status: currentStatus ? 1 : 0,
        id: fieldId
      };
      if (tagTitle.value === "") {
        setTagTitle({
          ...tagTitle,
          error: "Please enter the tag title !",
        });
        return
      }
      const temp = [...editFormData];
      temp[editIndex] = editContent;
      /* setCurrentItems(temp) */
      setEditFormData(temp);
      handleYes1(editContent);

      clear();
    } else {
      /* setCurrentItems([...discountListArr, content]) */
      // setEditFormData([...editFormData, content]);
      handleYes(content);

      /* console.log("added", editFormData); */
      clear();
    }
  };

  React.useEffect(() => {
    setBlogListData(topicList)
  }, [topicList, getAllTopics]);

  const onEdit = (e, item, index) => {
    e.preventDefault();
    setFieldId(item.topicId)
    // setLoading(true);
    setCurrentStatus(item.status)
    setEditData(item);
    setEditIndex(index);
    setTagTitle({ ...tagTitle, value: item.tag });
    setTopic({ ...topic, value: item.mainTopicId });
    // setUrl({ ...url, value: item.url });
  };
  const clear = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      fieldType: { value: "", error: "" },
      fieldInputType: { value: "", error: "" },
      label: { value: "", error: "" },
      fieldValue: { value: "", error: "" },
      placeholder: { value: "", error: "" },
      requiredType: { value: "", error: "" },
      validationType: { value: "", error: "" },
    });
    setLabel({ value: "", error: "" });
    setFieldValue({ value: "", error: "" });
    setPlaceholder({ value: "", error: "" });
    setFieldType({ value: "", error: "" });
    setFieldInputType({ value: "", error: "" });
    setLabel({ value: "", error: "" });
    setValidationType({ value: "", error: "" });
    setEditIndex(-1);
    // handleDiscountSubmit()
  };
  const closeModal = () => {
    resetAll();
    setShowModal(false);
  };
  const resetAll = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      tagTitle: { value: "", error: "" },
      topic: { value: "", error: "" },
    });
    setEditIndex(-1);
    setTagTitle({ ...tagTitle, value: "", error: "" });
    setTopic({ ...topic, value: "", error: "" });
  };
  const clearErrors = () => {
    setEditIndex(-1);
    setTagTitle({ ...tagTitle, value: "", error: "" });
    setTopic({ ...topic, value: "", error: "" });
  };



  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className="modal__container-dark">
          <div className="modal_heading__container-dark">
            <h1 className="modal_heading__text-dark">Manage Tags</h1>
            <button
              className="modal__heading--button-dark"
              onClick={() => closeModal()}
            >
              <svg className="modal_heading__icon-dark">
                <use xlinkHref="/sprite.svg#icon-cross" />
              </svg>
            </button>
          </div>
          <div className="modal__form-dark">
            <div className="modal_inner_content">
              <div className="modal_inner_content--left">
                <div className="inline-form-view">
                  <FormInputComponent
                    heading="Tag title:"
                    style={{ width: "70%" }}
                    required={true}
                    value={tagTitle.value}
                    onChange={(e) => {
                      setTagTitle({ value: e.target.value, error: "" });
                    }}
                    errorMessage={tagTitle.error}
                  />
                </div>
                <div className="inline-form-view">
                  <div className="select_field" style={{ width: "100%" }}>
                    <label
                      style={{
                        display: "block",
                        fontSize: "1.6rem",
                        color: "grey",
                        fontWeight: "500",
                      }}
                    >
                      Topic:
                      <span className="red_star">&#42;</span>
                    </label>
                    <select
                      id="fieldInputType"
                      name="fieldInputType"
                      style={{ marginBottom: "1rem", padding: '1rem' }}
                      className="select__state__fieldtype"
                      required={true}
                      value={topic.value}
                      onChange={(e) => {
                        setTopic({ value: e.target.value, error: "" });
                      }}
                    >
                      <option value={"Select Topic"}>
                        Select Topic
                      </option>

                      {
                        topicsData &&
                        topicsData.map((item, id) => <option selected={item.mainTopicId === topic.value} value={item.mainTopicId} key={id}>{item.title}</option>)}
                    </select>
                    <button
                      style={{ marginLeft: '1rem' }}
                      type="button"
                      className="modal__button-dark"
                      onClick={addNewTopic}
                    >
                      Add New Topic
                    </button>

                  </div>
                </div>
                {topic.error !== "" ? (
                  <label
                    style={{
                      fontSize: "1.2rem",
                      color: "#e02b21",
                      marginTop: "-.5rem",
                    }}
                  >
                    {topic.error}
                  </label>
                ) : null}

                {fieldInputType.value === "Textbox" && <div className="inline-form-view">
                  <div className="select_field" style={{ width: "100%" }}>
                    <label
                      style={{
                        display: "block",
                        fontSize: "1.6rem",
                        color: "grey",
                        fontWeight: "500",
                      }}
                    >
                      Select Validation Type:
                    </label>
                    <select
                      id="validationType"
                      name="validationType"
                      style={{ marginBottom: "1rem" }}
                      className="select__state__fieldtype"
                      required={false}
                      value={validationType.value}
                      onChange={(e) => {
                        setValidationType({ value: e.target.value, error: "" });
                      }}
                    >
                      <option value={"None"}>
                        None
                      </option>

                      <option value={"Any Input"}>Any Input</option>

                      <option value={"Only Text"}>Only Text</option>

                      <option value={"Only Number"}>Only Number</option>

                      <option value={"Email"}>Email</option>

                      <option value={"Phone"}>Phone</option>

                      <option value={"Zip"}>Zip</option>

                    </select>
                  </div>
                </div>}
                {fieldInputType.value === "Textbox" && validationType.error !== "" ? (
                  <label
                    style={{
                      fontSize: "1.2rem",
                      color: "#e02b21",
                      marginTop: "-.5rem",
                    }}
                  >
                    {validationType.error}
                  </label>
                ) : null}

                <div className="inline-form-view">

                </div>
                {requiredType.error !== "" ? (
                  <label
                    style={{
                      fontSize: "1.2rem",
                      color: "#e02b21",
                      marginTop: "-.5rem",
                    }}
                  >
                    {requiredType.error}
                  </label>
                ) : null}
              </div>

              <div style={{ alignItems: 'end' }} className="modal_inner_content--right3">

                <div
                  className="modal_dark_buttons"
                  style={{ padding: "0.5rem" }}
                ></div>
                <div style={{ display: "flex", gap: 12 }}>
                  <button
                    type="button"
                    className="modal__button-reset-dark"
                    onClick={resetAll}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="modal__button-dark"
                    onClick={handleDiscountSubmit}
                  >
                    {loading ? "Saving.." : editIndex > -1 ? "Save" : "Add"}
                  </button>
                </div>
              </div>
            </div>
            <div className="modal_inner_content">
              <div className="modal_form_container">
                <table
                  className="modal__form-table"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ background: "#c4c4c4" }}
                >
                  <thead className="modal__form-sticky">
                    <tr className="modal__form-tr">
                      <th style={{ width: "15rem" }}>No.</th>
                      <th style={{ width: "15rem" }}>Topic</th>
                      <th style={{ width: "15rem" }}>Tag</th>
                      <th style={{ width: "15rem" }}>Status</th>
                      <th style={{ width: "15rem" }}>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      blogListData &&
                      blogListData?.topicsList &&
                      blogListData?.topicsList?.data &&
                      blogListData?.topicsList?.data && blogListData.topicsList.data.map((item, i) => {
                        return (
                          <tr className="modal__form-tr2" key={i}>
                            <td>{i + 1}</td>
                            <td>{item.mainTitle}</td>
                            <td>{item.tag}</td>
                            <td>
                              <label className={Styles.switch}>
                                <input
                                  // id={d._id}
                                  // checked={d.active}
                                  checked={item.status}
                                  onChange={(e) => {
                                    tagStatusChange(e, item)
                                    // window.location.reload();
                                  }}
                                  type='checkbox'
                                  className={Styles.checkbox}
                                  name='active'
                                />
                                <span className={`${Styles.slider} ${Styles.round}`} />
                              </label>
                            </td>

                            <td className="modal__form-buttons">
                              {item.softDelete ? <label style={{ margin: 'auto', paddingTop: '5px' }}>Deleted</label> : <><button
                                type="button"
                                style={{ marginRight: '5rem' }}
                                className="modal__button-edit"
                                onClick={(e) => onEdit(e, item, i)}
                              >
                                edit
                              </button>
                                {/* <button
                                  type="button"
                                  className="modal__button-delete"
                                  onClick={(e) => onFieldDelete(e, item, i)}
                                >
                                  delete
                                </button> */}
                              </>}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className='ms-pagination2'>
              <h1 className='paginator_head'>
                {' '}
                Showing {itemOffset + 1} - {itemOffset + currentItems?.length}{' '}
                of {discountListArr.length} results
              </h1>

              <ReactPaginate
                nextClassName={''}
                breakLabel='...'
                nextLabel='>>'
                pageCount={pageCount}
                onPageChange={onPageChange}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                previousLabel='<<'
                // renderOnZeroPageCount={null}
                containerClassName={'paginationBttns'}
                previousLinkClassName={'previousBttn'}
                nextLinkClassName={'nextBttn'}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginationActive'}
              />
            </div> */}
          </div>

          {/* <div className='modal_dark_buttons' style={{ paddingTop: '0' }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={(e) => handleDiscountSubmit()}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal>
  );
};

export default EditFieldTypeModal;
