import React from 'react';
import Banner from '../../../components/Banner/Banner';
import Content from '../../../components/Content/Content';
import Button from '../../../components/Inputs/Button';
import { Link } from 'react-router-dom';
import Slider from './slider';
import SimpleButton from '../../../components/Inputs/SimpleButton';

import './Careers.css';
import slider from './slider';

// const slider = () => {};

const Careers = (history) => {
	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="linear-gradient(rgba(47,35,98,0.4), rgba(47,35,98,0.4)), url(/images/careers-build.jpg)"
				title="Careers"
				subTitle="A team of peers on a bold mission"
			/>
			<div className="working_at__main-content-margin">
				<h1 className="working_at__heading__heading_1">Working at Mountain West</h1>
				<div className="working_at_home_outer">
					<div className="working_at_home">
						<p className="working_at__texts-heading_2">
							Here at Mountain West, our employees are our greatest asset. Our fantastic team of employees
							is what allows us to continue to do what we love: making a difference for our insured, and
							our community. If you’re looking to join a team that values you at the highest level,
							consider the excellent opportunities available with Mountain West Farm Bureau!<br />
							<br />Mountain West has over 170 employees at the home office in Laramie, and even more in
							the field, all of whom value honesty, integrity, teamwork, solution-driven work ethic, and
							personal accountability. Those traits are what set us apart in all sectors of our company:
							Accounting, Claims, IT, Analytics & Development, Sales, Marketing, Operations, Underwriting
							as well as our Claim Adjusters located through Wyoming and Montana. Sound like a good fit so
							far? Check our current job openings using the button below!
						</p>
					</div>
					<div className="career_image_outer">
						<img src="/images/Careers-icon.png" alt="fbf" className="career_image" />
					</div>
				</div>
				<div className="button_text">
					<Button
						label="View Job Openings"
						icon="/sprite.svg#icon-arrow_right"
						onClick={() =>
							window.open(
								'https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=4d135567-cded-463f-ae33-7fcc88e62ce0&ccId=19000101_000001&type=MP&lang=en_US'
							)}
					/>
				</div>
			</div>
			<div className="why_choose">
				<div className="why_choose__section">
					<h3 className="why_choose__heading-heading_1">Why choose Mountain West?</h3>
					<h5 className="why_choose__subheading">Building a workplace of support.</h5>
					<p className="why_choose__text">
						We recognize how important it is for your day-to-day job to be filled with challenging yet
						fulfilling tasks, but even more importantly, we recognize how significant it is to offer room
						for growth. We work with you to define development plans, performance expectations, and career
						paths for your future. Constant growth and expansion provide opportunities within all
						departments of our company.
						<br />
						<br />
						One of our other big commitments is developing our community. We offer Volunteer Time Off to our
						employees which gives them the chance to give back and support the local public. Whether it be
						volunteering at a food bank, building a house for Habitat for Humanity, or anything in between,
						we’re right behind you.
					</p>
				</div>
				<div className="testimonials">
					<div className="testi_heading">
						<h4 className="testimonials__heading">What is it like to work here?</h4>
					</div>

					<div className="testemonials__data">
						<Slider />
						{/* <video
							src="./images/testimonial-image.png"
							controls="controls"
							autoplay="true"
							className="slider_video"
						/> */}

						{/*	<div className="testimonial">
							<img
								src="./images/testimonial-image.png"
								alt="testimonial"
								className="testimonial__image"
							/>
							<p className="testimonial__text">
								The culture of Mountain West is a fun, engaging, collaborative, involved,
								family-oriented organization which leads to elevating employee engagement and makes for
								a great working environment.
							</p>
							<hr className="testimonial__hr" />
							<h6 className="testimonial__name heading_3">
								<b>Amanda Olaveson</b>
							</h6>
							<p className="testimonial__subtext heading_3">
								<b>Underwriter II</b>
							</p>
							<hr className="testimonial__hr1" />
						</div>*/}
					</div>
				</div>
			</div>

			<div className="application">
				<div className="application__card">
					<div>
						<img
							src="./images/ReadyToLoveJob-icon.png"
							alt="application icon"
							className="application__card--image"
						/>
					</div>
					<div className="application_card_text">
						<h4 className="application__card--heading">Ready to love your job?</h4>
						<p className="application__card--text">
							If you are excited to join our team, then click the<br /> button below to view our current
							job openings and <br />apply online today!
						</p>
						<div className="button_text1">
							<Button
								label="View Job Openings"
								icon="/sprite.svg#icon-search"
								onClick={() =>
									window.open(
										'https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=4d135567-cded-463f-ae33-7fcc88e62ce0&ccId=19000101_000001&type=MP&lang=en_US'
									)}
							/>
						</div>
					</div>
				</div>
				<div className="application__card">
					<div>
						<img
							src="./images/SubmitApplication-icon.png"
							alt="application icon"
							className="application__card--image"
						/>
					</div>
					<div className="application_card_text">
						<h4 className="application__card--heading">Submit an application</h4>
						<p className="application__card--text">
							Print and send your completed application/resume to: <br />
							<b>Email: </b>
							<Link to="" className="humanresource__link">
								humanresources@mwfbi.com
							</Link>
							OR<br />
							<b>Mail:</b> 931 Boulder Dr, Laramie, WY 82070 <br />
							<b>Attn: </b>HR Department
						</p>
						<div className="button_text">
							<SimpleButton
								varient="red"
								onClick={() => {
									window.open('images/PER38-Job-App-2019.pdf');
								}}
							>
								Download Application
							</SimpleButton>
						</div>
					</div>
				</div>
			</div>
			<div className="department">
				<div className="depatment__section">
					<h1 className="department__heading1">Our Departments</h1>
				</div>
				<div className="department__contents">
					<div className="department__maingrid">
						<div className="department__gridcard">
							<img
								className="department__gridcard__image"
								alt="finance icon"
								src="images/Accounting&Finance-icon.png"
							/>
							<div className="depatment__labels">
								<label className="department__heading2">Accounting/Finance</label>
								<label className="department__heading3">
									Works with company accounting and financials
								</label>
							</div>
						</div>
						<div className="department__gridcard">
							<img
								className="department__gridcard__image"
								alt="analytics"
								src="images/Analytics-icon.png"
							/>
							<div className="depatment__labels">
								<label className="department__heading2">Analytics/Development</label>
								<label className="department__heading3">
									Works with data developement and product pricing
								</label>
							</div>
						</div>
						<div className="department__gridcard">
							<img className="department__gridcard__image" alt="claims" src="images/Claims-icon.png" />
							<div className="depatment__labels">
								<label className="department__heading2">Claims</label>
								<label className="department__heading3">
									Claims reps helps investigate and process claims
								</label>
							</div>
						</div>
					</div>

					<div className="department__maingrid">
						<div className="department__gridcard">
							<img className="department__gridcard__image" alt="it icon" src="images/IT-icons.png" />
							<div className="depatment__labels">
								<label className="department__heading2">IT</label>

								<h4 className="department__heading3">
									Works with technology support and infrastructure.
								</h4>
							</div>
						</div>
						<div className="department__gridcard">
							<img
								className="department__gridcard__image"
								alt="sales marketing"
								src="images/Sales&Marketing-icon.png"
							/>
							<div className="depatment__labels">
								<label className="department__heading2">Sales/Marketing</label>
								<label className="department__heading3">
									Helps our agency force and company marketing efforts.
								</label>
							</div>
						</div>
						<div className="department__gridcard">
							<img
								className="department__gridcard__image"
								alt="operations"
								src="images/Operations-icon.png"
							/>
							<div className="depatment__labels">
								<label className="department__heading2">Operations</label>
								<label className="department__heading3">
									Works with policy coding and day-to-day business operations.
								</label>
							</div>
						</div>
					</div>

					<div className="department__maingrid">
						<div className="department__gridcard">
							<img
								className="department__gridcard__image"
								alt="underwriting"
								src="images/Underwriting-icon.png"
							/>
							<div className="depatment__labels">
								<label className="department__heading2">Underwriting</label>
								<label className="department__heading3">
									Review and analyze the risk of each insurance application.
								</label>
							</div>
						</div>
						<div className="department__gridcard">
							<img
								className="department__gridcard__image"
								alt="training"
								src="images/Training-icon.png"
							/>
							<div className="depatment__labels">
								<label className="department__heading2">Training</label>
								<label className="department__heading3">
									Company job training and leadership growth.
								</label>
							</div>
						</div>
						<div className="department__gridcard">
							<img className="department__gridcard__image" alt="Hr Icon" src="images/HR-icons.png" />
							<div className="depatment__labels">
								<label className="department__heading2">HR</label>
								<label className="department__heading3">
									Work with employee on-boarding and recruiting.
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Content>
	);
};

export default Careers;
