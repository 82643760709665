import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import axios from 'axios'
import Modal from '../../DarkModal'
import { BASE_URL } from '../../../redux/actions/ip'
import FormInputComponent from '../../FormInputComponent/FormInputComponent'
import Checkbox from '../../Inputs/Checkbox'
import { useDropzone } from 'react-dropzone'
import { checkIsEmpty } from '../../../redux/constants/utils'
import { addContent, imageUpload } from '../../../redux/actions/contentAction'

const ShowModal = ({ setShowModal, showModal, pageName, bannerData, setBannerData }) => {
  const [loading, setLoading] = useState(false)

  const [uploadImagePreview, setUploadImagePreview] = useState(bannerData ? bannerData.imageUrl.value : '')
  const [uploadImage, setUploadImage] = useState({ value: bannerData ? bannerData.imageUrl.value : '', error: '' })

  const [title, setTitle] = useState({ value: '', error: '' })
  const [subTitle, setSubTitle] = useState({ value: '', error: '' })
  const [tagline, setTagline] = useState({ value: '', error: '' })
  const [button1, setButton1] = useState({ value: '', error: '' })
  const [button2, setButton2] = useState({ value: '', error: '' })
  const [button1Status, setButton1Status] = useState({ value: false, error: '' })
  const [button2Status, setButton2Status] = useState({ value: false, error: '' })
  const [button1Link, setButton1Link] = useState({ value: '', error: '' })
  const [button2Link, setButton2Link] = useState({ value: '', error: '' })

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles);
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0]
    var reader = new FileReader();
    setUploadImage({ value: file, error: '' })
    reader.onloadend = function () {
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })
  useEffect(() => {
    setUploadImagePreview(bannerData ? bannerData.imageUrl.value : { value: '', error: '' })
    setUploadImage(bannerData ? bannerData.imageUrl : { value: '', error: '' })
    setTitle(bannerData ? bannerData.title : { value: '', error: '' })
    setSubTitle(bannerData ? bannerData.subTitle : { value: '', error: '' })
    setTagline(bannerData ? bannerData.tagline : { value: '', error: '' })
    setButton1(bannerData ? bannerData.button1 : { value: '', error: '' })
    setButton2(bannerData ? bannerData.button2 : { value: '', error: '' })
    setButton1Status(bannerData ? bannerData.button1Status : { value: false, error: '' })
    setButton2Status(bannerData ? bannerData.button2Status : { value: false, error: '' })
    setButton1Link(bannerData ? bannerData.button1Link : { value: '', error: '' })
    setButton2Link(bannerData ? bannerData.button2Link : { value: '', error: '' })
  }, [bannerData])
  
  const handleYes = async (e) => {
    e.preventDefault();
    if (checkIsEmpty(title.value)) {
      setTitle({ ...title, error: "Please enter title!" })
      return
    } else setTitle({ ...title, error: "" })
    if (checkIsEmpty(subTitle.value)) {
      setSubTitle({ ...subTitle, error: "Please enter subtitle!" })
      return
    } else setSubTitle({ ...subTitle, error: "" })
    if (!checkIsEmpty(button1.value) && checkIsEmpty(button1Link.value)) {
      setButton1Link({ ...button1Link, error: "Please enter link!" })
      return
    } else setButton1Link({ ...button1Link, error: "" })
    if (!checkIsEmpty(button2.value) && checkIsEmpty(button2Link.value)) {
      setButton2Link({ ...button2Link, error: "Please enter link!" })
      return
    } else setButton2Link({ ...button2Link, error: "" });
    if (!uploadImage.value) {
      setUploadImage({ ...uploadImage, error: "Please select background image." })
      return
    } else setUploadImage({ ...uploadImage, error: "" });

    setLoading(true)
    let imageUrl = !(uploadImage.value instanceof File) ? uploadImage.value : ''
    if (uploadImage.value instanceof File) {
      const res = await imageUpload(uploadImage.value);
      imageUrl = res.data.path;
      setUploadImagePreview(res.data.path);
      setUploadImage({ value: res.data.path });
    }
    let content = {
      title,
      subTitle,
      tagline,
      button1,
      button1Link,
      button1Status,
      button2,
      button2Link,
      button2Status,
      imageUrl: { value: imageUrl }
    }
    addContent({
      pageName: pageName,
      section: 'TopBanner',
      content
    }).then((result) => {
      setLoading(false)
      if (result.data.status) {
        setBannerData(content)
        alert('Content Saved');
        setShowModal(false)
      }
    }).catch(err => {
      setLoading(false)
    })
  }
  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Header</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    // required={true}
                    value={title.value}
                    onChange={(e) => {
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    // required={true}
                    value={subTitle.value}
                    onChange={(e) => {
                      setSubTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={subTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Tag Line:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    value={tagline?.value}
                    onChange={(e) => {
                      setTagline({ error: '', value: e.target.value })
                    }}
                    errorMessage={tagline?.error}
                  />
                </div>
                <div className='inline-form-view' style={{display: 'flex',justifyContent: 'space-between'}}>
                  <FormInputComponent
                    heading='Link 1 Label:'
                    style={{ width: '35%' }}
                    value={button1?.value}
                    onChange={(e) => {
                      setButton1({ error: '', value: e.target.value })
                    }}
                    errorMessage={button1?.error}
                  />
                  <FormInputComponent
                    heading='Link 1:'
                    style={{ width: '35%' }}
                    value={button1Link?.value}
                    onChange={(e) => {
                      setButton1Link({ error: '', value: e.target.value })
                    }}
                    errorMessage={button1Link?.error}
                  />
                  <Checkbox
                    text={button1Status?.value ? 'Enabled' : 'Disabled'}
                    key='b1'
                    checked={button1Status?.value}
                    onChange={(e) => {
                      setButton1Status({ value: !button1Status?.value, error: '' })
                    }}
                  />
                </div>
                <div className='inline-form-view' style={{display: 'flex',justifyContent: 'space-between'}}>
                  <FormInputComponent
                    heading='Link 2 Label:'
                    style={{ width: '35%' }}
                    value={button2?.value}
                    onChange={(e) => {
                      setButton2({ error: '', value: e.target.value })
                    }}
                    errorMessage={button1?.error}
                  />
                  <FormInputComponent
                    heading='Link 2:'
                    style={{ width: '35%' }}
                    value={button2Link?.value}
                    onChange={(e) => {
                      setButton2Link({ error: '', value: e.target.value })
                    }}
                    errorMessage={button2Link?.error}
                  />
                  <Checkbox
                    text={button2Status?.value ? 'Enabled' : 'Disabled'}
                    key='b2'
                    checked={button2Status?.value}
                    onChange={(e) => {
                      setButton2Status({ value: !button2Status?.value, error: '' })
                    }}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right'>
                <label>Background Image</label>
                <div className='upload_border' {...getRootProps()}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  <svg className='modal_upload__icon-dark'>
                    <use xlinkHref='/sprite.svg#icon-cloud' />
                  </svg>
                </div>
                {checkIsEmpty(uploadImage.error) && <label style={{ color: 'red' }}>{uploadImage.error}</label>}
                <div className='upload_preview_modal'>
                  <img height={100} width={200} src={uploadImagePreview}></img>
                </div>
              </div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            <button className='modal__button-dark' disabled={loading} type='submit'>
              {loading ? 'Saving...' : 'Submit'}
            </button>

          </div>
        </div>
      </form>
    </Modal>
  )
}

export default ShowModal
