import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'

const EditInfo = ({ setShowModal, showModal, handleYes, section4, setSection4 }) => {
  const [loading, setLoading] = useState(false)

  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(null)

  const [title, setTitle] = useState({ value: '', error: '' })
  const [subTitle, setSubTitle] = useState({ value: '', error: '' })
  const [tagline, setTagline] = useState({ value: '', error: '' })
  const [desc, setDesc] = useState({ value: '', error: '' })

  const onDrop = useCallback((acceptedFiles) => {
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0]
    setUploadImage(file)
    var reader = new FileReader()
    reader.onloadend = function () {
      console.log('RESULT', reader.result)
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })

  useEffect(() => { }, [])
  const submit = () => {
    if (section4.title.value == '') {
      let temp = { ...section4 };
      temp.title.error = 'Please enter title!'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 };
      temp.title.error = ''
      setSection4(temp)
    }
    if (section4.subTitle.value == '') {
      let temp = { ...section4 };
      temp.subTitle.error = 'Please enter sub title!'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 };
      temp.subTitle.error = ''
      setSection4(temp)
    }
    if (section4.tagLine.value == '') {
      let temp = { ...section4 };
      temp.tagLine.error = 'Please enter Tag Line!'
      setSection4(temp)
      return
    } else {
      let temp = { ...section4 };
      temp.tagLine.error = ''
      setSection4(temp)
    }
    handleYes(uploadImage)
  }
  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Info</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section4.title.value}
                    onChange={(e) => {
                      let temp = { ...section4 }
                      temp.title.value = e.target.value
                      setSection4(temp)
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    required={true}
                    value={section4.subTitle.value}
                    onChange={(e) => {
                      let temp = { ...section4 }
                      temp.subTitle.value = e.target.value
                      setSection4(temp)
                      setSubTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.subTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Tag Line:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    required={true}
                    value={section4.tagLine.value}
                    onChange={(e) => {
                      let temp = { ...section4 }
                      temp.tagLine.value = e.target.value
                      setSection4(temp)
                      setTagline({ error: '', value: e.target.value })
                    }}
                    errorMessage={section4.tagLine.error}
                  />
                </div>

              </div>
              <div className='modal_inner_content--right'>
                <label>About background Image</label>
                <div className='upload_border' {...getRootProps()}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  <svg className='modal_upload__icon-dark'>
                    <use xlinkHref='/sprite.svg#icon-cloud' />
                  </svg>
                </div>
                <div className='upload_preview_modal'>
                  <img height={200} src={uploadImagePreview}></img>
                </div>
              </div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditInfo
