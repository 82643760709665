import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import ReactPaginate from 'react-paginate'
import {
  deleteContentItem,
  imageUpload,
  addContentItem,
} from '../../../../redux/actions/contentAction'

const itemsPerPage = 10

const EditDiscountType = ({
  setShowModal,
  showModal,
  handleYes,
  discountListArr,
  setDiscountListArr,
}) => {
  const [accountNumber, setAccountNumber] = useState({ value: '', error: '' })

  const [loading, setLoading] = useState(false)
  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(null)

  const [name, setName] = useState({ value: '', error: '' })
  const [description, setDescription] = useState({ value: '', error: '' })

  const [editIndex, setEditIndex] = useState(-1)
  const [editData, setEditData] = useState({
    title: { value: '', error: '' },
    description: { value: '', error: '' },
  })

  const [officeHoursList, setOfficeHoursList] = useState([])
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    // console.log(acceptedFiles);
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0]
    setUploadImage(file)
    var reader = new FileReader()
    reader.onloadend = function () {
      console.log('RESULT', reader.result)
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })

  const handleDiscountSubmit = (e) => {
    e.preventDefault()
    if (name.value == '') {
      setName({ ...name, error: 'Please enter title!' })
      return
    }
    if (description.value == '') {
      setDescription({ ...description, error: 'Please enter description!' })
      return
    } else if (description.value.length < 30) {
      setDescription({
        ...description,
        error: 'description must be if of length above 30 characters',
      })
      return
    }
    if (!uploadImage && !uploadImagePreview) {
      setUploadImage({ error: 'Please upload image!' })
      return
    }

    clearErrors()

    if (uploadImage) {
      imageUpload(uploadImage).then((res) => {
        if (res.data.status) {
          setUploadImagePreview(res.data.path)
          let content = {
            title: name.value,
            description: description.value,
            src: res.data.path,
          }
          if (editIndex > -1) {
            const temp = [...discountListArr]
            temp[editIndex] = content
            /* setCurrentItems(temp) */
            setDiscountListArr(temp)
            handleYes(temp)
            clear()
          } else {
            /* setCurrentItems([...discountListArr, content]) */
            setDiscountListArr([...discountListArr, content])
            handleYes([...discountListArr, content])
            console.log('added', discountListArr)
            clear()
          }
        }
      })
    } else {
      let content = {
        title: name.value,
        description: description.value,
        src: uploadImagePreview,
      }
      if (editIndex > -1) {
        const temp = [...discountListArr]
        temp[editIndex] = content
        /* setCurrentItems(temp) */
        setDiscountListArr(temp)
        handleYes(temp)
        clear()
      }
    }
  }
  const onAdd = (e) => {
    e.preventDefault()
    // setLoading(true);
    handleDiscountSubmit()
  }

  const onEdit = (item, index) => {
    // e.preventDefault();
    // setLoading(true);
    setEditData(item)
    setEditIndex(index)
    setName({ ...name, value: item.title })
    setDescription({ ...description, value: item.description })

    setUploadImagePreview(item.src)
  }
  const clear = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      name: { value: '', error: '' },
      description: { value: '', error: '' },
    })
    setName({ value: '', error: '' })
    setDescription({ value: '', error: '' })

    setEditIndex(-1)
    setUploadImagePreview('')
    setUploadImage(null)
    // handleDiscountSubmit()
  }
  const closeModal = () => {
    resetAll()
    setShowModal(false)
  }
  const resetAll = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      name: { value: '', error: '' },
      description: { value: '', error: '' },
    })
    setEditIndex(-1)
    setName({ ...name, value: '', error: '' })
    setDescription({ ...description, value: '', error: '' })

    setUploadImagePreview('')
    setUploadImage(null)
    setOfficeHoursList([])
  }
  const clearErrors = () => {
    setEditIndex(-1)
    setName({ ...name, error: '' })
    setDescription({ ...description, error: '' })

    setOfficeHoursList([])
  }
  const onDiscountDelete = (item, index) => {
    if (window.confirm('Are You Sure to Delete?')) {
      const temp = [...discountListArr]
      temp.splice(index, 1)
      setDiscountListArr(temp)
      handleYes(temp)
      /* deleteContentItem({ key: "Discount", primaryKey: item.title }).then(res => {
        let temp = [...discountListArr];
        temp.splice(index, 1);
        setDiscountListArr(temp)
        if (res.data.status) {
          alert("Item has been deleted!");
        }
      }) */
    }
  }
  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Discount Types</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => closeModal()}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '100%' }}
                    required={true}
                    value={name.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.name.value = e.target.value
                      // setEditData(temp)
                      setName({ error: '', value: e.target.value })
                    }}
                    errorMessage={name.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Description:'
                    type={'textarea'}
                    style={{ width: '100%' }}
                    required={true}
                    value={description.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.name.value = e.target.value
                      // setEditData(temp)
                      setDescription({ error: '', value: e.target.value })
                    }}
                    errorMessage={description.error}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right3'>
                <label>Upload the image</label>
                <div
                  className='upload_border'
                  {...getRootProps()}
                  style={{ marginTop: '-1rem' }}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  {uploadImagePreview == '' ? (
                    <svg className='modal_upload__icon-dark'>
                      <use xlinkHref='/sprite.svg#icon-cloud' />
                    </svg>
                  ) : (
                    <img
                      height={60}
                      width={60}
                      src={uploadImagePreview}
                      style={{ marginTop: '0rem', alignSelf: 'center' }}></img>
                  )}
                </div>
                {uploadImage && uploadImage.error && (
                  <p className='form_input__error'>{uploadImage.error}</p>
                )}

                <div
                  className='modal_dark_buttons'
                  style={{ padding: '0.5rem' }}></div>
                <div style={{ display: 'flex', gap: 12 }}>
                  <button
                    type='button'
                    className='modal__button-reset-dark'
                    onClick={resetAll}>
                    Reset
                  </button>
                  <button
                    type='button'
                    className='modal__button-dark'
                    onClick={handleDiscountSubmit}>
                    {loading ? 'Saving..' : editIndex > -1 ? 'Save' : 'Add'}
                  </button>
                </div>
              </div>
            </div>
            <div className='modal_inner_content'>
              <div className='modal_form_container'>
                <table
                  className='modal__form-table'
                  cellSpacing='0'
                  cellPadding='0'>
                  <thead className='modal__form-sticky'>
                    <tr className='modal__form-tr'>
                      <th>No.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {discountListArr &&
                      discountListArr &&
                      discountListArr.map((item, i) => {
                        return (
                          <tr
                            style={{ height: '60px' }}
                            className='modal__form-tr2'
                            key={i}>
                            <td>{i + 1}</td>
                            <td>{item.title}</td>
                            <td>{item.description}</td>
                            <td
                              style={{ marginTop: '12px' }}
                              className='modal__form-buttons'>
                              <button
                                type='button'
                                className='modal__button-edit'
                                onClick={(e) => onEdit(item, i)}>
                                edit
                              </button>
                              <button
                                type='button'
                                className='modal__button-delete'
                                onClick={(item) => onDiscountDelete(item, i)}>
                                delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className='ms-pagination2'>
              <h1 className='paginator_head'>
                {' '}
                Showing {itemOffset + 1} - {itemOffset + currentItems?.length}{' '}
                of {discountListArr.length} results
              </h1>

              <ReactPaginate
                nextClassName={''}
                breakLabel='...'
                nextLabel='>>'
                pageCount={pageCount}
                onPageChange={onPageChange}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                previousLabel='<<'
                // renderOnZeroPageCount={null}
                containerClassName={'paginationBttns'}
                previousLinkClassName={'previousBttn'}
                nextLinkClassName={'nextBttn'}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginationActive'}
              />
            </div> */}
          </div>

          {/* <div className='modal_dark_buttons' style={{ paddingTop: '0' }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={(e) => handleDiscountSubmit()}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal>
  )
}

export default EditDiscountType
