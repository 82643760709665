import React from 'react';
import { Link } from 'react-router-dom';

import './Header.css';
const MeetingHeader = () => {
	return (
		<header className='header'>
			<img src='/images/logo2.png' alt='Logo' className='logo' />
			<nav className='navigation'>
				<ul className='navigation__links'>
					<li>
						<Link to='/meeting' className='navigation__link'>
							Home
						</Link>
					</li>
					<li>
						<Link to='/meeting/upcoming' className='navigation__link'>
							Upcoming
						</Link>
					</li>
					<li>
						<Link to='/meeting/previous' className='navigation__link'>
							Previous
						</Link>
					</li>
					<li>
						<Link to='/meeting/awards' className='navigation__link'>
							2021 Awards Booklet
						</Link>
					</li>
					<li>
						<Link to='/meeting/meeting-request' className='navigation__link'>
							Meeting Request
						</Link>
					</li>
					<li>
						<Link to='/meeting/contact' className='navigation__link'>
							Contact Us
						</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
};

export default MeetingHeader;
