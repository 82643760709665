import axios from 'axios';
import { CONTACT_US_FAIL, CONTACT_US_REQUEST, CONTACT_US_SUCCESS, SEND_MAIL_FAIL, SEND_MAIL_REQUEST, SEND_MAIL_SUCCESS, SEND_START_A_QUOTE_FAIL, SEND_START_A_QUOTE_REQUEST, SEND_START_A_QUOTE_SUCCESS } from '../constants/mailConstants';
import { BASE_URL } from './ip';


export const sendStartAQuoteAction = (formData) => async (dispatch) => {
	try {
		dispatch({ type: SEND_START_A_QUOTE_REQUEST });

		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/auth/send-start-a-quote`, formData, config);

		dispatch({
			type: SEND_START_A_QUOTE_SUCCESS,
			payload: data
		});

	} catch (err) {
		dispatch({
			type: SEND_START_A_QUOTE_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const sendERSAction = (formData) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		return axios.post(`${BASE_URL}/auth/ers`, formData, config);

	} catch (err) {
		return Promise.reject(err)
	}
};


export const contactMeAction = (formData) => async (dispatch) => {
	try {
		dispatch({ type: SEND_MAIL_REQUEST });

		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/auth/send-contact`, formData, config);

		dispatch({
			type: SEND_MAIL_SUCCESS,
			payload: data
		});

	} catch (err) {
		dispatch({
			type: SEND_MAIL_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
export const contactUsAction = (formData) => async (dispatch) => {
	try {
		dispatch({ type: CONTACT_US_REQUEST });

		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/auth/contact-us`, formData, config);

		dispatch({
			type: CONTACT_US_SUCCESS,
			payload: data
		});

	} catch (err) {
		dispatch({
			type: CONTACT_US_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
export const contactUsActionAPI = async (formData) => {
	try {

		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		return axios.post(`${BASE_URL}/auth/contact-us`, formData, config);


	} catch (err) {
		return Promise.reject()
	}
};