import React, { useState } from 'react';
import './CheckBox.css';

const CheckBox = ({ text, checked, onChange }) => {
	const [inputType, setInputType] = useState(true);

	const handlePassword = () => {
		setInputType(!inputType);
	};

	return (
		<div className='switchContainer'>
			<label className='switch'>
				<input
					id='active'
					type='checkbox'
					className='checkbox'
					name='active'
					checked={checked}
					onChange={onChange}
				/>
				<span className='switch-slider round' />
			</label>
			<h3 className='switch-text'>{text}</h3>
		</div>
	);
};

export default CheckBox;
