import React from "react";
import styles from "./LinkCard.module.css";
import GenricLinkChip from "./GenricLinkChip/GenricLinkChip";
import LinkChip from "./LinkChip/LinkChip";
import GenricPdfCard from "../GenricPdfCard/PdfCard";

const LinkCard = ({ key, title, list }) => {

  const linkItem = (item) => {
    if (!item.link && item.pdfPath) {
      if (item.pdfThumbnail) {
        return (
          <GenricPdfCard key={item.title} pdfPath={item.pdfPath} title={item.title} />
        )
      }
      else {
        return (
          <GenricLinkChip key={item.title} title={item.title} link={item.pdfPath} />
        )
      }
    }
    else {
      return (
        <LinkChip key={item.title} title={item.title} link={item.link} />
      )
    }
  }

  return (
    <div key={key} className={styles.cardContainer}>
      <label className={styles.title}>{title}</label>
      {list.map((item, index) => linkItem(item))}
    </div>
  );
};

export default LinkCard;
