import React, { useState, useCallback, useEffect, useMemo } from "react";
import Modal from "../../../DarkModal";

import Styles from '../Section1/EditFirstSection.module.css';
import FormInputComponent from "../../../FormInputComponent/FormInputComponent";
import ImageOption from "./Forms/ImageOption";
import PdfOption from "./Forms/PdfOption";
import PdfUploader from "../../../GenricPdfUploader/PdfUploader";
import { imageUpload } from "../../../../redux/actions/contentAction";
import Style from './EditPoints.module.css'

let editIndex = -1;
let timeout;
const EditSecondSection = ({ editPoint, setEditPoint, showSection1Modal, onSave, onClose, sectionTitleEdit, pointIdx }) => {
    const [pointList, setPointList] = useState([]);
    const [title, setTitle] = useState({ value: "", error: "" });
    const [pointTitle, setPointTitle] = useState({ value: "", error: "" });
    const [link, setLink] = useState({ value: "", error: "" });
    const [editIndex, setEditIndex] = useState(-1);
    const [pdfName, setPdfName] = useState();
    const [loading, setLoading] = useState(false);

    const [uploadFile, setUploadFile] = useState(null);
    const [pdfFileError, setPdfFileError] = useState(null);
    const [uploadFilePreview, setUploadFilePreview] = useState("");
    const [linkType, setLinkType] = useState('link')

    const [pdfThumnail, setPdfThumnail] = useState({ value: false, error: '' })


    useEffect(() => {
        if (editPoint && editPoint.points) {
            setPointList(editPoint.points)
            setTitle({ ...title, value: editPoint.title })
        }
    }, [editPoint])

    useEffect(() => {
        if (!uploadFile) setLinkType('link');
        else setLinkType('Pdf')
    }, [uploadFile])

    useEffect(() => {
        if (editIndex > -1) {
            setPointTitle({ value: pointList[editIndex].title })
            setLink({ value: pointList[editIndex].link })
            if (pointList[editIndex].type === 'Pdf') {
                console.log(pointList[editIndex].pdfPathData, '1')
                setUploadFilePreview(pointList[editIndex].pdfPathData)
            }
            else setUploadFilePreview("");
            if (pointList[editIndex].pdfThumbnail) {
                setPdfThumnail({ error: '', value: true })
            }
            else setPdfThumnail({ error: '', value: false })
        } else clear()
    }, [editIndex])


    const clear = () => {
        setEditIndex(-1)
        setPointTitle({ value: "" })
        setUploadFilePreview("")
        setLink({ value: "" })
        setPdfName(false)
    }

    console.log(editIndex, 'pdfName')

    const onEdit = useCallback((item, index) => {
        setEditIndex(index)
    }, [])

    const handleSubmit = useCallback(async () => {
        let temp = [...pointList];
        const uploadedFile = await imageUpload(uploadFile);
        if (pointTitle.value === "") {
            setPointTitle({ ...pointTitle, error: 'Please enter title!' });
            return
        }
        if (editIndex > -1) {
            temp[editIndex].title = pointTitle.value
            temp[editIndex].link = link.value
            temp[editIndex].type = linkType
            temp[editIndex].pdfPath = uploadedFile?.data?.path
            temp[editIndex].pdfThumbnail = pdfThumnail.value
        } else {
            temp.push({
                title: pointTitle.value,
                link: link.value,
                type: linkType,
                pdfPath: uploadedFile?.data?.path,
                pdfThumbnail: pdfThumnail.value
            })
        }
        setPointList(temp);
        setEditPoint({ ...editPoint, points: temp })
        onSave({ ...editPoint, points: temp })
        clear()
    }, [editIndex, editPoint, link.value, linkType, onSave, pdfThumnail.value, pointList, pointTitle, setEditPoint, uploadFile])

    const onSectionTitleEdit = () => {
        if (title.value === "") {
            setTitle({ ...title, error: 'Please enter section title!' });
            return
        }
        else {
            let temp = { ...editPoint };
            temp.title = title.value;
            sectionTitleEdit(title.value)
            setEditPoint(temp)
        }
    }

    const extractedPdfName = useMemo(() => {
        if (!editPoint?.points[editIndex]?.pdfPath) {
            return "";
        };
        setPdfName(true);
        const url = editPoint.points[editIndex].pdfPath;
        const lastSlashIndex = url?.lastIndexOf("/");
        const fileNameWithPrefix = url?.substring(lastSlashIndex + 1);
        const fileNameParts = fileNameWithPrefix?.split("-");
        fileNameParts.shift();
        const fileName = fileNameParts.join("-").trim();
        return fileName;
    }, [editIndex, editPoint])

    const pdfDelete = useCallback(() => {
        if (window.confirm("Are you sure to delete this Pdf?")) {
            const updatedPoints = pointList?.map((item) => {
                if (item.title === editPoint?.points[editIndex]?.title) {
                    const { pdfPath, ...rest } = item;
                    return rest;
                }
                return item;
            });
            console.log(updatedPoints, 'updatedPoints');
            setUploadFile(null);
            setUploadFilePreview("")
            setPointList(updatedPoints)
            onSave({ ...editPoint, points: updatedPoints });
            setPdfName(false);
        }
    }, [editIndex, editPoint, onSave, pointList])

    const onItemDelete = (item, index) => {
        if (window.confirm("Are you sure to delete?")) {
            let temp = [...pointList]
            temp.splice(index, 1)
            setPointList(temp)
            onSave({ ...editPoint, points: temp })
        }

    }
    const reposition = (index, item, direction) => {
        const tempArr = pointList.filter(
            (element, position) => index !== position
        );
        if (direction === "up" && index > 0) {
            tempArr.splice(index - 1, 0, item);
            clearTimeout(timeout);
        } else if (direction === "down" && index < pointList.length) {
            tempArr.splice(index + 1, 0, item);
            clearTimeout(timeout);
        } else return;
        setPointList(tempArr)
        setEditPoint({ ...editPoint, points: tempArr })
        timeout = setTimeout(() => {
            onSave({ ...editPoint, points: tempArr })
        }, 2000);
    }

    const getForm = () => {
        if (editPoint)
            switch (editPoint.type) {
                case "Image":
                    return <ImageOption edit={editPoint} onSave={(obj) => {
                        setEditPoint(obj)
                        onSave(obj, true)
                    }}></ImageOption>
                case "Pdf":
                    return <PdfOption edit={editPoint} onSave={(obj) => {
                        setEditPoint(obj)
                        onSave(obj, true)
                    }}></PdfOption>
                default:
                    return (<div className={"modal__form-dark"} style={{ display: 'flex' }}>
                        <div className="modal_inner_content">
                            <div className={Styles.modalContainer} style={{ height: '50rem' }}>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'end'
                                }}>
                                    <div style={{ width: '40%' }} className={'inline-form-view' + " " + Styles.mainTitle}>
                                        <FormInputComponent
                                            heading='Title:'
                                            style={{ width: '100%' }}
                                            required={true}
                                            value={title.value}
                                            onChange={(e) => {
                                                setTitle({ error: '', value: e.target.value });
                                            }}
                                            errorMessage={title.error}
                                        />
                                    </div>
                                    <button
                                        className='modal__button-dark'
                                        type='button'
                                        onClick={onSectionTitleEdit}>
                                        Save
                                    </button>
                                </div>
                                <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: "2rem" }}>
                                    <div style={{ marginBottom: "1rem", width: '40%' }} >
                                        <div style={{ alignItems: 'center' }} className={'inline-form-view' + " " + Styles.mainTitle}>
                                            <FormInputComponent
                                                heading='Point Title:'
                                                style={{ width: '100%', marginBottom: "2rem" }}
                                                required={true}
                                                value={pointTitle.value}
                                                onChange={(e) => {
                                                    setPointTitle({ error: '', value: e.target.value });
                                                    // let temp = { ...step2Selection };
                                                    // temp.selectedItem.title = e.target.value;
                                                    // setStep2Selection(temp);
                                                }}
                                                errorMessage={pointTitle.error}
                                            />

                                        </div>
                                        <div className={'inline-form-view' + " " + Styles.mainTitle}>
                                            <FormInputComponent
                                                heading='Point Link:'
                                                style={{ width: '100%', marginBottom: "2rem" }}
                                                value={link.value}
                                                onChange={(e) => {
                                                    setLink({ error: '', value: e.target.value });
                                                    // let temp = { ...step2Selection };
                                                    // temp.selectedItem.title = e.target.value;
                                                    // setStep2Selection(temp);
                                                }}
                                                errorMessage={link.error}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ width: '45%', alignItems: 'flex-end' }}>
                                        {pdfName && <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center', marginLeft: '5rem' }}>
                                            <span style={{ textAlign: 'center', display: 'block', marginLeft: '12px', fontSize: '1.2rem' }}>
                                                { editIndex > -1 && extractedPdfName}
                                            </span>
                                            <svg className={Style.trashIcon} onClick={pdfDelete}>
                                                <use xlinkHref="/sprite.svg#icon-trash" />
                                            </svg>
                                        </div>}
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                            <PdfUploader
                                                uploadFilePreview={uploadFilePreview}
                                                setUploadFilePreview={setUploadFilePreview}
                                                setPdfFileError={setPdfFileError}
                                                pdfFileError={pdfFileError}
                                                uploadFile={uploadFile}
                                                setUploadFile={setUploadFile}
                                            />
                                        </div>
                                        <div style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '2rem'
                                        }}>
                                            <label style={{ display: 'block', marginRight: '1rem', fontSize: '1.4rem', marginLeft: '5rem' }}>Display as thumbnail</label>
                                            <FormInputComponent
                                                style={{ display: 'flex', alignItems: 'flex-end' }}
                                                headingStyle={Styles.inputHeading}
                                                height={"2rem"}
                                                type={'checkbox'}
                                                checked={pdfThumnail.value}
                                                value={pdfThumnail.value}
                                                onChange={(e) => {
                                                    setPdfThumnail({ error: '', value: !pdfThumnail.value })
                                                }}
                                                errorMessage={pdfThumnail.error}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem', marginTop: '1rem' }}>
                                            <button
                                                className='modal__button-reset-dark'
                                                type='button'
                                                onClick={clear}>
                                                Reset
                                            </button>
                                            <button
                                                className='modal__button-dark'
                                                type='button'
                                                onClick={handleSubmit}>
                                                {loading ? 'Saving..' : editIndex > -1 ? 'Save' : 'Add'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {pointList && <div className='modal_form_container' >
                                    <table
                                        className='modal__form-table'
                                        cellSpacing='0'
                                        cellPadding='0'>
                                        <thead className='modal__form-sticky'>
                                            <tr className='modal__form-tr'>
                                                <th>No.</th>
                                                <th>Title</th>
                                                <th>Sort</th>
                                                <th style={{ textAlign: 'end', paddingRight: '5rem' }}>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {pointList && pointList.map((item, i) => {
                                                return (
                                                    <tr
                                                        style={{ height: '60px' }}
                                                        className='modal__form-tr2'
                                                        key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{item.title}</td>
                                                        <td>
                                                            <button type="button" onClick={(e) => reposition(i, item, 'up')}>
                                                                <svg className="modal_heading__icon-dark">
                                                                    <use xlinkHref="/sprite.svg#icon-up" />
                                                                </svg>
                                                            </button>
                                                            &nbsp;
                                                            <button type="button" onClick={(e) => reposition(i, item, 'down')}>
                                                                <svg className="modal_heading__icon-dark">
                                                                    <use xlinkHref="/sprite.svg#icon-down" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                        <td
                                                            style={{ marginTop: '12px' }}
                                                            className='modal__form-buttons'>
                                                            <button
                                                                type='button'
                                                                className='modal__button-edit'
                                                                onClick={(e) => onEdit(item, i)}>
                                                                edit
                                                            </button>
                                                            <button
                                                                type='button'
                                                                className='modal__button-delete'
                                                                onClick={(item) => onItemDelete(item.point, i)}>
                                                                delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>
                        {/* <button
                    style={{
                        alignSelf: 'flex-end',
                        marginTop: '8px',
                        marginRight: '12px',
                    }}
                    type="button"
                    className="modal__button-dark"
                >
                    Submit
                </button> */}
                    </div >)
            }
    }
    return (
        <Modal show={showSection1Modal}>
            <form>
                <div className="modal__container-dark">
                    <div className="modal_heading__container-dark">
                        <h1 className="modal_heading__text-dark">
                            Edit Points
                        </h1>
                        <button
                            className="modal__heading--button-dark"
                            onClick={() => {
                                onClose();
                                setPointTitle({ error: '', value: '' })
                                setLink({ error: '', value: '' })
                                setPdfThumnail({ error: '', value: false });
                                setEditIndex(-1)
                            }}
                        >
                            <svg className="modal_heading__icon-dark">
                                <use xlinkHref="/sprite.svg#icon-cross" />
                            </svg>
                        </button>
                    </div>

                    {getForm()}
                    {/* <div className={"modal__form-dark"} style={{ display: 'flex' }}>
                        <div className="modal_inner_content">
                            <div className={Styles.modalContainer} style={{ height: '50rem' }}>
                                <div>
                                    <div className={'inline-form-view' + " " + Styles.mainTitle}>
                                        <FormInputComponent
                                            heading='Title:'
                                            style={{ width: '50%' }}
                                            required={true}
                                            value={title.value}
                                            onChange={(e) => {
                                                setTitle({ error: '', value: e.target.value });
                                                // let temp = { ...step2Selection };
                                                // temp.selectedItem.title = e.target.value;
                                                // setStep2Selection(temp);
                                            }}
                                            errorMessage={title.error}
                                        />
                                    </div>
                                </div>
                                <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
                                <div style={{ marginBottom: "1rem" }} >
                                    <div className={'inline-form-view' + " " + Styles.mainTitle}>
                                        <FormInputComponent
                                            heading='Point Title:'
                                            style={{ width: '30%' }}
                                            required={true}
                                            value={pointTitle.value}
                                            onChange={(e) => {
                                                setPointTitle({ error: '', value: e.target.value });
                                                // let temp = { ...step2Selection };
                                                // temp.selectedItem.title = e.target.value;
                                                // setStep2Selection(temp);
                                            }}
                                            errorMessage={pointTitle.error}
                                        />
                                    </div>
                                    <div className={'inline-form-view' + " " + Styles.mainTitle}>
                                        <FormInputComponent
                                            heading='Point Link:'
                                            style={{ width: '30%' }}
                                            value={link.value}
                                            onChange={(e) => {
                                                setLink({ error: '', value: e.target.value });
                                            }}
                                            errorMessage={link.error}
                                        />

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1rem' }}>
                                        <button
                                            className='modal__button-reset-dark'
                                            type='button'
                                            onClick={clear}>
                                            Reset
                                        </button>
                                        <button
                                            className='modal__button-dark'
                                            type='button'
                                            onClick={handleSubmit}>
                                            {loading ? 'Saving..' : editIndex > -1 ? 'Save' : 'Add'}
                                        </button>
                                    </div>
                                </div>
                                {pointList && <div className='modal_form_container' >
                                    <table
                                        className='modal__form-table'
                                        cellSpacing='0'
                                        cellPadding='0'>
                                        <thead className='modal__form-sticky'>
                                            <tr className='modal__form-tr'>
                                                <th>No.</th>
                                                <th>Title</th>
                                                <th>Sort</th>
                                                <th style={{ textAlign: 'end', paddingRight: '5rem' }}>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {pointList && pointList.map((item, i) => {
                                                return (
                                                    <tr
                                                        style={{ height: '60px' }}
                                                        className='modal__form-tr2'
                                                        key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{item.title}</td>
                                                        <td>
                                                            <button type="button" onClick={(e) => reposition(i, 'up')}>
                                                                <svg className="modal_heading__icon-dark">
                                                                    <use xlinkHref="/sprite.svg#icon-up" />
                                                                </svg>
                                                            </button>
                                                            &nbsp;
                                                            <button type="button" onClick={(e) => reposition(i, 'down')}>
                                                                <svg className="modal_heading__icon-dark">
                                                                    <use xlinkHref="/sprite.svg#icon-down" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                        <td
                                                            style={{ marginTop: '12px' }}
                                                            className='modal__form-buttons'>
                                                            <button
                                                                type='button'
                                                                className='modal__button-edit'
                                                                onClick={(e) => onEdit(item, i)}>
                                                                edit
                                                            </button>
                                                            <button
                                                                type='button'
                                                                className='modal__button-delete'
                                                                onClick={(item) => onItemDelete(item.point, i)}>
                                                                delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>
                       
                    </div> */}
                </div>
            </form>
        </Modal >
    )
}

export default EditSecondSection;