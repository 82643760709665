import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment-timezone';
import Content from '../../../components/Content/Content';
import { getPublishedBlogById } from '../../../redux/actions/blogListAction';

import 'react-loading-skeleton/dist/skeleton.css'
import "react-quill/dist/quill.snow.css";
import {
	FacebookIcon,
	TwitterIcon,
	LinkedinIcon,
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton
} from "react-share";
import { getCustomLocalDate, getTimeZoneLocalDate } from '../../../constants';


// const ReactMarkdown = require("react-markdown"); //for displaying html

const LearningCenterDetails = () => {
	const history = useHistory();
	const [blog, setBlog] = useState({})
	const [items, setItems] = useState([])
	const [apiLoading, setAPILoading] = useState(true)
	const params = useParams();

	useEffect(() => {
		setAPILoading(true)
		const data = JSON.parse(localStorage.getItem("preview"))
		setBlog(data)
		let temp = []
		for (const item of [data.relatedPost1Data, data.relatedPost2Data, data.relatedPost3Data]) {
			if (item && temp.findIndex(it => it.blogId === item.blogId) < 0) {
				temp.push(item)
			}
		}
		setItems(temp);
		setAPILoading(false)
		console.log(moment.tz.guess())
	}, [])

	return (
		<Content headerFooterMenu="mountain-west">
			<Fragment>
				<div style={{ backgroundColor: "yellow", width: "97.8%", position: "fixed", height: "3rem", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.5rem" }}>
					You are viewing this page in the preview mode. Please publish it to make it available on the website.
				</div>
				{blog && !apiLoading && <div
					className="mwfbi_banner3 main-content-padding"
					style={{ backgroundImage: `url("${blog?.imageLink?.replace(/ /g, "%20")}")` }}
				>
					<h3 className="mwfbi_banner__heading2 heading_1">
						{apiLoading && <Skeleton count={2} style={{ height: '3rem' }} />}
						{!apiLoading && blog.title}
					</h3>
					{/* <h1 className='mwfbi_banner__text2'>The more interesting side of insurance information!</h1> */}
					{!apiLoading && <div className="mwfbi_banner__header">
						<p className="mwfbi_banner__header--date">
							<svg className="mwfbi_banner__header--date_icon">
								<use xlinkHref="/sprite.svg#icon-access_time" />
							</svg>
							{getTimeZoneLocalDate(blog.publishedDate).format("MMM DD YYYY")}
							{/* {moment.tz(blog.publishedDate, moment.tz.guess()).format("MMM DD YYYY")} */}
						</p>
						{blog.tagList && <p className="mwfbi_banner__header--date">
							<svg className="mwfbi_banner__header--date_icon">
								<use xlinkHref="/sprite.svg#icon-edit-copy" />
							</svg>
							{blog.tagList && blog.tagList.map(tg => tg.title).reduce((sum, c) => !sum.includes(c) ? [...sum, c] : sum, []).join(", ")}
						</p>
						}
						{blog.tagList && <p className="mwfbi_banner__header--date">
							<svg className="mwfbi_banner__header--date_icon">
								<use xlinkHref="/sprite.svg#icon-tag" />
							</svg>
							{blog?.tagList.map(tg => tg.tag).join(", ")}
						</p>}
					</div>}
				</div>}
				{apiLoading && <Skeleton height={500} />}
				<div className="blog__red_section">
					<p className="blog__red_section--text">
						{!apiLoading && blog.subTitle}
					</p>
				</div>
				<div className="icon-bar">
					<a href="#" className="facebook"><FacebookShareButton url={window.location.href} >
						<FacebookIcon size={32} round={true} url={''} />
					</FacebookShareButton></a>
					<a href="#" className="twitter"><TwitterShareButton url={window.location.href} >
						<TwitterIcon size={32} round={true} url={''} />
					</TwitterShareButton></a>
					<a href="#" className="linkedin"><LinkedinShareButton url={window.location.href} >
						<LinkedinIcon size={32} round={true} url={''} />
					</LinkedinShareButton></a>
					{/* <a href="#" className="facebook"><FacebookIcon size={32} round={true} url={''} /></a> */}

				</div>
				<div className="blog__data">

					{!apiLoading && blog && blog.richText && <div dangerouslySetInnerHTML={{ __html: blog.richText }}></div>}
					{/* {!apiLoading && blog && blog.richText && HtmlParser(blog.richText)} */}
					{apiLoading && <Skeleton count={50} />}
				</div>
				<div className="blog__related">
					{items &&
						items.length ? <h3 className="blog__related--title">Related Posts</h3> : ""}
					<div className="blog__related--posts">
						{items &&
							items.length ?
							items.map((content) => (content &&
								<a href={"/#/mwfbi/about/blog/" + content.blogId} target='_blank' rel="noreferrer" className="blog__related--post" key={content.blogId}>
									<img
										src={content.imageLink}
										alt={content.title}
										className="blog__related--image"
									/>
									<h5 className="blog__related--heading">{content.title}</h5>
									<p className="blog__related--link">Read More</p>
								</a>
							)) : ""}
					</div>
				</div>
				<a className='mw_button' href="javascript:history.back()" style={{ zIndex: 1000,left:"5rem", bottom: "10rem", width: "10rem", position: "fixed", height: "3rem", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "1.5rem" }}>
					Back
				</a>
			</Fragment>

		</Content>
	);
};

export default LearningCenterDetails;
