import { BLOGS_LIST_FAIL, BLOGS_LIST_REQUEST, BLOGS_LIST_SUCCESS, TOPICS_LIST_FAIL, TOPICS_LIST_REQUEST, TOPICS_LIST_SUCCESS } from '../constants/blogConstants';

export const blogsReducer = (state = {}, action) => {
	switch (action.type) {
		case BLOGS_LIST_REQUEST:
			return { loading: true };

		case BLOGS_LIST_SUCCESS:
			return { blogsList: action.payload, loading: false };

		case BLOGS_LIST_FAIL:
			return { loading: false, error: action.payload };


		default:
			return state;
	}
};

export const blogsTopicsReducer = (state = {}, action) => {
	switch (action.type) {
		case TOPICS_LIST_REQUEST:
			return { loading: true };

		case TOPICS_LIST_SUCCESS:
			return { topicsList: action.payload, loading: false };

		case TOPICS_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};
