import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import FooterNavigation from './FooterNavigation'
import EditSitemap from '../../screens/MWFBI/HomeScreen/edit/EditSitemap'
import './Footer.css'
import ReactHtmlParser from 'html-react-parser';
import { addContent, getPageContent } from '../../redux/actions/contentAction'
import { useSelector } from 'react-redux'
import htmlParse from 'html-react-parser';
import { ADMIN_USERS } from '../../redux/actions/ip'


const MwfbiFooter = () => {

	const azureLogin = useSelector(state => state.azureLogin)
	const [showEdit, setShowEdit] = useState(false)
	const [showSitemapModal, setShowSitemapModal] = useState(false);
	const [section6, setSection6] = useState({
		facebookLink: { value: "https://www.facebook.com/360InsuranceCompany/", action: "", type: "" },
		linkedinLink: { value: "https://www.linkedin.com/company/360-insurance-company/", action: "", type: "" },
		twitterLink: { value: `https://twitter.com/MWFBI`, action: "", type: "" },
		instagramLink: { value: "https://www.instagram.com/mwfbi/", action: "", type: "" },
		copyright: { value: " ©2021 360 Insurance Company", action: "", type: "" },
		termTitle: { value: " Terms &amp; Conditions", action: "", type: "" },
		termLink: { value: `/360/TermsConditions`, action: "", type: "" },
		disclosureTitle: { value: "Disclosure", action: "", type: "" },
		disclosureLink: { value: `/360/disclosure/`, action: "", type: "" },
		policyTitle: { value: "Privacy Policy", action: "", type: "" },
		policyLink: { value: "/360/PrivacyPolicy", action: "", type: "" },
		footerDescription: {
			value: `  Property-casualty insurance products are offered through 360 Insurance Company/Laramie, WY and are intended for residents of CO.
    <br />
    Fixed life insurance and annuity products are offered through Greenfields Life Insurance Company/West Des Moines, Iowa.`, action: "", type: ""
		}
	})
	const handleSection6Submit = () => {
		addContent({ pageName: "360_Home", section: "Section_6", content: section6 })
			.then(result => {
				if (result.data.status) {
					alert("Content Saved")
				}
			})
		setShowSitemapModal(false)
	}
	useEffect(() => {
		if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
			if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
				setShowEdit(true)
			} else setShowEdit(false)
		} else setShowEdit(false)
	}, [azureLogin])
	useEffect(() => {
		getPageContent({ pageName: "360_Home" })
			.then(res => {
				if (res.data.status) {
					if (res.data.result.Section_6) {
						setSection6(res.data.result.Section_6)
					}
				}
			})
	}, [])

	return (
		<footer className='footer'>
			<EditSitemap
				showModal={showSitemapModal}
				setShowModal={setShowSitemapModal}
				section6={section6}
				setSection6={setSection6}
				handleYes={handleSection6Submit}
			/>
			<FooterNavigation />
			<div className='footer__social' style={{ width: '100%', position: 'relative' }}>
				{showEdit && <div
					className='mwfbi_banner__edit2'
					style={{ top: '1rem', right: '3.5rem' }}
					onClick={() => setShowSitemapModal(true)}>
					<svg>
						<use xlinkHref='/sprite.svg#icon-edit' />
					</svg>
				</div>}
				<a
					href={section6.facebookLink.value}
					className='footer__social--btn'>
					<svg className='footer__social--icon icon-fb'>
						<use xlinkHref='/sprite.svg#icon-facebook' />
					</svg>
				</a>
				<a
					href={section6.linkedinLink.value}
					className='footer__social--btn'>
					<svg className='footer__social--icon icon-ln'>
						<use xlinkHref='/sprite.svg#icon-linkedIn' />
					</svg>
				</a>
				{/* <a href={section6.twitterLink.value} className='footer__social--btn'>
					<svg className='footer__social--icon icon-tw'>
						<use xlinkHref='/sprite.svg#icon-twitter' />
					</svg>
				</a>
				<a
					href={section6.instagramLink.value}
					className='footer__social--btn'>
					<svg className='footer__social--icon icon-in'>
						<use xlinkHref='/sprite.svg#icon-instagram' />
					</svg>
				</a> */}
			</div>
			<p className='footer__copyright'>
				{section6.copyright.value}
			</p>
			<div className='footer__links'>
				<Link to={section6.termLink.value} className='footer__links--link'>
					{htmlParse(section6.termTitle.value)}
				</Link>
				<Link to={section6.policyLink.value} className='footer__links--link'>
					{section6.policyTitle.value}
				</Link>
				<Link to={section6.disclosureLink ? section6.disclosureLink.value : '/360/disclosure'} className='footer__links--link'>
					{section6.disclosureTitle ? section6.disclosureTitle.value : 'Disclosure'}
				</Link>
			</div>
			<p className='footer__text'>
			{ReactHtmlParser(section6.footerDescription.value)}
			</p>
		</footer>
	)
}

export default MwfbiFooter
