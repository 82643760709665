import React from 'react';
import { Link } from 'react-router-dom';

import './Footer.css';
const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer__social">
				<a
					href="https://www.facebook.com/360InsuranceCompany/"
					className="footer__social--btn"
				>
					<svg className="footer__social--icon icon-fb">
						<use xlinkHref="/sprite.svg#icon-facebook" />
					</svg>
				</a>
				<a
					href="https://www.linkedin.com/company/mountain-west-farm-bureau-mutual-insurance-company/"
					className="footer__social--btn"
				>
					<svg className="footer__social--icon icon-ln">
						<use xlinkHref="/sprite.svg#icon-linkedIn" />
					</svg>
				</a>
				{/* <a href="https://twitter.com/MWFBI" className="footer__social--btn">
					<svg className="footer__social--icon icon-tw">
						<use xlinkHref="/sprite.svg#icon-twitter" />
					</svg>
				</a>
				<a href="https://www.instagram.com/mwfbi/" className="footer__social--btn">
					<svg className="footer__social--icon icon-in">
						<use xlinkHref="/sprite.svg#icon-instagram" />
					</svg>
				</a> */}
			</div>
			<p className="footer__copyright">©2021 Mountain West Farm Bureau Mutual Insurance Company</p>
			<div className="footer__links">
				<Link to="#!" className="footer__links--link">
					Terms &amp; Conditions
				</Link>
				<Link to="#!" className="footer__links--link">
					Privacy Policy
				</Link>
			</div>
		</footer>
	);
};

export default Footer;
