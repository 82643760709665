import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import './Header.css';
const GolfHeader = () => {
	return (
		<div class='megaMenu'>
			<div className='menu'>
				<div className='left__menu'>
					<img src='/images/logo2.png' alt='Logo' className='logo' />
					<ul class='exo-menu'>
						<li>
							<NavLink exact to='/golf/' activeClassName='navigation__active' className='exo-menu__head'>
								Home
							</NavLink>
						</li>
						<li>
							<NavLink exact to='/golf/register' activeClassName='navigation__active' className='exo-menu__head'>
								Registration
							</NavLink>
						</li>
						<li>
							<NavLink exact to='/golf/broncos-raffle' activeClassName='navigation__active' className='exo-menu__head'>
								Broncos Raffle
							</NavLink>
						</li>
						<li>
							<NavLink exact to='/golf/sponsers' activeClassName='navigation__active' className='exo-menu__head'>
								2021 Sponsers
							</NavLink>
						</li>
						<li>
							<NavLink exact to='/golf/contact' activeClassName='navigation__active' className='exo-menu__head'>
								Contact
							</NavLink>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default GolfHeader;
