import React, { Fragment, useState, useEffect, useCallback } from "react";
// import { useHistory } from 'react-router-dom'
import Modal from "../../../../components/DarkModal/index";
import FormInputComponent from "../../../../components/FormInputComponent/FormInputComponent";
import { useDropzone } from "react-dropzone";
import ReactPaginate from "react-paginate";
import {
  deleteContentItem,
  imageUpload,
  addContentItem,
  addContent,
} from "../../../../redux/actions/contentAction";

const itemsPerPage = 10;

const EditFindAgent = ({
  setShowModal,
  showModal,
  handleYes,
  data,
  setData,
}) => {
  const [headerText, setHeaderText] = useState({
    value: data.headerText.value,
    error: "",
  });
  const [headerSubText, setHeaderSubText] = useState({
    value: data.headerSubText.value,
    error: "",
  });
  const [footerText, setFooterText] = useState({
    value: data.footerText.value,
    error: "",
  });
  const [stepList, setStepList] = useState(data.steps.value);

  const [editIndex, setEditIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({
    title: { value: "", error: "" },
    description: { value: "", error: "" },
    description1: { value: "", error: "" },
  });

  useEffect(() => {
    setStepList(data.steps.value);
  }, [data.steps]);
  useEffect(() => {
    setHeaderText({ ...headerText, value: data.headerText.value });
    setHeaderSubText({ ...headerSubText, value: data.headerSubText.value });
    setFooterText({ ...footerText, value: data.footerText.value });
  }, [data]);

  const handleSave = () => {
    if (editData.title.value === "") {
      setEditData({
        ...editData,
        title: { ...editData.title, error: "Please enter title!" },
      });
      return;
    }

    if (editData.description.value === "") {
      setEditData({
        ...editData,
        description: {
          ...editData.description,
          error: "Please enter description!",
        },
      });
      return;
    }

    let temp = [...stepList];
    if (editIndex > -1) {
      temp[editIndex] = {
        title: editData.title.value,
        description: editData.description.value,
        description1: editData.description1.value,
      };
    } else {
      let step = {
        title: editData.title.value,
        description: editData.description.value,
        description1: editData.description1.value,
      };
      temp.push(step);
    }
    setStepList(temp);

    clearErrors();
    let content = {
      headerText: { ...data.headerText },
      headerSubText: { ...data.headerSubText },
      footerText: { ...data.footerText },
      steps: { ...data.steps, value: JSON.stringify(temp) },
    };
    setLoading(true);
    addContent({ pageName: "Claims", section: "Section_4", content })
      .then((result) => {
        setLoading(false);
        if (result.data.status) {
          clear();
          alert("Step Saved!");
          setData({ ...data, steps: { ...data.steps, value: temp } });
        }
      })
      .catch((err) => setLoading(false));
  };
  const handleHeaderSave = () => {
    if (headerText.value === "") {
      setHeaderText({ ...headerText, error: "Please enter header text!" });
      return;
    }
    if (headerSubText.value === "") {
      setHeaderSubText({
        ...headerSubText,
        error: "Please enter sub header text!",
      });
      return;
    }
    if (footerText.value === "") {
      setFooterText({ ...footerText, error: "Please enter footer text!" });
      return;
    }

    clearErrors();
    let content = {
      headerText,
      headerSubText,
      footerText,
      steps: { ...data.steps, value: JSON.stringify(data.steps.value) },
    };
    addContent({ pageName: "Claims", section: "Section_4", content }).then(
      (result) => {
        if (result.data.status) {
          setData({
            ...data,
            headerText,
            headerSubText,
            footerText,
          });
          alert("Header Text Saved!");
        }
      }
    );
  };

  const onEdit = (item, index) => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: item.title, error: "" },
      description: { value: item.description, error: "" },
      description1: { value: item.description1, error: "" },
    });
    setEditIndex(index);
    // setHeaderText({ ...headerText, value: item.title })
  };
  const clear = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: "", error: "" },
      description: { value: "", error: "" },
      description1: { value: "", error: "" },
    });
    setEditIndex(-1);
    // setShowModal(false)
    // handleAgentSubmit()
  };

  const closeModal = () => {
    resetAll();
    setShowModal(false);
  };
  const resetAll = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: "", error: "" },
      description: { value: "", error: "" },
      description1: { value: "", error: "" },
    });
    setEditIndex(-1);
  };
  const clearErrors = () => {
    setHeaderText({ ...headerText, error: "" });
    setEditData({ ...editData, title: { ...editData.title, error: "" } });
    setEditData({
      ...editData,
      description: { ...editData.description, error: "" },
    });
    setEditData({
      ...editData,
      description1: { ...editData.description1, error: "" },
    });
  };
  const onStepDelete = (item, index) => {
    if (window.confirm("Are You Sure to Delete?")) {
      let temp = [...stepList];
      temp.splice(index, 1);
      setStepList(temp);
      let content = {
        headerText: { ...data.title, value: headerText.value },
        headerSubText: { ...data.title, value: headerSubText.value },
        footerText: { ...data.title, value: footerText.value },
        steps: { ...data.steps, value: JSON.stringify(temp) },
      };
      addContent({ pageName: "Claims", section: "Section_4", content }).then(
        (result) => {
          console.log(result.data);
          if (result.data.status) {
            alert("Step Deleted!");
            setData({ ...data, steps: { ...data.steps, value: temp } });
          }
        }
      );
    }
  };
  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className="modal__container-dark">
          <div className="modal_heading__container-dark">
            <h1 className="modal_heading__text-dark">Edit Guidlines</h1>
            <button
              className="modal__heading--button-dark"
              onClick={() => closeModal()}
            >
              <svg className="modal_heading__icon-dark">
                <use xlinkHref="/sprite.svg#icon-cross" />
              </svg>
            </button>
          </div>
          <div className="modal__form-dark">
            <div className="modal_inner_content">
              <div className="modal_inner_content--left">
                <div className="inline-form-view">
                  <FormInputComponent
                    heading="Title"
                    style={{ width: "50%" }}
                    value={headerText.value}
                    required={true}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setHeaderText({ error: "", value: e.target.value });
                    }}
                    errorMessage={headerText.error}
                  />
                  <FormInputComponent
                    heading="Sub Title"
                    style={{ width: "50%" }}
                    value={headerSubText.value}
                    required={true}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setHeaderSubText({ error: "", value: e.target.value });
                    }}
                    errorMessage={headerSubText.error}
                  />
                </div>
                <div className="inline-form-view">
                  <FormInputComponent
                    heading="Footer Text"
                    style={{ width: "100%" }}
                    value={footerText.value}
                    required={true}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setFooterText({ error: "", value: e.target.value });
                    }}
                    errorMessage={footerText.error}
                  />
                </div>
                <div className="inline-form-view">
                  <button
                    type="button"
                    style={{ marginTop: "1rem", marginLeft: '0.5rem' }}
                    className="modal__button-dark"
                    onClick={handleHeaderSave}
                  >
                    Save
                  </button>
                </div>
                <hr style={{ marginTop: "1rem", marginBottom: "1rem" }} />
                <div className="inline-form-view">
                  <FormInputComponent
                    heading="Title:"
                    style={{ width: "100%" }}
                    value={editData.title.value}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...editData };
                      temp.title.value = e.target.value;
                      setEditData(temp);
                    }}
                    errorMessage={editData.title.error}
                  />
                </div>
                <div className="inline-form-view">
                  <FormInputComponent
                    heading="Description:"
                    style={{ width: "100%" }}
                    required={true}
                    type={"text"}
                    value={editData.description.value}
                    onChange={(e) => {
                      let temp = { ...editData };
                      temp.description.value = e.target.value;
                      setEditData(temp);
                    }}
                    errorMessage={editData.description.error}
                  />
                </div>
                <div className="inline-form-view">
                  <FormInputComponent
                    heading="Description 1:"
                    style={{ width: "100%" }}
                    type={"text"}
                    value={editData.description1.value}
                    onChange={(e) => {
                      let temp = { ...editData };
                      temp.description1.value = e.target.value;
                      setEditData(temp);
                    }}
                    errorMessage={editData.description1.error}
                  />
                </div>
                <div
                  style={{
                    padding: "0.5rem",
                    display: "flex",
                    columnGap: "1rem",
                  }}
                >
                  <button
                    type="button"
                    className="modal__button-reset-dark"
                    onClick={resetAll}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="modal__button-dark"
                    onClick={handleSave}
                  >
                    {loading ? "Saving.." : editIndex > -1 ? "Save" : "Add"}
                  </button>
                </div>
              </div>
              <div className="modal_inner_content--right3"></div>
            </div>
            <div className="modal_inner_content">
              <div className="modal_form_container">
                <table
                  className="modal__form-table"
                  cellspacing="0"
                  cellpadding="0"
                >
                  <thead className="modal__form-sticky">
                    <tr className="modal__form-tr">
                      <th>No.</th>
                      <th>Title</th>
                      <th>Description 1</th>
                      <th>Description 2</th>
                      <th style={{ textAlign: "right", paddingRight: "5rem" }}>
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {stepList &&
                      stepList.length &&
                      stepList.map((item, i) => {
                        return (
                          <tr className="modal__form-tr2" key={i}>
                            <td>{i + 1}</td>
                            <td style={{ textOverflow: "ellipsis" }}>
                              {item.title}
                            </td>
                            <td>{item.description}</td>
                            <td>{item.description1}</td>
                            <td
                              className="modal__form-buttons"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                type="button"
                                className="modal__button-edit"
                                onClick={(e) => onEdit(item, i)}
                              >
                                edit
                              </button>
                              <button
                                type="button"
                                className="modal__button-delete"
                                onClick={(e) => onStepDelete(item, i)}
                              >
                                delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* <div className='modal_dark_buttons' style={{ paddingTop: '0' }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={(e) => handleSave()}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal>
  );
};

export default EditFindAgent;
