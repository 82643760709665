import React, { useState, useEffect } from 'react';
import Banner from '../../../components/Banner/Banner';
import { Link } from 'react-router-dom';

import Content from '../../../components/Content/Content';
import './OtherOfferings.css';
import EditServices from './edit/EditServices';
import EditDiscountTypeModal from './edit/EditDiscountTypeModal';
import { addContent, getPageContent } from '../../../redux/actions/contentAction';
import { useSelector } from 'react-redux';
import { ADMIN_USERS } from '../../../redux/actions/ip';

const OtherOfferings = () => {
	const azureLogin = useSelector(state => state.azureLogin)
	// const [ checked, setChecked ] = useState('checked1');
	const pageName = "OtherOffering_1";
	const [showEdit, setShowEdit] = useState(false)
	const [showServiceModal, setShowServiceModal] = useState(false)
	const [section1, setSection1] = useState({
		title: { value: 'Financial Services', action: '', type: '' },
		description: {
			value: `Our relationship with 360 Insurance Financial Services, allows many of our 360 Insurance agents to offer the following services. Contact your local agent office to see if these services are offered near you.`,
		},
	})
	const [showDiscountTypeModal, setShowDiscountTypeModal] = useState(false);
	const [discountListArr, setDiscountListArr] = useState([
		{
			title: "Retirement Funding ",
			description: "Individual Retirement Accounts (IRAs) can be a good option for individuals who do not have access to an employer-sponsored retirement plan or who want an option in addition to an employer-sponsored plan",
			src: 'https://www.mwfbi.com/images/Graphics-Retirement-Funding.png',
			url: "https://www.fbfs.com/investments/college-funding"
		},
		{
			title: "Retirement Funding ",
			description: "When you invest in mutual funds, you’re pooling your money with other investors to buy stocks, bonds, short-term securities or a combination of these investments.",
			src: 'https://www.mwfbi.com/images/Graphics-Mutual-Funds.png',
			url: "https://www.fbfs.com/investments/college-funding"
		},
		{
			title: "Retirement Funding ",
			description: "Individual Retirement Accounts (IRAs) can be a good option for individuals who do not	have access to an employer- sponsored retirement plan or who want an option in addition	to an employersponsored plan",
			src: 'https://www.mwfbi.com/images/Graphics-College-Funding.png',
			url: "https://www.fbfs.com/investments/college-funding"
		}
	])
	const [section2, setSection2] = useState({
		discountList: [
			{
				title: "Retirement Funding ",
				description: "Individual Retirement Accounts (IRAs) can be a good option for individuals who do not have access to an employer-sponsored retirement plan or who want an option in addition to an employer-sponsored plan",
				src: '/images/Graphics-Retirement-Funding.png',
				url: ""
			},
			{
				title: "Mutual Funds",
				description: "When you invest in mutual funds, you’re pooling your money with other investors to buy stocks, bonds, short-term securities or a combination of these investments.",
				src: '/images/Graphics-Mutual-Funds.png',
				url: ""
			},
			{
				title: "College Funding",
				description: "It’s never too soon to start setting money aside for a child’s college education. It seems to get more expensive each year, so investing now, even in small amounts, can make a big difference.",
				src: '/images/Graphics-College-Funding.png',
				url: ""
			},
			{
				title: "Long-Term Care",
				description: "It may sound obvious, but the longer we live, the more likely it is that we will need long-term care at some point in our lives. It makes sense to plan for long-term care expenses, and fund while you are able.",
				src: 'https://mwfbiportal.blob.core.windows.net/imagecontainer/images/7578884623455622-Graphics-Long-Term-Care.png',
				url: ""
			},
			{
				title: "Disability Income Insurance",
				description: "Three months off work due to an injury or accident? Now what? if you are hurt and unable to work for an extended period, your paycheck might stop coming, but your bills won’t.",
				src: 'https://mwfbiportal.blob.core.windows.net/imagecontainer/images/21910606450198533-Graphics-Disability-Income-Insurance.png',
				url: ""
			},
			{
				title: "Estate Strategies",
				description: "When you plan for the future of your estate, you are assisting your loved ones in carrying out your final wishes without indecision or speculation. In the simplest terms, your estate plan is a blueprint that outlines what you would like to happen with your home and assets when you are gone.",
				src: 'https://mwfbiportal.blob.core.windows.net/imagecontainer/images/6854225794741637-Graphics-Estate-Strategies.png',
				url: ""
			},
		]
	})
	const [bannerData, setBannerData] = useState({
		title: { value: 'Other Services Offered', error: '' },
		subTitle: {
			value: 'Your local 360 Insurance agent can help you with so much more!',
			error: '',
		},
		tagline: {
			value:
				'Start a quote and begin working with your local agent!',
			error: '',
		},
		button1: { value: 'START A QUOTE', error: '' },
		button1Link: { value: '/360/start-a-quote', error: '' },
		button1Status: { value: true, error: '' },
		button2: { value: 'FIND AN AGENT', error: '' },
		button2Link: { value: '/360/find-an-agent', error: '' },
		button2Status: { value: true, error: '' },
		imageUrl: { value: '/images/otherOffering.jpg', error: '' },
	})
	const handleServiceSubmit = () => {
		addContent({ pageName: pageName, section: "Section_1", content: section1 })
			.then(result => {
				console.log(result.data);
				if (result.data.status) {
					alert("Content Saved")
				}
			})
	}

	const handleDiscountType = (content) => {
		addContent({ pageName: pageName, section: "Section_2", content: { list: { value: JSON.stringify(content) } } })
			.then(result => {
				console.log(result.data);
				if (result.data.status) {
					getPageContent({ pageName: pageName }).then((res) => {
						if (res.data.status) {
							console.log(res.data.status);
							console.log(section2)
							if (res.data.result.Section_2) {
								setSection2({
									discountList: JSON.parse(res.data.result.Section_2.list.value),
								});
								setDiscountListArr(
									JSON.parse(res.data.result.Section_2.list.value)
								);
							}
						}
					});
					alert("Content Saved");
				}
			})
	}
	useEffect(() => {
		if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
			if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
				setShowEdit(true)
			} else setShowEdit(false)
		} else setShowEdit(false)
	}, [azureLogin]);
	useEffect(() => {
		getPageContent({ pageName: pageName })
			.then(res => {
				if (res.data.status) {
					console.log(res.data.status)
					if (res.data.result.Section_1)
						setSection1(res.data.result.Section_1)
					if (res.data.result.Section_2) {
						setSection2({ discountList: JSON.parse(res.data.result.Section_2.list.value) })
						setDiscountListArr(JSON.parse(res.data.result.Section_2.list.value))
					}
					if (res.data.result.TopBanner)
						setBannerData({
							...res.data.result.TopBanner,
							button1Status: {
								value: res.data.result.TopBanner.button1Status.value === 'true',
							},
							button2Status: {
								value: res.data.result.TopBanner.button2Status.value === 'true',
							},
						})
				}
			})
	}, [])

	const splitArray = (array, perChunk = 3) => {
		if (Array.isArray(array) && array.length) {
			return array.reduce((resultArray, item, index) => {
				const chunkIndex = Math.floor(index / perChunk)

				if (!resultArray[chunkIndex]) {
					resultArray[chunkIndex] = [] // start a new chunk
				}

				resultArray[chunkIndex].push(item)

				return resultArray
			}, [])
		}
		return []
	}


	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="linear-gradient(rgba(47,35,98,0.4), rgba(47,35,98,0.4)),url(/images/otherOffering.jpg)"
				title="Other Services Offered"
				subTitle="Your local 360 Insurance agent can help you with so much more!"
				bannerData={bannerData}
				setBannerData={setBannerData}
				showEdit={showEdit}
				pageName={pageName}
			/>
			<div className='quote2'>
				<div className='flex2'>
					<h3 className='quote__text2'>
						{bannerData && bannerData.tagline
							? bannerData.tagline.value
							: 'Start a business insurance quote and begin Working with your local agent.'}
					</h3>
				</div>
				<div className='header_buttons'>
					{bannerData && bannerData.button1 && bannerData.button1Status.value && (
						<Link to={bannerData.button1Link.value} className='mw_button'>
							{bannerData.button1.value}
						</Link>
					)}
					{bannerData && bannerData.button2 && bannerData.button2Status.value && (
						<Link to={bannerData.button2Link.value} className='mw_button'>
							{bannerData.button2.value}
						</Link>
					)}
				</div>
			</div>

			<div className="other_offerings_options" id="offerings" style={{ width: '100%', position: 'relative' }}>
				<h1 className="phase2_heading">{section1.title.value}</h1>
				<p className="phase2_subheading">
					{section1.description.value}
				</p>
				<EditServices
					showModal={showServiceModal}
					setShowModal={setShowServiceModal}
					handleYes={handleServiceSubmit}
					section1={section1}
					setSection1={setSection1}
				/>
				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						style={{ top: '1rem' }}
						id='admin-edit'
						onClick={() => setShowServiceModal(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
				{/* <div className="homeowner_insurance_discounts-grid">
					<div className="discounts-types">
						<img
							src="./images/Graphics-Retirement-Funding.png"
							alt="retirement"
							className="discounts_images_nails"
						/>

						<div className="discount_image_text">
							<h1 className="phase2_heading1">Retirement Funding </h1>
							<p className="phase2_subheading">
								Individual Retirement Accounts (IRAs) can be a good option for individuals who do not
								have access to an employer-sponsored retirement plan or who want an option in addition
								to an employer-sponsored plan
							</p>
						</div>
						<div className="line" />
					</div>

					<div className="discounts-types">
						<img
							src="./images/Graphics-Mutual-Funds.png"
							alt="mutual Funds"
							className="discounts_images_nails"
						/>
						<div className="discount_image_text">
							<h1 className="phase2_heading1">Mutual Funds</h1>
							<p className="phase2_subheading">
								When you invest in mutual funds, you’re pooling your money with other investors to buy
								stocks, bonds, short-term securities or a combination of these investments.
							</p>
						</div>
						<div className="line" />
					</div>
					<div className="discounts-types">
						<img
							src="./images/Graphics-College-Funding.png"
							alt="collede funding"
							className="discounts_images_nails"
						/>
						<div className="discount_image_text">
							<h1 className="phase2_heading1">College Funding</h1>
							<p className="phase2_subheading">
								It’s never too soon to start setting money aside for a child’s college education. It
								seems to get more expensive each year, so investing now, even in small amounts, can make
								a big difference.
							</p>
						</div>
					</div>
				</div> */}
				{/* <div className="homeowner_insurance_discounts-grid">
					<div className="discounts-types">
						<img
							src="./images/Graphics-Long-Term-Care.png"
							alt="long term care"
							className="discounts_images_nails"
						/>

						<div className="discount_image_text">
							<h1 className="phase2_heading1">Long-Term Care</h1>
							<p className="phase2_subheading">
								It may sound obvious, but the longer we live, the more likely it is that we will need
								long-term care at some point in our lives. It makes sense to plan for long-term care
								expenses, and fund while you are able.
							</p>
						</div>
						<div className="line" />
					</div>

					<div className="discounts-types">
						<img
							src="./images/Graphics-Disability-Income-Insurance.png"
							alt="income insurance"
							className="discounts_images_nails"
						/>
						<div className="discount_image_text">
							<h1 className="phase2_heading1">Disability Income Insurance</h1>
							<p className="phase2_subheading">
								Three months off work due to an injury or accident? Now what? if you are hurt and unable
								to work for an extended period, your paycheck might stop coming, but your bills won’t.
							</p>
						</div>
						<div className="line" />
					</div>
					<div className="discounts-types">
						<img
							src="./images/Graphics-Estate-Strategies.png"
							alt="estate strategies"
							className="discounts_images_nails"
						/>
						<div className="discount_image_text">
							<h1 className="phase2_heading1">Estate Strategies</h1>
							<p className="phase2_subheading">
								When you plan for the future of your estate, you are assisting your loved ones in
								carrying out your final wishes without indecision or speculation. In the simplest
								terms, your estate plan is a blueprint that outlines what you would like to happen with
								your home and assets when you are gone.
							</p>
						</div>
					</div>
				</div> */}

			</div>
			<div className="discounts_options" id="coverage_options" style={{ width: '100%', position: 'relative', marginTop: '-4rem' }}>
				<EditDiscountTypeModal
					handleYes={handleDiscountType}
					showModal={showDiscountTypeModal}
					setShowModal={setShowDiscountTypeModal}
					section2={section2}
					discountListArr={discountListArr}
					setDiscountListArr={setDiscountListArr}
					setSection2={setSection2}
				/>
				{section2 && section2.discountList.length ?
					splitArray(section2.discountList, 3).map((elements, i) => (
						<div className="homeowner_insurance_discounts-grid" key={'parent' + i}>
							{
								elements.length &&
								elements.map((item, i) => (
									<div className="discounts-types" key={i}>
										<img
											src={item.src}
											alt={item.title}
											className="discounts_images_nails"
										/>
										<div className="discount_image_text">
											<h1 className="phase2_heading1">{item.title}</h1>
											<p className="phase2_subheading">
												{item.description}
											</p>
											{/* <a
												href={item.url}
												className="phase2_subheading2"
												target="_blank"
											>
												learn more
											</a> */}
										</div>
										{i <= 1 ? <div className="line" /> : <div />}
									</div>
								))
							}
						</div>
					)) : null}
				<EditDiscountTypeModal
					handleYes={handleDiscountType}
					showModal={showDiscountTypeModal}
					setShowModal={setShowDiscountTypeModal}
					section2={section2}
					discountListArr={discountListArr}
					setDiscountListArr={setDiscountListArr}
					setSection2={setSection2}
				/>
				{showEdit && <div
					className='mwfbi_banner__edit'
					style={{ top: '1rem' }}
					id='admin-edit'
					onClick={() => setShowDiscountTypeModal(true)}>
					<svg>
						<use xlinkHref='/sprite.svg#icon-edit' />
					</svg>
				</div>}
			</div>
			{/* <div className="other_offerings_services">
				<h1 className="phase2_heading1">
					Securities & services offered through FBL Marketing Services, LLC, +5400 University Avenue, West Des
					Moines, IA 50266, 877.860.2904, Member SIPC.<p className="phase2_subheading">
						Advisory Services offered through FBL Wealth Management, LLC.+ Individual must be a registered
						representative of FBL Marketing Services, LLC or an investment adviser representative with FBL
						Wealth Management, LLC to discuss securities products. Individual must be released by FBL Wealth
						Management, LLC to offer advisory services. Long-term care and Disability Income Insurance
						policies are underwritten by insurance companies that are not affiliated with our companies.
						+Affiliates
					</p>
				</h1>
			</div> */}
		</Content>
	);
};
export default OtherOfferings;
