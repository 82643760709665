import React, { useState, useCallback } from "react";
import Modal from "../../../DarkModal";

import Styles from '../Section1/EditFirstSection.module.css';

const EditSecondSection = ({ pointList = [], setPointList, showSection1Modal, setShowSection1Modal, onEdit, onAddPoint, onDelete, onSave }) => {


    const onItemDelete = (item, index) => {
        if (window.confirm("Are you sure to delete?")) {
            let temp = [...pointList]
            temp.splice(index, 1)
            setPointList(temp)
            onDelete(temp)
        }

    }
    const reposition = (index, direction) => {
        let temp = [...pointList]
        const element = temp.splice(index, 1)[0];
        if (direction === 'up' && index > 0) {
            temp.splice(index - 1, 0, element);
        } else if (direction === 'down' && index < pointList.length) {
            temp.splice(index + 1, 0, element);
        }
        setPointList(temp)
        onSave(temp)
    }
    return (
        <Modal show={showSection1Modal}>
            <form>
                <div className="modal__container-dark">
                    <div className="modal_heading__container-dark">
                        <h1 className="modal_heading__text-dark">
                            Points
                        </h1>
                        <button
                            className="modal__heading--button-dark"
                            onClick={() => setShowSection1Modal(false)}
                        >
                            <svg className="modal_heading__icon-dark">
                                <use xlinkHref="/sprite.svg#icon-cross" />
                            </svg>
                        </button>
                    </div>
                    <div className={"modal__form-dark"} style={{ display: 'flex' }}>
                        <div className="modal_inner_content">
                            <div className={Styles.modalContainer} style={{ height: '40rem' }}>

                                {pointList && <div className='modal_form_container' style={{ height: '40rem' }}>
                                    <table
                                        className='modal__form-table'
                                        cellSpacing='0'
                                        cellPadding='0'>
                                        <thead className='modal__form-sticky'>
                                            <tr className='modal__form-tr'>
                                                <th>No.</th>
                                                <th>Title</th>
                                                <th>Sort</th>
                                                <th style={{ textAlign: 'end', paddingRight: '5rem' }}>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {pointList.map((item, i) => {
                                                return (
                                                    <tr
                                                        style={{ height: '60px' }}
                                                        className='modal__form-tr2'
                                                        key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{item.title}</td>
                                                        <td>
                                                            <button type="button" onClick={(e) => reposition(i, 'up')}>
                                                                <svg className="modal_heading__icon-dark">
                                                                    <use xlinkHref="/sprite.svg#icon-up" />
                                                                </svg>
                                                            </button>
                                                            &nbsp;
                                                            <button type="button" onClick={(e) => reposition(i, 'down')}>
                                                                <svg className="modal_heading__icon-dark">
                                                                    <use xlinkHref="/sprite.svg#icon-down" />
                                                                </svg>
                                                            </button>
                                                        </td>
                                                        <td
                                                            style={{ marginTop: '12px' }}
                                                            className='modal__form-buttons'>
                                                            <button
                                                                type='button'
                                                                className='modal__button-edit'
                                                                onClick={(e) => onEdit(item, i)}>
                                                                edit
                                                            </button>
                                                            <button
                                                                type='button'
                                                                className='modal__button-delete'
                                                                onClick={(item) => onItemDelete(item.point, i)}>
                                                                delete
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>}
                            </div>
                        </div>
                        <button
                            style={{
                                alignSelf: 'flex-end',
                                marginTop: '8px',
                                marginRight: '12px',
                            }}
                            type="button"
                            className="modal__button-dark"
                            onClick={onAddPoint}
                        >
                            Add Section
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default EditSecondSection;