export const validateJsonString = (jsonString) => {
  return jsonString
    .replace(/“/g, '\\u201c')
    .replace(/”/g, '\\u201d')
    .replace(/”/g, '\\u201d')
    .replace(/'/g, 'U0027')
    .replace(/`/g, 'U0060')
}
export const checkIsEmpty = (str) => {
  if (!str)
    return true;
  if (str == "")
    return true;
  if (str.length == 0)
    return true;
  return false;
}


export const splitArray = (array, perChunk = 3) => {
  if (Array.isArray(array) && array.length) {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [] // start a new chunk
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])
  }
  return []
}

export const splitArrayTo2D = (array) => {

  return array.reduce((preArray, currentItem) => {
    if (preArray.length === 0)
      return [[currentItem]];
    if (preArray[preArray.length - 1].length < 2
      && ["Textbox", "Select", "Date"].includes(preArray[preArray.length - 1][0].FieldInputType)
      && ["Textbox", "Select", "Date"].includes(currentItem.FieldInputType)) {
      preArray[preArray.length - 1].push(currentItem);
      return preArray
    }
    return [...preArray, [currentItem]];
  }, [])
};
export const commaSeprator = (string) => string?.split(",")
