import React, { useEffect, useState } from 'react'
import Banner from '../../../components/Banner/Banner'
import { Link } from 'react-router-dom'

import Content from '../../../components/Content/Content'
import './HomeOwnersInsurance.css'
import { useSelector } from 'react-redux'
import {
  addContent,
  getPageContent,
} from '../../../redux/actions/contentAction'
import EditHomeInsuranceInfo from './edit/EditHomeInsuranceInfo'
import EditCountryInsuranceInfo from './edit/EditCountryInsuranceInfo'
import EditInsurancePoints from './edit/EditInsurancePoints'
import EditRenterInsuranceInfo from './edit/EditRenterInsuranceInfo'
import EditHomeInsuranceDiscount from './edit/EditHomeInsuranceDiscount'
import EditInsuranceProperties from './edit/EditInsuranceProperties'
import EditDiscountType from './edit/EditDiscountType'
import { splitArray, validateJsonString } from '../../../redux/constants/utils'
import { ADMIN_USERS } from '../../../redux/actions/ip'

const HomeOwnersInsurance = () => {
  const [checked, setChecked] = useState('checked0')
  const [secondChecked, setSecondChecked] = useState('checked0')
  const [checkedP, setCheckedP] = useState('checked5')
  const [showEdit, setShowEdit] = useState(false)
  const [showHomeInsuranceInfoModal, setShowHomeInsuranceInfoModal] =
    useState(false)
  const [showInsurancePoints, setShowInsurancePoints] = useState(false)
  const [showCountryInsuranceInfoModal, setShowCountryInsuranceInfoModal] =
    useState(false)
  const [showRenterInsurance, setShowReenterInsuranceInfoModal] =
    useState(false)

  const [showHomeInsuranceDiscount, setHomeInsuranceDiscountModal] =
    useState(false)
  const [showPropertyModel, setShowPropertyModal] = useState(false)
  const [showCoverageList, setShowCoverageListModal] = useState(false)
  const [showDiscount, setShowDiscountModal] = useState(false)
  const azureLogin = useSelector((state) => state.azureLogin)
  const [bannerData, setBannerData] = useState({
    title: { value: 'Homeowners Insurance', error: '' },
    subTitle: {
      value: 'Providing peace of mind for the place you call home.',
      error: '',
    },
    tagline: {
      value:
        'Start a home insurance quote and begin working with your local agent.',
      error: '',
    },
    button1: { value: 'START A QUOTE', error: '' },
    button1Link: { value: '/mwfbi/start-a-quote', error: '' },
    button1Status: { value: true, error: '' },
    button2: { value: 'FIND AN AGENT', error: '' },
    button2Link: { value: '/mwfbi/find-an-agent', error: '' },
    button2Status: { value: true, error: '' },
    imageUrl: { value: '/images/country-home-fall.jpg', error: '' },
  })
  const [section1, setSection1] = useState({
    title: { value: 'Home Insurance Coverage', action: '', type: '' },
    description: {
      value: `We offer a variety of home protection plans so that you can select the one that is right for you.
			Your home is a source of pride and a place of security – protect your investment with the finest in
			property and liability insurance.`,
    },
  })

  const [section2, setSection2] = useState([
    {
      title: { value: 'Personal Property Coverage', error: '' },
      description: {
        value: `Your furniture, clothing, electronics, and jewelry are important to you as well. We offer solid coverage for your personal property, including the option of replacement cost coverage.`,
        error: '',
      },
      example: {
        value:
          'During a thunderstorm, lighting strikes your home and damages your household electronics. The damage will be covered minus your deductible. *exclusions/limitations apply',
        error: '',
      },
    },
    {
      title: { value: 'Liability Insurance', error: '' },
      description: {
        value: `Our home insurance provides coverage when you are legally liable for bodily injury or property damage caused by a covered loss on your property or as a result of your activities.`,
        error: '',
      },
      example: {
        value:
          'Your neighbor stops by and slips and falls on your icy steps, breaking their arm. Your homeowners insurance can pay for their medical bills and lost wages while they’re injured..',
        error: '',
      },
    },
    {
      title: { value: 'Other Structures', error: '' },
      description: {
        value: `We have the coverage you need to protect your detached garage or storage shed on your premises.`,
        error: '',
      },
      example: {
        value:
          'A hailstorm comes through and damages the roof and windows of your garden shed. Your homeowners coverage extends to this structure.',
        error: '',
      },
    },
    {
      title: { value: 'Loss of Use', error: '' },
      description: {
        value: `If your home is damaged by a covered loss and you are unable to live in it while repairs are being made, we provide coverage for your additional living expenses during reconstruction.`,
        error: '',
      },
      example: {
        value:
          'A tree crashes through your roof and you have to stay two weeks at a hotel and eat out for all your meals. Your insurance can cover the cost of this additional living expense. *some limitations apply',
        error: '',
      },
    },
  ])

  const [section3, setSection3] = useState({
    title: { value: 'Country Home Insurance', action: '', type: '' },
    description: {
      value: `Our Country Home policy is designed to meet the special needs of country living. A traditional homeowner’s policy may leave gaps in coverage. If you are a homeowner whose lifestyle includes a small acreage and perhaps some horses, livestock or limited agricultural productions, this policy may be a good fit for you.`,
    },
  })
  const [section5, setSection5] = useState({
    title: { value: 'Renters Insurance', action: '', type: '' },
    subMenuTitle: {
      value: 'Cover the replacement cost of your personal property, such as:',
      action: '',
      type: '',
    },
    description: {
      value: `If you rent your home, it’s essential that you have protection for the things that are important to you, especially since your landlord’s insurance won’t cover you. You may not realize the total value of your possessions, but they add up quickly. In the event of a loss, could you afford to replace everything if it were stolen or damaged? Renter’s insurance is an inexpensive way to ensure your peace of mind. For less than a dollar a day, you can protect yourself and your property from the unexpected.`,
    },
  })
  const [section6, setSection6] = useState({
    title: { value: 'Home Insurance Discounts', action: '', type: '' },
    description: {
      value: `Are you looking for quality home insurance that you can afford without sacrificing coverage? 360 Insurance is proud of the high-quality products and services we offer. The following discounts reflect our commitment to offer these products at an affordable rate without sacrificing coverage.`,
    },
  })
  const [section7, setSection7] = useState([
    'Clothing',
    'Furniture',
    'Electronics',
    'Outdoor Recreation Equipment',
    'Firearms(limit apply)',
    'Musical Instruments',
    'Jewelry(limits apply)',
    'Kitchen Appliances and Cookware',
    'Refrigerated Products($500 limit)',
    'Bedding, Towels, and Cleaning Supplies',
  ])

  const [section4, setSection4] = useState([
    {
      title: { value: 'Property Coverage', error: '' },
      description: {
        value: `We offer coverage for your property exposures including your home, additional structures, and limited farm personal property(including saddles, tack, livestock, and hay). some optional coverages include: replacement cost coverage for household personal property, earthquake, backup of sewer or drains, and waiver of glass deductible.`,
        error: '',
      },
      example: { value: '', error: '' },
    },
    {
      title: { value: 'Auto Coverage', error: '' },
      description: {
        value: `Our auto insurance provides coverage for comprehensive, collision, and liability exposures. In addition, various deductible options and discounts allow you to customize the policy to meet your needs. Some optional coverages include: theft rental reimbursement, special equipment or paint, rental expense, additional living expense, and accidental death or dismemberment.`,
        error: '',
      },
      example: { value: '', error: '' },
    },
    {
      title: { value: 'Liability Insurance', error: '' },
      description: {
        value: `In addition to standard liability coverage, we offer farm liability coverage for the unique exposures of a country home. Standard coverages include bodily injury and property damage to protect you from damages to others for which you are legally obligated and medical payments to cover medical expenses of those who are injured on your property.`,
        error: '',
      },
      example: { value: '', error: '' },
    },
    {
      title: { value: 'Inland Marine', error: '' },
      description: {
        value: `Enhanced coverage is available for your valuable personal property such as jewelry, furs, silverware, guns, fine art, hearing aids and more. You can also cover your watercraft, ATVs and other recreational equipment.`,
        error: '',
      },
      example: {
        value:
          'If your dog eats your hearing aids, inland marine can extend coverage to replace your hearing aids.',
        error: '',
      },
    },
    {
      title: { value: 'Umbrella', error: '' },
      description: {
        value: `If you add on an Umbrella policy, you can get additional liability coverage to protect you against catastrophic liability claims.`,
        error: '',
      },
      example: {
        value:
          'If you own a home that is very high value, this protection can enhance coverage to protect your assets in case of a lawsuit.',
        error: '',
      },
    },
  ])
  const [discountListArr, setDiscountListArr] = useState([
    {
      title: 'New Home',
      description:
        'Our premium automatically reflects a discount if your home was built in the last 12 years.',
      src: './images/Graphics-New-Home.png',
    },
    {
      title: 'Home Alert',
      description:
        'A discount for homeowners who protect their homes with various qualifying alarm and alert systems.',
      src: './images/Graphics-Home-Alert.png',
    },
    {
      title: 'Resistive Roofing',
      description:
        'A discount for roofs that are constructed of qualifying “wind resistive” or “hail resistive” materials.',
      src: './images/Graphics-Resistive-Roofing.png',
    },
    {
      title: 'Loyalty',
      description:
        'If you have been insured by 360 Insurance for certain periods of time, we want to reward your loyalty!',
      src: './images/Graphics-Loyalty.png',
    },
    {
      title: 'Package Policy',
      description:
        'If you choose to insure your home and autos with 360 Insurance, there could be a discount.',
      src: './images/Graphics-Package-Policy.png',
    },
  ])
  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true)
      } else setShowEdit(false)
    } else setShowEdit(false)
  }, [azureLogin]);

  useEffect(() => {
    getPageContent({ pageName: 'HomeInsurance' }).then((res) => {
      if (res.data.status) {
        if (res.data.result.Section_1) setSection1(res.data.result.Section_1)
        if (res.data.result.Section_2)
          setSection2(JSON.parse(res.data.result.Section_2.list.value))
        if (res.data.result.Section_3) setSection3(res.data.result.Section_3)
        if (res.data.result.Section_6) setSection6(res.data.result.Section_6)
        if (res.data.result.Section_4)
          setSection4(JSON.parse(res.data.result.Section_4.list.value))
        if (res.data.result.Section_5) setSection5(res.data.result.Section_5)
        if (res.data.result.Section_7)
          if (res.data.result.Section_8)
            //   setSection7(JSON.parse(res.data.result.Section_7.list.value))
            setDiscountListArr(JSON.parse(res.data.result.Section_8.list.value))
        if (res.data.result.TopBanner)
          setBannerData({
            ...res.data.result.TopBanner,
            button1Status: {
              value: res.data.result.TopBanner.button1Status.value === 'true',
            },
            button2Status: {
              value: res.data.result.TopBanner.button2Status.value === 'true',
            },
          })
      }
    })
  }, [])

  const handleHomeInsuranceInfoSubmit = () => {
    addContent({
      pageName: 'HomeInsurance',
      section: 'Section_1',
      content: section1,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleInsurancePointsSubmit = (content) => {
    setShowInsurancePoints(false)
    addContent({
      pageName: 'HomeInsurance',
      section: 'Section_4',
      content: {
        list: { value: validateJsonString(JSON.stringify(content)) },
      },
    }).then((result) => {
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleCountryInsuranceInfoSubmit = () => {
    addContent({
      pageName: 'HomeInsurance',
      section: 'Section_3',
      content: section3,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleCoverageSubmit = (content) => {
    setShowCoverageListModal(false)
    addContent({
      pageName: 'HomeInsurance',
      section: 'Section_2',
      content: {
        list: { value: validateJsonString(JSON.stringify(content)) },
      },
    }).then((result) => {
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }
  const handleDiscountType = (content) => {
    setShowDiscountModal(false)
    addContent({
      pageName: 'HomeInsurance',
      section: 'Section_8',
      content: {
        list: { value: validateJsonString(JSON.stringify(content)) },
      },
    }).then((result) => {
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleRenterInsuranceInfoSubmit = () => {
    addContent({
      pageName: 'HomeInsurance',
      section: 'Section_5',
      content: section5,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }
  const handleHomeInsuranceDiscountSubmit = () => {
    addContent({
      pageName: 'HomeInsurance',
      section: 'Section_6',
      content: section6,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handlePropertySubmit = (content) => {
    addContent({
      pageName: 'HomeInsurance',
      section: 'Section_7',
      content: { list: { value: JSON.stringify(content) } },
    }).then((result) => {
      if (result.data.status) {
        alert('Content Saved')
      }
      setShowPropertyModal(false)
    })
  }

  //   const handlePropertySubmit = () => {
  //     addContent({
  //       pageName: 'HomeInsurance',
  //       section: 'Section_7',
  //       content: { list: { value: JSON.stringify(section7) } },
  //     }).then((result) => {
  //       console.log(result.data)
  //       if (result.data.status) {
  //         alert('Content Saved')
  //       }
  //     })
  //   }

  return (
    <Content headerFooterMenu='mountain-west'>
      <Banner
        backgroundImage='linear-gradient(rgba(47,35,98,0.4), rgba(47,35,98,0.4)),url(./images/country-home-fall.jpg)'
        title='Homeowners Insurance'
        subTitle='Providing peace of mind for the place you call home.'
        bannerData={bannerData}
        setBannerData={setBannerData}
        showEdit={showEdit}
        pageName={'HomeInsurance'}
      />
      <div className='quote2'>
        <div className='flex2'>
          <h3 className='quote__text2'>
            {bannerData && bannerData.tagline
              ? bannerData.tagline.value
              : 'Start a home insurance quote and begin working with your local agent.'}
          </h3>
        </div>
        <div className='header_buttons'>
          {bannerData &&
            bannerData.button1 &&
            bannerData.button1Status.value && (
              <Link to={bannerData.button1Link.value} className='mw_button'>
                {bannerData.button1.value}
              </Link>
            )}
          {bannerData &&
            bannerData.button2 &&
            bannerData.button2Status.value && (
              <Link to={bannerData.button2Link.value} className='mw_button'>
                {bannerData.button2.value}
              </Link>
            )}
        </div>
      </div>
      <div
        className='homeowner_insurance'
        id='home_coverage'
        style={{ width: '100%', position: 'relative' }}>
        <h1 className='phase2_heading'>{section1.title.value}</h1>
        <p className='phase2_subheading'>{section1.description.value}</p>
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '1rem' }}
            id='admin-edit'
            onClick={() => setShowHomeInsuranceInfoModal(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
        <EditHomeInsuranceInfo
          handleYes={handleHomeInsuranceInfoSubmit}
          section1={section1}
          showModal={showHomeInsuranceInfoModal}
          setSection1={setSection1}
          setShowModal={setShowHomeInsuranceInfoModal}
        />
        <EditCountryInsuranceInfo
          handleYes={handleCountryInsuranceInfoSubmit}
          section3={section3}
          showModal={showCountryInsuranceInfoModal}
          setSection3={setSection3}
          setShowModal={setShowCountryInsuranceInfoModal}
        />
        {section2 && section2.length && (
          <div
            className='tabset'
            style={{ marginTop: '2rem', width: '100%', position: 'relative' }}>
            {section2.map((item, index) => (
              <React.Fragment key={`checked${index}`}>
                <input
                  type='radio'
                  name='tabset1'
                  id={`tab${index}`}
                  aria-controls={'tab-fixed-annuities' + index}
                  checked={checked === `checked${index}`}
                  onChange={() => setChecked(`checked${index}`)}
                />
                <label
                  htmlFor={`tab${index}`}
                  style={{
                    height: '4rem',
                    width: '20rem',
                    display: 'inline-flex',
                    lineHeight: '1.5rem',
                    fontSize: '1.5rem',
                    position: 'relative',
                    textAlign: 'center',
                    paddingBottom: '0',
                    paddingTop: '0',
                  }}>
                  <div
                    style={{
                      position: 'absolute',
                      width: '20rem',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      fontSize: '1.4rem',
                    }}>
                    {item.title.value}
                  </div>
                </label>
              </React.Fragment>
            ))}
            <div className='tab-panels'>
              {section2.map((item, index) => (
                <section
                  id={`tab-fixed-annuities${index}`}
                  className='tab-panel'
                  key={`tab-fixed-annuities${index}`}>
                  <p className='phase2_subheading'>{item.description.value}</p>
                  {item.example.value !== '' ? <br /> : null}
                  {item.example.value !== '' ? (
                    <p className='phase2_subheading'>
                      <div className='tab_second-comprehensive'>
                        <p className='phase2_heading1'>Example:</p>
                        <p className='phase2_subheading'>
                          {item.example.value}
                        </p>
                      </div>
                    </p>
                  ) : null}
                </section>
              ))}
            </div>
            <br />
            <br />
          </div>
        )}
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '12rem' }}
            id='admin-edit'
            onClick={() => setShowCoverageListModal(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}

        <EditInsurancePoints
          showModal={showCoverageList}
          setShowModal={setShowCoverageListModal}
          handleYes={handleCoverageSubmit}
          section2={section2}
          setSection2={setSection2}
        />
      </div>

      <div
        className='country_home_insurance'
        id='country_home'
        style={{ width: '100%', position: 'relative' }}>
        <h1 className='phase2_heading'>{section3.title.value}</h1>
        <p className='phase2_subheading'>{section3.description.value}</p>
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '1rem' }}
            id='admin-edit'
            onClick={() => setShowCountryInsuranceInfoModal(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
        {section4 && section4.length && (
          <div
            className='tabset'
            style={{ marginTop: '2rem', width: '100%', position: 'relative' }}>
            {section4.map((item, index) => (
              <React.Fragment key={`checked${index}`}>
                <input
                  type='radio'
                  name='tabset'
                  id={`tab1${index}`}
                  key={`tab1${index}`}
                  aria-controls={'tab1-fixed-annuities' + index}
                  checked={secondChecked === `checked${index}`}
                  onChange={() => setSecondChecked(`checked${index}`)}
                />
                <label
                  htmlFor={`tab1${index}`}
                  key={`label1${index}`}
                  style={{
                    height: '4rem',
                    width: '20rem',
                    display: 'inline-flex',
                    lineHeight: '1.5rem',
                    fontSize: '1.5rem',
                    position: 'relative',
                    textAlign: 'center',
                    paddingBottom: '0',
                    paddingTop: '0',
                  }}>
                  <div
                    style={{
                      position: 'absolute',
                      width: '20rem',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      fontSize: '1.4rem',
                    }}>
                    {item.title.value}
                  </div>
                </label>
              </React.Fragment>
            ))}
            <div className='tab-panels'>
              {section4.map((item, index) => (
                <section
                  id={`tab1-fixed-annuities${index}`}
                  className='tab-panel'
                  key={`tab1-fixed-annuities${index}`}>
                  <p className='phase2_subheading'>{item.description.value}</p>
                  {item.example.value !== '' ? <br /> : null}
                  {item.example.value !== '' ? (
                    <p className='phase2_subheading'>
                      <div className='tab_second-comprehensive'>
                        <p className='phase2_heading1'>Example:</p>
                        <p className='phase2_subheading'>
                          {item.example.value}
                        </p>
                      </div>
                    </p>
                  ) : null}
                </section>
              ))}
            </div>
            <br />
            <br />
          </div>
        )}
        <EditInsurancePoints
          showModal={showInsurancePoints}
          setShowModal={setShowInsurancePoints}
          handleYes={handleInsurancePointsSubmit}
          section2={section4}
          setSection2={setSection4}
        />
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '14rem' }}
            id='admin-edit'
            onClick={() => setShowInsurancePoints(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
      </div>
      <div
        className='homeowner-renter'
        id='renters_insurance'
        style={{ width: '100%', position: 'relative' }}>
        <div>
          <h1 className='phase2_heading'>{section5.title.value}</h1>
          <p className='phase2_subheading'>{section5.description.value}</p>
          {showEdit && (
            <div
              className='mwfbi_banner__edit'
              style={{ top: '1rem' }}
              id='admin-edit'
              onClick={() => setShowReenterInsuranceInfoModal(true)}>
              <svg>
                <use xlinkHref='/sprite.svg#icon-edit' />
              </svg>
            </div>
          )}
          <EditRenterInsuranceInfo
            handleYes={handleRenterInsuranceInfoSubmit}
            section5={section5}
            showModal={showRenterInsurance}
            setSection5={setSection5}
            setShowModal={setShowReenterInsuranceInfoModal}
          />
        </div>
        <div className='homeowner-renter-insurance'>
          <h1 className='phase2_heading'>{section5.subMenuTitle.value}</h1>

          <div className='homeowner_renter_insurance-cover'>
            {section7 &&
              Array.isArray(section7) &&
              splitArray(section7, 5).map((itemArray, arrayIndex) => (
                <div
                  className='homeowner-renter-insurance-list'
                  key={'listItem' + arrayIndex}>
                  {itemArray.map((item, index) => (
                    <h1 className='phase2_subheading' key={'point' + index}>
                      &#10004; {item}
                    </h1>
                  ))}
                </div>
              ))}
          </div>

          <EditInsuranceProperties
            handleYes={handlePropertySubmit}
            section7={section7}
            showModal={showPropertyModel}
            setSection7={setSection7}
            setShowModal={setShowPropertyModal}
          />
          {showEdit && (
            <div
              className='mwfbi_banner__edit'
              style={{ top: '12rem' }}
              id='admin-edit'
              onClick={() => setShowPropertyModal(true)}>
              <svg>
                <use xlinkHref='/sprite.svg#icon-edit' />
              </svg>
            </div>
          )}
        </div>
      </div>
      <div
        className='home-insurance-discounts'
        id='home_discounts'
        style={{ width: '100%', position: 'relative' }}>
        <h1 className='phase2_heading'>{section6.title.value}</h1>
        <p className='phase2_subheading'>{section6.description.value}</p>
        <EditHomeInsuranceDiscount
          handleYes={handleHomeInsuranceDiscountSubmit}
          section6={section6}
          showModal={showHomeInsuranceDiscount}
          setSection6={setSection6}
          setShowModal={setHomeInsuranceDiscountModal}
        />
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '1rem' }}
            id='admin-edit'
            onClick={() => setHomeInsuranceDiscountModal(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '12rem' }}
            id='admin-edit'
            onClick={() => setShowDiscountModal(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
        <EditDiscountType
          handleYes={handleDiscountType}
          showModal={showDiscount}
          setShowModal={setShowDiscountModal}
          discountListArr={discountListArr}
          setDiscountListArr={setDiscountListArr}
        />
        {discountListArr &&
          discountListArr.length &&
          splitArray(discountListArr, 3).map((elements, i) => (
            <div className='homeowner_insurance_discounts-grid'>
              {elements.map((item, subIndex) => (
                <div className='discounts-types' key={item.title}>
                  <img src={item.src} className='discounts_images_nails' />
                  <div className='discount_image_text'>
                    <h1 className='phase2_heading1'>{item.title}</h1>
                    <p className='phase2_subheading'>{item.description}</p>
                  </div>
                  {subIndex < 2 && <div className='line' />}
                </div>
              ))}
            </div>
          ))}
      </div>
    </Content>
  )
}
export default HomeOwnersInsurance
