import React from 'react'; 

const Marker = ({ onClick, text }) => {

    return (
        <div
            alt={text}
            onClick={onClick}
        />
    );
};

export default Marker;
