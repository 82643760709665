import React, { Fragment, useState, useEffect, useCallback } from "react";
// import { useHistory } from 'react-router-dom'
import Modal from "../../../../components/DarkModal/index";
import FormInputComponent from "../../../../components/FormInputComponent/FormInputComponent";
import { useDropzone } from "react-dropzone";
import ReactPaginate from "react-paginate";
import {
  addEmail,
  getEmail,
  deleteEmail
} from "../../../../redux/actions/contentAction";

const itemsPerPage = 10;

const EditEmailAddressModal = ({
  setShowModal,
  showModal,
  page
}) => {

  const [SendThrough, setSendThrough] = useState({ value: "to", error: "" });
  const [Email, setEmail] = useState({ value: "", error: "" });
  const [EmailList, setEmailList] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    getEmails()
  }, []);

  const getEmails = () => {
    getEmail(page)
      .then(result => {
        setEmailList(result.data.result)
      })
      .catch(error => {

      })
  }
  const clear = () => {
    setSendThrough({ ...SendThrough, error: '', value: 'to' })
    setEmail({ ...Email, error: '', value: '' })
  }

  const onAdd = (e) => {
    e.preventDefault();
    if (SendThrough.value === "") {
      setSendThrough({ ...SendThrough, error: 'Please select type!' })
      return
    }
    if (Email.value === "") {
      setEmail({ ...Email, error: 'Please add email' })
      return
    }

    addEmail({ sendThrough: SendThrough.value, pageName: page, email: Email.value })
      .then(result => {
        if (result.data.status) {
          clear()
          getEmails()
          alert("Emails has been saved.")
        }
      })
      .catch(error => { })

  };

  const closeModal = () => {
    setShowModal(false);
  };
  const onDelete = (item) => {
    if (window.confirm("Are You Sure to Delete?")) {
      deleteEmail(item.ID)
        .then(result => {
          if (result.data.status) {
            getEmails()
            alert("Emails has been deleted.")
          }
        })
        .catch(error => { })
    }
  };
  return (
    <Modal show={showModal}>
      <form onSubmit={onAdd}>
        <div className="modal__container-dark">
          <div className="modal_heading__container-dark">
            <h1 className="modal_heading__text-dark">Add Email</h1>
            <button
              className="modal__heading--button-dark"
              onClick={() => closeModal()}
            >
              <svg className="modal_heading__icon-dark">
                <use xlinkHref="/sprite.svg#icon-cross" />
              </svg>
            </button>
          </div>
          <div className="modal__form-dark">
            <div className="modal_inner_content">
              <div className="modal_inner_content--left">
                <div className="inline-view">
                  <label
                    style={{
                      display: 'block',
                      fontSize: "1.6rem",
                      color: "grey",
                      fontWeight: "500",
                    }}
                  >
                    Send Through:
                    <span className='red_star'>&#42;</span>
                  </label>

                  <select
                    id="SendThrough"
                    name="SendThrough"
                    style={{ marginBottom: "1rem" }}
                    className="select__state__fieldtype"
                    value={SendThrough.value}
                    onChange={(e) => {
                      setSendThrough({ ...SendThrough, value: e.target.value })
                    }}
                  >
                    <option value={"to"}>To</option>
                    <option value={"cc"}>CC</option>
                    <option value={"bcc"}>BCC</option>
                  </select>
                </div>
                {SendThrough.error !== '' ? <label style={{ fontSize: '1.2rem', color: '#e02b21', marginTop: '-.5rem' }}>
                  {SendThrough.error}
                </label> : null}

                <div className="inline-form-view">
                  <FormInputComponent
                    heading="Email:"
                    style={{ width: "70%" }}
                    required={true}
                    value={Email.value}
                    onChange={(e) => {
                      setEmail({ value: e.target.value, error: '' });
                    }}
                    errorMessage={Email.error}
                  />
                </div>
              </div>
              <div className="modal_inner_content--right3">
                <div
                  className='modal_dark_buttons'
                  style={{ padding: '0.5rem' }}></div>
                <div style={{ display: 'flex', gap: 12 }}>
                  <button
                    type='button'
                    className='modal__button-reset-dark'
                    onClick={clear}>
                    Reset
                  </button>
                  <button
                    type='button'
                    className='modal__button-dark'
                    onClick={onAdd}>
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div className="modal_inner_content">
              <div className="modal_form_container">
                <table
                  className="modal__form-table"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ background: '#c4c4c4' }}
                >
                  <thead className="modal__form-sticky">
                    <tr className="modal__form-tr">
                      <th style={{ width: '15rem' }}>No.</th>
                      <th style={{ width: '15rem' }}>Type</th>
                      <th style={{ width: '15rem' }}>Email</th>
                      <th style={{ width: '15rem' }}>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {EmailList &&
                      EmailList &&
                      EmailList.map((item, i) => {
                        return (
                          <tr className="modal__form-tr2" key={i}>
                            <td>{i + 1}</td>
                            <td>{item.SendThrough}</td>
                            <td>{item.Email}</td>
                            <td className="modal__form-buttons">
                              <button
                                type="button"
                                className="modal__button-delete"
                                onClick={(e) => onDelete(item)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className='ms-pagination2'>
              <h1 className='paginator_head'>
                {' '}
                Showing {itemOffset + 1} - {itemOffset + currentItems?.length}{' '}
                of {discountListArr.length} results
              </h1>

              <ReactPaginate
                nextClassName={''}
                breakLabel='...'
                nextLabel='>>'
                pageCount={pageCount}
                onPageChange={onPageChange}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                previousLabel='<<'
                // renderOnZeroPageCount={null}
                containerClassName={'paginationBttns'}
                previousLinkClassName={'previousBttn'}
                nextLinkClassName={'nextBttn'}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginationActive'}
              />
            </div> */}
          </div>

          {/* <div className='modal_dark_buttons' style={{ paddingTop: '0' }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={(e) => handleDiscountSubmit()}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal>
  );
};

export default EditEmailAddressModal;
