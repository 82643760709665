import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import { addContent, getPageContent } from '../../../../redux/actions/contentAction'

const EdirFooter = ({ setShowModal, showModal, handleYes, footerData, setSetFooterData }) => {
  const [loading, setLoading] = useState(false)

  const [title, setTitle] = useState({ value: '', error: '' })
  const [subTitle, setSubTitle] = useState({ value: '', error: '' })
  const [link, setLink] = useState({ value: '', error: '' })
  const [currentSublink, setCurrentSublink] = useState(null)
  const [isSubLinkUpdate, setIsSubLinkUpdate] = useState(false)
  const [oldSubLinkData, setOldSubLinkData] = useState(null)
  const [isLinkUpdate, setIsLinkUpdate] = useState(false)
  const [linkOldData, setLinkOldData] = useState(null)

  const [linkList, setLinkList] = useState([
    {
      title: 'Tools',
      subLinks: [
        {
          title: 'pay bill',
          url: 'https://ipn.paymentus.com/rotp/mwfb',
        },
        {
          title: 'start a quote',
          url: 'http://localhost:3000/#/360/start-a-quote',
        },
        {
          title: 'find a agent',
          url: 'http://localhost:3000/#/360/find-an-agent',
        },
        {
          title: 'contact us',
          url: 'http://localhost:3000/#/360/contact',
        },
      ],
    },
  ])

  const [name, setName] = useState({ value: '', error: '' })
  const [toolTitle, setToolTitle] = useState({ value: 'Tools', link: '', error: '' });
  const [payBill, setPayBill] = useState({ value: 'Pay Bill', link: 'https://ipn.paymentus.com/rotp/mwfb', error: '' });
  const [startQuote, setstartQuote] = useState({ value: 'Start A Quote', link: '/mwfbi/start-a-quote', error: '' });
  const [findAnAgent, setfindAnAgent] = useState({ value: 'Find an Agent', link: '/mwfbi/find-an-agent', error: '' });
  const [contactUs, setcontactUs] = useState({ value: 'Contact Us', link: '/mwfbi/contact', error: '' });

  const [insuranceTitle, setInsuranceTitle] = useState({ value: 'Insurance Offerings', error: '' })
  const [autoInsurance, setautoInsurance] = useState({ value: 'Auto Insurance', link: '/mwfbi/auto-insurance', error: '' });
  const [homeInsurance, sethomeInsurance] = useState({ value: 'Home Insurance', link: '/mwfbi/home-owners-insurance', error: '' });
  const [farmInsurance, setfarmInsurance] = useState({ value: 'Farm/Ranch Insurance', link: '/mwfbi/farm-&-ranch-insurance', error: '' });
  const [businessInsurance, setbusinessInsurance] = useState({ value: 'Business Insurance', link: '/mwfbi/business-insurance', error: '' });

  const [otherOfferTitle, setOtherOfferTitle] = useState({ value: 'Other Offerings', error: '' })
  const [lifeInsurance, setlifeInsurance] = useState({ value: 'Life Insurance', link: '/mwfbi/life-insurance', error: '' });
  const [annuities, setannuities] = useState({ value: 'Annuities', link: '/mwfbi/annuities', error: '' });
  const [financialServices, setfinancialServices] = useState({ value: 'Financial Services', link: '/mwfbi/other-offerings', error: '' });

  const [resourcesTitle, setResourcesTitle] = useState({ value: 'Resources', error: '' })
  const [learningCenter, setlearningCenter] = useState({ value: 'Learning Center', link: '/mwfbi/about/learning-center', error: '' });
  const [FAQs, setFAQs] = useState({ value: 'FAQs', link: '/mwfbi/faq', error: '' });
  const [Career, setCareer] = useState({ value: 'Career', link: '/mwfbi/about/careers', error: '' });
  const [companyLogin, setcompanyLogin] = useState({ value: 'Company Login', link: 'https://myportal.mwfbi.com/web/public/home', error: '' });

  const [doYouHaveQuestions, setdoYouHaveQuestions] = useState({ value: 'Do you have questions?', link: '', error: '' });
  const [contact, setcontact] = useState({ value: 'Contact us', link: '', error: '' });
  const [phone, setphone] = useState({ value: '1-877-742-9991', link: '', error: '' });

  useEffect(() => {
    if (footerData) {
      setToolTitle(footerData.tools.title)
      setPayBill(footerData.tools.payBill)
      setstartQuote(footerData.tools.startQuote)
      setfindAnAgent(footerData.tools.findAnAgent)
      setcontactUs(footerData.tools.contactUs)

      setInsuranceTitle(footerData.insurance.title)
      setautoInsurance(footerData.insurance.autoInsurance)
      setfarmInsurance(footerData.insurance.farmInsurance)
      setbusinessInsurance(footerData.insurance.businessInsurance)

      setOtherOfferTitle(footerData.other.title)
      setlifeInsurance(footerData.other.lifeInsurance)
      setannuities(footerData.other.annuities)
      setfinancialServices(footerData.other.financialServices)

      setResourcesTitle(footerData.resources.title)
      setlearningCenter(footerData.resources.learningCenter)
      setFAQs(footerData.resources.FAQs)
      setCareer(footerData.resources.Career)
      setcompanyLogin(footerData.resources.companyLogin)

      setdoYouHaveQuestions(footerData.contact.doYouHaveQuestions)
      setcontact(footerData.contact.contact)
      setphone(footerData.contact.phone)
    }
  }, [footerData])
  const onSubmit = () => {
    if (toolTitle.value == '') {
      setToolTitle({ ...toolTitle, error: 'Please enter tool title!' });
      return
    } else setToolTitle({ ...toolTitle, error: '' });

    if (insuranceTitle.value == '') {
      setInsuranceTitle({ ...insuranceTitle, error: 'Please enter insurance title!' });
      return
    } else setInsuranceTitle({ ...insuranceTitle, error: '' });
    if (otherOfferTitle.value == '') {
      setOtherOfferTitle({ ...otherOfferTitle, error: 'Please enter other offering title!' });
      return
    } else setOtherOfferTitle({ ...otherOfferTitle, error: '' });
    if (resourcesTitle.value == '') {
      setResourcesTitle({ ...resourcesTitle, error: 'Please enter resource title!' });
      return
    } else setResourcesTitle({ ...resourcesTitle, error: '' });


    const content = {
      tools: {
        title: toolTitle,
        payBill,
        startQuote,
        findAnAgent,
        contactUs
      },
      insurance: {
        title: insuranceTitle,
        autoInsurance,
        homeInsurance,
        farmInsurance,
        businessInsurance
      },
      other: {
        title: otherOfferTitle,
        lifeInsurance,
        annuities,
        financialServices
      },
      resources: {
        title: resourcesTitle,
        learningCenter,
        FAQs,
        Career,
        companyLogin
      },
      contact: {
        doYouHaveQuestions,
        contact,
        phone
      }
    };
    addContent({ pageName: "360_Home", section: "Section_5_Navigation", content: { navList: { value: JSON.stringify(content) } } })
      .then(result => {
        setSetFooterData(content)
        if (result.data.status) {
          alert("Content Saved")
        }
      })
  }

  const resetInputHandler = () => {
    setTitle({ value: '', error: '' })
    setSubTitle({ value: '', error: '' })
    setLink({ value: '', error: '' })
    setIsSubLinkUpdate(false)
    setOldSubLinkData(null)
  }

  const onAddSubLinkHanlder = () => {
    let validated = true
    if (title.value === '' && title.value.trim() === '') {
      setTitle({
        ...title,
        error: 'Please enter title',
      })
      validated = false
    }
    if (subTitle.value === '' && subTitle.value.trim() === '') {
      setSubTitle({
        ...subTitle,
        error: 'Please enter sub title',
      })
      validated = false
    }
    if (link.value == '' && link.value.trim() === '') {
      setLink({
        ...link,
        error: 'Please enter redirection link',
      })
      validated = false
    }

    if (validated) {
      let index = null
      linkList.forEach((item, i) => {
        if (item.title === title.value) {
          index = i
          setCurrentSublink(title.value)
        }
      })

      if (index !== null) {
        let superArray = linkList
        let array = linkList[index].subLinks

        array.push({
          title: subTitle.value,
          url: link.value,
        })

        const newArr = linkList.map((obj) => {
          if (obj.title === title.value) {
            return { ...obj, subLinks: array }
          }
          return obj
        })

        setLinkList(newArr)
        resetInputHandler()
      } else {
        let array = linkList
        setCurrentSublink(title.value)
        array.push({
          title: title.value,
          subLinks: [
            {
              title: subTitle.value,
              url: link.value,
            },
          ],
        })

        setLinkList(array)
      }
    }
  }

  // console.log('linkList', linkList)

  // console.log('currentSublink', currentSublink)

  const onDeleteLinkHandler = (data, link, i) => {
    let index
    linkList.forEach((item, i) => {
      console.log('item', item, data)
      if (item.title === data.title) {
        index = i
      }
    })

    console.log('idenx', index)

    if (index !== null) {
      let array = linkList[index].subLinks

      let newArray = array.filter((item) => item.title !== link.title)

      const newList = linkList.map((obj) => {
        if (obj.title === data.title) {
          return { ...obj, subLinks: newArray }
        }
        return obj
      })

      setLinkList(newList)
    }
  }

  const editLinkHandler = (data, link, i) => {
    setTitle({ value: data.title, error: '' })
    setSubTitle({ value: link.title, error: '' })
    setLink({ value: link.url, error: '' })
    setIsSubLinkUpdate(true)
    setOldSubLinkData(i)
    setIsLinkUpdate(false)
  }

  const onUpdateSubLinkHandler = () => {
    let validated = true
    if (title.value === '' && title.value.trim() === '') {
      setTitle({
        ...title,
        error: 'Please enter title',
      })
      validated = false
    }
    if (subTitle.value === '' && subTitle.value.trim() === '') {
      setSubTitle({
        ...subTitle,
        error: 'Please enter sub title',
      })
      validated = false
    }
    if (link.value == '' && link.value.trim() === '') {
      setSubTitle({
        ...link,
        error: 'Please enter redirection link',
      })
      validated = false
    }

    if (validated) {
      let index = null
      linkList.forEach((item, i) => {
        if (item.title === title.value) {
          index = i
          setCurrentSublink(title.value)
        }
      })

      if (index !== null) {
        let array = linkList[index].subLinks

        console.log('Array', array)

        const newSubLinksArray = array.map((obj, i) => {
          if (i === oldSubLinkData) {
            return {
              title: subTitle.value,
              url: link.value,
            }
          }
          return obj
        })

        const newArr = linkList.map((obj) => {
          if (obj.title === title.value) {
            return { ...obj, subLinks: newSubLinksArray }
          }
          return obj
        })

        setLinkList(newArr)
        setSubTitle({ value: '', error: '' })
        setLink({ value: '', error: '' })
        setIsSubLinkUpdate(false)
        setOldSubLinkData(null)
        setIsLinkUpdate(true)
        // resetInputHandler()
      }
    }
  }

  const editListHandler = (item) => {
    setCurrentSublink(item.title)
    setLinkOldData(item)
    setTitle({ value: item.title, error: '' })
    setIsLinkUpdate(true)
  }

  const updateLinkHandler = () => {
    let index = null
    linkList.forEach((item, i) => {
      if (item.title === linkOldData.title) {
        index = i
      }
    })

    if (index !== null) {
      const newArr = linkList.map((obj) => {
        if (obj.title === linkOldData.title) {
          return { ...obj, title: title.value }
        }
        return obj
      })

      setLinkList(newArr)
      setIsLinkUpdate(false)
      setLinkOldData(null)
      setCurrentSublink(null)
      resetInputHandler()
    }
  }

  const deleteListHandler = (data) => {
    let index = null
    linkList.forEach((item, i) => {
      if (item.title === data.title) {
        index = i
      }
    })

    if (index !== null) {
      const newArr = linkList.filter((obj) => {
        return obj.title !== data.title
      })

      setLinkList(newArr)
    }
  }

  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Footer navigation</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>

          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--equal' style={{ flex: 1 }}>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Tools Title:'
                    style={{ width: '50%' }}
                    required={true}
                    value={toolTitle.value}
                    onChange={(e) => {
                      setToolTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={toolTitle.error}
                  />
                </div>
                <table className='modal__form-table'>
                  <thead>
                    <tr className='modal__form-tr'>
                      <th>Label</th>
                      <th>Link</th>
                      {/* <th className='modal__form_link'>Sub Titles</th>
                      <th style={{ textAlign: 'right', paddingRight: '5rem' }}>
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='modal__form-tr2' >
                      <td>
                        <input
                          className='table_input'
                          value={payBill.value}
                          onChange={(e) => {
                            setPayBill({ ...payBill, value: e.target.value })
                          }}
                        />
                      </td>
                      <td> <input
                        className='table_input'
                        value={payBill.link}
                        onChange={(e) => {
                          setPayBill({ ...payBill, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={startQuote.value}
                        onChange={(e) => {
                          setstartQuote({ ...startQuote, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={startQuote.link}
                        onChange={(e) => {
                          setstartQuote({ ...startQuote, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={findAnAgent.value}
                        onChange={(e) => {
                          setfindAnAgent({ ...findAnAgent, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={findAnAgent.link}
                        onChange={(e) => {
                          setfindAnAgent({ ...findAnAgent, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={contactUs.value}
                        onChange={(e) => {
                          setcontactUs({ ...contactUs, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={contactUs.link}
                        onChange={(e) => {
                          setcontactUs({ ...contactUs, link: e.target.value })
                        }}
                      /></td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div className='modal_inner_content--equal' style={{ flex: 1 }}>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Insurance Offerings Title:'
                    style={{ width: '50%' }}
                    required={true}
                    value={insuranceTitle.value}
                    onChange={(e) => {
                      setInsuranceTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={insuranceTitle.error}
                  />
                </div>
                <table className='modal__form-table'>
                  <thead>
                    <tr className='modal__form-tr'>
                      <th>Label</th>
                      <th>Link</th>
                      {/* <th className='modal__form_link'>Sub Titles</th>
                      <th style={{ textAlign: 'right', paddingRight: '5rem' }}>
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={autoInsurance.value}
                        onChange={(e) => {
                          setautoInsurance({ ...autoInsurance, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={autoInsurance.link}
                        onChange={(e) => {
                          setautoInsurance({ ...autoInsurance, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={homeInsurance.value}
                        onChange={(e) => {
                          sethomeInsurance({ ...homeInsurance, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={homeInsurance.link}
                        onChange={(e) => {
                          sethomeInsurance({ ...homeInsurance, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={farmInsurance.value}
                        onChange={(e) => {
                          setfarmInsurance({ ...farmInsurance, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={farmInsurance.link}
                        onChange={(e) => {
                          setfarmInsurance({ ...farmInsurance, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={businessInsurance.value}
                        onChange={(e) => {
                          setbusinessInsurance({ ...businessInsurance, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={businessInsurance.link}
                        onChange={(e) => {
                          setbusinessInsurance({ ...businessInsurance, link: e.target.value })
                        }}
                      /></td>
                    </tr>

                  </tbody>
                </table>
              </div>

            </div>
            <br />
            <div className='modal_inner_content'>
              <div className='modal_inner_content--equal' style={{ flex: 1 }}>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Other Offerings Title:'
                    style={{ width: '50%' }}
                    required={true}
                    value={otherOfferTitle.value}
                    onChange={(e) => {
                      setOtherOfferTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={otherOfferTitle.error}
                  />
                </div>
                <table className='modal__form-table'>
                  <thead>
                    <tr className='modal__form-tr'>
                      <th>Label</th>
                      <th>Link</th>
                      {/* <th className='modal__form_link'>Sub Titles</th>
                      <th style={{ textAlign: 'right', paddingRight: '5rem' }}>
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>

                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={lifeInsurance.value}
                        onChange={(e) => {
                          setlifeInsurance({ ...lifeInsurance, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={lifeInsurance.link}
                        onChange={(e) => {
                          setlifeInsurance({ ...lifeInsurance, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={annuities.value}
                        onChange={(e) => {
                          setannuities({ ...annuities, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={annuities.link}
                        onChange={(e) => {
                          setannuities({ ...annuities, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={financialServices.value}
                        onChange={(e) => {
                          setfinancialServices({ ...financialServices, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={financialServices.link}
                        onChange={(e) => {
                          setfinancialServices({ ...financialServices, link: e.target.value })
                        }}
                      /></td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div className='modal_inner_content--equal' style={{ flex: 1 }}>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Resources Title:'
                    style={{ width: '50%' }}
                    required={true}
                    value={resourcesTitle.value}
                    onChange={(e) => {
                      setResourcesTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={resourcesTitle.error}
                  />
                </div>
                <table className='modal__form-table'>
                  <thead>
                    <tr className='modal__form-tr'>
                      <th>Label</th>
                      <th>Link</th>
                      {/* <th className='modal__form_link'>Sub Titles</th>
                      <th style={{ textAlign: 'right', paddingRight: '5rem' }}>
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={learningCenter.value}
                        onChange={(e) => {
                          setlearningCenter({ ...learningCenter, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={learningCenter.link}
                        onChange={(e) => {
                          setlearningCenter({ ...learningCenter, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={FAQs.value}
                        onChange={(e) => {
                          setFAQs({ ...FAQs, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={FAQs.link}
                        onChange={(e) => {
                          setFAQs({ ...FAQs, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    {/* <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={Career.value}
                        onChange={(e) => {
                          setCareer({ ...Career, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={Career.link}
                        onChange={(e) => {
                          setCareer({ ...Career, link: e.target.value })
                        }}
                      /></td>
                    </tr> */}
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={companyLogin.value}
                        onChange={(e) => {
                          setcompanyLogin({ ...companyLogin, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={companyLogin.link}
                        onChange={(e) => {
                          setcompanyLogin({ ...companyLogin, link: e.target.value })
                        }}
                      /></td>
                    </tr>

                  </tbody>
                </table>
              </div>

            </div>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--equal' style={{ flex: 1 }}>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Contact:'
                    style={{ width: '50%' }}
                    disabled={true}
                    value={"Contact"}

                  />
                </div>
                <table className='modal__form-table'>
                  <thead>
                    <tr className='modal__form-tr'>
                      <th>Label</th>
                      <th>Link</th>
                      {/* <th className='modal__form_link'>Sub Titles</th>
                      <th style={{ textAlign: 'right', paddingRight: '5rem' }}>
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>

                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={doYouHaveQuestions.value}
                        onChange={(e) => {
                          setdoYouHaveQuestions({ ...doYouHaveQuestions, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={doYouHaveQuestions.link}
                        onChange={(e) => {
                          setdoYouHaveQuestions({ ...doYouHaveQuestions, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={contact.value}
                        onChange={(e) => {
                          setcontact({ ...contact, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={contact.link}
                        onChange={(e) => {
                          setcontact({ ...contact, link: e.target.value })
                        }}
                      /></td>
                    </tr>
                    <tr className='modal__form-tr2' >
                      <td> <input
                        className='table_input'
                        value={phone.value}
                        onChange={(e) => {
                          setphone({ ...phone, value: e.target.value })
                        }}
                      /></td>
                      <td> <input
                        className='table_input'
                        value={phone.link}
                        onChange={(e) => {
                          setphone({ ...phone, link: e.target.value })
                        }}
                      /></td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div className='modal_inner_content--equal' style={{ flex: 1 }}>

              </div>

            </div>

          </div>

          <div className='modal_dark_buttons'>
            <button
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={onSubmit} type='button'>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EdirFooter
