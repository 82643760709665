import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Content from '../../../components/Content/Content'
import ReactPaginate from 'react-paginate'
import { components } from 'react-select'
import './FindAnAgent.css'
import Banner from '../../../components/Banner/Banner'
import MarkerClustererClass from '@google/markerclusterer'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  geocodeByAddress,
} from 'react-google-places-autocomplete'
import GoogleMap from '../../../components/GoogleMap/GoogleMap'
import Marker from '../../../components/GoogleMap/Marker'
import EditSearchModal from './edit/EditSearch'
import EditFindAgentModal from './edit/EditFindAgent'
import EditInfo from './edit/EditInfo'
import { addContent, getPageContent, getPageContentItemList, imageUpload } from '../../../redux/actions/contentAction'
import { useDispatch, useSelector } from 'react-redux'
import { getAgentList } from '../../../redux/actions/agentListActions'
import { ADMIN_USERS } from '../../../redux/actions/ip'
const google = window.google

const FindAnAgent = () => {

  const dispatch = useDispatch();
  const azureLogin = useSelector(state => state.azureLogin)
  const [showEdit, setShowEdit] = useState(false)


  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const [searchValue, setSearchValue] = useState('')
  const [searchLocation, setSearchLocation] = useState('')
  const [addressObj, setAddressObj] = useState('')
  const [searchListArr, setSearchListArr] = useState([])

  const [tempArray, setTempArray] = useState([])
  const [mapRef, setMapRef] = useState(null)
  const [mapsRef, setMapsRef] = useState(null)
  const [itemOffset, setItemOffset] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 46.43734,
      lng: -109.85292,
    },
    zoom: 6,
  })
  const itemsPerPage = 10
  const { agentList } = useSelector(state => state.agentList)
  const [showEditSearchModal, setShowEditSearchModal] = useState(false)
  const [showFindAgentModal, setShowFindAgentModal] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [section1, setSection1] = useState({
    title: { value: "Find An Agent", action: "", type: "" },
    image: { value: "/images/header-img.jpg", action: "", type: "" },
    subTitle: { value: "Learn more about your insurance needs by working with a local agent.", action: "", type: "" },
    tagLine: { value: "We take pride in our client relationships.", action: "", type: "" },
    button1: { value: "Start A Quote", action: "mwfbi/start-a-quote", type: "" },
  })
  const [section2, setSection2] = useState({
    title: { value: "AGENT SEARCH FORM", action: "", type: "" },
    edit1: { value: "Agent Search", action: "", type: "" },
    edit2: { value: "Enter a location", action: "", type: "" }
  })
  const [section3, setSection3] = useState({
    agentList: [

    ]
  })
  const [section4, setSection4] = useState({
    title: { value: "Meeting One-On-One", action: "", type: "" },
    image: { value: '/images/find-an-agent-shaking.jpg', action: "", type: "" },
    subTitle: { value: "Working with you local agent.", action: "", type: "" },
    tagLine: { value: "Whether you are a new home owner, or starting a business - you can rely on our experience to help you cover what matters most 360 Insurance is a multi-line insurance company, serving the needs of individuals, families, and businesses throughout Wyoming and Montana for over 70 years, Our mission is to provide financial peace of mind of our customers with competitive, quality products and services.", action: "", type: "" },
  })

  const [bannerData, setBannerData] = useState({
    title: { value: 'Find An Agent', error: '' },
    subTitle: { value: 'Learn more about your insurance needs by working with a local agent.', error: '' },
    tagline: { value: 'WE TAKE PRIDE IN OUR CLIENT RELATIONSHIPS', error: '' },
    button1: { value: 'START A QUOTE', error: '' },
    button1Link: { value: '/mwfbi/start-a-quote', error: '' },
    button1Status: { value: true, error: '' },
    button2: { value: 'FIND AN AGENT', error: '' },
    button2Link: { value: '/mwfbi/find-an-agent', error: '' },
    button2Status: { value: false, error: '' },
    imageUrl: { value: '/images/find-an-agent.jpg', error: '' },
  })
  useEffect(() => {
    const func = async () => {
      const geocodeObj =
        searchLocation &&
        searchLocation.value &&
        (await geocodeByPlaceId(searchLocation.value.place_id))
      const addressObject =
        geocodeObj && getAddressObject(geocodeObj[0].address_components)
      let geocodeByAddressObj =
        addressObject &&
        (addressObject.city || addressObject.state) &&
        (await geocodeByAddress(addressObject.city + ',' + addressObject.state))

      if (
        geocodeByAddressObj &&
        geocodeByAddressObj.length > 0 &&
        geocodeByAddressObj[0]
      ) {
        if (geocodeByAddressObj[0].geometry.viewport) {
          mapRef.fitBounds(geocodeByAddressObj[0].geometry.viewport)
        } else {
          let location = geocodeByAddressObj[0].geometry.location
          mapRef.setCenter(location)
          mapRef.setZoom(17)
        }
      }
      setAddressObj(addressObject)
      onSearchLocationHandler(addressObject)
    }
    func()
  }, [searchLocation])

  useEffect(() => {
    if (window.google.maps) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {}
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        setSearchLocation({ label: place.formatted_address, value: place });
        if (!place) {
          mapRef.setCenter({
            lat: 46.43734,
            lng: -109.85292
          });
          mapRef.setZoom(6);
        }
      });
    }
  }, []);

  const getAddressObject = (address_components) => {
    const ShouldBeComponent = {
      street_number: ['street_number'],
      postal_code: ['postal_code'],
      street: ['street_address', 'route'],
      province: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      city: [
        'locality',
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
      ],
      country: ['country'],
    }

    let address = {
      street_number: '',
      postal_code: '',
      street: '',
      province: '',
      city: '',
      country: '',
    }

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === 'country') {
            address[shouldBe] = component.short_name
          } else {
            address[shouldBe] = component.long_name
          }
        }
      }
    })

    // Fix the shape to match our schema
    address.address = address.street_number + ' ' + address.street
    delete address.street_number
    delete address.street
    if (address.country === 'US') {
      address.state = address.province
      delete address.province
    }
    return address
  }

  useEffect(() => {
    // Fetch items from another resources.
    if (searchValue.trim().length > 0) {
      const endOffset = itemOffset + itemsPerPage
      let values = searchListArr.filter((obj) =>
        obj.title.toLowerCase().includes(searchValue.toLowerCase())
      )
      setTempArray(
        values
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .slice(itemOffset, endOffset)
      )
      setPageCount(Math.ceil(values.length / itemsPerPage))
    } else if (
      searchLocation &&
      searchLocation.city &&
      searchLocation.city.length > 0
    ) {
      const endOffset = itemOffset + itemsPerPage
      let values = searchListArr.filter((obj) =>
        obj.address.toLowerCase().includes(searchLocation.city.toLowerCase())
      )
      setTempArray(
        values
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .slice(itemOffset, endOffset)
      )
      setPageCount(Math.ceil(values.length / itemsPerPage))
    } else {
      const endOffset = itemOffset + itemsPerPage
      setTempArray(
        searchListArr
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .slice(itemOffset, endOffset)
      )
      setPageCount(Math.ceil(searchListArr.length / itemsPerPage))
    }
  }, [itemOffset, itemsPerPage, searchLocation, searchLocation, searchListArr])

  useEffect(() => {
    getPageContent({ pageName: "FindAnAgent" })
      .then(res => {
        if (res.data.status) {
          /* console.log("anil",res.data) */
          if (res.data.result.Section_3)
            setSection3({ agentList: JSON.parse(res.data.result.Section_3.agentList.value) })
          if (res.data.result.Section_2)
            setSection2(res.data.result.Section_2)
          if (res.data.result.Section_4)
            setSection4(res.data.result.Section_4)

          if (res.data.result.TopBanner) setBannerData({
            ...res.data.result.TopBanner,
            button1Status: { value: res.data.result.TopBanner.button1Status.value === 'true' },
            button2Status: { value: res.data.result.TopBanner.button2Status.value === 'true' }
          })
          // if (res.data.result.Section_2) {
          //   let s2 = res.data.result.Section_2
          //   s2.productList = JSON.parse(s2.productList.value)
          //   setSection2(s2)
          // }
          // if ((res.data.result.Section_3))
          //   setSection3(res.data.result.Section_3)
          // if (res.data.result.Section_4)
          //   setSection4(res.data.result.Section_4)
        }
      })
  }, [])
  useEffect(() => {

    // getPageContentItemList({ key: "Agent" }).then(res => {
    //   if (res.data.status) {
    //     setSearchListArr(res.data.result)
    //   }
    // })
    if (agentList && agentList.status && agentList.result) {
      setSearchListArr(agentList.result.sort((a, b) => a.title > b.title ? 1 : -1))
    }
  }, [agentList])
  useEffect(() => {
    dispatch(getAgentList())
  }, [showFindAgentModal])

  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true)
      } else setShowEdit(false)
    } else setShowEdit(false)
  }, [azureLogin])

  const onSearchHandler = (e) => {
    setSearchValue(e)
    setItemOffset(0)
    setPageCount(0)
    if (e.trim().length > 0) {
      let values = searchListArr.filter((obj) =>
        obj.title.toLowerCase().includes(e.toLowerCase())
      )
      const endOffset = itemOffset + itemsPerPage
      setTempArray(
        values
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .slice(itemOffset, endOffset)
      )
      setPageCount(Math.ceil(values.length / itemsPerPage))
    } else {
      const endOffset = itemOffset + itemsPerPage
      setTempArray(
        searchListArr
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .slice(itemOffset, endOffset)
      )
      setPageCount(Math.ceil(searchListArr.length / itemsPerPage))
    }
  }

  const onSearchLocationHandler = async (e) => {
    setSearchLocation(e)
    setItemOffset(0)
    setPageCount(0)
    if (e && e.city && e.city.trim().length > 0) {
      let values = searchListArr.filter((obj) =>
        obj.address.toLowerCase().includes(e.city.toLowerCase())
      )
      const endOffset = itemOffset + itemsPerPage
      setTempArray(
        values
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .slice(itemOffset, endOffset)
      )
      setPageCount(Math.ceil(values.length / itemsPerPage))
    } else {
      const endOffset = itemOffset + itemsPerPage
      setTempArray(
        searchListArr
          .sort((a, b) => (a.title > b.title ? 1 : -1))
          .slice(itemOffset, endOffset)
      )
      setPageCount(Math.ceil(searchListArr.length / itemsPerPage))
    }
  }

  const onPageChange = (event) => {
    if (searchValue.trim().length > 0) {
      let values = searchListArr.filter((obj) =>
        obj.title.toLowerCase().includes(searchValue.toLowerCase())
      )
      const newOffset = (event.selected * itemsPerPage) % values.length
      setItemOffset(newOffset)
    } else if (searchLocation && searchLocation.city.trim().length > 0) {
      let values = searchListArr.filter((obj) =>
        obj.address.toLowerCase().includes(searchLocation.city.toLowerCase())
      )
      const newOffset = (event.selected * itemsPerPage) % values.length
      setItemOffset(newOffset)
    } else {
      const newOffset = (event.selected * itemsPerPage) % searchListArr.length
      setItemOffset(newOffset)
    }
  }

  const setGoogleMapRef = (map, maps) => {
    const markers = []
    const infowindows = []
    setMapRef(map)
    setMapsRef(maps)

    let retunArr =
      searchListArr &&
      searchListArr.filter(item => (item.lat && item.lng)).forEach((location) => {
        //async
        // let LatLong = await getLatLng(location.address);
        // console.log(LatLong, 'LatLong');
        markers.push(
          new maps.Marker({
            animation: maps.Animation.DROP,
            position: {
              lat: parseFloat(location.lat),
              lng: parseFloat(location.lng),
            },
            map,
          })
        )

        infowindows.push(
          new maps.InfoWindow({
            content: getInfoWindowString(location),
          })
        )

        let marker = new maps.Marker({
          animation: maps.Animation.DROP,
          position: {
            lat: parseFloat(location.lat),
            lng: parseFloat(location.lng),
          },
        })
        return markers
      })

    let markerCluster = new MarkerClustererClass(map, markers, {
      imagePath:
        'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      // gridSize: 10,
      // minimumClusterSize: 2
    })

    // Get bounds by our places
    const bounds = getMapBounds(map, maps, searchListArr)
    // Fit map to bounds
    map.fitBounds(bounds)
    // Bind the resize listener
    bindResizeListener(map, maps, bounds)

    markers.forEach((marker, i) => {
      marker.addListener('click', () => {
        infowindows[i].open(map, marker)
      })
    })
  }

  const getInfoWindowString = (place) => {
    return `<div>
		<div style="font-size: 1.5rem;">
		<img width="180vh" src=${place.image} alt=${place.title} /> <br /> 
		</div>  
		<div style="display: flex;flex-direction: column;align-items: start;"> 
      	<div style="font-size: 1.5rem;display: flex;gap: 1rem;color: red;font-weight: 600;margin:1rem;"> 
		  <a class="title" href="#/mwfbi/agent-details/${place.id} " style="text-decoration: none;color: red;">
		   ${place.title} 
		   </a> 
		   </div> 
	  	<div  style="font-size: 1.5rem;display: flex;gap: 1rem;">   
	  	<svg style="height:1.8rem;width:1.8rem;"> 
		  <use xlink:href="/sprite.svg#icon-pin"></use></svg> <span style="max-width: 18rem;text-align: left;"> ${place.address} </span></div> 
		<div style="font-size: 1.5rem;display: flex;gap: 1rem;">  
		<svg style="height:1.8rem;width:1.8rem;"><use xlink:href="/sprite.svg#icon-email"></use></svg> <a class="agentemail" href="mailto: ${place.email}"> ${place.email} </a> </div> 
		<div style="font-size: 1.5rem;display: flex;gap: 1rem;">  
		<svg style="height:1.8rem;width:1.8rem;"><use xlink:href="/sprite.svg#icon-phone"></use></svg> <span style="max-width: 18rem;text-align: left;"> ${place.mobile} </span> </div> 
		</div>
    </div > `
  }

  // Return map bounds based on list of places
  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds()
    places.map((place) => {
      bounds.extend(new maps.LatLng(place.lat, place.lng))
    })
    return bounds
  }

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds)
      })
    })
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No location found with name</span>
      </components.NoOptionsMessage>
    )
  }

  const getLatLng = async (address) => {
    const geocoder = new google.maps.Geocoder()
    let response = await geocoder.geocode(
      { address: address },
      function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          return results[0].geometry.location
        } else {
          return false
        }
      }
    )

    return response
  }
  const handleSubmit = () => { }
  const handleSearchSubmit = () => {
    addContent({ pageName: "FindAnAgent", section: "Section_2", content: section2 })
      .then(result => {
        if (result.data.status) {
          alert("Content Saved")
        }
      })
  }
  const handleInfoSubmit = (file) => {

    if (file) {
      imageUpload(file).then(res => {
        if (res.data.status) {
          console.log(res.data);
          let temp = { ...section4 }
          if (!temp.image)
            temp.image = { value: "" }
          temp.image.value = res.data.path;
          setSection4(temp)
          addContent({ pageName: "FindAnAgent", section: "Section_4", content: temp })
            .then(result => {
              console.log(result.data);
              if (result.data.status) {
                alert("Content Saved")
              }
            })
        }
      })
    } else {
      addContent({ pageName: "FindAnAgent", section: "Section_4", content: section4 })
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            alert("Content Saved")
          }
        })
    }
    setShowInfoModal(false)
  }
  useEffect(() => {
    if (window.google.maps) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {}
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        setSearchLocation({ label: place.formatted_address, value: place });
        if (!place) {
          mapRef.setCenter({
            lat: 46.43734,
            lng: -109.85292
          });
          mapRef.setZoom(6);
        }
      });
    };
  }, []);

  return (
    <Content headerFooterMenu='mountain-west'>
      <Banner
        page="FindAnAgent"
        section1={section1}
        backgroundImage='linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/find-an-agent.jpg)'
        title='Find An Agent'
        subTitle='Learn more about your insurance needs by working with a local agent.'
        bannerData={bannerData}
        showEdit={showEdit}
        setBannerData={setBannerData}
        pageName={'FindAnAgent'}
      />

      <div className='quote'>
        <div className='flex'>
          <img
            src='/images/sponsers/sponsers.png'
            alt='Hand Shake'
            className='quote__image'
          />
          <h3 className='quote__text'>
            {bannerData && bannerData.tagline ? bannerData.tagline.value : "We take pride in our client relationships"}
          </h3>
        </div>
        {bannerData && bannerData.button1 && bannerData.button1Status.value && <Link to={bannerData.button1Link.value} className='mw_button'>
          {bannerData.button1.value}
        </Link>}
        {bannerData && bannerData.button2 && bannerData.button2Status.value && <Link to={bannerData.button2Link.value} className='mw_button'>
          {bannerData.button2.value}
        </Link>}
      </div>

      <div className='findanagent-search-main-section'>
        <label>{section2.title.value}</label>
        <div className='findanagent-search-main-section-input'>
          <div className='input_container'>
            <svg className='left_image'>
              <use xlinkHref='/sprite.svg#icon-user' />
            </svg>
            <input
              type='text'
              id='input'
              placeholder={section2.edit1.value}
              value={searchValue}
              onChange={(e) => onSearchHandler(e.target.value)}
            />
          </div>
          <div
            className='input_container'
            style={{ display: 'flex', flexDirection: 'column' }}>
            <svg className='left_image'>
              <use xlinkHref='/sprite.svg#icon-pin' />
            </svg>
            {/* <div>
              <GooglePlacesAutocomplete
                GooglePlacesDetailsQuery={{ fields: 'geometry' }}
                fetchDetails={true} // you need this to fetch the details object onPress
                minLength={4}
                autoFocus={true}
                apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                selectProps={{
                  components: { NoOptionsMessage },
                  placeholder: section2.edit2.value,
                  isClearable: true,
                  value: searchLocation,
                  onChange: (val) => {
                    console.log({ val });
                    setSearchLocation(val)
                    if (!val) {
                      mapRef.setCenter({
                        lat: 46.43734,
                        lng: -109.85292,
                      })
                      mapRef.setZoom(6)
                    }
                  },
                }}
              />
            </div> */}
            <input className="findanagent-search-main-section-input-item" onChange={(e) => {
              if (e.target.value === "") {
                setItemOffset(0);
                setPageCount(0);
                setTempArray(searchListArr);
              }
            }} ref={inputRef} placeholder="ENTER A LOCATION" />
            {/* < svg className="right_image">
							<use xlinkHref="/sprite.svg#icon-settings" />
						</svg> */}
          </div>
        </div>

        {showEdit && <div
          className='mwfbi_banner__edit'
          style={{ top: '1rem' }}
          id='admin-edit'
          onClick={() => setShowEditSearchModal(true)}>
          <svg>
            <use xlinkHref='/sprite.svg#icon-edit' />
          </svg>
        </div>}
      </div>

      <EditSearchModal
        showModal={showEditSearchModal}
        setShowModal={setShowEditSearchModal}
        handleYes={handleSearchSubmit}
        section2={section2}
        setSection2={setSection2}
      />

      <div style={{ width: '100%', position: 'relative' }}>
        <div className='main-section'>
          <div className='main-section-left'>
            <div className='main-section-left-content'>
              {tempArray && tempArray.length > 0 ? (
                tempArray.map((item, i) => (
                  <div className='ms-cart' key={i}>
                    <img src={item.image} alt={item.title} />
                    <div className='ms-content-details'>
                      <Link
                        to={`/360/agent-details/${item.email}`}
                        className='title'
                        style={{ textDecoration: 'none' }}>
                        {item.title}
                      </Link>

                      {item.address && (
                        <label className='subtitle'>
                          <svg className='ms--icon'>
                            <use xlinkHref='/sprite.svg#icon-pin' />
                          </svg>
                          {item.address}
                        </label>
                      )}

                      {item.address2 && <span>{item.address2}</span>}

                      {item.mobile && (
                        <label className='subtitle'>
                          <svg className='ms--icon'>
                            <use xlinkHref='/sprite.svg#icon-phone' />
                          </svg>
                          {item.mobile}
                        </label>
                      )}

                      {item.phone && item.phone}

                      {item.email && (
                        <label className='subtitle'>
                          <svg className='ms--icon'>
                            <use xlinkHref='/sprite.svg#icon-email' />
                          </svg>

                          <a
                            className='agentemail'
                            href={'mailto:' + item.email}>
                            {item.email}
                          </a>
                        </label>
                      )}

                      {item.website2 &&
                        <label className="subtitle">
                          <svg className="ms--icon">
                            <use xlinkHref="/sprite.svg#icon-globe" />
                          </svg>
                          <a href={item.website2} className="agentwebsite">
                            {item.website2}
                          </a>
                        </label>
                      }
                    </div>
                  </div>
                ))
              ) : (
                <h1 className='no_data_message'>No data found</h1>
              )}
            </div>
          </div>
          <div className='ms-pagination1'>
            <h1 className='paginator_head'>
              Showing {itemOffset + 1} - {itemOffset + tempArray.length} of{' '}
              {searchListArr.length} results
            </h1>

            <ReactPaginate
              nextClassName={''}
              breakLabel='...'
              nextLabel='>>'
              pageCount={pageCount}
              onPageChange={onPageChange}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              previousLabel='<<'
              renderOnZeroPageCount={null}
              containerClassName={'paginationBttns'}
              previousLinkClassName={'previousBttn'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
              activeClassName={'paginationActive'}
            />
          </div>
          <div className='main-section-right'>
            <div style={{ height: '60vh', width: '100%' }}>
              {searchListArr.length && <GoogleMap
                defaultProps={defaultProps}
                setGoogleMapRef={setGoogleMapRef}>
                {searchListArr &&
                  searchListArr.map((place) => (
                    <Marker
                      key={place.id}
                      text={place.title}
                      lat={place.lat}
                      lng={place.lng}
                    />
                  ))}
              </GoogleMap>
              }
            </div>
          </div>
        </div>

        {showEdit && <div
          className='mwfbi_banner__edit'
          style={{ top: '1rem' }}
          id='admin-edit'
          onClick={() => setShowFindAgentModal(true)}>
          <svg>
            <use xlinkHref='/sprite.svg#icon-edit' />
          </svg>
        </div>}
      </div>

      <EditFindAgentModal
        showModal={showFindAgentModal}
        setShowModal={setShowFindAgentModal}
        handleYes={handleSubmit}
        section3={section3}
        searchListArr={searchListArr}
        setSearchListArr={setSearchListArr}
        setSection3={setSection3}
      />
      <div className='ms-pagination'>
        <h1 className='paginator_head'>
          Showing {itemOffset + 1} - {itemOffset + tempArray.length} of{' '}
          {searchListArr.length} results
        </h1>

        <ReactPaginate
          nextClassName={''}
          breakLabel='...'
          nextLabel='>>'
          pageCount={pageCount}
          onPageChange={onPageChange}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          previousLabel='<<'
          renderOnZeroPageCount={null}
          containerClassName={'paginationBttns'}
          previousLinkClassName={'previousBttn'}
          nextLinkClassName={'nextBttn'}
          disabledClassName={'paginationDisabled'}
          activeClassName={'paginationActive'}
        />
      </div>

      <EditInfo
        showModal={showInfoModal}
        setShowModal={setShowInfoModal}
        section4={section4}
        setSection4={setSection4}
        handleYes={handleInfoSubmit}
      />

      <div className='fa-bottom-main'>
        <div className='image_bottom'>
          <img
            src={section4.image.value}
            alt='find an agent'
            className='find-an-agent-image'
          />
        </div>
        <div className='bottom_section'>
          <h1 className='heading1_an_agent'>{section4.title.value}</h1>
          <h2 className='subtitle_an_agent'>{section4.subTitle.value}</h2>
          <p className='description'>
            {section4.tagLine.value}
          </p>
        </div>
        {showEdit && <div
          className='mwfbi_banner__edit'
          style={{ top: '1rem' }}
          id='admin-edit'
          onClick={() => setShowInfoModal(true)}>
          <svg>
            <use xlinkHref='/sprite.svg#icon-edit' />
          </svg>
        </div>}
      </div>
    </Content>
  )
}

export default FindAnAgent
