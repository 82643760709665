import React, { Fragment, useState, useEffect, useCallback } from "react";
// import { useHistory } from 'react-router-dom'
import Modal from "../../../../components/DarkModal/index";
import FormInputComponent from "../../../../components/FormInputComponent/FormInputComponent";

let timeout = null;
let selectedPosition = -1;
const EditFieldTypeModal = ({
  setShowModal,
  showModal,
  handleYes,
  editFormData,
  setEditFormData,
  handleYes1,
  onFieldDelete,
  fieldTypeArr,
  handleChangeFieldOrder,
  setFieldTypeArr
}) => {
  const [loading, setLoading] = useState(false);
  const [fieldType, setFieldType] = useState({
    value: "Select Field Type",
    error: "",
  });
  const [fieldInputType, setFieldInputType] = useState({
    value: "Select Input Type",
    error: "",
  });
  const [label, setLabel] = useState({ value: "", error: "" });
  const [fieldValue, setFieldValue] = useState({ value: "", error: "" });
  const [placeholder, setPlaceholder] = useState({ value: "", error: "" });
  const [validationType, setValidationType] = useState({ value: "None", error: "" });
  const [requiredType, setRequiredType] = useState({
    value: "False",
    error: "",
    checked: false
  });
  const [multiValueFields, setMultiValueFields] = useState(false);
  const [multiValue, setMultiValue] = useState(false);

  const [fieldId, setFieldId] = useState('');

  const [editIndex, setEditIndex] = useState(-1);
  const [editData, setEditData] = useState({
    fieldType: { value: "", error: "" },
    fieldInputType: { value: "", error: "" },
    label: { value: "", error: "" },
    fieldValue: { value: "", error: "" },
    placeholder: { value: "", error: "" },
    requiredType: { value: "", error: "" },
    validationType: { value: "", error: "" },
  });

  const handleDiscountSubmit = (e) => {
    e.preventDefault();
    if (fieldType.value === "Select Field Type") {
      setFieldType({ ...fieldType, error: "Please select a field Type!" });
      return
    }
    if (fieldInputType.value === "Select Input Type") {
      setFieldInputType({
        ...fieldInputType,
        error: "Please select a Input Type!",
      });
      return
    }
    if (fieldValue === "") {
      setFieldValue({ ...fieldValue, error: "Please enter the Value" })
    }

    if (multiValue) {
      if (fieldValue.value === "") {
        setFieldValue({ ...fieldValue, error: "Please enter the Value" });
        return;
      }
    }
    clearErrors();
    let content = {
      fieldType: fieldType.value,
      fieldInputType: fieldInputType.value,
      fieldLabel: label.value,
      fieldValue: fieldValue.value,
      placeHolder: placeholder.value,
      requiredStatus: requiredType.value,
      validationType: validationType.value
    };
    if (editIndex > -1) {
      const temp = [...editFormData];
      temp[editIndex] = content;
      /* setCurrentItems(temp) */
      setEditFormData(temp);
      handleYes1(fieldId, content);

      clear();
    } else {
      /* setCurrentItems([...discountListArr, content]) */
      setEditFormData([...editFormData, content]);
      handleYes(content);

      /* console.log("added", editFormData); */
      clear();
    }
  };

  const onEdit = (e, item, index) => {
    e.preventDefault();
    setFieldId(item.ID)
    console.log(fieldId, 'FieldID')
    // setLoading(true);
    setEditData(item);
    setEditIndex(index);
    setFieldType({ ...fieldType, value: item.FieldType });
    setFieldInputType({ ...fieldInputType, value: item.FieldInputType });
    setFieldValue({ ...fieldValue, value: item.FieldValue });
    setLabel({ ...label, value: item.FieldLabel });
    setPlaceholder({ ...placeholder, value: item.PlaceHolder });
    setRequiredType({ ...requiredType, value: item.RequiredStatus ? 'True' : 'False', checked: item.RequiredStatus });
    setValidationType({ ...validationType, value: item.ValidationType });
    if (item.FieldType === 'Multi-Value') {
      setMultiValue(true)
      setMultiValueFields(true)
    }
    else if (item.FieldInputType === 'Select' | 'Radio' | 'Switch') {
      setMultiValue(true)
      setMultiValueFields(true)
    }
    else if (item.FieldInputType === 'Textbox' | 'Textarea') {
      setMultiValue(false)
      setMultiValueFields(false)
    }
    // setUrl({ ...url, value: item.url });
  };
  const clear = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      fieldType: { value: "", error: "" },
      fieldInputType: { value: "", error: "" },
      label: { value: "", error: "" },
      fieldValue: { value: "", error: "" },
      placeholder: { value: "", error: "" },
      requiredType: { value: "", error: "", checked: false },
      validationType: { value: "", error: "" },
    });
    setLabel({ value: "", error: "" });
    setFieldValue({ value: "", error: "" });
    setPlaceholder({ value: "", error: "" });
    setFieldType({ value: "", error: "" });
    setFieldInputType({ value: "", error: "" });
    setLabel({ value: "", error: "" });
    setValidationType({ value: "", error: "" });
    setRequiredType({ value: "", error: "", checked: false });
    setEditIndex(-1);
    // handleDiscountSubmit()
  };
  const closeModal = () => {
    resetAll();
    setShowModal(false);
  };
  const resetAll = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      fieldType: { value: "", error: "" },
      fieldInputType: { value: "", error: "" },
      label: { value: "", error: "" },
      fieldValue: { value: "", error: "" },
      placeholder: { value: "", error: "" },
      requiredType: { value: "", error: "", checked: false },
      validationType: { value: "", error: "" },
    });
    setEditIndex(-1);
    setFieldType({ ...fieldType, value: "", error: "" });
    setFieldInputType({ ...fieldInputType, value: "", error: "" });
    setLabel({ ...label, value: "", error: "" });
    setFieldValue({ ...fieldValue, value: "", error: "" });
    setPlaceholder({ ...placeholder, value: "", error: "" });
    setRequiredType({ ...requiredType, value: "", error: "", checked: false });
    setValidationType({ ...validationType, value: "", error: "" });
  };

  const orderFields = (direction, item, index) => {
    const tempArr = fieldTypeArr.filter((element) => element.ID !== item.ID);
    let position = index;
    if (direction === "Up" && index > 0) {
      position = position - 1;
      clearTimeout(timeout);
    } else if (direction === "Down" && index < fieldTypeArr.length) {
      position = position + 1;
      clearTimeout(timeout);
    } else return;
    tempArr.splice(position, 0, item);
    selectedPosition = position;
    setFieldTypeArr(tempArr);
    timeout = setTimeout(() => {
      handleChangeFieldOrder(item.ID, position);
      selectedPosition = -1;
    }, 2000);
  };
  const clearErrors = () => {
    setEditIndex(-1);
    setFieldType({ ...fieldType, value: "", error: "" });
    setFieldInputType({ ...fieldInputType, value: "", error: "" });
    setLabel({ ...label, value: "", error: "" });
    setFieldValue({ ...fieldValue, value: "", error: "" });
    setPlaceholder({ ...placeholder, value: "", error: "" });
    setRequiredType({ ...requiredType, value: "", error: "", checked: false });
    setValidationType({ ...validationType, value: "", error: "" });
  };

  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className="modal__container-dark">
          <div className="modal_heading__container-dark">
            <h1 className="modal_heading__text-dark">Add Field</h1>
            <button
              className="modal__heading--button-dark"
              onClick={() => closeModal()}
            >
              <svg className="modal_heading__icon-dark">
                <use xlinkHref="/sprite.svg#icon-cross" />
              </svg>
            </button>
          </div>
          <div className="modal__form-dark">
            <div className="modal_inner_content">
              <div className="modal_inner_content--left">
                <div className="inline-view">
                  <label
                    style={{
                      display: "block",
                      fontSize: "1.6rem",
                      color: "grey",
                      fontWeight: "500",
                    }}
                  >
                    Field Type:
                    <span className="red_star">&#42;</span>
                  </label>

                  <select
                    id="fieldType"
                    name="fieldType"
                    style={{ marginBottom: "1rem" }}
                    className="select__state__fieldtype"
                    value={fieldType.value}
                    onChange={(e) => {
                      if (e.target.value === "Multi-Value") {
                        setFieldType({ value: e.target.value, error: "" });
                        setMultiValueFields(true);
                      } else {
                        setMultiValueFields(false);
                        setFieldType({ value: e.target.value, error: "" });
                      }
                    }}
                  >
                    <option value={"Select Field Type"}>
                      Select Field Type
                    </option>
                    <option value={"Single Value"}>Single Value</option>
                    <option value={"Multi-Value"}>Multi-Value</option>
                  </select>
                </div>
                {fieldType.error !== "" ? (
                  <label
                    style={{
                      fontSize: "1.2rem",
                      color: "#e02b21",
                      marginTop: "-.5rem",
                    }}
                  >
                    {fieldType.error}
                  </label>
                ) : null}
                <div className="inline-form-view">
                  <div className="select_field" style={{ width: "100%" }}>
                    <label
                      style={{
                        display: "block",
                        fontSize: "1.6rem",
                        color: "grey",
                        fontWeight: "500",
                      }}
                    >
                      Field Input Type:
                      <span className="red_star">&#42;</span>
                    </label>
                    <select
                      id="fieldInputType"
                      name="fieldInputType"
                      style={{ marginBottom: "1rem" }}
                      className="select__state__fieldtype"
                      required={true}
                      value={fieldInputType.value}
                      onChange={(e) => {
                        setFieldInputType({ value: e.target.value, error: "" });
                      }}
                    >
                      <option value={"Select Input Type"}>
                        Select Input Type
                      </option>
                      {!multiValueFields ? (
                        <option value={"Textbox"}>TextBox</option>
                      ) : null}
                      {!multiValueFields ? (
                        <option value={"TextboxSingle"}>Single Line TextBox</option>
                      ) : null}
                      {!multiValueFields ? (
                        <option value={"Textarea"}>Textarea</option>
                      ) : null}
                      {!multiValueFields ? (
                        <option value={"Date"}>Date</option>
                      ) : null}
                      {!multiValueFields ? (
                        <option value={"Select"}>Select</option>
                      ) : null}
                      {!multiValueFields ? (
                        <option value={"FileUpload"}>File Upload</option>
                      ) : null}
                      {!multiValueFields ? (
                        <option value={"Switch"}>Switch</option>
                      ) : null}
                      {!multiValueFields ? (
                        <option value={"Radio"}>Radio</option>
                      ) : null}

                      {multiValueFields ? (
                        <option value={"Chips"}>Chips</option>
                      ) : null}
                      {multiValueFields ? (
                        <option value={"Checkbox"}>Checkbox</option>
                      ) : null}
                      {multiValueFields ? (
                        <option value={"MultiSelect"}>Multi-Select</option>
                      ) : null}
                    </select>
                  </div>
                </div>
                {fieldInputType.error !== "" ? (
                  <label
                    style={{
                      fontSize: "1.2rem",
                      color: "#e02b21",
                      marginTop: "-.5rem",
                    }}
                  >
                    {fieldInputType.error}
                  </label>
                ) : null}

                {fieldInputType.value === "Textbox" && <div className="inline-form-view">
                  <div className="select_field" style={{ width: "100%" }}>
                    <label
                      style={{
                        display: "block",
                        fontSize: "1.6rem",
                        color: "grey",
                        fontWeight: "500",
                      }}
                    >
                      Select Validation Type:
                    </label>
                    <select
                      id="validationType"
                      name="validationType"
                      style={{ marginBottom: "1rem" }}
                      className="select__state__fieldtype"
                      required={false}
                      value={validationType.value}
                      onChange={(e) => {
                        setValidationType({ value: e.target.value, error: "" });
                      }}
                    >
                      <option value={"None"}>
                        None
                      </option>

                      <option value={"Any Input"}>Any Input</option>

                      <option value={"Only Text"}>Only Text</option>

                      <option value={"Only Number"}>Only Number</option>

                      <option value={"Email"}>Email</option>

                      <option value={"Phone"}>Phone</option>

                      <option value={"Zip"}>Zip</option>

                    </select>
                  </div>
                </div>}
                {fieldInputType.value === "Textbox" && validationType.error !== "" ? (
                  <label
                    style={{
                      fontSize: "1.2rem",
                      color: "#e02b21",
                      marginTop: "-.5rem",
                    }}
                  >
                    {validationType.error}
                  </label>
                ) : null}


                <div className="inline-form-view">
                  <FormInputComponent
                    heading="Field Label:"
                    style={{ width: "70%" }}
                    required={true}
                    value={label.value}
                    onChange={(e) => {
                      setLabel({ value: e.target.value, error: "" });
                    }}
                    errorMessage={label.error}
                  />
                </div>
                {/* {multiValue ? 
                <div className="inline-form-view">
                  <FormInputComponent
                    type={"textarea"}
                    heading="Value:"
                    required={multiValue}
                    style={{ width: "70%" }}
                    value={fieldValue.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setFieldValue({ value: e.target.value, error: "" });
                    }}
                    errorMessage={fieldValue.error}
                  />
                </div> : null } */}

                <div className="inline-form-view">
                  <FormInputComponent
                    type={"textarea"}
                    heading="Value:"
                    required={multiValue}
                    style={{ width: "70%" }}
                    value={fieldValue.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setFieldValue({ value: e.target.value, error: "" });
                    }}
                    errorMessage={fieldValue.error}
                  />
                </div>
                {!multiValueFields ? <div className="inline-form-view">
                  <FormInputComponent
                    heading="Field Placeholder:"
                    required={false}
                    style={{ width: "70%" }}
                    value={placeholder.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setPlaceholder({ value: e.target.value, error: "" });
                    }}
                    errorMessage={placeholder.error}
                  />
                </div> : null}
                <div className="inline-form-view">
                  <div className="select_field" style={{ width: "100%" }}>
                    <label
                      style={{
                        marginTop: ".4rem",
                        display: "block",
                        fontSize: "1.6rem",
                        color: "grey",
                        fontWeight: "500",
                      }}
                    >
                      Required:
                      <span className="red_star">&#42;</span>
                    </label>
                    <Fragment>
                      <input
                        type="checkbox"
                        id={"checkbox"}
                        // name={item.title}
                        style={{ marginRight: "1rem" }}
                        checked={requiredType.checked}
                        onChange={(e) => {
                          if (e.target.checked) setRequiredType({ value: 'True' })
                          else setRequiredType({ value: 'False' })
                        }}
                      />
                      <label
                        className="label_heading_quote"
                        htmlFor="phone"
                        style={{ marginRight: "2rem" }}
                      >
                        Yes
                      </label>
                    </Fragment>

                    {/* <label
                      style={{
                        marginTop: ".4rem",
                        display: "block",
                        fontSize: "1.6rem",
                        color: "grey",
                        fontWeight: "500",
                      }}
                    >
                      Required Type:
                      <span className="red_star">&#42;</span>
                    </label>
                    <select
                      id="requiredType"
                      name="setRequiredType"
                      style={{ marginBottom: "1rem" }}
                      className="select__state__fieldtype"
                      value={requiredType.value}
                      onChange={(e) => {
                        console.log(e.target.value)
                        setRequiredType({value: e.target.value});
                      }}
                    >
                      <option value={"Select Required Type"}>
                        Select Required Type
                      </option>
                      <option value={"False"}>False</option>
                      <option value={"True"}>True</option>
                    </select> */}
                  </div>
                </div>
                {requiredType.error !== "" ? (
                  <label
                    style={{
                      fontSize: "1.2rem",
                      color: "#e02b21",
                      marginTop: "-.5rem",
                    }}
                  >
                    {requiredType.error}
                  </label>
                ) : null}
              </div>
              <div style={{ alignItems: 'end' }} className="modal_inner_content--right3">
                {/* <label>Upload the image</label>
                <div
                  className='upload_border'
                  {...getRootProps()}
                  style={{ marginTop: '-1rem' }}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  {uploadImagePreview == '' ? (
                    <svg className='modal_upload__icon-dark'>
                      <use xlinkHref='/sprite.svg#icon-cloud' />
                    </svg>
                  ) : (
                    <img
                      height={60}
                      width={60}
                      src={uploadImagePreview}
                      style={{ marginTop: '0rem', alignSelf: 'center' }}></img>
                  )}
                </div>
                {noImage ? (
                  <label style={{ fontSize: '1.2rem', color: '#e02b21' }}>
                    Please upload a image*
                  </label>
                ) : (
                  <></>
                )}
                 */}
                <div
                  className="modal_dark_buttons"
                  style={{ padding: "0.5rem" }}
                ></div>
                <div style={{ display: "flex", gap: 12 }}>
                  <button
                    type="button"
                    className="modal__button-reset-dark"
                    onClick={resetAll}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="modal__button-dark"
                    onClick={handleDiscountSubmit}
                  >
                    {loading ? "Saving.." : editIndex > -1 ? "Save" : "Add"}
                  </button>
                </div>
              </div>
            </div>
            <div className="modal_inner_content">
              <div className="modal_form_container">
                <table
                  className="modal__form-table"
                  cellSpacing="0"
                  cellPadding="0"
                  style={{ background: "#c4c4c4" }}
                >
                  <thead className="modal__form-sticky">
                    <tr className="modal__form-tr">
                      <th style={{ width: "15rem" }}>No.</th>
                      <th style={{ width: "15rem" }}>Input type</th>
                      <th style={{ width: "15rem" }}>Label</th>
                      <th style={{ width: "15rem" }}>Required</th>
                      <th style={{ width: "15rem" }}>Order</th>
                      <th style={{ width: "15rem" }}>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {fieldTypeArr &&
                      fieldTypeArr &&
                      fieldTypeArr.map((item, i) => {
                        return (
                          <tr className="modal__form-tr2" key={i}>
                            <td>{i + 1}</td>
                            <td>{item.FieldType}</td>
                            <td>{item.FieldInputType}</td>
                            <td>{item.FieldLabel !== 'undefined' ? item.FieldLabel : '-'}</td>
                            <td>{item.RequiredStatus ? 'Yes' : 'No'}</td>
                            <td style={{ padding: '0.5rem' }}>
                              <button
                                // style={{
                                //   background:
                                //     selectedPosition === i ? "blue" : "black",
                                // }}
                                onClick={() => orderFields("Up", item, i)}
                                style={{ marginRight: "1rem" }}
                              >
                                <svg className="modal_heading__icon-dark">
                                  <use xlinkHref="/sprite.svg#icon-up" />
                                </svg>
                              </button>
                              <button
                                onClick={() => orderFields("Down", item, i)}
                              >
                                <svg className="modal_heading__icon-dark">
                                  <use xlinkHref="/sprite.svg#icon-down" />
                                </svg>
                              </button>
                            </td>
                            <td className="modal__form-buttons">
                              <button
                                type="button"
                                className="modal__button-edit"
                                onClick={(e) => onEdit(e, item, i)}
                              >
                                edit
                              </button>
                              <button
                                type="button"
                                className="modal__button-delete"
                                onClick={(e) => onFieldDelete(e, item, i)}
                              >
                                delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className='ms-pagination2'>
              <h1 className='paginator_head'>
                {' '}
                Showing {itemOffset + 1} - {itemOffset + currentItems?.length}{' '}
                of {discountListArr.length} results
              </h1>

              <ReactPaginate
                nextClassName={''}
                breakLabel='...'
                nextLabel='>>'
                pageCount={pageCount}
                onPageChange={onPageChange}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                previousLabel='<<'
                // renderOnZeroPageCount={null}
                containerClassName={'paginationBttns'}
                previousLinkClassName={'previousBttn'}
                nextLinkClassName={'nextBttn'}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginationActive'}
              />
            </div> */}
          </div>

          {/* <div className='modal_dark_buttons' style={{ paddingTop: '0' }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={(e) => handleDiscountSubmit()}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal>
  );
};

export default EditFieldTypeModal;
