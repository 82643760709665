import React from 'react';
import { Link } from 'react-router-dom';

import './Header.css';
const Header = () => {
	return (
		<header className='header'>
			<img src='/images/logo2.png' alt='Logo' className='logo' />
			<nav className='navigation'>
				<ul className='navigation__links'>
					<li>
						<Link to='/' className='navigation__link'>
							Home
						</Link>
					</li> 
					<li>
						<Link to='/about' className='navigation__link'>
							About
						</Link>
					</li>
					<li>
						<Link to='#!' className='navigation__link'>
							Contact
						</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
};

export default Header;
