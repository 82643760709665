import React, { useState, useEffect } from 'react'
import Banner from '../../../components/Banner/Banner'
import { Link } from 'react-router-dom'

import Content from '../../../components/Content/Content'
import './Annuities.css'
import EditAnnuity from './edit/EditAnnuity'
import EditAnnuityPoints from './edit/EditAnnuityPoints'

import ReactHtmlParser from 'html-react-parser';
import {
  addContent,
  getPageContent,
} from '../../../redux/actions/contentAction'
import { useDispatch, useSelector } from 'react-redux'
import { ADMIN_USERS } from '../../../redux/actions/ip'

const Annuities = () => {
  const [checked, setChecked] = useState('checked0')
  const [showEdit, setShowEdit] = useState(false)
  const [showAnutyModal, setShowAnutyModal] = useState(false)
  const [showAnnuityTypesModal, setShowAnnuityTypesModal] = useState(false)
  const [section1, setSection1] = useState({
    title: { value: 'Annuities', action: '', type: '' },
    description: {
      value: `When it comes to filling the gaps of your retirement income, an annuity can help. Annuities are often just one component of a retirement strategy. Your 360 Insurance agent can be valuable resource when it comes to choosing an annuity as part of your overall strategy.`,
    },
  })
  const [section2, setSection2] = useState([
    {
      title: 'Fixed Annuities',
      description: `Fixed Annuities offer a fixed interest rate ensuring your account will grow at a steady rate. To ensure you have the flexibility you need, we offer multiple options for the length of the contract.`,
      url: 'http://www.greenfieldslife.com/annuities',
    },
    {
      title: 'Indexed Annuities',
      description: `Indexed annuities are often considered the ‘best of both worlds’ because they allow you to take advantage of potential gains in the market while protecting you from loss with a guaranteed1 minimum interest rate.
      </br></br>
	  1 The guarantees expressed on this Web page are based on the claims-paying ability of Farm Bureau Life Insurance Company.`,
      url: 'http://www.greenfieldslife.com/annuities',
    },
  ])
  const [bannerData, setBannerData] = useState({
    title: { value: 'Annuities', error: '' },
    subTitle: {
      value: 'Providing peace of mind so you can retire confidently.',
      error: '',
    },
    tagline: {
      value:
        'Start your retirement solution planning with your local agent.',
      error: '',
    },
    button1: { value: 'START A QUOTE', error: '' },
    button1Link: { value: '/360/start-a-quote', error: '' },
    button1Status: { value: true, error: '' },
    button2: { value: 'FIND AN AGENT', error: '' },
    button2Link: { value: '/360/find-an-agent', error: '' },
    button2Status: { value: true, error: '' },
    imageUrl: { value: '/images/older-couple-beach-sunset.jpg', error: '' },
  })
  const azureLogin = useSelector((state) => state.azureLogin)

  const handleAnnuitySubmit = () => {
    addContent({
      pageName: 'Annuities',
      section: 'Section_1',
      content: section1,
    }).then((result) => {
      console.log(result.data)
      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  const handleAnnutiesDetailsSubmit = (content) => {
    addContent({
      pageName: 'Annuities',
      section: 'Section_2',
      content: { detailsList: { value: JSON.stringify(content), error: '' } },
    }).then((result) => {
      console.log(result.data)

      if (result.data.status) {
        alert('Content Saved')
      }
    })
  }

  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true)
      } else setShowEdit(false)
    } else setShowEdit(false)
  }, [azureLogin])

  useEffect(() => {
    getPageContent({ pageName: 'Annuities' }).then((res) => {
      // debugger
      if (res.data.status) {

        if (res.data.result.Section_1) setSection1(res.data.result.Section_1)
        if (res.data.result.Section_2)
          setSection2(JSON.parse(res.data.result.Section_2.detailsList.value))

        if (res.data.result.TopBanner)
          setBannerData({
            ...res.data.result.TopBanner,
            button1Status: {
              value: res.data.result.TopBanner.button1Status.value === 'true',
            },
            button2Status: {
              value: res.data.result.TopBanner.button2Status.value === 'true',
            },
          })
      }
    })
  }, [])

  return (
    <Content headerFooterMenu='mountain-west'>
      <Banner
        backgroundImage='linear-gradient(rgba(47,35,98,0.4), rgba(47,35,98,0.4)),url(/images/older-couple-beach-sunset.jpg)'
        title='Annuities'
        subTitle='Providing peace of mind so you can retire confidently.'
        bannerData={bannerData}
        setBannerData={setBannerData}
        showEdit={showEdit}
        pageName={'Annuities'}
      />
      <div className='quote2'>
        <div className='flex2'>
          <h3 className='quote__text2'>
            {bannerData && bannerData.tagline
              ? bannerData.tagline.value
              : 'Start a business insurance quote and begin Working with your local agent.'}
          </h3>
        </div>
        <div className='header_buttons'>
          {bannerData && bannerData.button1 && bannerData.button1Status.value && (
            <Link to={bannerData.button1Link.value} className='mw_button'>
              {bannerData.button1.value}
            </Link>
          )}
          {bannerData && bannerData.button2 && bannerData.button2Status.value && (
            <Link to={bannerData.button2Link.value} className='mw_button'>
              {bannerData.button2.value}
            </Link>
          )}
        </div>
      </div>
      {/* <div className='annuities' id='annuities'>
        <h1 className='phase2_heading'>Annuities</h1>
        <p className='phase2_subheading'>
          When it comes to filling the gaps of your retirement income, an
          annuity can help. Annuities are often just one component of a
          retirement strategy. Your 360 Insurance agent can be valuable resource
          when it comes to choosing an annuity as part of your overall strategy.
        </p>
      </div> */}
      <div
        className='annuities'
        id='annuities'
        style={{ width: '100%', position: 'relative' }}>
        <h1 className='phase2_heading'>{section1.title.value}</h1>
        <p className='phase2_subheading'>{section1.description.value}</p>
        {showEdit && (
          <div
            className='mwfbi_banner__edit'
            style={{ top: '1rem' }}
            id='admin-edit'
            onClick={() => setShowAnutyModal(true)}>
            <svg>
              <use xlinkHref='/sprite.svg#icon-edit' />
            </svg>
          </div>
        )}
      </div>
      <EditAnnuity
        showModal={showAnutyModal}
        setShowModal={setShowAnutyModal}
        handleYes={handleAnnuitySubmit}
        section1={section1}
        setSection1={setSection1}
      />

      {/* <div className='annuities_tabset tabset'>
        <input
          type='radio'
          name='tabset'
          id='tab1'
          aria-controls='tab-fixed-annuities'
          checked={checked === 'checked1'}
          onChange={() => setChecked('checked1')}
        />
        <label for='tab1'>Fixed Annuities</label>

        <input
          type='radio'
          name='tabset'
          id='tab2'
          aria-controls='tab-indexed-annuities'
          checked={checked === 'checked2'}
          onChange={() => setChecked('checked2')}
        />
        <label for='tab2'>Indexed Annuities</label>

        <div class='tab-panels'>
          <section id='tab-fixed-annuities' class='tab-panel'>
            <p className='phase2_subheading'>
              Fixed Annuities offer a fixed interest rate ensuring your account
              will grow at a steady rate. To ensure you have the flexibility you
              need, we offer multiple options for the length of the contract.
            </p>
            <a
              href='http://www.greenfieldslife.com/annuities'
              className='phase2_heading3'
              style={{ textDecoration: 'none' }}
              target='_blank'>
              LEARN MORE
            </a>
          </section>
          <section id='tab-indexed-annuities' class='tab-panel'>
            <p className='phase2_subheading'>
              Indexed annuities are often considered the ‘best of both worlds’
              because they allow you to take advantage of potential gains in the
              market while protecting you from loss with a guaranteed1 minimum
              interest rate. <br />
              <br />1 The guarantees expressed on this Web page are based on the
              claims-paying ability of Farm Bureau Life Insurance Company.
            </p>
            <a
              href='http://www.greenfieldslife.com/annuities'
              className='phase2_heading3'
              style={{ textDecoration: 'none' }}
              target='_blank'>
              LEARN MORE
            </a>
          </section>
        </div>
        <br />
        <br />
      </div> */}
      {section2 && section2.length && (
        <div
          className='annuities_tabset tabset'
          style={{ width: '100%', position: 'relative' }}>
          {section2.map((item, index) => (
            <React.Fragment key={`checked${index}`}>
              <input
                type='radio'
                name='tabset'
                id={`tab${index}`}
                aria-controls={'tab-fixed-annuities' + index}
                checked={checked === `checked${index}`}
                onChange={() => setChecked(`checked${index}`)}
              />
              <label style={{ width: '200px' }} htmlFor={`tab${index}`}>
                {item.title}
              </label>
            </React.Fragment>
          ))}
          <div className='tab-panels'>
            {section2.map((item, index) => (
              <section
                id={`tab-fixed-annuities${index}`}
                className='tab-panel'
                key={`tab-fixed-annuities${index}`}>
                <p className='phase2_subheading'>{ReactHtmlParser(item.description)}</p>
                <a
                  href={item.url}
                  className='phase2_heading3'
                  style={{ textDecoration: 'none' }}
                  target='_blank'>
                  LEARN MORE
                </a>
              </section>
            ))}
            {showEdit && (
              <div
                className='mwfbi_banner__edit'
                style={{ top: '1rem' }}
                id='admin-edit'
                onClick={() => setShowAnnuityTypesModal(true)}>
                <svg>
                  <use xlinkHref='/sprite.svg#icon-edit' />
                </svg>
              </div>
            )}
          </div>
          <br />
          <br />
        </div>
      )}
      <EditAnnuityPoints
        showModal={showAnnuityTypesModal}
        setShowModal={setShowAnnuityTypesModal}
        handleYes={handleAnnutiesDetailsSubmit}
        section2={section2}
        setSection2={setSection2}
      />
    </Content>
  )
}

export default Annuities
