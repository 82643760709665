import React, { useState, useEffect } from 'react';
import Banner from '../../../components/Banner/Banner';
import Content from '../../../components/Content/Content';
import Button from '../../../components/Inputs/Button';
import PaymentModal from '../HomeScreen/PaymentModal';

import { Link } from 'react-router-dom';
import './TermsConditions.css';

const TermsConditions = () => {
	const [paymentModal, setPaymentModal] = useState(false);

	useEffect(() => {
		let acc = document.getElementsByClassName('accordion');

		acc[0].classList.toggle('active');
		acc[0].nextElementSibling.classList.toggle('show');

		for (let i = 0; i < acc.length; i++) {
			acc[i].onclick = function () {
				this.classList.toggle('active');
				this.nextElementSibling.classList.toggle('show');
			};
		}
	});
	useEffect(() => {
		window.scrollTo(0, 0)
	}, []);
	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/hat-and-rope.jpg)"
				title="Terms and Conditions"
			// subTitle="Look for answers to your questions form the list below."
			/>
			<div className="quote2">
				{/* <div className="flex2">
					<h3 className="quote__text2">Start your retirement solution planning with your local agent. </h3>
				</div>
				<div className="header_buttons">
					<Link to="/mwfbi/start-a-quote" className="mw_button">
						Start a Quote
					</Link>
					<Link to="/mwfbi/find-an-agent" className="mw_button">
						find an agent
					</Link>
				</div> */}
			</div>

			<div className="faqs">
				<div className="faq">
					<div>
						<p className="accordion">
							Terms & Conditions
							{/* <svg className="accordion_icon">
								<use xlinkHref="/sprite.svg#icon-plus" />
							</svg> */}
						</p>
						<div className="panel">
							<p className="phase2_subheading">
								This document outlines the Terms and Conditions relating to your use of this website which are applicable to your use of this website. These Terms and Conditions apply to your use of this site and do not change or alter any other contract or agreement between you and 360 Insurance Company (hereinafter “360”). If you do not agree to be bound by the following Terms and Conditions of use, please do not use this site.
							</p>
						</div>
						<PaymentModal setPaymentModal={setPaymentModal} paymentModal={paymentModal} />
						{/* <Button label="Make Payment" icon="/sprite.svg#icon-arrow_right" /> */}
					</div>
				</div>
				<div className="faq">

					<div>
						<p className="accordion">Authorized Use of this Site</p>
						<div className="panel">
							<p className="phase2_subheading">
								This website is owned and operated by 360 Insurance Company. No part of this website can be redistributed, copied, reproduced, transmitted or distributed in any way without the prior written consent of 360. The user agrees that any information provided through this site will be truthful and accurate. The user also agrees to comply with all applicable laws in using this website or the data, information, and material contained herein.
							</p>
						</div>
					</div>
				</div>
				<div className="faq">

					<div>
						<p className="accordion">Privacy</p>
						<div className="panel">
							<p className="phase2_subheading">
								Please see 360’s Privacy Policy also available at this site. We may change 360’s Privacy Policy over time to address new issues and to reflect changes to the internet and to our site. Your continued use of the Website following the institution of any such changes denotes your acceptance of those changes.
							</p>
							{/* <input type="button" value="make payment" className="FAQ_button" onSubmit={''} /> */}
						</div>
					</div>
				</div>
				<div className="faq">

					<div>
						<p className="accordion">Disclaimer of Warranties and Liability</p>

						<div className="panel">
							<p className="phase2_subheading">
								The materials on this website, including, but not limited to, all software, are provided “as is” and without warranties of any kind either expressed or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, or non-infringement. 360 does not warrant that the data, information, and material included in this website or on the Internet will be uninterrupted or error free; nor does 360 warrant that the data, information, software, or other material accessible from this web site is free of viruses or other harmful components.
								<br />
								<br />
								While 360 uses reasonable efforts to include accurate and up to date information on this website, errors or omissions may occur. Under no circumstances will 360 be liable for any direct, compensatory, special, incidental, indirect, consequential, exemplary (punitive) damages, or any damages whatsoever, including, but not limited to, those resulting from lost profits, lost data or business interruption, arising out of the use of, or the inability to use, or performance of the data, information, or material included in this website, whether based on warranty, contract, tort or any other legal theory.
							</p>
						</div>
					</div>
				</div>
				<div className="faq">

					<div>
						<p className="accordion">Governing Law</p>

						<div className="panel">
							<p className="phase2_subheading">
								Those who choose to access this website do so on their own initiative and are responsible for compliance with local laws. These Terms and Conditions shall be governed by and interpreted according to the laws of the State of Wyoming without respect to any conflict of law’s provisions. If any portion of these Terms and Conditions is unlawful, void or unenforceable, it shall not affect the validity or enforceability of any other provision.
							</p>
						</div>
					</div>
				</div>
				<div className="faq">

					<div>
						<p className="accordion">Trademarks and Service Marks</p>

						<div className="panel">
							<p className="phase2_subheading">
								All trademarks, service marks, trade names, logos, and icons are either licensed or proprietary to 360, and may not be used in any commercial manner without prior written consent of 360. Nothing contained in this Website should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any trademark displayed on this Website without permission of 360 or such third party that may own the trademarks displayed on this Website.All other trademarks appearing on the Website are the property of their respective owners. Unauthorized use of any trademarks appearing on the Website may be a violation of federal and/or state trademark laws. If you breach any of these Terms and Conditions, your authorization to use this Website automatically terminates and you must immediately destroy any downloaded or printed materials.<br />
								<br />
								* The content of this site and these Terms and Conditions are subject to change without prior notice.
							</p>

						</div>
					</div>
				</div>
			</div>
		</Content>
	);
};

export default TermsConditions;
