import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import axios from 'axios';
import Button from "../../../components/Inputs/Button";
import {
  ADMIN_USERS,
  PAYMENT_BASE_URL,
  PAYMENT_US_URL,
  PORTAL_PAYMENT_REDIRECTION,
} from "../../../redux/actions/ip";
import style from "./login.module.css";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { useDispatch, useSelector } from "react-redux";
import { azureLogin } from "../../../redux/actions/userActions";

const CustomerLogin = ({ history }) => {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const azureLoginState = useSelector((state) => state.azureLogin);
  const methods = useForm();
  const {
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  const onSubmit = (data) => console.log(data);
  useEffect(() => {
    if (
      azureLoginState &&
      azureLoginState.userInfo &&
      azureLoginState.userInfo.accessToken &&
      history.location.pathname === "/login"
    ) {
      history.push("/");
    }
  }, [azureLoginState]);

  const handleAzureLoginSubmit = (e) => {
    e.preventDefault();
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        if (res.account) {
          localStorage.setItem("az", JSON.stringify(res));
          dispatch(azureLogin(res));
          if (ADMIN_USERS.includes(res.account.username)) {
            history.push("/mwfbi/agent-landing");
          } else history.push("/");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCustomerloginSubmit = () => {
    window.open(process.env.REACT_APP_B2C_SIGNIN);
  };

  const handleCustomerSignupSubmit = () => {
    window.open(process.env.REACT_APP_B2C_SIGNUP);
  };
  const quickPayB2c = async (data) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const accountDetail = await axios.get(`${PAYMENT_BASE_URL}/auth/quick-pay-session`, config);
      if (accountDetail.data.ResponseCode === "Success") {
        let container = window.$('#portalOneContainer');
        container.portalOne();
        let portalOne = container.data('portalOne');
        portalOne.run({
          sessionId: accountDetail.data.PortalOneSessionKey,
          accessTokenId: accountDetail.data.Token,
          displayMode: 'Modal',
          allowClosing: 'true'
        });
        // setAlertMessage("Please contact your agent.")
        // setPaymentMessageModal(true)
        // callback(true)
        return Promise.resolve(accountDetail.data)
      } else {
        // callback(false)
        if (accountDetail.data.redirect) {
          const win = window.open(PAYMENT_US_URL, '_blank');
          if (win != null) {
            win.focus();
          }
          return Promise.resolve({ status: true })
        } else
          return Promise.resolve(accountDetail.data)
      }
    } catch (error) {

      return Promise.reject(error)

    }
  }

  return (
    <FormProvider {...methods}>
      <div
        className={style.container}
        style={{
          backgroundImage: "url(/images/header-img.jpg) ",
        }}
      >
        <div className={style.centerContainer}>
          <div className={style.topContainer}>
            {/* <div className={style.leftContainer}>
                
                <div className={style.input3}>
                  <div className={style.buttons_vertical}>
                    <Button
                      label="login"
                      varient="red"
                      icon="/sprite.svg#icon-login"
                      style={{
                        height: "5rem",
                      }}
                      onClick={handleSubmit(onSubmit)}
                    />
                    <Button
                      label="sign up"
                      varient="red"
                      icon="/sprite.svg#icon-login"
                      style={{
                        height: "5rem",
                      marginTop:'4rem'
                    }}
                      onClick={() => {}}
                  />
                </div>
              </div>
            </div> */}
            <div className={style.leftContainer}>
              <div className={style.customerLogin}>
                <div className={style.header}>Customer Hub Login</div>
                <div className={style.desc}>
                  Access the Customer Hub
                </div>
                <div className={style.input1}>
                  <Button
                    label="Login"
                    varient="red"
                    icon="/sprite.svg#icon-login"
                    style={{
                      width: "100%",
                    }}
                    onClick={handleCustomerloginSubmit}
                  />
                </div>
              </div>
              <div className={style.customerSignup}>
                <div className={style.header}>Customer Hub Registration</div>
                <div className={style.desc}>
                  New to Customer Hub? Register Now.
                </div>
                <div className={style.input1}>
                  <Button
                    label="Register"
                    varient="red"
                    icon="/sprite.svg#icon-login"
                    style={{
                      width: "100%",
                    }}
                    onClick={handleCustomerSignupSubmit}
                  />
                </div>
              </div>
            </div>

            <div className={style.line}></div>
            <div className={style.rightContainer}>
              <div style={{ margin: '0', top: '30%', position: 'absolute' }}>
                <div className={style.header}>Portal Payment</div>
                <div className={style.desc}>
                  Make a portal payment without logging into your account
                </div>
                <div className={style.input1}>
                  <Button
                    label="Payment Wallet"
                    varient="red"
                    icon="/sprite.svg#icon-login"
                    style={{
                      width: "100%",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = PORTAL_PAYMENT_REDIRECTION;
                    }}
                  />
                </div>
              </div>
              {/* <div className={style.quickPay}>
                <div className={style.header}>Quick Pay</div>
                <div className={style.desc}>
                  Make a quick payment without logging into your account
                </div>
                <div className={style.input1}>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href = PAYMENT_US_URL;
                    }}
                    label="Quick Pay - Paymentus"
                    varient="red"
                    icon="/sprite.svg#icon-login"
                    style={{
                      width: "100%",
                    }}
                  />
                </div>
              </div> */}

            </div>
          </div>
          <div className={style.bottomContainer}>
            {/* <div className={style.input1}>
           
              <Button
                label="Quick Pay"
                varient="red"
                icon="/sprite.svg#icon-login"
                style={{
                  width: "45rem",
                }}
                onClick={(e) => {
                  quickPayB2c()
                }}
              />
            </div> */}
            <div className={style.header1}>
              For QuickPay - OneInc: Please contact your agent.
            </div>
            <div className={style.header1}>
              If you need immediate assistance, please contact:
            </div>
            <div className={style.header3}>Customer Service Support</div>
            <div className={style.header2}>
              Phone: <span className={style.phone}>866-882-9871</span>
            </div>
          </div>
        </div>
        <div id="portalOneContainer" key="portalOneContainer" />
      </div>
    </FormProvider>
  );
};

export default CustomerLogin;
