import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import styles from './PdfOption.module.css'
import FormInputComponent from "../../../../../../../FormInputComponent/FormInputComponent";
import { useCallback } from "react";
import PdfUploader from "../../../../../../../PdfUploader/PdfUploader";
import { imageUpload } from "../../../../../../../../redux/actions/contentAction";
import { useEffect } from "react";
import axios from "axios";

const PdfOption = ({ edit, onSave, sections }) => {
    const [uploadFilePreview, setUploadFilePreview] = useState(edit ? sections.selectedItem.pdfTemplatePath : "");
    const [uploadFile, setUploadFile] = useState(null);
    const [pdfFileError, setPdfFileError] = useState(null);

    const [title, setTitle] = useState({ value: edit ? sections.selectedItem.title : '', error: '' })

    console.log(sections, 'uploadFilePreview')

    const handleSubmit = useCallback(async () => {
        if (title.value === '') {
            setTitle({ ...title, error: 'Please Enter the title!' });
            return
        }
        if (!uploadFilePreview) {
            setPdfFileError({ error: 'Please upload a pdf file!' })
            return
        }
        if (uploadFile) {
            const uploadedFile = await imageUpload(uploadFile);
            // console.log("Image", { title: title.value, imageTemplatePath: uploadedFile.data.path, withImageTemplate: true, points: [] });
            onSave({ title: title.value, pdfTemplatePath: uploadFilePreview ,withPdfTemplate: true, imageTemplatePath: uploadedFile.data.path, points: [] })
        } else
            onSave({ title: title.value, imageTemplatePath: sections.selectedItem.imageTemplatePath, withPdfTemplate: true, points: [] })
    }, [title, uploadFile, uploadFilePreview])

    useEffect(() => {
        if (edit) {
            // toDataUrl(sections.selectedItem.imageTemplatePath).then(response => {
            //     console.log(response.data);
            //     setUploadFilePreview(Buffer.from(response.data, 'binary').toString('base64'))
            // })
        }
    }, [])
    const toDataUrl = async (url) => {
        return axios
            .get(url, {
                responseType: 'blob'
            })
        // .then(response => Buffer.from(response.data, 'binary').toString('base64'))
        // const data = await fetch(url);
        // const blob = await data.blob();
        // return new Promise((resolve) => {
        //     const reader = new FileReader();
        //     reader.readAsDataURL(blob);
        //     reader.onloadend = () => {
        //         const base64data = reader.result;
        //         resolve(base64data);
        //     }
        // });
    }
    return (
        <div className={styles.main}>
            <div style={{ width: '50%' }}>
                <FormInputComponent
                    headingStyle={styles.inputHeading}
                    height={"5rem"}
                    style={{ width: "34rem" }}
                    required
                    value={title.value}
                    heading={"Title"}
                    onChange={(e) => {
                        setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={title.error}
                />
                <div className="modal_inner_content--right3" style={{ padding: '0rem', alignItems: 'inherit' }}>
                    <label
                        style={{
                            fontSize: "1.6rem",
                            marginTop: "1rem",
                            color: "#808080",
                        }}
                    >
                        Pdf
                    </label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <PdfUploader
                            uploadFilePreview={uploadFilePreview}
                            setUploadFilePreview={setUploadFilePreview}
                            setPdfFileError={setPdfFileError}
                            pdfFileError={pdfFileError}
                            uploadFile={uploadFile}
                            setUploadFile={setUploadFile}
                        />
                    </div>
                </div>
            </div>
            {uploadFilePreview && <embed style={{ overflow: 'hidden', marginLeft: '1rem', marginTop: '25px' }} draggable='true' title="pdfimage" src={uploadFilePreview} height="100%" width="100%" />}
            <button
                style={{
                    alignSelf: 'end',
                    marginTop: '-4px',
                }}
                type="button"
                className="modal__button-reset-dark"
                onClick={handleSubmit}
            >
                Save
            </button>
        </div>
    )
}

export default PdfOption;
