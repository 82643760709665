import React, { useEffect, useState } from 'react';
import Banner from '../../../components/Banner/Banner';
import Content from '../../../components/Content/Content';
import Button from '../../../components/Inputs/Button';
import { Link } from 'react-router-dom';
import EditInfo from './edit/EditInfo'
import ReactHtmlParser from 'html-react-parser';

import './HowToFileClaimScreen.css';
import { useSelector } from 'react-redux';
import { ADMIN_USERS } from '../../../redux/actions/ip';
import EditHowWeWorkModal from './edit/EditHowWeWork'
import EditGuidlinesModal from './edit/EditGuidlines'
import { addContent, getPageContent, imageUpload } from '../../../redux/actions/contentAction';
const HowToClaim = () => {

	const azureLogin = useSelector(state => state.azureLogin)
	const [showEdit, setShowEdit] = useState(false)
	const [showEditHowWeWorkModal, setShowEditHowWeWorkModal] = useState(false)
	const [showInfoModal, setShowInfoModal] = useState(false)
	const [showGuidlineModal, setShowGuidlineModal] = useState(false)
	const [section2, setSection2] = useState({
		title: { value: "SEE HOW WE WORK", action: "", type: "" },
		steps: {
			value: [
				{
					title: 'STEP 1',
					subTitle: 'Contact your office agent.',
					icon: '/images/Phone-icon.png',
					description: `To report a claim, contact your agent’s office via phone or email to report the circumstances and damages.`
				},
				{
					title: 'STEP 2',
					subTitle: 'Agent will file the claim.',
					icon: '/images/FileClaim-icon.png',
					description: `Your agent will file a claim electronically with our claims department.`
				},
				{
					title: 'STEP 3',
					subTitle: 'You will be contacted.',
					icon: '/images/Communication-icon.png',
					description: `A claims representative will contact you within 3 business days. In the instance of a large
					catastrophic event our contact time frame may be slightly longer.`
				},
				{
					title: 'STEP 4',
					subTitle: 'The claim will be processed.',
					icon: '/images/ProcessedClaim-icon.png',
					description: `Your personal claims representative will conduct a thorough investigation and make a fair and equitable settlement when the circumstances dictate.`
				},

			], action: "", type: "array"
		},
	})

	const [section3, setSection3] = useState({
		title: { value: "Meeting the Needs of Our Clients and Agents", action: "", type: "" },
		image: { value: '/images/storm-trailer.jpg', action: "", type: "" },
		subTitle: { value: "", action: "", type: "" },
		tagLine: { value: `360's Claims Department is dedicated to the prompt and professional investigation of each claim while maintaining an empathetic approach towards each individual policyholder. Our success is built on the foundation of shared values, quality services and relationships, integrity, mutual trust, and financial strength. We maintain a working relationship with our sales force to service the promises of the product they sell. We aspire to be recognized in our communities as reputable and professional.`, action: "", type: "" },
	})
	const [section4, setSection4] = useState({
		headerText: { value: "Find a Contractor You Can Trust", action: "", type: "" },
		headerSubText: {
			value: `When looking for a contractor follow these basic guidelines. This is intended for use as a guide.
			360 Insurance does not choose or recommend contractors.`, action: "", type: ""
		},
		footerText: { value: `360 Insurance will inspect your property when a claim is submitted. This service is part of your insurance contract and is free of charge. We will never send a contractor to look at your property without talking to you first.`, action: "", type: "" },
		steps: {
			value: [
				{
					title: 'Licensed',
					description:
						'Check with the state or city to see if they require contractors to be licensed. If so, make sure the contractor has the correct license.',
					description1: 'Not all states or cities require that contractors be licensed.'
				},
				{
					title: 'Permits',
					description:
						'Permits are required for most repairs, and the contractor should obtain the permits. If the contractor does not obtain a permit, ask why and check with the state or city. If you live in a rural area, check with your County Clerk to see if they require permits.',
					description1: ''

				},
				{
					title: 'Contract',
					description:
						'Always sign a written contract. If a verbal agreement is made outside the contract, ask the contractor to place it in the written agreement.',
					description1: ''
				},
				{
					title: 'Payment',
					description:
						'Do not pay in cash! Never pay the contractor in advance or pay more than 10% or $1,000 down. Always get a receipt. Prior to making final payment, inspect the work and make sure it is totally completed and to your satisfaction.',
					description1: ''
				}
			], action: "", type: "array"
		},
	})
	const [contractList, setContractList] = useState([
		{
			title: 'Licensed',
			des:
				'Check with the state or city to see if they require contractors to be licensed. If so, make sure the contractor has the correct license.',
			des1: 'Not all states or cities require that contractors be licensed.'
		},
		{
			title: 'Permits',
			des:
				'Permits are required for most repairs, and the contractor should obtain the permits. If the contractor does not obtain a permit, ask why and check with the state or city. If you live in a rural area, check with your County Clerk to see if they require permits.'
		},
		{
			title: 'Contract',
			des:
				'Always sign a written contract. If a verbal agreement is made outside the contract, ask the contractor to place it in the written agreement.'
		},
		{
			title: 'Payment',
			des:
				'Do not pay in cash! Never pay the contractor in advance or pay more than 10% or $1,000 down. Always get a receipt. Prior to making final payment, inspect the work and make sure it is totally completed and to your satisfaction.'
		}
	]);

	const [bannerData, setBannerData] = useState({
		title: { value: 'How to File a Claim', error: '' },
		subTitle: { value: 'Our claim team strives to provide great customer service, while investigating claim efficiently and objectively.', error: '' },
		tagline: { value: 'HAVE YOU FILED A CLAIM WITH YOUR AGENT?', error: '' },
		button1: { value: 'START A QUOTE', error: '' },
		button1Link: { value: '/mwfbi/start-a-quote', error: '' },
		button1Status: { value: false, error: '' },
		button2: { value: 'FIND YOUR LOCAL AGENT', error: '' },
		button2Link: { value: '/mwfbi/find-an-agent', error: '' },
		button2Status: { value: true, error: '' },
		imageUrl: { value: '/images/broken-windshield.jpg', error: '' },
	})
	useEffect(() => {
		if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
			if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
				setShowEdit(true)
			} else setShowEdit(false)
		} else setShowEdit(false)
	}, [azureLogin])
	const ContractCard = (item) => {
		return (
			<div className="contract-card">
				<lable className="title heading_2">{item.data.title}</lable>
				<lable className="desc heading_3">{item.data.description}</lable>
				<label className="desc heading_4">{item.data.description1}</label>
			</div>
		);
	};
	const handleSubmit = (data) => {

	};
	const handleInfoSubmit = (file) => {

		if (file) {
			imageUpload(file).then(res => {
				if (res.data.status) {
					let temp = { ...section3 }
					if (!temp.image)
						temp.image = { value: "" }
					temp.image.value = res.data.path;
					setSection3(temp)
					addContent({ pageName: "Claims", section: "Section_3", content: temp })
						.then(result => {
							if (result.data.status) {
								alert("Content Saved")
							}
						})
				}
			})
		} else {
			addContent({ pageName: "Claims", section: "Section_3", content: section3 })
				.then(result => {
					if (result.data.status) {
						alert("Content Saved")
					}
				})
		}
		setShowInfoModal(false)
	}
	useEffect(() => {
		getPageContent({ pageName: "Claims" })
			.then(res => {
				if (res.data.status) {
					if (res.data.result.Section_2) {
						setSection2({ ...res.data.result.Section_2, steps: { ...res.data.result.Section_2.steps, value: JSON.parse(res.data.result.Section_2.steps.value) } })
					}
					if (res.data.result.Section_3)
						setSection3(res.data.result.Section_3)
					if (res.data.result.Section_4) {
						setSection4({ ...res.data.result.Section_4, steps: { ...res.data.result.Section_4.steps, value: JSON.parse(res.data.result.Section_4.steps.value) } })
					}
					if (res.data.result.TopBanner) setBannerData({
						...res.data.result.TopBanner,
						button1Status: { value: res.data.result.TopBanner.button1Status.value === 'true' },
						button2Status: { value: res.data.result.TopBanner.button2Status.value === 'true' }
					})

				}
			})
	}, [])
	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="linear-gradient(rgba(47,35,98,0.4), rgba(47,35,98,0.4)), url(/images/broken-windshield.jpg)"
				title="How to File a Claim"
				subTitle="Our claim team strives to provide great customer service, while investigating claim efficiently and
					objectively."
				bannerData={bannerData}
				showEdit={showEdit}
				setBannerData={setBannerData}
				pageName={'Claims'}
			/>
			<div className="quote">
				<div className="flex">
					<h3 className="quote__text">
						{bannerData && bannerData.tagline ? bannerData.tagline.value : "HAVE YOU FILED A CLAIM WITH YOUR AGENT?"}</h3>
				</div>
				{bannerData && bannerData.button1 && bannerData.button1Status.value && <Link to={bannerData.button1Link.value} className='mw_button'>
					{bannerData.button1.value}
				</Link>}
				{bannerData && bannerData.button2 && bannerData.button2Status.value && <Link to={bannerData.button2Link.value} className='mw_button'>
					{bannerData.button2.value}
				</Link>}

			</div>

			<div className="how-we-work-main" style={{ position: 'relative' }}>
				<h3 className="how-we-work-main__heading">{section2.title.value}</h3>

				<div className="how-we-work-main__steps">
					{section2 && section2.steps && section2.steps.value.map(step => (<div className="grid-card">
						<img className="grid-card-image" src={step.icon} alt={step.title} />
						<h2 className="grid-card-title">{step.title}</h2>
						<h2 className="grid-card-sub-title">{step.subTitle}</h2>
						<h2 className="grid-card-desc">
							{step.description}
						</h2>
					</div>))}
					{/* <div className="grid-card">
						<img className="grid-card-image" src="/images/FileClaim-icon.png" alt="step 2" />
						<h2 className="grid-card-title">Step 2</h2>
						<h2 className="grid-card-sub-title">Agent will file the claim.</h2>
						<h2 className="grid-card-desc">
							Your agent will file a claim electronically with our claims department.
						</h2>
					</div>
					<div className="grid-card">
						<img className="grid-card-image" src="/images/Communication-icon.png" alt="step 3" />
						<h2 className="grid-card-title ">Step 3</h2>
						<h2 className="grid-card-sub-title">You will be contacted.</h2>
						<h2 className="grid-card-desc">
							A claims representative will contact you within 3 business days. In the instance of a large
							catastrophic event our contact time frame may be slightly longer.
						</h2>
					</div>
					<div className="grid-card">
						<img className="grid-card-image" src="/images/ProcessedClaim-icon.png" alt="step 4" />
						<h2 className="grid-card-title">Step 4</h2>
						<h2 className="grid-card-sub-title">The claim will be processed.</h2>
						<h2 className="grid-card-desc ">
							Your personal claims representative will conduct a thorough investigation and make a
							reasonable evaluation of your claim.
						</h2>
					</div> */}
				</div>
				{showEdit && <div
					className='mwfbi_banner__edit2'
					id='admin-edit-hww'
					style={{ top: '1rem' }}
					onClick={() => setShowEditHowWeWorkModal(true)}>
					<svg>
						<use xlinkHref='/sprite.svg#icon-edit' />
					</svg>
				</div>}
			</div>
			<EditHowWeWorkModal
				showModal={showEditHowWeWorkModal}
				setShowModal={setShowEditHowWeWorkModal}
				handleYes={handleSubmit}
				data={section2}
				setData={setSection2}
			/>
			<div className="how-we-work-desc" style={{ position: 'relative' }}>
				<div>
					<h3 className="how-we-work-desc-heading">
						{section3.title.value} <span>{section3.subTitle.value}</span>
					</h3>
					<p className="phase2_subheading">
						{section3.tagLine && ReactHtmlParser(section3.tagLine.value)}
					</p>
				</div>
				<div className="clip_image">
					<img src={section3.image.value} alt="fbf" className="fbf__image__bus" />
				</div>
				{showEdit && <div
					className='mwfbi_banner__edit2'
					id='admin-edit-hww'
					style={{ top: '1rem' }}
					onClick={() => setShowInfoModal(true)}>
					<svg>
						<use xlinkHref='/sprite.svg#icon-edit' />
					</svg>
				</div>}
			</div>
			<EditInfo
				showModal={showInfoModal}
				setShowModal={setShowInfoModal}
				section3={section3}
				setSection3={setSection3}
				handleYes={handleInfoSubmit}
			/>
			<div className="contract-main" style={{ position: 'relative' }}>
				<div className="contract-content">
					<p className="how-we-work-desc-heading">{section4.headerText.value}</p>
					<h3 className="how-we-work-desc-heading1">
						{ReactHtmlParser(section4.headerSubText.value)}
					</h3>

					<div className="contract-card-list">{section4 && section4.steps && section4.steps.value.map((item) => <ContractCard data={item} />)}</div>
					<div className="how-we-work-desc-heading1">
						{ReactHtmlParser(section4.footerText.value)}
					</div>
				</div>
				{showEdit && <div
					className='mwfbi_banner__edit2'
					id='admin-edit-hww'
					style={{ top: '1rem' }}
					onClick={() => setShowGuidlineModal(true)}>
					<svg>
						<use xlinkHref='/sprite.svg#icon-edit' />
					</svg>
				</div>}
			</div>
			<EditGuidlinesModal
				showModal={showGuidlineModal}
				setShowModal={setShowGuidlineModal}
				handleYes={handleSubmit}
				data={section4}
				setData={setSection4}
			/>
		</Content >
	);
};

export default HowToClaim;
