import React from "react";
import styles from "./Tooltip.module.css"

const Tooltip = ({ tooltipStyle, children }) => {
    return (
        <div class={styles.tooltip} style={tooltipStyle}>
            <div class={styles.tooltip__arrow}></div>
            {children}
        </div>
    )
}

export default Tooltip;