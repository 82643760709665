import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Banner from '../../../components/Banner/Banner';
import Content from '../../../components/Content/Content';
import SimpleButton from '../../../components/Inputs/SimpleButton';
import './FarmBureauFederation.css';

const FarmBureauFederation = () => {
	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="linear-gradient(rgba(47,35,98,0.4), rgba(47,35,98,0.4)),url(/images/Dixie-Roth.JPG)"
				title="360 Insurance Federation"
				subTitle="The voice of agriculture for over 100 years"
			/>
			<div className="fbf__content">
				<div>
					<p className="fbf_heading">360 Insurance Federation</p>
					<p className="fbf__desc">
						The Wyoming and Montana 360 Insurance Federations are the largest farm and ranch organizations in
						the states of Wyoming and Montana. 360 Insurance is the unified voice of agriculture, working
						through grassroots organization to enhance and strengthen the lives of rural Americans and to
						build strong, prosperous agricultural communities.<br />
						<br />Our company was created by the Wyoming 360 Insurance in 1948 to provide quality insurance
						products and risk management solutions to their members. The company partnered with Montana Farm
						Bureau in 1958 and exists to serve the members of 360 Insurance in Wyoming and Montana.
					</p>
					<div className="mwfbi__buttons">
						<a href="https://mfbf.org/join">
							<SimpleButton varient="red">Join Montana FB Federation</SimpleButton>
						</a>
						<a href="https://wy.fbiris.com/join">
							<SimpleButton varient="black">Join Wyoming FB Federation</SimpleButton>
						</a>
					</div>
				</div>
				<div className="clip_image">
					<img src="/images/KerinClark.JPG" alt="fbf" className="fbf__image" />
				</div>
			</div>
			<div className="mwfbi__quote">
				<p className="mwfbi__quote--text">
					<span>
						We’re the state’s largest agriculture association <br /> and a tireless advocate for
						agriculture.
					</span>
				</p>
			</div>
			<div className="learn_more">
				<h2 className="learn_more__heading">Learn More</h2>
				<p className="learn_more__subheading">
					If you would like to learn more about your local 360 Insurance Federation and what services they
					provide to the agriculture community, click the links below to be taken to their website.
				</p>
				<div className="learn_more__logos">
					<a href="https://www.wyfb.org/">
						<img src="/images/wyoming.png" alt="fbf" className="learn_more__logo1" />
					</a>
					<a href="https://mfbf.org/">
						<img src="/images/mountain-FBF.png" alt="fbf" className="learn_more__logo" />
					</a>
				</div>
			</div>
		</Content>
	);
};

export default FarmBureauFederation;
