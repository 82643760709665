import React, { Fragment, useState, useEffect, useCallback } from "react";
// import { useHistory } from 'react-router-dom'
import Modal from "../../../../components/DarkModal/index";
import FormInputComponent from "../../../../components/FormInputComponent/FormInputComponent";
import { useDropzone } from "react-dropzone";
import { imageUpload } from "../../../../redux/actions/contentAction";

let timeout;
let selectedPosition = -1;
const EditInsuranceInfo = ({
  setShowModal,
  showModal,
  handleYes,
  section1,
  setSection1,
  pointsArr,
  handleChangeFieldOrder,
  setPointsArr,
  contractList,
  setContractList,
}) => {
  const [loading, setLoading] = useState(false);

  const [uploadImagePreview, setUploadImagePreview] = useState("");
  const [uploadImage, setUploadImage] = useState(null);

  const [editIndex, setEditIndex] = useState(-1);

  const [title, setTitle] = useState({ value: "", error: "" });
  const [subTitle, setSubTitle] = useState({ value: "", error: "" });
  const [timenlineTitle, setTimenlineTitle] = useState({
    value: "",
    error: "",
  });
  const [point, setPoint] = useState({ value: "", error: "" });
  const [year, setYear] = useState({ value: "", error: "" });

  const onEdit = (item, index) => {
    setEditIndex(index);
    setSubTitle({
      ...subTitle,
      value: item.subTitle,
    });
    setTimenlineTitle({
      ...title,
      value: item.title,
    });
    setYear({
      ...year,
      value: item.year,
    });
  };

  const onDelete = (item, index) => {
    console.log(contractList);
    if (window.confirm("Are You Sure to Delete?")) {
      let temp = [...contractList];
      temp.splice(index, 1);
      setContractList(temp);
      handleYes(temp);
    }
  };

  const onAdd = () => {
    let valid = true;

    if (timenlineTitle.value === "" && timenlineTitle.value.trim() === "") {
      setTimenlineTitle({
        ...timenlineTitle,
        error: "Please enter Title",
      });
      valid = false;
    }
    if (subTitle.value === "" && subTitle.value.trim() === "") {
      setSubTitle({
        ...subTitle,
        error: "Please enter Description",
      });
      valid = false;
    }
    if (year.value === "" && year.value.trim() === "") {
      setYear({
        ...year,
        error: "Please enter year",
      });
      valid = false;
    }

    if (valid) {
      let content = {
        title: timenlineTitle.value,
        year: year.value,
        subTitle: subTitle.value,
      };

      if (editIndex > -1) {
        const temp = [...contractList];
        temp[editIndex] = content;

        console.log("updated", temp);
        setContractList(temp);
        handleYes(temp);
      } else {
        const temp = [...contractList];
        temp.push(content);
        setContractList(temp);
        handleYes(temp);

        console.log("added", temp);
      }
    }
  };

  const resetAll = () => {
    setTimenlineTitle({ value: "", error: "" });
    setSubTitle({ value: "", error: "" });
    setYear({ value: "", error: "" });
    setEditIndex(-1);
  };

  const orderFields = (direction, item, index) => {
    const tempArr = contractList.filter(
      (element, position) => index !== position
    );
    if (direction === "Up" && index > 0) {
      tempArr.splice(index - 1, 0, item);
      clearTimeout(timeout);
    } else if (direction === "Down" && index < contractList.length) {
      tempArr.splice(index + 1, 0, item);
      clearTimeout(timeout);
    } else return;
    setContractList(tempArr);
    timeout = setTimeout(() => {
      handleYes(tempArr);
    }, 2000);
  };

  const closeModal = () => {
    resetAll()
    setShowModal(false)
  }

  return (
    <Modal show={showModal}>
      <form>
        <div className="modal__container-dark">
          <div className="modal_heading__container-dark">
            <h1 className="modal_heading__text-dark">Edit Timeline</h1>
            <button
              className="modal__heading--button-dark"
              onClick={() => closeModal()}>
              <svg className="modal_heading__icon-dark">
                <use xlinkHref="/sprite.svg#icon-cross" />
              </svg>
            </button>
          </div>
          <div className="modal__form-dark">
            <div className="modal_inner_content">
              <div className="modal_inner_content--left">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <div className="pre_heading" style={{ display: "flex" }}>
                      <FormInputComponent
                        heading="Title"
                        style={{ width: "80%" }}
                        required={true}
                        value={timenlineTitle.value}
                        onChange={(e) => {
                          setTimenlineTitle({
                            error: "",
                            value: e.target.value,
                          });
                        }}
                        errorMessage={timenlineTitle.error}
                      />
                    </div>
                    <div className="pre_heading" style={{ display: "flex" }}>
                      <FormInputComponent
                        heading="Subtitle"
                        type={"textarea"}
                        style={{ width: "80%" }}
                        required={true}
                        value={subTitle.value}
                        onChange={(e) => {
                          setSubTitle({ error: "", value: e.target.value });
                        }}
                        errorMessage={subTitle.error}
                      />
                    </div>
                    <div className="pre_heading" style={{ display: "flex" }}>
                      <FormInputComponent
                        heading="Year"
                        style={{ width: "40%" }}
                        required={true}
                        value={year.value}
                        onChange={(e) => {
                          setYear({ error: "", value: e.target.value });
                        }}
                        errorMessage={year.error}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal_inner_content--right">
                <div
                  style={{
                    marginTop: "21rem",
                    marginLeft: "20rem",
                    display: "flex",
                    gap: 34,
                  }}
                >
                  <button
                    type="button"
                    className="modal__button-reset-dark"
                    onClick={resetAll}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="modal__button-dark"
                    onClick={onAdd}
                  >
                    {loading ? "Saving.." : editIndex > -1 ? "Save" : "Add"}
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </form>
      <div className="modal_inner_content" style={{margin: '2rem', marginTop: '0'}}>
              <div className="modal_form_container">
                <table
                  className="modal__form-table"
                  cellSpacing="0"
                  cellPadding="0"
                >
                  <thead className="modal__form-sticky">
                    <tr className="modal__form-tr">
                      <th>No.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Year</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {contractList &&
                      contractList.length > 0 &&
                      contractList.map((item, i) => {
                        return (
                          <tr
                            style={{ height: "60px" }}
                            className="modal__form-tr2"
                            key={i}
                          >
                            <td>{i + 1}</td>
                            <td style={{ width: "220px" }}>{item.title}</td>
                            <td style={{ padding: "0.5rem" }}>
                              <button
                                // style={{
                                //   background:
                                //     selectedPosition === i ? "blue" : "black",
                                // }}
                                onClick={() => orderFields("Up", item, i)}
                                style={{ marginRight: "1rem" }}
                              >
                                <svg className="modal_heading__icon-dark">
                                  <use xlinkHref="/sprite.svg#icon-up" />
                                </svg>
                              </button>
                              <button
                                onClick={() => orderFields("Down", item, i)}
                              >
                                <svg className="modal_heading__icon-dark">
                                  <use xlinkHref="/sprite.svg#icon-down" />
                                </svg>
                              </button>
                            </td>
                            <td style={{ width: "180px" }}>{item?.year}</td>
                            <td
                              className="modal__form-buttons"
                              style={{
                                justifyContent: "center",
                                paddingTop: "1.1rem",
                              }}
                            >
                              <button
                                type="button"
                                className="modal__button-edit"
                                onClick={(e) => onEdit(item, i)}
                              >
                                edit
                              </button>
                              <button
                                type="button"
                                className="modal__button-delete"
                                onClick={(e) => onDelete(item, i)}
                              >
                                delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
    </Modal>
  );
};

export default EditInsuranceInfo;
