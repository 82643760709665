import React from 'react';
import { Link } from 'react-router-dom';

import './Header.css';
const StoreHeader = () => {
	return (
		<header className='header'>
			<img src='/fav.png' alt='Logo' className='logo' />
			<nav className='navigation'>
				<ul className='navigation__links'>
					<li>
						<Link to='/' className='navigation__link'>
							Home
						</Link>
					</li>
					<li>
						<Link to='/' className='navigation__link'>
							Apparel
						</Link>
					</li>
					<li>
						<Link to='/' className='navigation__link'>
							Hats
						</Link>
					</li>
					<li>
						<Link to='/' className='navigation__link'>
							Offline Supplies
						</Link>
					</li>
					<li>
						<Link to='/' className='navigation__link'>
							Promotional
						</Link>
					</li>
					<li>
						<Link to='/mwfbi/about' className='navigation__link'>
							Clearance
						</Link>
					</li>
				</ul>
				<ul className='navigation__links'>
					<li>
						<Link to='/' className='navigation__link'>
							<svg className='footer__social--icon'>
								<use xlinkHref='/sprite.svg#icon-user' />
							</svg>
						</Link>
					</li>
					<li>
						<Link to='/' className='navigation__link'>
							<svg className='footer__social--icon'>
								<use xlinkHref='/sprite.svg#icon-cart' />
							</svg>
						</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
};

export default StoreHeader;
