import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Content from '../../../components/Content/Content';
import './FindAnAgent.css';
import Banner from '../../../components/Banner/Banner';
const FindAnAgentDetail = ({ history }) => {
	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="url(/images/find-an-agent-bg.png)"
				title="Find Details"
				subTitle="All you need to know about agent in detail"
			/>

			<div className="findanagent-main-section" style={{ top: '56%' }}>
				<div className="findanagent-box detail-card">
					<div className="find-an-agent-left-card">
						<img src="/images/Martin-Amanda.jpg" className="user_profile" />
					</div>
					<div className="find-an-agent-right-card">
						<h1 className="search_heading heading_1">Amanda Martin</h1>
						<h4>
							<svg className="findanagentContainer__formgroup--icon">
								<use xlinkHref="/sprite.svg#icon-phone" />
							</svg>
							Phone (406) 453-5255
						</h4>

						<h4>
							<svg className="findanagentContainer__formgroup--icon">
								<use xlinkHref="/sprite.svg#icon-fax" />
							</svg>
							Fax (406) 771-9060
						</h4>

						<h4>
							<svg className="findanagentContainer__formgroup--icon">
								<use xlinkHref="/sprite.svg#icon-email" />
							</svg>
							samuel@testmail.com
						</h4>

						<h4>
							<svg className="findanagentContainer__formgroup--icon">
								<use xlinkHref="/sprite.svg#icon-pin" />
							</svg>
							807 13th Ave S, Great Falls, MT 59405, USA{' '}
						</h4>
					</div>
				</div>

				<div className="find-an-agent-quote-card">
					<p>
						Get started with our online quote form if you are interested in receiving a comprehensive
						insurance analysis from our office.
					</p>

					<button
						className="button"
						onClick={() => {
							history.push('/360/start-a-quote');
						}}
						style={{ marginTop: '1rem' }}
					>
						<p className="button__text btn-text">Start a Quote</p>
					</button>
				</div>
			</div>
			<div className="find-an-agent-detail-main-section main-content-margin">
				<div className="main-section-left">
					<label className="dash_heading_1">About Me</label>
					<div className="dash" />
					<div className="searchListContainer">
						<p className="text-about">
							As your local Farm Bureau agent in Great Falls, Montana, I help customers like you identify
							the insurance coverage that best fits your needs. This process is straightforward and
							personalized to help you become more knowledgeable about your insurance coverages. I have
							the knowledge and experience to help you better understand your coverage options for: auto,
							home, renters, farm and ranch, business insurance and more.
						</p>{' '}
						<p className="text-about">
							Call, email, or stop by our office at your convenience and we can help walk you through the
							insurance purchasing process today.
						</p>{' '}
						<p className="text-about">
							If you have any questions please call our office at (406) 771-8866 or click the button
							below.
						</p>
						<button className="button" onClick={null} style={{ marginTop: '1rem' }}>
							<p className="button__text btn-text">Contact Me</p>
						</button>
					</div>
				</div>
				<div className="main-section-right" style={{ width: '30rem' }} />
			</div>
			<div className="google-map-section main-content-margin">
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d511.96578065483044!2d-104.9351236899215!3d39.73228628541163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876b80aa231f17cf%3A0x118ef4f8278a36d6!2sDenver%2C%20CO%2C%20USA!5e0!3m2!1sen!2sin!4v1634309617582!5m2!1sen!2sin"
					width="1220"
					height="350"
					style={{ border: '0' }}
				/>
			</div>
			<div className="mwfbi_products" style={{ margin: '5rem 0rem' }}>
				<div className="mwfbi__header" style={{ margin: '0rem 28rem' }}>
					<h3 className="mwfbi__products--heading">
						Insurance <span>Products Offered</span>
					</h3>
				</div>
				<div className="google-map-section">
					<div className="mwfbi__products--list">
						<Link to="/" className="mwfbi_product">
							<img src="/images/product-1.png" alt="Product 1" className="mwfbi_product--image" />
							<h4 className="mbfbi__product--heading">Farm & Ranch</h4>
						</Link>
						<Link to="/" className="mwfbi_product">
							<img src="/images/product-4.png" alt="Product 1" className="mwfbi_product--image" />
							<h4 className="mbfbi__product--heading">Auto</h4>
						</Link>
						<Link to="/" className="mwfbi_product">
							<img src="/images/product-3.png" alt="Product 1" className="mwfbi_product--image" />
							<h4 className="mbfbi__product--heading">Home</h4>
						</Link>
						<Link to="/" className="mwfbi_product">
							<img src="/images/product-2.png" alt="Product 1" className="mwfbi_product--image" />
							<h4 className="mbfbi__product--heading">Business</h4>
						</Link>
					</div>
				</div>
			</div>
		</Content>
	);
};

export default FindAnAgentDetail;
