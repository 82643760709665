import React from "react";
import styles from "./PdfCard.module.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const PdfCard = ({ title, pdfUrl, pdfPath }) => {

  const [pdfData, setPdfData] = useState('');

  useEffect(() => {
    if (pdfPath) {
      convertPdfToBase64(pdfPath)
    }
  }, [pdfPath])

  const convertPdfToBase64 = (url) => {
    axios.get(url, { responseType: "blob" })
      .then(function (response) {

        var baseFile = new Blob([response.data], { type: 'application/pdf' })
        setPdfData(URL.createObjectURL(baseFile));
      });
  }

  return (
    <div style={{ paddingBottom: '6rem', justifyContent: 'center' }}>
      <label style={{ fontSize: '20px' }} className={styles.title}>{title}</label>
      <div style={{ justifyContent: 'center', display: 'flex' }}>
        <iframe onClick={() => console.log('hi')} style={{ overflow: 'hidden', marginTop: '25px' }} draggable='true' title="pdfimage" src={pdfData} height="350" width="320" ></iframe>
      </div>
    </div>
  );
};

export default PdfCard;
