import React, { useState } from 'react';
// import Recaptcha from "react-recaptcha";
import ReCAPTCHA from "react-google-recaptcha";
const apy_key = "6LdEk18UAAAAAKfpWl5vEEfEHvx4OVjzwHWdNVDR"; //Test key 6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI

const site_key = '6LdNkyAaAAAAAPoAyg-NO_0mbH4KJKknWwcxeqVu'; 
const secret_key = '6LdNkyAaAAAAABzuU-F4rCDQHw9AQ00GT8QbTKJ1';

const ReaptchaCard = ({ data }) => {
    const { recaptchaInstance, SetRecaptchaInstance, resetRecaptcha, callback, verifyCallback } = data;

    return (
        <ReCAPTCHA
            // style={{ display: "inline-block" }}
            ref={e => SetRecaptchaInstance(e)}
            sitekey={apy_key}
            onChange={verifyCallback}
            asyncScriptOnLoad={callback}
        />
    );
};

export default ReaptchaCard;
