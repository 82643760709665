import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import ReactPaginate from 'react-paginate'
import { deleteContentItem, imageUpload, addContentItem, addContent } from '../../../../redux/actions/contentAction'

const itemsPerPage = 10

const EditFindAgent = ({ setShowModal, showModal, handleYes, data, setData }) => {
  const [accountNumber, setAccountNumber] = useState({ value: '', error: '' })

  const [loading, setLoading] = useState(false)
  const [sessionId, setSessionId] = useState('')
  const [accessTokenId, setAccessTokenId] = useState('')
  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(null)
  const [currentStatus, setCurrentStatus] = useState([])
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [currentItems, setCurrentItems] = useState(null)


  const [headerText, setHeaderText] = useState({ value: data.title.value, error: '' })
  const [stepList, setStepList] = useState(data.steps.value)

  const [editIndex, setEditIndex] = useState(-1)
  const [editData, setEditData] = useState({
    title: { value: "", error: "" },
    subtitle: { value: "", error: "" },
    description: { value: "", error: "" },
    icon: { value: "", error: "" }
  })

  useEffect(() => {
    setStepList(data.steps.value)
  }, [data.steps])

  const handleSave = () => {
    if (editData.title.value === '') {
      setEditData({ ...editData, title: { ...editData.title, error: 'Please enter title!' } });
      return
    }
    if (editData.subtitle.value === '') {
      setEditData({ ...editData, subtitle: { ...editData.subtitle, error: 'Please enter sub title!' } });
      return
    }
    if (editData.icon.value === '') {
      setEditData({ ...editData, icon: { ...editData.icon, error: 'Please enter icon!' } });
      return
    }
    if (editData.description.value === '') {
      setEditData({ ...editData, description: { ...editData.description, error: 'Please enter description!' } });
      return
    }

    let temp = [...stepList];
    if (editIndex > -1) {
      temp[editData] = {
        title: editData.title.value,
        subTitle: editData.subtitle.value,
        icon: editData.icon.value,
        description: editData.description.value,
      }
    } else {

      let step = {
        title: editData.title.value,
        subTitle: editData.subtitle.value,
        icon: editData.icon.value,
        description: editData.description.value,
      }
      temp.push(step)

    }
    setStepList(temp)

    clearErrors()
    let content = {
      title: { ...data.title },
      steps: { ...data.steps, value: JSON.stringify(temp) }
    }
    setLoading(true);
    addContent({ pageName: "Claims", section: "Section_2", content })
      .then(result => {
        setLoading(false);
        if (result.data.status) {
          clear()
          alert("Step Saved!");
          setData({ ...data, steps: { ...data.steps, value: temp } })
        }
      }).catch(err => setLoading(false))



  }
  const handleHeaderSave = () => {
    if (headerText.value == '') {
      setHeaderText({ ...headerText, error: 'Please enter Name!' });
      return
    }

    clearErrors()
    let content = {
      title: { ...data.title, value: headerText.value },
      steps: { ...data.steps, value: JSON.stringify(data.steps.value) }
    }
    addContent({ pageName: "Claims", section: "Section_2", content })
      .then(result => {
        console.log(result.data);
        if (result.data.status) {
          alert("Header Text Saved!")
        }
      })

  }

  const onEdit = (item, index) => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: item.title, error: "" },
      subtitle: { value: item.subTitle, error: "" },
      description: { value: item.description, error: "" },
      icon: { value: item.icon, error: "" }
    })
    setEditIndex(index)
    // setHeaderText({ ...headerText, value: item.title })
  }
  const clear = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: "", error: "" },
      subtitle: { value: "", error: "" },
      description: { value: "", error: "" },
      icon: { value: "", error: "" }
    })
    setEditIndex(-1)
    // setShowModal(false)
    // handleAgentSubmit()
  }

  const closeModal = () => {
    resetAll();
    setShowModal(false)
  }
  const resetAll = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      title: { value: "", error: "" },
      subtitle: { value: "", error: "" },
      description: { value: "", error: "" },
      icon: { value: "", error: "" }
    })
    setEditIndex(-1)
    // setHeaderText({ ...headerText, value: "", error: "" })
  }
  const clearErrors = () => {
    setHeaderText({ ...headerText, error: "" })
    setEditData({ ...editData, title: { ...editData.title, error: '' } });
    setEditData({ ...editData, subtitle: { ...editData.subtitle, error: '' } });
    setEditData({ ...editData, icon: { ...editData.icon, error: '' } });
    setEditData({ ...editData, description: { ...editData.description, error: '' } });
  }
  const onStepDelete = (item, index) => {
    if (window.confirm('Are You Sure to Delete?')) {
      let temp = [...stepList];
      temp.splice(index, 1);
      setStepList(temp);
      let content = {
        title: { ...data.title, value: headerText.value },
        steps: { ...data.steps, value: JSON.stringify(temp) }
      }
      addContent({ pageName: "Claims", section: "Section_2", content })
        .then(result => {
          console.log(result.data);
          if (result.data.status) {
            alert("Step Deleted!");
            setData({ ...data, steps: { ...data.steps, value: temp } })
          }
        })
    }
  }
  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => closeModal()}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>

                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Header Text'
                    style={{ width: '70%' }}
                    value={headerText.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setHeaderText({ error: '', value: e.target.value })
                    }}
                    errorMessage={headerText.error}
                  />
                  <button
                    type="button"
                    style={{ marginTop: "1rem", marginLeft: '0.5rem' }}
                    className="modal__button-dark"
                    onClick={handleHeaderSave}
                  >
                    Save
                  </button>
                </div>
                <hr style={{ marginTop: '1rem', marginBottom: '1rem' }} />
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '100%' }}
                    value={editData.title.value}
                    onChange={(e) => {
                      let temp = { ...editData }
                      temp.title.value = e.target.value
                      setEditData(temp)
                    }}
                    errorMessage={editData.title.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Sub Title:'
                    style={{ width: '100%' }}
                    type={'text'}
                    value={editData.subtitle.value}
                    onChange={(e) => {
                      let temp = { ...editData }
                      temp.subtitle.value = e.target.value
                      setEditData(temp)
                    }}
                    errorMessage={editData.subtitle.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Icon:'
                    style={{ width: '100%' }}
                    type={'text'}
                    value={editData.icon.value}
                    onChange={(e) => {
                      let temp = { ...editData }
                      temp.icon.value = e.target.value
                      setEditData(temp)
                    }}
                    errorMessage={editData.icon.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Description:'
                    style={{ width: '100%' }}
                    type={'textarea'}
                    required={true}
                    value={editData.description.value}
                    onChange={(e) => {
                      let temp = { ...editData }
                      temp.description.value = e.target.value
                      setEditData(temp)
                    }}
                    errorMessage={editData.description.error}
                  />
                </div>

              </div>
              <div style={{justifyContent: 'inherit'}} className='modal_inner_content--right3'>
                <label>Icon Preview</label>
                <div
                  className='upload_border'
                  style={{ marginTop: '-1rem', width: '15rem', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {editData.icon.value ? <img alt='Icon' height={80} width={80} src={editData.icon.value} /> : null}
                </div>

                <div
                  className='modal_dark_buttons'
                  style={{ padding: '0rem', marginTop: '20rem' }}>
                  <button type='button' className='modal__button-reset-dark' onClick={resetAll}>Reset</button>
                  <button type='button' className='modal__button-dark' onClick={handleSave}>{loading ? 'Saving..' : (editIndex > -1 ? 'Save' : 'Add')}</button>
                </div>
              </div>
            </div>
            <div className='modal_inner_content'>
              <div className='modal_form_container'>
                <table
                  className='modal__form-table'
                  cellspacing='0'
                  cellpadding='0'>
                  <thead className='modal__form-sticky'>
                    <tr className='modal__form-tr'>
                      <th>No.</th>
                      <th>Title</th>
                      <th>Sub Title</th>
                      <th style={{ textAlign: 'right', paddingRight: '5rem' }}>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {stepList && stepList.length &&
                      stepList.map((item, i) => {
                        return (
                          <tr className='modal__form-tr2' key={i}>
                            <td>{i + 1}</td>
                            <td>{item.title}</td>
                            <td>{item.subTitle}</td>
                            <td className='modal__form-buttons' style={{ textAlign: 'center' }}>
                              <button type='button' className='modal__button-edit' onClick={(e) => onEdit(item, i)}>
                                edit
                              </button>
                              <button type='button' className='modal__button-delete'
                                onClick={(e) => onStepDelete(item, i)}
                              >
                                delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>

          </div>

          {/* <div className='modal_dark_buttons' style={{ paddingTop: '0' }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={(e) => handleSave()}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal >
  )
}

export default EditFindAgent
