import React from 'react';
import { Link } from 'react-router-dom';
import Content from '../../components/Content/Content';

import './HomeScreen.css';
const HomeScreen = () => {
	return (
		<Content>
			<div
				className="slider"
				style={{
					backgroundImage: 'url(/images/header-img.jpg) '
				}}
			>
				<h1 className="sider__heading">Lorem ipsum dolor sit amet</h1>
				<h3 className="sider__subheading">
					Lorem ipsum dolor, sit amet consectetur adipisicing elit. Reprehenderit, quia.
				</h3>
				{/* <img src='/images/header-img.png' alt='Header' className='slider__img' /> */}
			</div>
			<div className="sections">
				<p className="section__heading">How can we serve you</p>
				<div className="section__contents">
					<Link to="/mwfbi" className="section">
						<img src="/images/icon1.png" alt="icon 1" className="section__image" />
						<h2 className="section__title">Mutual Insurance</h2>
						<img src="/images/logo.png" alt="Logo" className="section__logo" />
					</Link>
					<Link to="/golf" className="section">
						<img src="/images/icon2.png" alt="icon_2" className="section__image" />
						<h2 className="section__title">Golf Fundraiser</h2>
						<img src="/images/golf-logo.png" alt="Logo" className="section__logo" />
					</Link>
					<Link to="/" className="section">
						<img src="/images/icon3.png" alt="icon_3" className="section__image" />
						<h2 className="section__title">Search all</h2>
						<img src="/images/directory-logo.png" alt="Logo" className="section__logo" />
					</Link>
					<Link to="/sales-marketing" className="section">
						<img src="/images/icon4.png" alt="icon 4" className="section__image" />
						<h2 className="section__title">Sales & Marketing</h2>
						<img src="/images/sales-logo.png" alt="Logo" className="section__logo" />
					</Link>
					<Link to="/store" className="section">
						<img src="/images/icon5.png" alt="icon 5" className="section__image" />
						<h2 className="section__title">Visit Store</h2>
						<img src="/images/store-logo.png" alt="Logo" className="section__logo" />
					</Link>
				</div>
			</div>
			<div className="contact" style={{ backgroundImage: 'url(/images/contact-bg.jpg)' }}>
				<h4 className="contact__heading">Nobody wants to be stranded</h4>
				<p className="contact__subheading">That's why we have Emergency Roadside Service</p>
				<p className="contact__text">Need ERS? 888-292-3308</p>
				<button className="contact__btn">Contact Us</button>
			</div>
		</Content>
	);
};

export default HomeScreen;
