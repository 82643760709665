import React, { Fragment, useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom'
import axios from 'axios';
import Modal from '../../../components/Modal';
const qs = require('qs');
// const BASE_URL = 'http://localhost:8080';

const API_BASE_URL = 'https://stgportalone.processonepayments.com';

const PaymentMessageModal = ({ setPaymentMessageModal, paymentMessageModal, message = "Please contact your agent." }) => {

	return (
		<Modal show={paymentMessageModal}>
			<div className='modal__container'>
				<div className='modal_heading__container'>
					<h1 className='modal_heading__text'></h1>
					<button className='modal__heading--button' onClick={() => setPaymentMessageModal(false)}>
						<svg className='modal_heading__icon'>
							<use xlinkHref='/sprite.svg#icon-close' />
						</svg>
					</button>
				</div>
				<div className='modal__form'>
					<label className='alert-message'>
						{message}
					</label>
				</div>
				<button className='modal__button' onClick={() => setPaymentMessageModal(false)}>Close</button>
			</div>
		</Modal>
	);
};

export default PaymentMessageModal;
