import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import styles from './TextEditor.module.css';
import { imageUpload } from '../../redux/actions/contentAction';
const FONT_SIZE = ['8px', '10px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '30px', '35px'];
const Size = Quill.import('attributors/style/size');
Size.whitelist = FONT_SIZE;
Quill.register(Size, true);

const TextEditor = ({ placeholder, onContentChange, errorMessage, value, style }) => {
  const [editorHtml, setEditorHtml] = useState(value);
  const editorRef = useRef(null);

  useEffect(() => {
    setEditorHtml(value);
  }, [value]);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  }, []);

  const handleChange = html => {
    setEditorHtml(html);
    onContentChange(html);
  };

  const imageHandler = e => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (/^image\//.test(file.type)) {
        const res = await imageUpload(file); // upload data into server or aws or cloudinary
        const url = res?.data?.path;
        const editor = editorRef.current.getEditor();
        try {
          const range = editor.getSelection();
          // editor.updateContents(new Delta()
          //     .retain(range.index)
          //     .delete(range.length)
          //     .insert({ image: url })
          //     , Quill.sources.USER);
          editor.insertEmbed(range.index, 'image', url);
          console.log('upload ');
        } catch (err) {
          console.log('upload err:', err);
        }
      } else {
        alert('You could only upload images.');
      }
    };
  };
  const imageHandler1 = async (file, callback) => {
    // const file = input.files[0];
    // if (/^image\//.test(file.type)) {
    const res = await imageUpload(file); // upload data into server or aws or cloudinary
    const url = res?.data?.path;
    callback(url);
    // const editor = editorRef.current.getEditor();
    // try {
    //     const range = editor.getSelection()
    //     // editor.updateContents(new Delta()
    //     //     .retain(range.index)
    //     //     .delete(range.length)
    //     //     .insert({ image: url })
    //     //     , Quill.sources.USER);
    //     editor.insertEmbed(range.index, "image", url);
    //     console.log("upload ");

    // } catch (err) {
    //     console.log("upload err:", err);
    // }
    // } else {
    //     alert('You could only upload images.');
    // }
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          // [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, true] }],
          [{ size: FONT_SIZE }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' },],
          ['link'],
          ['clean'],
          ['image', 'video'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const formats = ['header', 'font', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video'];

  return (
    <>
      <ReactQuill
        style={style}
        ref={editorRef}
        theme='snow'
        onChange={handleChange}
        value={editorHtml}
        modules={modules}
        formats={formats}
        bounds={'.app'}
        placeholder={placeholder}
      />
      {errorMessage && <p className={styles.form_input__error}>{errorMessage}</p>}
    </>
  );
};

export default TextEditor;
