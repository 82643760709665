import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import ReactPaginate from 'react-paginate'
import {
  deleteContentItem,
  imageUpload,
  addContentItem,
} from '../../../../redux/actions/contentAction'

const itemsPerPage = 10

const EditCoverageOptionsList = ({
  setShowModal,
  showModal,
  handleYes,
  section6,
  setSection6,
  searchListArr,
  setSearchListArr,
}) => {
  const [loading, setLoading] = useState(false)
  const [uploadImagePreview, setUploadImagePreview] = useState('')
  const [uploadImage, setUploadImage] = useState(null)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [currentItems, setCurrentItems] = useState(null)

  const [title, setTitle] = useState({ value: '', error: '' })
  const [description, setDescription] = useState({ value: '', error: '' })
  const [linkLabel, setLinkLabel] = useState({ value: '', error: '' })
  const [link, setLink] = useState({ value: '', error: '' })

  const [editIndex, setEditIndex] = useState(-1)

  const onDrop = useCallback((acceptedFiles) => {
    encodeImageFileAsURL(acceptedFiles)
  }, [])
  const encodeImageFileAsURL = (files) => {
    var file = files[0]
    setUploadImage(file)
    var reader = new FileReader()
    reader.onloadend = function () {
      if (reader.result) {
        setUploadImagePreview(reader.result)
      }
    }
    reader.readAsDataURL(file)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg,image/png',
    onDrop,
  })

  const handleOnSubmit = () => {
    if (title.value == '') {
      setTitle({ ...title, error: 'Please enter Title!' })
      return
    }
    if (description.value == '') {
      setDescription({ ...description, error: 'Please enter description!' })
      return
    }
    if (linkLabel.value == '') {
      setLinkLabel({ ...linkLabel, error: 'Please enter label for link!' })
      return
    }
    if (link.value == '') {
      setLink({ ...link, error: 'Please enter link!' })
      return
    }
    if (!uploadImage && !uploadImagePreview) {
      setUploadImage({ ...link, error: 'Please enter link!' })
      return
    }
    clearErrors()
    // setLoading(true)
    if (uploadImage) {
      imageUpload(uploadImage).then((res) => {
        if (res.data.status) {
          setUploadImagePreview(res.data.path)
          let content = {
            image: res.data.path,
            title: title.value,
            description: description.value,
            linkLabel: linkLabel.value,
            link: link.value,
          }
          if (editIndex > -1) {
            let temp = [...section6]
            temp[editIndex] = content
            setSection6(temp)
            handleYes(temp)
          } else {
            let temp = [...section6]
            temp.push(content)
            setSection6(temp)
            handleYes(temp)
          }
        }
      })
    } else {
      let content = {
        image: uploadImagePreview,
        title: title.value,
        description: description.value,
        linkLabel: linkLabel.value,
        link: link.value,
      }
      if (editIndex > -1) {
        let temp = [...section6]
        temp[editIndex] = content
        setSection6(temp)
        handleYes(temp)
      }
    }
  }
  const onAdd = (e) => {
    e.preventDefault()
    // setLoading(true);
    handleOnSubmit()
  }

  const onEdit = (item, index) => {
    // e.preventDefault();
    // setLoading(true);
    setEditIndex(index)
    setTitle({ ...title, value: item.title })
    setDescription({ ...description, value: item.description })
    setLink({ ...link, value: item.link })
    setLinkLabel({ ...linkLabel, value: item.linkLabel })
    setUploadImagePreview(item.image)
  }
  const clear = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditIndex(-1)
    setShowModal(false)
    // handleOnSubmit()
  }

  const closeModal = () => {
    resetAll()
    setShowModal(false)
  }
  const resetAll = () => {
    setEditIndex(-1)
    setTitle({ ...title, value: '', error: '' })
    setDescription({ ...description, value: '', error: '' })
    setLinkLabel({ ...linkLabel, value: '', error: '' })
    setLink({ ...link, value: '', error: '' })
    setUploadImagePreview('')
    setUploadImage(null)
  }
  const clearErrors = () => {
    setEditIndex(-1)
    setTitle({ ...title, value: '', error: '' })
    setDescription({ ...description, value: '', error: '' })
    setLinkLabel({ ...linkLabel, value: '', error: '' })
    setLink({ ...link, value: '', error: '' })
    setUploadImagePreview('')
    setUploadImage(null)
  }

  const onItemDelete = (item, index) => {
    if (window.confirm('Are You Sure to Delete?')) {
      let temp = [...section6]
      temp.splice(index, 1)
      setSection6(temp)
      handleYes(temp)
    }
  }
  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>
              Edit Coverage Options Details
            </h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => closeModal()}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '50%' }}
                    required={true}
                    value={title.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.name.value = e.target.value
                      // setEditData(temp)
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={title.error}
                  />
                  <FormInputComponent
                    heading='Description:'
                    style={{ width: '50%' }}
                    type={'textarea'}
                    required={true}
                    value={description.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.email.value = e.target.value
                      // setEditData(temp)
                      setDescription({ error: '', value: e.target.value })
                    }}
                    errorMessage={description.error}
                  />
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Link Label:'
                    style={{ width: '80%' }}
                    value={linkLabel.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setLinkLabel({ error: '', value: e.target.value })
                    }}
                    errorMessage={linkLabel.error}
                  />
                  <FormInputComponent
                    heading='Link:'
                    style={{ width: '80%' }}
                    value={link.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setLink({ error: '', value: e.target.value })
                    }}
                    errorMessage={link.error}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right3'>
                <label>Upload Icon</label>
                <div
                  className='upload_border'
                  {...getRootProps()}
                  style={{ marginTop: '-1rem' }}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  {uploadImagePreview == '' ? (
                    <svg className='modal_upload__icon-dark'>
                      <use xlinkHref='/sprite.svg#icon-cloud' />
                    </svg>
                  ) : (
                    <img
                      height={120}
                      src={uploadImagePreview}
                      style={{ marginTop: '-5rem' }}></img>
                  )}
                </div>

                <div
                  className='modal_dark_buttons'
                  style={{ padding: '0.5rem' }}>
                  <button
                    type='button'
                    className='modal__button-reset-dark'
                    onClick={resetAll}>
                    Reset
                  </button>
                  <button
                    type='button'
                    className='modal__button-dark'
                    onClick={handleOnSubmit}>
                    {loading ? 'Saving..' : editIndex > -1 ? 'Save' : 'Add'}
                  </button>
                </div>
              </div>
            </div>
            <div className='modal_inner_content'>
              <div className='modal_form_container'>
                <table
                  className='modal__form-table'
                  cellspacing='0'
                  cellpadding='0'>
                  <thead className='modal__form-sticky'>
                    <tr className='modal__form-tr'>
                      <th>No.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Label</th>
                      <th>Link</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {section6 &&
                      section6.length &&
                      section6.map((item, i) => {
                        return (
                          <tr className='modal__form-tr2' key={i}>
                            <td>{i + 1}</td>
                            <td>{item.title}</td>
                            <td>{item.description}</td>
                            <td>{item.linkLabel}</td>
                            <td>{item.link}</td>
                            <td className='modal__form-buttons'>
                              <button
                                type='button'
                                className='modal__button-edit'
                                onClick={(e) => onEdit(item, i)}>
                                edit
                              </button>
                              <button
                                type='button'
                                className='modal__button-delete'
                                onClick={(e) => onItemDelete(item, i)}>
                                delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* <div className='modal_dark_buttons' style={{ paddingTop: '0' }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={(e) => handleOnSubmit()}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal>
  )
}

export default EditCoverageOptionsList
