import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'

const EditInsurancePremiereType = ({
  setShowModal,
  showModal,
  handleYes,
  section7,
  setSection7,
}) => {
  const [loading, setLoading] = useState(false)

  const [title, setTitle] = useState({ value: '', error: '' })
  const [description, setDescription] = useState({ value: '', error: '' })

  const [editIndex, setEditIndex] = useState(-1)

  useEffect(() => {}, [])

  const resetAll = () => {
    setTitle({ value: '', error: '' })
    setDescription({ value: '', error: '' })
    setEditIndex(-1)
  }

  const onEdit = (item, index) => {
    setEditIndex(index)
    setTitle({
      ...title,
      value: item.title.value,
    })
    setDescription({
      ...description,
      value: item.description.value,
    })
  }

  const onDelete = (item, index) => {
    if (window.confirm('Are You Sure to Delete?')) {
      let temp = [...section7]
      temp.splice(index, 1)
      setSection7(temp)
      handleYes(temp)
    }
  }

  const onAdd = () => {
    let valid = true

    if (title.value === '' && title.value.trim() === '') {
      setTitle({
        ...title,
        error: 'Please enter title',
      })
      valid = false
    }
    if (description.value === '' && description.value.trim() === '') {
      setDescription({
        ...description,
        error: 'Please enter description',
      })
      valid = false
    }

    if (valid) {
      let content = {
        title: { value: title.value, action: '', type: '' },
        description: {
          value: description.value,
        },
      }

      if (editIndex > -1) {
        const temp = [...section7]
        temp[editIndex] = content

        console.log('updated', temp)
        setSection7(temp)
        handleYes(temp)
      } else {
        const temp = [...section7]
        temp.push(content)
        setSection7(temp)
        handleYes(temp)

        console.log('added', temp)
      }
    }
  }

  return (
    <Modal show={showModal}>
      <form>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>
              Edit Auto Insurance Premier Type
            </h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={title.value}
                    onChange={(e) => {
                      setTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={title.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Description:'
                    style={{ width: '80%' }}
                    type={'textarea'}
                    required={true}
                    value={description.value}
                    onChange={(e) => {
                      setDescription({
                        error: '',
                        value: e.target.value,
                      })
                    }}
                    errorMessage={description.error}
                  />
                </div>
              </div>
              <div
                className='modal_inner_content--right'
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}>
                <div
                  className='modal_dark_buttons'
                  style={{ padding: '0.5rem' }}></div>
                <div
                  style={{
                    display: 'flex',
                    gap: 30,
                  }}>
                  <button
                    type='button'
                    className='modal__button-reset-dark'
                    onClick={resetAll}>
                    Reset
                  </button>
                  <button
                    type='button'
                    className='modal__button-dark'
                    onClick={onAdd}>
                    {loading ? 'Saving..' : editIndex > -1 ? 'Save' : 'Add'}
                  </button>
                </div>
              </div>
            </div>
            <div className='modal_inner_content'>
              <div className='modal_form_container'>
                <table
                  className='modal__form-table'
                  cellSpacing='0'
                  cellPadding='0'>
                  <thead className='modal__form-sticky'>
                    <tr className='modal__form-tr'>
                      <th>No.</th>
                      <th>Title</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {section7 &&
                      section7.length > 0 &&
                      section7.map((item, i) => {
                        return (
                          <tr className='modal__form-tr2' key={i}>
                            <td>{i + 1}</td>
                            <td>{item.title.value}</td>
                            <td>{item.description.value}</td>
                            <td className='modal__form-buttons'>
                              <button
                                type='button'
                                className='modal__button-edit'
                                onClick={(e) => onEdit(item, i)}>
                                edit
                              </button>
                              <button
                                type='button'
                                className='modal__button-delete'
                                onClick={(e) => onDelete(item, i)}>
                                delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* <div className='modal_dark_buttons'>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal>
  )
}

export default EditInsurancePremiereType
