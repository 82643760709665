import React from "react";
import styles from "./LinkChip.module.css";

const GenricLinkChip = ({ title, link }) => {
 const downloadPdf = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = fileName;
      downloadLink.click();
      URL.revokeObjectURL(downloadLink.href);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  const handleDownload = () => {
    const fileName = `${title}.pdf`;
    downloadPdf(link, fileName);
  };

  return (
    <div onClick={handleDownload} className={styles.chipContainer}>
      <label style={{ fontSize: "1.6rem", cursor: "pointer" }}>{`» ${title}`}</label>
      <div style={{ marginBottom: "-2rem" }}>
        <svg className={styles.link_button}>
          <use style={{ marginBottom: "-2rem" }} xlinkHref="/sprite.svg#icon-link-close-button" />
        </svg>
      </div>
    </div>
  );
};

export default GenricLinkChip;