import React from 'react';
import { NavLink } from 'react-router-dom';

const TopicCard = ({ topic, value, onClick, selected = false }) => {


    return (
        <NavLink
            key={topic}
            className={`learning__filters--btn heading_3 ${selected ? 'learning__filters--active' : ''}`}
            to="#"
            onClick={(e) => {
                e.preventDefault()
                onClick(value)
            }}
            style={{ textDecoration: 'none' }}
        >
            {topic}
        </NavLink>
    );
};

export default TopicCard;
