import React, { Fragment, useEffect, useState } from 'react';
import Content from '../../../components/Content/Content';
import './ContactUs.css';
import FormInputComponent from '../../../components/FormInputComponent/FormInputComponent';
import { validateEmail, validateOnlyChacters, validateOnlyNumber, validatePhone, validateZipCode } from '../utils/validators';
import { contactUsAction, contactUsActionAPI } from '../../../redux/actions/mailActions';
import { useDispatch, useSelector } from 'react-redux';
import GoogleMap from '../../../components/GoogleMap/GoogleMap';
import ReaptchaCard from '../../../components/Reaptcha/Reaptcha';
import { CONTACT_US_RESET } from '../../../redux/constants/mailConstants';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useDropzone } from 'react-dropzone';
import { addFormContent, deleteFormField, getFormContent, getPageContent, updateFormContent } from '../../../redux/actions/contentAction';
import { ADMIN_USERS } from '../../../redux/actions/ip';
import { commaSeprator, splitArray, splitArrayTo2D } from '../../../redux/constants/utils';
import EditSection1 from './edit/EditSection1'
import EditSection2 from './edit/EditSection2'
import EditFieldTypeModal from "../StartAQuote/edit/EditFieldTypeModal";
import EditEmailAddressesModal from "../StartAQuote/edit/EditEmailAddresses"
import MultiSelectDropdown from '../../../components/MultiSeclectDropdown/MultiSelectDropdown';
import CheckBox from '../../../components/Inputs/Checkbox';
const google = window.google;

const PAGE_NAME = "Contact";
let fileIndex = [-1, -1];
const ContactUs = () => {
	const dispatch = useDispatch();
	const azureLogin = useSelector((state) => state.azureLogin)
	const [showEdit, setShowEdit] = useState(false);
	const [apiStatus, setApiStatus] = useState(false);
	const [showEmailEdit, setShowEmailEdit] = useState(false);
	const [showSection1, setShowSection1] = useState(false);
	const [showSection2, setShowSection2] = useState(false);
	const [section1, setSection1] = useState({
		lat: { value: 41.30451557903054, action: "", type: "" },
		lng: { value: -105.54504026149297, action: "", type: "" },
	})
	const [section2, setSection2] = useState({
		title: { value: "Contact", action: "", type: "" },
		contact: { value: "(307) 343-1959", action: "", type: "" },
		image: { value: '/images/contact-building.jpg', action: "", type: "" },
		description1: { value: "If you have questions or comments regarding your policy, or its products or services, we first request you contact your Farm Bureau insurance agent. Otherwise, fill out the form to direct your inquiry to Customer Service.", action: "", type: "" },
		description2: { value: `931 Boulder Drive, Laramie, WY 82070`, action: "", type: "" },
	})

	const [showFieldTypeModal, setShowFieldTypeModal] = useState(false);
	const [fieldTypeArr, setFieldTypeArr] = useState([]);
	const [resultArr, setResultArr] = useState([]);
	const [editFormData, setEditFormData] = useState([]);
	const [state, setState] = useState("");
	const [currentInsuranceError, setCurrentInsuranceError] = useState("");
	const onDrop = (files) => {
		// Do something with the files
		var file = files[0];
		if (file) {
			if (fileIndex.length === 2) {
				if (fileIndex[0] > -1 && fileIndex[1] > -1 && resultArr.length) {
					const temp = [...resultArr];
					temp[fileIndex[0]][fileIndex[1]].selectedValue = file;
					setResultArr(temp)
				}
			}
		}
	}
	const onDropRejected = (file) => {
		if (file.length && fileIndex.length === 2) {
			if (fileIndex[0] > -1 && fileIndex[1] > -1 && resultArr.length) {
				const temp = [...resultArr];
				temp[fileIndex[0]][fileIndex[1]].error = file[0].errors.map(item => item.code === 'file-too-large' ? 'File is larger than 5MB' : item.message).join(',');
				setResultArr(temp)
			}
		}
	}
	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/jpeg,image/png,application/pdf', minSize: 0,
		maxSize: 5242880, onDrop, onDropRejected
	})
	const [defaultProps, setDefaultProps] = useState({
		center: {
			lat: 41.30451557903054,
			lng: -105.54504026149297
		},
		zoom: 16,
	});

	const setGoogleMapRef = (map, maps) => {
		const markers = [];
		const infowindows = [];
		if (defaultProps && defaultProps.center && defaultProps.center.lat && map && maps)
			markers.push(
				new maps.Marker({
					animation: maps.Animation.DROP,
					icon: {
						url: '/images/map-marker.png',
						anchor: new google.maps.Point(5, 58),
					},
					position: defaultProps.center,
					map
				})
			);

		// let marker = new maps.Marker({
		// 	animation: maps.Animation.DROP,
		// 	position: {
		// 		lat: 46.43734,
		// 		lng: -109.85292
		// 	}
		// });
	};

	const [recaptchaInstance, SetRecaptchaInstance] = useState('');
	const [preferred, setPreferred] = useState('Phone');
	const [firstName, setFirstName] = useState('');
	const [firstNameError, setFirstNameError] = useState('');
	const [lastName, setLastName] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [policyNumber, setPolicyNumber] = useState('');
	const [policyNumberError, setPolicyNumberError] = useState('');
	const [comments, setComments] = useState('');
	const [commentsError, setCommentsError] = useState('');
	const [verifyRecaptcha, setVerifyRecaptcha] = useState({ value: '', error: '' });

	const contactUs = useSelector((state) => state.contactUs);

	useEffect(
		() => {
			if (contactUs && contactUs.userInfo && contactUs.userInfo.status) {
				alert(contactUs.userInfo.message);
				clearData();
				dispatch({ type: CONTACT_US_RESET })
			} else if (contactUs && contactUs.userInfo && !contactUs.userInfo.status && contactUs.userInfo.message) {
				alert(contactUs.userInfo.message);
				dispatch({ type: CONTACT_US_RESET })
			}
		},
		[contactUs]
	);


	useEffect(() => {
		if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
			if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
				setShowEdit(true)
			} else setShowEdit(false)
		} else setShowEdit(false)
	}, [azureLogin])

	useEffect(() => {
		setDefaultProps({
			...defaultProps, center: {
				lat: Number(section1.lat.value),
				lng: Number(section1.lng.value)
			}
		})
	}, [section1.address])

	useEffect(() => {
		getPageContent({ pageName: PAGE_NAME })
			.then(res => {
				if (res.data.status) {
					console.log(res.data);
					if (res.data.result.Section_1)
						setSection1(res.data.result.Section_1)
					if (res.data.result.Section_2)
						setSection2(res.data.result.Section_2)
					setApiStatus(true)

				}
			})

		getFormContent(PAGE_NAME).then((res) => {
			if (res.data.status) {
				setFieldTypeArr(
					res.data.result.map((item) => ({
						...item,
						selectedValue: item.FieldType === "Multi-Value" ? [] : "",
						error: "",
					}))
				);
				setResultArr(
					splitArrayTo2D(
						res.data.result.map((item) => ({
							...item,
							selectedValue: item.FieldType === "Multi-Value" ? [] : "",
							error: "",
						}))
					)
				);
			}
		})
	}, [])

	const onHandleForm = async (e) => {
		e.preventDefault();
		let formValid = true;
		const temp = resultArr.map((field) => {
			let fieldTemp = [...field];
			fieldTemp = fieldTemp.map((element) => {
				let item = { ...element };
				if (item.RequiredStatus) {
					if (item.selectedValue === "") {
						formValid = false;
						item.error = `Please enter the ${item.FieldLabel}`;
					} else item.error = "";
					if (item.selectedValue.length === 0) {
						formValid = false;
						item.error = `Please enter the ${item.FieldLabel}`;
					} else item.error = "";

					switch (item.ValidationType) {
						case "Only Text":
							if (
								item.selectedValue.length > 0 &&
								validateOnlyChacters(item.selectedValue) === false
							) {
								formValid = false;
								item.error = `Please enter valid ${item.FieldLabel}`;
							}
							return item;
						case "Only Number":
							if (
								item.selectedValue.length > 0 &&
								validateOnlyNumber(item.selectedValue) === false
							) {
								formValid = false;
								item.error = `Please enter valid ${item.FieldLabel}`;
							}
							return item;
						case "Email":
							if (
								item.selectedValue.length > 0 &&
								validateEmail(item.selectedValue) === false
							) {
								formValid = false;
								item.error = `Please enter valid ${item.FieldLabel}`;
							}
							return item;
						case "Phone":
							if (
								item.selectedValue.length > 0 &&
								validatePhone(item.selectedValue) === false
							) {
								formValid = false;
								item.error = `Please enter valid ${item.FieldLabel}`;
							}
							return item;
						case "Zip":
							if (
								item.selectedValue.length > 0 &&
								validateZipCode(item.selectedValue) === false
							) {
								formValid = false;
								item.error = `Please enter valid ${item.FieldLabel}`;
							}
							return item;
						default:
					}
				}
				return item;
			});
			return fieldTemp;
		});
		if (verifyRecaptcha.value === '' && verifyRecaptcha.value.trim() === '') {
			setVerifyRecaptcha({ value: '', error: 'Please verify reCAPTCHA' });
		}
		setResultArr(temp);
		let formData = new FormData();
		if (formValid) {
			let body = [];
			let email = ''
			resultArr.forEach((field) => {
				field.forEach((item) => {
					if (item.FieldLabel === 'email' || item.FieldLabel === 'Email' || item.ValidationType === 'Email')
						email = item.selectedValue
					if (item.FieldInputType === "FileUpload" && item.selectedValue) {
						formData.append('files', item.selectedValue);
						body = [...body, { key: item.FieldLabel, value: item.selectedValue.name, isFile: true }];
					} else
						body = [...body, { key: item.FieldLabel, value: item.selectedValue }];
				});
			});
			formData.append("name", temp.length && temp[0].length ? temp[0][0].selectedValue : "");
			formData.append("from_email", "no-reply@360insuranceco.com");
			formData.append("email", email);
			formData.append("to", 'custservice@mwfbi.com');
			formData.append("subject", "360 Contact Form - 360 Insurance");
			formData.append("body", JSON.stringify(body));
			// dispatch(contactUsAction(formData));
			contactUsActionAPI(formData).then(res => {
				alert(res?.data?.message)
				resetForm()
			}).catch(error => console.log(error.message))
		}

	};
	const clearData = () => {
		setPreferred('Phone');
		setFirstName('');
		setFirstNameError('');
		setLastName('');
		setLastNameError('');
		setEmail('');
		setEmailError('');
		setPhone('');
		setPhoneError('');
		setPolicyNumber('');
		setPolicyNumberError('');
		setComments('');
		setVerifyRecaptcha({ value: '', error: '' });
	}
	const resetForm = () => {
		const temp = resultArr.map((field) => {
			let fieldTemp = [...field];
			fieldTemp = fieldTemp.map((element) => {
				let item = { ...element };
				item.selectedValue = item.FieldType === "Multi-Value" ? [] : "";
				return item;
			});
			return fieldTemp;
		});

		setResultArr(temp);
	}
	// create a reset function
	const resetRecaptcha = () => {
		recaptchaInstance.reset();
	};

	// specifying your onload callback function
	const callback = (response) => {
		console.log('Done!!!!', response);
	};

	// specifying verify callback function
	const verifyCallback = (response) => {
		console.log(response);
		setVerifyRecaptcha(response);
	};
	const handleDynamicFormSubmit = (content) => {
		addFormContent({
			fieldType: content.fieldType,
			fieldInputType: content.fieldInputType,
			fieldLabel: content.fieldLabel,
			fieldValue: content.fieldValue,
			placeHolder: content.placeHolder,
			validationType: content.validationType,
			pageName: PAGE_NAME,
			requiredStatus: content.requiredStatus === "True" ? 1 : 0,
		}).then((res) => {
			if (res.data.status) {
				getFormContent(PAGE_NAME).then((res) => {
					setFieldTypeArr(res.data.result);
					// setResultArr(splitArray(res.data.result));
					setResultArr(
						splitArrayTo2D(
							res.data.result.map((item) => ({
								...item,
								selectedValue: item.FieldType === "Multi-Value" ? [] : "",
								error: "",
							}))
						)
					);
				});
				alert("Field Saved")
			}
		});
	};

	const handleDynamicFormUpdate = (id, content) => {
		updateFormContent(id, {
			fieldType: content.fieldType,
			fieldInputType: content.fieldInputType,
			fieldLabel: content.fieldLabel,
			fieldValue: content.fieldValue,
			placeHolder: content.placeHolder,
			validationType: content.validationType,
			pageName: PAGE_NAME,
			requiredStatus: content.requiredStatus === "True" ? 1 : 0,
		}).then((res) => {
			if (res.data.status) {
				getFormContent(PAGE_NAME).then((res) => {
					setFieldTypeArr(res.data.result);
					// setResultArr(splitArray(res.data.result));
					setResultArr(
						splitArrayTo2D(
							res.data.result.map((item) => ({
								...item,
								selectedValue: item.FieldType === "Multi-Value" ? [] : "",
								error: "",
							}))
						)
					);
				});
				alert("Field Saved")
			}
		});
	};
	const onFieldDelete = (e, item, index) => {
		e.preventDefault();
		console.log(item.ID, "id");
		if (window.confirm("Are You Sure to Delete?")) {
			deleteFormField(item.ID).then((res) => {
				if (res.data.status) {
					getFormContent(PAGE_NAME).then((res) => {
						setResultArr(splitArray(res.data.result));
						setFieldTypeArr(res.data.result);
					});
					alert("Field Deleted")
				}
			});
		}
	};
	const dropDownValues = (item) => {
		let arr_obj = [];
		commaSeprator(item.FieldValue).forEach((data) => {
			let obj = { label: data, value: data };
			arr_obj.push(obj);
		});
		return arr_obj;
	};

	return (
		<Content headerFooterMenu="mountain-west">
			<div style={{ height: '50vh', width: '100%', position: 'relative' }}>
				{apiStatus && <GoogleMap
					defaultProps={defaultProps}
					setGoogleMapRef={setGoogleMapRef}
				>
				</GoogleMap>}
				{showEdit && (
					<div
						className='mwfbi_banner__edit'
						style={{ top: '1rem', marginRight: '4rem' }}
						id='admin-edit'
						onClick={() => setShowSection1(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>
				)}
			</div>

			{apiStatus && <EditSection1
				showModal={showSection1}
				setShowModal={setShowSection1}
				pageName={PAGE_NAME}
				section1={section1}
				setSection1={setSection1}
			/>
			}
			<div className="mwefbicontact_maincontainer">
				<div className="container-mwefbicontact-left">
					<img src={section2?.image.value} alt='contact building' className='contact-building-image' />
					<div className="container-mwefbicontact-left-contact">
						<div className="heading_1">
							<label className="heading_contact_1" style={{ color: '#fff' }}>{section2?.title.value}</label>
						</div>
						<p className="contactUs__desc">
							{section2?.description1.value}
						</p>
						<p className="contactUs__desc">
							{section2?.description2.value}<br /><br />

							<span className="contact-mwfbi">
								<svg className="contact-mwfbi--icon">
									<use xlinkHref="/sprite.svg#icon-phone" />
								</svg>{section2?.contact.value}
							</span>
						</p>
					</div>
					{showEdit && (
						<div
							className='mwfbi_banner__edit'
							style={{ top: '1rem' }}
							id='admin-edit'
							onClick={() => setShowSection2(true)}>
							<svg>
								<use xlinkHref='/sprite.svg#icon-edit' />
							</svg>
						</div>
					)}

					{<EditSection2
						showModal={showSection2}
						setShowModal={setShowSection2}
						section2={section2}
						setSection2={setSection2}
						pageName={PAGE_NAME}
					/>
					}
				</div>
				<div className="container-mwefbicontact--right" style={{ position: 'relative' }}>
					<form onSubmit={onHandleForm}>
						<div className="main_outer_component12">
							<div>
								<label className="heading_contact_1">Send us a message</label>
							</div>

							{resultArr.map((field, parentIndex) => {
								return (
									<div
										key={'parent' + parentIndex}
										style={{
											display: "flex",
											width: "100%",
											justifyContent: "space-between",
										}}
									>
										{field?.map((item, index) => {
											switch (item?.FieldInputType) {
												case "Textbox":
													return (
														<div
															key={index}
															style={{ width: "48%", marginBottom: "3rem" }}
														>
															<FormInputComponent
																heading={
																	item.FieldLabel !== "undefined"
																		? item.FieldLabel
																		: null
																}
																placeholder={
																	item.PlaceHolder ? item.PlaceHolder : null
																}
																style={{ width: "100%" }}
																requiredStar={
																	item.FieldLabel !== "undefined"
																		? item.RequiredStatus
																		: false
																}
																value={item.selectedValue}
																onChange={(e) => {
																	let temp = [...resultArr];
																	temp[parentIndex][index].selectedValue =
																		e.target.value;
																	setResultArr(temp);
																}}
																errorMessage={currentInsuranceError}
															/>
															<p className="form_input__error">{item.error}</p>
														</div>
													);
												case "Date":
													return (
														<div
															key={index}
															style={{ width: "48%", marginBottom: "3rem" }}
														>
															<FormInputComponent
																heading={item.FieldLabel !== "undefined" ? item.FieldLabel : null}
																type={"date"}
																placeholder={
																	item.PlaceHolder ? item.PlaceHolder : null
																}
																style={{ width: "100%" }}
																requiredStar={
																	item.FieldLabel !== "undefined"
																		? item.RequiredStatus
																		: false
																}
																value={item.selectedValue}
																onChange={(e) => {
																	let temp = [...resultArr];
																	temp[parentIndex][index].selectedValue =
																		e.target.value;
																	setResultArr(temp);
																}}
																errorMessage={currentInsuranceError}
															/>
															<p className="form_input__error">{item.error}</p>
														</div>
													);
												case "Textarea":
													return (
														<div
															key={index}
															style={{ width: "100%", marginBottom: "2rem" }}
														>
															<FormInputComponent
																height="140px"
																type={"textarea"}
																requiredStar={
																	item.FieldLabel !== "undefined"
																		? item.RequiredStatus
																		: false
																}
																heading={
																	item.FieldLabel !== "undefined"
																		? item.FieldLabel
																		: null
																}
																placeholder={
																	item.PlaceHolder ? item.PlaceHolder : null
																}
																style={{ width: "100%" }}
																// required={
																//   item.FieldLabel !== "undefined"
																//     ? item.RequiredStatus
																//     : false
																// }
																value={item.selectedValue}
																onChange={(e) => {
																	let temp = [...resultArr];
																	temp[parentIndex][index].selectedValue =
																		e.target.value;
																	setResultArr(temp);
																}}
																errorMessage={currentInsuranceError}
															/>
															<p className="form_input__error">{item.error}</p>
														</div>
													);

												case "Select":
													return (
														<div key={index} style={{ width: "48%" }}>
															<label
																htmlFor="State"
																className="label_heading_quote"
															>
																{item.FieldLabel}
																{item.RequiredStatus ? (
																	<span className="red_star">&#42;</span>
																) : null}
															</label>
															<div>
																<select
																	id="State"
																	name="State"
																	style={{ width: "100%", marginBottom: 0 }}
																	className="select__state__textbox1"
																	value={state}
																	onChange={(e) => {
																		setState(e.target.value);
																		let temp = [...resultArr];
																		temp[parentIndex][index].selectedValue =
																			e.target.value;
																	}}
																>
																	<option value="">Select</option>
																	{commaSeprator(item.FieldValue).map((value) => (
																		<option value={value}>{value}</option>
																	))}
																</select>
															</div>
															<p className="form_input__error">{item.error}</p>
														</div>
													);

												case "Checkbox":
													return (
														<div
															style={{ marginBottom: "2rem" }}
															key={index}
															className="label_heading_quote"
														>
															<label className="label_heading_quote">
																{item.FieldLabel}
																{item.RequiredStatus ? (
																	<span className="red_star">&#42;</span>
																) : null}
															</label>
															<br />
															{commaSeprator(item.FieldValue).map((value) => (
																<Fragment key={value}>
																	<input
																		type="checkbox"
																		id={value + "checkbox"}
																		// name={item.title}
																		style={{ marginRight: "2rem" }}
																		// checked={item.selectedValue.indexOf(value) > 0}
																		onChange={(e) => {
																			let temp = [...resultArr];
																			if (e.target.checked)
																				temp[parentIndex][index].selectedValue.push(
																					value
																				);
																			else {
																				temp[parentIndex][
																					index
																				].selectedValue.splice(
																					temp[parentIndex][
																						index
																					].selectedValue.indexOf(value),
																					1
																				);
																			}
																			setResultArr(temp);
																		}}
																	/>
																	<label
																		className="label_heading_quote"
																		htmlFor="phone"
																		style={{ marginRight: "2rem" }}
																	>
																		{value}
																	</label>
																</Fragment>
															))}
															<p className="form_input__error">{item.error}</p>
														</div>
													);

												case "Chips":
													return (
														<div
															key={index}
															style={{ marginBottom: "2rem" }}
															className="Insurance__list"
														>
															<label className="label_heading_quote">
																{item.FieldLabel}
																{item.RequiredStatus ? (
																	<span className="red_star">&#42;</span>
																) : null}
															</label>
															<div className="list__of__contents">
																<div className="list__of__contents2">
																	<ul>
																		{commaSeprator(item.FieldValue).map(
																			(value) => (
																				<li
																					/* key={value} */
																					className={`list__of__contents1 ${item.selectedValue?.includes(value)
																						? "list__of__contents1_active"
																						: ""
																						}`}
																					onClick={() => {
																						let temp = [...resultArr];

																						if (
																							item.selectedValue?.includes(value)
																						) {
																							temp[parentIndex][
																								index
																							].selectedValue.splice(
																								item.selectedValue.indexOf(value),
																								1
																							);
																						} else {
																							if (!temp[parentIndex][index].selectedValue)
																								temp[parentIndex][index].selectedValue = []
																							temp[parentIndex][index].selectedValue = [...temp[parentIndex][index].selectedValue, value,
																							];
																						}
																						setResultArr(temp);
																					}}
																				>
																					{value}
																				</li>
																			)
																		)}
																	</ul>
																</div>
															</div>

															<p className="form_input__error">{item.error}</p>
														</div>
													);

												case "Switch":
													return (
														<div key={index} style={{ marginTop: "2.4rem" }}>
															<label className="label_heading_quote">
																{item.FieldLabel}
																{item.RequiredStatus ? (
																	<span className="red_star">&#42;</span>
																) : null}
															</label>
															<CheckBox
																text={
																	item.selectedValue ===
																		commaSeprator(item.FieldValue)[1]
																		? commaSeprator(item.FieldValue)[1]
																		: commaSeprator(item.FieldValue)[0]
																}
																key="b2"
																/* checked={button2Status.value} */
																onChange={(e) => {
																	let temp = [...resultArr];
																	if (e.target.checked) {
																		console.log(e.target.value, "check");
																		temp[parentIndex][index].selectedValue =
																			commaSeprator(item.FieldValue)[1];
																	} else {
																		console.log("anil");
																		temp[parentIndex][index].selectedValue =
																			commaSeprator(item.FieldValue)[0];
																	}
																	setResultArr(temp);
																}}
															/>
															<p className="form_input__error">{item.error}</p>
														</div>
													);

												case "Radio":
													return (
														<div key={index} style={{ marginTop: "2.4rem" }}>
															<label className="label_heading_quote">
																{item.FieldLabel}
																{item.RequiredStatus ? (
																	<span className="red_star">&#42;</span>
																) : null}
															</label>
															<br />
															{commaSeprator(item.FieldValue).map((item) => (
																<>
																	<input
																		type="radio"
																		name="radioValue"
																		id={item}
																		value={item}
																		onChange={(e) => {
																			/* setRadioValue(e.target.value) */
																			console.log(e.target.value);
																			let temp = [...resultArr];
																			// eslint-disable-next-line eqeqeq
																			if (e.target.checked == true) {
																				console.log(e.target.checked, "check");
																				temp[parentIndex][index].selectedValue =
																					item;
																			} else {
																				console.log(
																					temp[parentIndex][index].selectedValue
																				);
																				temp[parentIndex][index].selectedValue = "";
																			}
																			setResultArr(temp);
																		}}
																	/>
																	<label
																		className="label_heading_quote"
																		htmlFor="phone"
																		style={{
																			marginLeft: "2rem",
																			marginRight: "2rem",
																		}}
																	>
																		{item}
																	</label>
																</>
															))}
															<p className="form_input__error">{item.error}</p>
														</div>
													);

												case "MultiSelect":
													return (
														<div
															key={index}
															style={{
																marginTop: "2.4rem",
																marginBottom: "3.2rem",
															}}
														>
															<label className="label_heading_quote">
																{item.FieldLabel}
																{item.RequiredStatus ? (
																	<span className="red_star">&#42;</span>
																) : null}
															</label>
															<br />

															<MultiSelectDropdown
																/* className="multi_dropdown_start_a_quote" */
																options={dropDownValues(item)}
																placeHolder={item.PlaceHolder && item.PlaceHolder}
																onChange={(e) => {
																	let vals = [];
																	console.log(e, "anil");
																	e.map((val) => {
																		vals = [...vals, val.value];
																	});
																	console.log("985", vals);
																	let temp = [...resultArr];
																	temp[parentIndex][index].selectedValue = vals;
																	setResultArr(temp);
																}}
															/>

															<p className="form_input__error">{item.error}</p>
														</div>
													);

												case "FileUpload":
													return (
														<div
															key={'upload' + parentIndex + index + item.FieldLabel}
															id={'upload' + parentIndex + index + item.FieldLabel}
															style={{
																width: "100%",
																marginTop: "2.4rem",
																marginBottom: "3.2rem",
															}}
														>
															<label key={'uploadhead' + parentIndex + index} className="label_heading_quote">
																{item.FieldLabel}
																{item.RequiredStatus ? (
																	<span className="red_star">&#42;</span>
																) : null}
															</label>
															<div key={'uploadClick' + parentIndex + index} className="upload_border" {...getRootProps({
																onClick: (e) => {
																	fileIndex = [parentIndex, index]
																}
															})}>
																<input key={'uploadKey' + parentIndex + index} {...getInputProps()} />
																<label key={'uploadplca' + parentIndex + index}>{item.PlaceHolder ? item.PlaceHolder : 'Click or Drag a file to this area to upload'}</label>
															</div>
															{(
																item &&
																item.selectedValue && <label>{item.selectedValue.name}</label>
															)}
															{(
																item &&
																item.error && <p key={'upliaderror' + parentIndex + index} className="form_input__error">{item.error}</p>
															)}
														</div>)
												default:
													return null
											}
										})}

									</ div >

								)
							})}

							<ReaptchaCard data={{ recaptchaInstance, SetRecaptchaInstance, resetRecaptcha, callback, verifyCallback }} />

							{verifyRecaptcha && verifyRecaptcha.error && <p className="form_input__error">{verifyRecaptcha.error}</p>}

							<div style={{ position: 'relative' }}>
								<button className="main__form_button" type="submit" >
									Submit
								</button>
								{showEdit && (
									<div
										className='mwfbi_banner__edit'
										id='admin-edit'
										style={{ marginTop: '-8rem' }}
										onClick={() => setShowEmailEdit(true)}>
										<svg>
											<use xlinkHref='/sprite.svg#icon-edit' />
										</svg>
									</div>
								)}
							</div>
						</div>
					</form>

					{showEdit && (
						<div
							className="mwfbi_banner__edit"
							style={{ top: "2rem" }}
							id="admin-edit"
							onClick={() => setShowFieldTypeModal(true)}
						>
							<svg>
								<use xlinkHref="/sprite.svg#icon-edit" />
							</svg>
						</div>
					)}
				</div>
			</div>
			<EditEmailAddressesModal
				showModal={showEmailEdit}
				setShowModal={setShowEmailEdit}
				page={PAGE_NAME}
			/>
			<EditFieldTypeModal
				handleYes={handleDynamicFormSubmit}
				handleYes1={handleDynamicFormUpdate}
				showModal={showFieldTypeModal}
				setShowModal={setShowFieldTypeModal}
				editFormData={editFormData}
				setEditFormData={setEditFormData}
				fieldTypeArr={fieldTypeArr}
				onFieldDelete={onFieldDelete}
				setFieldTypeArr={setFieldTypeArr}
			/>
		</Content >
	);
};

export default ContactUs;
