import React, { useState, useCallback } from "react";
import Modal from "../../../DarkModal";

import Styles from './EditFirstSection.module.css';
import FormInputComponent from "../../../FormInputComponent/FormInputComponent";
import { useDropzone } from "react-dropzone";

const EditFirstSection = ({ sectionArray, onSectionSave, showSection1Modal, setShowSection1Modal }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [sectionTitle, setSectionTitle] = useState({ value: "", error: "" });
    const [title, setTitle] = useState({ value: "", error: "" });
    const [uploadImagePreview, setUploadImagePreview] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [link, setLink] = useState({ value: "", error: "" });
    const [uploadImage, setUploadImage] = useState(null);
    const [token, setToken] = useState({ value: false, error: '' })

    const encodeImageFileAsURL = (files) => {
        var file = files[0];
        setUploadImage(file);
        var reader = new FileReader();
        reader.onloadend = function () {
            if (reader.result) {
                setUploadImagePreview(reader.result);
            }
        };
        reader.readAsDataURL(file);
    };

    const onDrop = useCallback((acceptedFiles) => {
        encodeImageFileAsURL(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/jpeg,image/png",
        onDrop,
    });


    const onSectionChange = (e) => {
        e.preventDefault();
        if (e.target.value !== '') {
            console.log(sectionArray[e.target.value]);
            setSelectedIndex(parseInt(e.target.value))
            setTitle({ ...title, value: sectionArray[e.target.value].title })
            setLink({ ...link, value: sectionArray[e.target.value].link })
            setUploadImagePreview(sectionArray[e.target.value]?.imageUrl)
            setSectionTitle({ ...sectionTitle, value: sectionArray[e.target.value]?.title, error: null })
            setToken({ ...token, value: sectionArray[e.target.value].hasOwnProperty("hasToken") ? sectionArray[e.target.value].hasToken : false, error: null })
        }
        else {
            setSectionTitle({ ...title, value: '' })
            setTitle({ ...title, value: '' })
            setToken({ ...token, value: false })
            setUploadImagePreview('')
        }
    }

    const onSave = useCallback(async () => {
        if (sectionTitle.value === '') {
            setSectionTitle({ ...sectionTitle, error: 'Please select a section' })
            return;
        }
        if (title.value === '') {
            setSectionTitle({ ...sectionTitle, error: 'Please enter a title' })
            return;
        }
        let temporaryTemp = [...sectionArray]
        temporaryTemp[selectedIndex].sectionTitle = sectionTitle.value;
        temporaryTemp[selectedIndex].title = title.value;
        temporaryTemp[selectedIndex].link = link.value;
        temporaryTemp[selectedIndex].hasToken = token.value;
        temporaryTemp[selectedIndex].imageUrl = uploadImagePreview;
        onSectionSave(temporaryTemp);
    }, [link.value, onSectionSave, sectionArray, sectionTitle, selectedIndex, title.value, token.value, uploadImagePreview])

    return (
        <Modal show={showSection1Modal}>
            <form>
                <div className="modal__container-dark">
                    <div className="modal_heading__container-dark">
                        <h1 className="modal_heading__text-dark">
                            Edit Section 1
                        </h1>
                        <button
                            className="modal__heading--button-dark"
                            onClick={() => {
                                setSectionTitle({ ...title, value: '' })
                                setTitle({ ...title, value: '' })
                                setLink({ ...link, value: '' })
                                setToken({ ...token, value: false })
                                setUploadImagePreview('')
                                setShowSection1Modal(false)
                            }}
                        >
                            <svg className="modal_heading__icon-dark">
                                <use xlinkHref="/sprite.svg#icon-cross" />
                            </svg>
                        </button>
                    </div>
                    <div className={"modal__form-dark"} style={{ display: 'flex' }}>
                        <div className="modal_inner_content">
                            <div className={Styles.modalContainer}>
                                <label className={Styles.title}>
                                    Select Section <span className="red_star">&#42;</span>
                                </label>
                                <select
                                    id="selectstate"
                                    name="selectstate"
                                    className={`${Styles.agentlanding_selectbox} select__state__textbox`}
                                    onChange={onSectionChange}
                                    // value={step1Selection && step1Selection.index}
                                    style={{ width: '100%', marginBottom: '1rem' }}
                                >
                                    <option value="">Select</option>
                                    {sectionArray.map((item, index) =>
                                        item.title.length > 0 ?
                                            <option
                                                key={index}
                                                value={index}
                                                id="">
                                                {item.title}
                                            </option> : null)
                                    }
                                </select>{" "}
                                <p style={{ marginBottom: '0.8rem' }} className="form_input__error">{sectionTitle.error && sectionTitle.error}</p>

                                <div className={Styles.inputContainer}>
                                    <div className={Styles.section1Container}>
                                        <FormInputComponent
                                            name="Section"
                                            headingStyle={Styles.inputTitle}
                                            height={"5rem"}
                                            style={{ width: '100%' }}
                                            heading={"Title"}
                                            value={title.value}
                                            // errorMessage={title.error && title.error}
                                            onChange={(e) => {
                                                setTitle({ error: '', value: e.target.value })
                                            }}
                                        />
                                        <FormInputComponent
                                            name="Section"
                                            headingStyle={Styles.inputTitle}
                                            height={"5rem"}
                                            style={{ width: '100%', marginTop: '1rem' }}
                                            heading={"Link"}
                                            value={link.value}
                                            // errorMessage={title.error && title.error}
                                            onChange={(e) => {
                                                setLink({ error: '', value: e.target.value })
                                            }}
                                        />
                                        <FormInputComponent
                                            style={{ display: 'flex', flexDirection: "column", alignItems: 'flex-start' }}
                                            headingStyle={Styles.inputHeading}
                                            height={"2rem"}
                                            type={'checkbox'}
                                            checked={token.value}
                                            value={token.value}
                                            heading={"Want to send token?"}
                                            onChange={(e) => {
                                                setToken({ error: '', value: !token.value })
                                            }}
                                            errorMessage={token.error}
                                        />
                                        <div className={Styles.modal_inner_content_right_landing}>
                                            <label
                                                style={{
                                                    fontSize: "1.4rem",
                                                    marginTop: "1rem",
                                                    color: "#808080",
                                                }}
                                                className={Styles.title}
                                            >
                                                Upload the image
                                            </label>
                                            <div
                                                className="upload_border"
                                                {...getRootProps()}
                                                style={{ marginTop: "-1rem", width: "100%" }}
                                            >
                                                <input {...getInputProps()} />
                                                <>
                                                    <svg className="modal_upload__icon-dark">
                                                        <use xlinkHref="/sprite.svg#icon-cloud" />
                                                    </svg>
                                                    <label style={{ fontSize: '1.4rem' }}>Please choose image to upload</label>
                                                </>
                                            </div>
                                        </div>
                                    </div>
                                    {uploadImagePreview && <div className={Styles.sectionContainer}>
                                        <div className={Styles.imagePreview}>
                                            {<img
                                                alt="upload icon"
                                                height={200}
                                                width={200}
                                                src={uploadImagePreview}
                                                style={{ marginTop: "0rem" }}
                                            />}
                                        </div>
                                    </div>}
                                </div>

                            </div>
                        </div>
                        <button
                            style={{
                                alignSelf: 'flex-end',
                                marginTop: '8px',
                                marginRight: '12px',
                            }}
                            type="button"
                            className="modal__button-dark"
                            onClick={onSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}

export default EditFirstSection;