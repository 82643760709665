import React from "react";
import { Link } from "react-router-dom";
import Banner from "../../../components/Banner/Banner";
import Content from "../../../components/Content/Content";
import "./index.css";

const InternalLandingScreen = () => {
  return (
    <Content headerFooterMenu="mountain-west">
      <Banner
        backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/older-couple-beach-sunset.jpg)"
        pageName={"Landing"}
      />
      <div className="quote3">
        <div className="flex1">
          <h3 className="quote__text3">Agent Information Hub</h3>
        </div>
      </div>
      <div className="main_view">
        <div className="icon_grid">
          <div className="icon_division">
            <img
              src="/images/ProcessedClaim-icon.png"
              alt="Icon"
              className="icon"
            />
            <span className="division_text">PRODUCER ENGAGE</span>
          </div>
          <div className="icon_division">
            <img
              src="/images/Communication-icon.png"
              alt="Icon"
              className="icon"
            />
            <span className="division_text">COMMISSIONS DASHBOARD</span>
          </div>
          <div className="icon_division">
            <img src="/images/FileClaim-icon.png" alt="Icon" className="icon" />
            <span className="division_text">START A QUOTE</span>
          </div>
        </div>
        <div className="options_menu">
          <div className="option_division">
            <div className="option_section">
              <span>Business Tools</span>
              <a href="!#">» New Policy Browser</a>
              <a href="!#">» Infoweb Policy Document</a>
              <a href="!#">» Payment on New Business</a>
              <a href="!#">» Policy Payment</a>
              <a href="!#">» Brokerage Commissions</a>
            </div>
            <div className="option_section">
              <span>Licensing Information</span>
              <a href="!#">» Contact Mountain West</a>
              <a href="!#">» Find an Agent</a>
              <a href="!#">» Start A Quote</a>
              <a href="!#">» FAQ</a>
            </div>
            <div className="option_section">
              <span>Important Links</span>
              <a href="!#">» Homefield</a>
              <a href="!#">» Lexis Nexis</a>
              <a href="!#">» AS400</a>
              <a href="!#">» Workfront</a>
              <a href="!#">» Meetings Website</a>
              <a href="!#">» Sales and Marketing Website</a>
              <a href="!#">» Company Store Website</a>
            </div>
          </div>
          <div className="option_division">
            <div className="option_section">
              <span>Claims Resources</span>
              <a href="!#">» New Policy Browser</a>
              <a href="!#">» Infoweb Policy Document</a>
              <a href="!#">» Payment on New Business</a>
              <a href="!#">» Policy Payment</a>
              <a href="!#">» Brokerage Commissions</a>
            </div>
            <div className="option_section customer_section">
              <span>Contact Customer Service</span>
              <a href="!#">Two ways to contact Customer Service?</a>
            </div>
            <div className="agent_call">
              <span>Call Customer Service Agent Line:</span>
              <span>1-800-814-6623</span>
            </div>
            <div className="agent_call">
              <span>Download & Submit</span>
              <span>Customer Service Request Form:</span>
            <img className="form_image" src="/Images/customer-form.png" />
            </div>
          </div>
          <div className="option_division">
            <div className="option_section">
              <span>State Sales Directors</span>
              <a href="!#">» Auto Insurance</a>
              <a href="!#">» Home Insurance</a>
              <a href="!#">» Farm/Ranch Insurance</a>
              <a href="!#">» Business Insurance</a>
            </div>
            <div className="document_archive agent_call">
            <img className='search_doc' src="/Images/search_doc.png" />
              <strong>Document Archive</strong>
              <p>Search Documents</p>
              <input></input>
            </div>
            <div className="option_section">
              <span>Last Searched Results</span>
              <a href="!#">» Adjuster Codes - PDF</a>
              <a href="!#">» Agent Code Listing - PDF</a>
              <a href="!#">» Agent Directory - PDF</a>
              <a href="!#">» Claims Contact & Code - PDF</a>
            </div>
          </div>
        </div>
        <div className="options_menu secondary_menu">
          <div className="option_division">
            <div className="option_section secondary_section">
              <span className="option_title">FRIDAY FORUM</span>
              <a href="!#">
                1. <a href="!#" className="blue_options"> Friday Forum – 10/21/2022</a>
              </a>
              <a href="!#">
                2. <a href="!#" className="blue_options"> Friday Forum – 10/14/2022</a>
              </a>
              <a href="!#">
                3. <a href="!#" className="blue_options"> Friday Forum – 10/07/2022</a>
              </a>
              <a href="!#">
                4. <a href="!#" className="blue_options"> Friday Forum – 09/30/2022</a>
              </a>
            </div>
          </div>
          <div className="option_division">
            <div className="option_section secondary_section">
              <span className="option_title">NEED TO KNOWS</span>
              <a href="!#">
                1. <a href="!#" className="blue_options"> Need to Know – 10/21/2022</a>
              </a>
              <a href="!#">
                2. <a href="!#" className="blue_options"> Need to Know – 10/14/2022</a>
              </a>
              <a href="!#">
                3. <a href="!#" className="blue_options"> Need to Know – 10/07/2022</a>
              </a>
              <a href="!#">
                4. <a href="!#" className="blue_options"> Need to Know – 09/30/2022</a>
              </a>
            </div>
          </div>
          <div className="option_division">
            <div className="option_section secondary_section">
              <span className="option_title">SCOREBOARD MONTHLY REPORT</span>
              <a href="!#">
                1.{" "}
                <a href="!#" className="blue_options"> Scoreboard Report – 10/21/2022</a>
              </a>
              <a href="!#">
                2.{" "}
                <a href="!#" className="blue_options"> Scoreboard Report – 10/14/2022</a>
              </a>
              <a href="!#">
                3.{" "}
                <a href="!#" className="blue_options"> Scoreboard Report – 10/07/2022</a>
              </a>
              <a href="!#">
                4.{" "}
                <a href="!#" className="blue_options"> Scoreboard Report – 09/30/2022</a>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default InternalLandingScreen;
