import React, { useEffect, useState } from 'react';
import Banner from '../../../components/Banner/Banner';
import { Link, NavLink } from 'react-router-dom';
import EditInsuranceModal from './edit/EditInsurance';
import EditInsurancePointsModal from './edit/EditInsurancePoints';
import EditGettingStartedModal from './edit/EditGettingStarted';
import EditCoverageOptionsModal from './edit/EditCoverageOptions';
import EditCoverageOptionsListModal from './edit/EditCoverageOptionsList';

import Content from '../../../components/Content/Content';
import './LifeInsurance.css';
import { useSelector } from 'react-redux';
import { ADMIN_USERS } from '../../../redux/actions/ip';
import { addContent, getPageContent } from '../../../redux/actions/contentAction';

import ReactHtmlParser from 'html-react-parser';

const LifeInsurance = () => {
	const azureLogin = useSelector(state => state.azureLogin)
	const [showEdit, setShowEdit] = useState(false)
	const [showInsuranceModal, setShowInsuranceModal] = useState(false)
	const [showCoverageOptionsModal, setShowCoverageOptionsModal] = useState(false)
	const [showCoverageOptionsModalList, setShowCoverageOptionsModalList] = useState(false)
	const [showInsuranceDetailModal, setShowInsuranceDetailModal] = useState(false)
	const [showGettingStartedModal, setShowGettingStartModal] = useState(false)
	const [searchListArr, setSearchListArr] = useState([
		{ title: "What is life insurance", description: "Simply put, life insurance is a policy that will provide benefits to your chosen beneficiaries upon your death. If your death occurs during the coverage period of your selected policy, the people you specify as beneficiaries will receive benefits from the policy either as a limp sum or through flexible income payment options. These benefits are paid directly to your beneficiaries, so there should be no additional costs or delays in receiving the financial benefit you’ve secured for them." },
		{ title: "Why is it important", description: "Life insurance is an essential part of your financial well-being. It’s also great at providing peace of mind for the future. But did you know it can provide so much more than that? Life insurance provides benefits you may not be aware of. A lot goes into choosing life insurance coverage – policies are not one-size-fits-all." }
	])
	const [checked, setChecked] = useState('checked0');

	const [section2, setSection2] = useState({
		title: { value: "Life Insurance", action: "", type: "" },
		description: {
			value: `Life insurance helps protect the financial future of your family or business upon your death. Without life insurance, your family could be left without enough money to maintain their standard of living, pay bills or even take care of your final expenses. Through a relationship with Greenfields Life Insurance Company, our agents can help you find the type of life insurance that is right for you.`, action: "", type: ""
		}
	})
	const [section3, setSection3] = useState([
		{
			title: "What is life insurance",
			description: `Simply put, life insurance is a policy that will provide benefits to your chosen beneficiaries upon your death. If your death occurs during the coverage period of your selected
			policy, the people you specify as beneficiaries will receive benefits from the policy
			either as a limp sum or through flexible income payment options. These benefits are paid
			directly to your beneficiaries, so there should be no additional costs or delays in
			receiving the financial benefit you’ve secured for them.`
		},
		{
			title: "What is important",
			description: `Life insurance is an essential part of your financial well-being. It’s also great at
			providing peace of mind for the future. But did you know it can provide so much more than
			that? Life insurance provides benefits you may not be aware of. A lot goes into choosing
			life insurance coverage – policies are not one-size-fits-all.`
		}
	])
	const [section5, setSection5] = useState({
		title: { value: "Coverage Options", action: "", type: "" },
		description: {
			value: `It might surprise you, but your life insurance needs change over time. You need coverage that will
		grow with you through all of life’s changes–whether with a new spouse, new baby, new home, or new
		job. When you work with us, you can be confident that your life insurance is with you every step of
		the way.`, action: "", type: ""
		}
	})
	const [section6, setSection6] = useState([
		{
			image: 'https://www.mwfbi.com/images/Graphics-Term-Life-Insurance.png',
			title: 'Term Life Insurance',
			description: 'Term life insurance is straightforward: it provides coverage for a specific period. Your coverage ends when your term ends, or until you convert it into a more permanent life insurance policy.',
			link: '',
			linkLabel: ''
		},
		{
			image: 'https://www.mwfbi.com/images/Graphics-Whole-Life-Insurance.png',
			title: 'Whole Life Insurance',
			description: 'With whole life insurance you can provide financial protection for your loved ones upon your death and have access to your policy’s accumulated cash value while you’re living. Whole life insurance can give you the lifetime protection you want.',
			link: 'http://www.greenfieldslife.com/life-insurance',
			linkLabel: 'LEARN MORE'
		},
		{
			image: 'https://www.mwfbi.com/images/Graphics-Universal-Life-Insurance.png',
			title: 'Universal Life Insurance',
			description: 'Universal life insurance provides the flexibility you need to navigate your journey. It is a form of whole life insurance that covers you throughout your lifetime, but along the way you can adjust certain elements to meet your current needs and goals.',
			link: '',
			linkLabel: ''
		},
	])
	const [section4, setSection4] = useState({
		title: { value: "Get Started", action: "", type: "" },
		description: { value: `Your local Farm Bureau agent can provide unmatched value–your agent can take the guesswork out of choosing life insurance and can help you customize a policy that works for you and your family.`, action: "", type: "" },
		question1: { value: "How Much coverage do you need?", action: "", type: "" },
		question1Answer: { value: `Make sure you have the appropriate life insurance for whatever life throws at you – check your coverage with the Farm Bureau Financial Services life insurance calculator or contact your agent.`, action: "", type: "" },
		question2: { value: "What is right for you?", action: "", type: "" },
		question2Answer: { value: `With the right type of policy, you many also be able to use accumulated cash value in the account to fi nance a college education, fill in the gaps of your retirement income, purchase a home or pay other expenses. Our agents offer three types of life insurance, term life, whole life and universal life. Each serves as protection for your loved ones, but differs in price and coverage characteristics. No matter which type of coverage you choose, life insurance premiums are almost always lower when you’re younger and healthier, so don’t wait!`, action: "", type: "" },
		estimateLabel: { value: "Estimate Your Life Insurance Needs", action: "", type: "" },
		estimateLink: { value: "https://www.fbfs.com/landing-page/life-insurance-calculator", action: "", type: "" },

	})
	const [bannerData, setBannerData] = useState({
		title: { value: 'Life Insurance', error: '' },
		subTitle: { value: 'Providing peace of mind so you can enjoy life as it comes.', error: '' },
		tagline: { value: 'Start a life insurance quote and begin working with your local agent.', error: '' },
		button1: { value: 'START A QUOTE', error: '' },
		button1Link: { value: '/360/start-a-quote', error: '' },
		button1Status: { value: true, error: '' },
		button2: { value: 'FIND AN AGENT', error: '' },
		button2Link: { value: '/360/find-an-agent', error: '' },
		button2Status: { value: true, error: '' },
		imageUrl: { value: '/images/mother-baby2.jpg', error: '' },
	})

	useEffect(() => {
		if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
			if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
				setShowEdit(true)
			} else setShowEdit(false)
		} else setShowEdit(false)
	}, [azureLogin]);

	useEffect(() => {
		getPageContent({ pageName: "LifeInsurance" })
			.then(res => {
				if (res.data.status) {
					if (res.data.result.Section_2)
						setSection2(res.data.result.Section_2)
					if (res.data.result.Section_5)
						setSection5(res.data.result.Section_5)
					if (res.data.result.Section_4)
						setSection4(res.data.result.Section_4)
					if (res.data.result.Section_3)
						setSection3(JSON.parse(res.data.result.Section_3.detailsList.value))
					if (res.data.result.Section_6_6)
						setSection6(JSON.parse(res.data.result.Section_6_6.list.value))
					if (res.data.result.TopBanner) setBannerData({
						...res.data.result.TopBanner,
						button1Status: { value: res.data.result.TopBanner.button1Status.value === 'true' },
						button2Status: { value: res.data.result.TopBanner.button2Status.value === 'true' }
					})
				}
			})
	}, [])
	const handleInsuranceSubmit = () => {
		addContent({ pageName: "LifeInsurance", section: "Section_2", content: section2 })
			.then(result => {
				console.log(result.data);
				if (result.data.status) {
					alert("Content Saved")
				}
			}).catch(error => console.error(error))
	}
	const handleCoverageOptionsSubmit = (content) => {
		addContent({ pageName: "LifeInsurance", section: "Section_5", content })
			.then(result => {
				console.log(result.data);
				if (result.data.status) {
					alert("Content Saved")
				}
			})
	}
	// const handleGettingStartedSubmit = () => {
	// 	addContent({ pageName: "LifeInsurance", section: "Section_4", content: section4 })
	// 		.then(result => {
	// 			console.log(result.data);
	// 			if (result.data.status) {
	// 				alert("Content Saved")
	// 			}
	// 		})
	// }
	const handleCoverageOptionsListSubmit = (content) => {
		addContent({ pageName: "LifeInsurance", section: "Section_6_6", content: { list: { value: JSON.stringify(content) } } })
			.then(result => {
				if (result.data.status) {
					alert("Content Saved")
				}
			})
	}
	const handleInsuranceDetailsSubmit = (content) => {
		addContent({ pageName: "LifeInsurance", section: "Section_3", content: { detailsList: { value: JSON.stringify(content), error: '' } } })
			.then(result => {
				console.log(result.data);
				if (result.data.status) {
					alert("Content Saved")
				}
			})
	}
	const splitArray = (array, perChunk = 3) => {
		if (Array.isArray(array) && array.length) {
			return array.reduce((resultArray, item, index) => {
				const chunkIndex = Math.floor(index / perChunk)

				if (!resultArray[chunkIndex]) {
					resultArray[chunkIndex] = [] // start a new chunk
				}

				resultArray[chunkIndex].push(item)

				return resultArray
			}, [])
		}
		return []
	}

	return (
		<Content headerFooterMenu="mountain-west">
			<Banner
				backgroundImage="linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),url(/images/mother-baby2.jpg)"
				title="Life Insurance"
				subTitle="Providing peace of mind so you can enjoy life as it comes."
				bannerData={bannerData}
				showEdit={showEdit}
				setBannerData={setBannerData}
				pageName={'LifeInsurance'}
			/>
			<div className="quote2">
				<div className="flex2">
					<h3 className="quote__text2">
						{bannerData && bannerData.tagline ? bannerData.tagline.value : "Start a life insurance quote and begin working with your local agent."}
					</h3>
				</div>
				<div className="header_buttons">
					{bannerData && bannerData.button1 && bannerData.button1Status.value && <Link to={bannerData.button1Link.value} className='mw_button'>
						{bannerData.button1.value}
					</Link>}
					{bannerData && bannerData.button2 && bannerData.button2Status.value && <Link to={bannerData.button2Link.value} className='mw_button'>
						{bannerData.button2.value}
					</Link>}
				</div>
			</div>
			<div className="life_insurance" id="what_is_it" style={{ width: '100%', position: 'relative' }}>
				<h1 className="phase2_heading">{section2.title.value} </h1>
				<p className="phase2_subheading">
					{/* Life insurance helps protect the financial future of your family or business upon your death.
					Without life insurance, your family could be left without enough money to maintain their standard of
					living, pay bills or even take care of your final expenses. Through a relationship with {' '}
					Greenfields Life Insurance Company, our agents can help you find the type of life insurance that is right for you. */}
					{ReactHtmlParser(section2.description.value)}
				</p>
				{showEdit && <div
					className='mwfbi_banner__edit'
					style={{ top: '1rem' }}
					id='admin-edit'
					onClick={() => setShowInsuranceModal(true)}>
					<svg>
						<use xlinkHref='/sprite.svg#icon-edit' />
					</svg>
				</div>}
			</div>
			<div div class="life_insurance tabset" style={{ width: '100%', position: 'relative' }}>
				{/* <input
					type="radio"
					name="tabset"
					id="tab1"
					aria-controls="tab-life-insurance"
					checked={checked === 'checked1'}
					onChange={() => setChecked('checked1')}
				/>
				<label for="tab1">What is life insurance</label>

				<input
					type="radio"
					name="tabset"
					id="tab2"
					aria-controls="tab-its-importance"
					checked={checked === 'checked2'}
					onChange={() => setChecked('checked2')}
				/>
				<label for="tab2">Why is it important</label> */}
				{section3 && section3.length && section3.map((item, index) => (
					<React.Fragment key={`checked${index}`}>
						<input
							type="radio"
							name="tabset"
							id={`tab${index}`}
							aria-controls={"tab-life" + (index)}
							checked={checked === ('checked' + (index))}
							onChange={() => setChecked(('checked' + (index)))}
						/>
						<label style={{ width: '200px' }} htmlFor={`tab${index}`}>{item.title}</label>
					</React.Fragment>))}
				<div class="tab-panels">
					{section3 && section3.length && section3.map((item, index) => (
						<section id={"tab-life" + (index)} className="tab-panel">
							<p className="phase2_subheading">
								{item.description}
							</p>
						</section>))}
					{/* <section id="tab-life-insurance" class="tab-panel">
						<p className="phase2_subheading">
							Simply put, life insurance is a policy that will provide benefits to your chosen beneficiaries upon your death. If your death occurs during the coverage period of your selected
							policy, the people you specify as beneficiaries will receive benefits from the policy
							either as a limp sum or through flexible income payment options. These benefits are paid
							directly to your beneficiaries, so there should be no additional costs or delays in
							receiving the financial benefit you’ve secured for them.
						</p>
					</section>
					<section id="tab-its-importance" class="tab-panel">
						<p className="phase2_subheading">
							Life insurance is an essential part of your financial well-being. It’s also great at
							providing peace of mind for the future. But did you know it can provide so much more than
							that? Life insurance provides benefits you may not be aware of. A lot goes into choosing
							life insurance coverage – policies are not one-size-fits-all.
						</p>
					</section> */}
				</div>
				{showEdit && <div
					className='mwfbi_banner__edit'
					style={{ top: '1rem' }}
					id='admin-edit'
					onClick={() => setShowInsuranceDetailModal(true)}>
					<svg>
						<use xlinkHref='/sprite.svg#icon-edit' />
					</svg>
				</div>}
			</div>
			{/* <div className="life_insurance_starter" id="getting_started">
				<h1 className="phase2_heading">Get Started </h1>
				<p className="phase2_subheading">
					Your local Farm Bureau agent can provide unmatched value–your agent can take the guesswork out of
					choosing life insurance and can help you customize a policy that works for you and your family.
				</p>
				<div className="life_insurance_starter-outer">
					<div className="life_insurance_starter-left">
						<h1 className="phase2_heading1">How Much coverage do you need?</h1>
						<p className="phase2_subheading">
							Make sure you have the appropriate life insurance for whatever life throws at you – check
							your coverage with the Farm Bureau Financial Services life insurance calculator or contact
							your agent.
						</p>
						<form action="https://www.fbfs.com/landing-page/life-insurance-calculator" target="_blank">
							<input
								type="submit"
								value="Estimate Your Life Insurance Needs"
								className="life_insurance_button"
							/>
						</form>
					</div>
					<div className="life_insurance_starter-right">
						<h1 className="phase2_heading1">What is right for you?</h1>
						<p className="phase2_subheading">
							With the right type of policy, you many also be able to use accumulated cash value in the
							account to fi nance a college education, fill in the gaps of your retirement income,
							purchase a home or pay other expenses. Our agents offer three types of life insurance, term
							life, whole life and universal life. Each serves as protection for your loved ones, but
							differs in price and coverage characteristics. No matter which type of coverage you choose,
							life insurance premiums are almost always lower when you’re younger and healthier, so don’t
							wait!
						</p>
					</div>
				</div>
			</div> */}
			<div className="life_insurance_options" id="coverage_options" style={{ width: '100%', position: 'relative' }}>
				<h1 className="phase2_heading">{section5.title.value}</h1>
				<p className="phase2_subheading">
					{section5.description.value}
				</p>
				{showEdit && <div
					className='mwfbi_banner__edit'
					style={{ top: '1rem' }}
					id='admin-edit'
					onClick={() => setShowCoverageOptionsModal(true)}>
					<svg>
						<use xlinkHref='/sprite.svg#icon-edit' />
					</svg>
				</div>}

				<div >

					{section6 && section6.length && splitArray(section6).map((elements, ids) =>
					(< div className="homeowner_insurance_discounts-grid" key={'parent' + ids}>
						{elements.map((item, i) => (
							<div className="discounts-types">
								<img
									src={item.image}
									alt={item.title}
									className="discounts_images_nails"
								/>

								<div className="discount_image_text">
									<h1 className="phase2_heading1">{item.title}</h1>
									<p className="phase2_subheading">
										{item.description}
									</p>
									<a
										href={item.link}
										className="phase2_subheading2"
										target="_blank"
									>
										{item.linkLabel}
									</a>
								</div>
								{i <= 1 ? <div className="line" style={{width: '1px'}}/> : <div />}
							</div>))}

						{/* <div className="discounts-types">
						<img
							src="./images/Graphics-Whole-Life-Insurance.png"
							alt="whole life insurance"
							className="discounts_images_nails"
						/>
						<div className="discount_image_text">
							<h1 className="phase2_heading1">Whole Life Insurance</h1>
							<p className="phase2_subheading">
								With whole life insurance you can provide fi nancial protection for your loved ones upon
								your death and have access to your policy’s accumulated cash value while you’re living.
								Whole life insurance can give you the lifetime protection you want.
							</p>
							<a
								href="https://www.fbfs.com/insurance/life-insurance/whole-life-insurance"
								className="phase2_subheading2"
								target="_blank"
							>
								learn more
							</a>
						</div>
						<div className="line" />
					</div>
					<div className="discounts-types">
						<img
							src="./images/Graphics-Universal-Life-Insurance.png"
							alt="universal image"
							className="discounts_images_nails"
						/>
						<div className="discount_image_text">
							<h1 className="phase2_heading1">Universal Life Insurance</h1>
							<p className="phase2_subheading">
								Universal life insurance provides the fl exibility you need to navigate your journey. It
								is a form of whole life insurance that covers you throughout your lifetime, but along
								the way you can adjust certain elements to meet your current needs and goals.
							</p>
							<a
								href="https://www.fbfs.com/insurance/life-insurance/indexed-universal-life-insurance"
								className="phase2_subheading2"
								target="_blank"
							>
								learn more
							</a>
						</div>
					</div> */}

					</div>
					))}
					{showEdit && <div
						className='mwfbi_banner__edit'
						style={{ top: '12rem' }}
						id='admin-edit'
						onClick={() => setShowCoverageOptionsModalList(true)}>
						<svg>
							<use xlinkHref='/sprite.svg#icon-edit' />
						</svg>
					</div>}
					{/* <div className="homeowner_insurance_discounts-grid">
					<div className="discounts-types">
						<img
							src="./images/Graphics-Term-Life-Insurance.png"
							alt="life insurance"
							className="discounts_images_nails nail_icon_center"
						/>

						<div className="discount_image_text">
							<h1 className="phase2_heading1">Term Life Insurance</h1>
							<p className="phase2_subheading">
								Term life insurance is straightforward: it provides coverage for a specific period.
								Your coverage ends when your term ends, or until you convert it into a more permanent
								life insurance policy.
							</p>
							
						</div>
						<div className="line" />
					</div>

					<div className="discounts-types">
						<img
							src="./images/Graphics-Whole-Life-Insurance.png"
							alt="whole life insurance"
							className="discounts_images_nails nail_icon_center"
						/>
						<div className="discount_image_text">
							<h1 className="phase2_heading1">Whole Life Insurance</h1>
							<p className="phase2_subheading">
								With whole life insurance you can provide financial protection for your loved ones upon
								your death and have access to your policy’s accumulated cash value while you’re living.
								Whole life insurance can give you the lifetime protection you want.
							</p>
							<a
								href="http://www.greenfieldslife.com/life-insurance"
								className="phase2_subheading2"
								target="_blank"
							>
								learn more
							</a>
						</div>
						<div className="line" />
					</div>
					<div className="discounts-types">
						<img
							src="./images/Graphics-Universal-Life-Insurance.png"
							alt="universal image"
							className="discounts_images_nails nail_icon_center"
						/>
						<div className="discount_image_text">
							<h1 className="phase2_heading1">Universal Life Insurance</h1>
							<p className="phase2_subheading">
								Universal life insurance provides the flexibility you need to navigate your journey. It
								is a form of whole life insurance that covers you throughout your lifetime, but along
								the way you can adjust certain elements to meet your current needs and goals.
							</p>
							
						</div>
					</div>
				</div> */}
				</div>
			</div>
			<EditInsuranceModal
				showModal={showInsuranceModal}
				setShowModal={setShowInsuranceModal}
				handleYes={handleInsuranceSubmit}
				section2={section2}
				setSection2={setSection2}
			/>
			<EditCoverageOptionsModal
				showModal={showCoverageOptionsModal}
				setShowModal={setShowCoverageOptionsModal}
				handleYes={handleCoverageOptionsSubmit}
				section2={section5}
				setSection2={setSection5}
			/>
			{/* <EditGettingStartedModal
				showModal={showGettingStartedModal}
				setShowModal={setShowGettingStartModal}
				handleYes={handleGettingStartedSubmit}
				section4={section4}
				setSection4={setSection4}
			/> */}
			<EditCoverageOptionsListModal
				showModal={showCoverageOptionsModalList}
				setShowModal={setShowCoverageOptionsModalList}
				handleYes={handleCoverageOptionsListSubmit}
				section6={section6}
				setSection6={setSection6}
			/>
			<EditInsurancePointsModal
				showModal={showInsuranceDetailModal}
				setShowModal={setShowInsuranceDetailModal}
				handleYes={handleInsuranceDetailsSubmit}
				section3={section3}
				setSection3={setSection3}
			/>
		</Content>
	);
};
export default LifeInsurance;
