import React, { Fragment, useState, useEffect, useCallback } from "react";
// import { useHistory } from 'react-router-dom'
import Modal from "../../../components/DarkModal";
import FormInputComponent from "../../../components/FormInputComponent/FormInputComponent";
import Styles from './blogSettings.module.css'

const AddNewBlogModal = ({
  setShowModal,
  showModal,
  handleYes,
  blogsList,
  editFormData,
  setEditFormData,
  handleYes1,
  onFieldDelete,
  fieldTypeArr
}) => {
  const [loading, setLoading] = useState(false);
  const [fieldType, setFieldType] = useState({
    value: "Select Field Type",
    error: "",
  });
  const [fieldInputType, setFieldInputType] = useState({
    value: "Select Input Type",
    error: "",
  });
  const [label, setLabel] = useState({ value: "", error: "" });
  const [fieldValue, setFieldValue] = useState({ value: "", error: "" });
  const [placeholder, setPlaceholder] = useState({ value: "", error: "" });
  const [validationType, setValidationType] = useState({ value: "None", error: "" });
  const [requiredType, setRequiredType] = useState({
    value: "False",
    error: "",
    checked: false
  });
  const [multiValueFields, setMultiValueFields] = useState(false);
  const [multiValue, setMultiValue] = useState(false);

  const [fieldId, setFieldId] = useState('');

  const [tagsArray, setTagsArray] = useState([
    {
    title: 'New Policy Browser',
    topic: 'test',
    status: true
  },
  {
    title: 'New Policy Browser',
    topic: 'test',
    status: true
  },
  {
    title: 'New Policy Browser',
    topic: 'test',
    status: true
  }
])




  const [editIndex, setEditIndex] = useState(-1);
  const [editData, setEditData] = useState({
    tagTitle: { value: "", error: "" },
    topic: { value: "", error: "" },
  });

  const handleDiscountSubmit = (e) => {
    e.preventDefault();
    if (fieldType.value === "Select Field Type") {
      setFieldType({ ...fieldType, error: "Please select a field Type!" });
      return
    }
    if (label.value === "Select Label") {
      setLabel({
        ...label,
        error: "Please select a Label!",
      });
      return
    }
    if (fieldValue === "") {
      setFieldValue({ ...fieldValue, error: "Please enter the Value" })
    }

    if (multiValue) {
      if (fieldValue.value === "") {
        setFieldValue({ ...fieldValue, error: "Please enter the Value" });
        return;
      }
    }
    clearErrors();
    let content = {
      fieldType: fieldType.value,
      fieldInputType: fieldInputType.value,
      fieldLabel: label.value,
      fieldValue: fieldValue.value,
      placeHolder: placeholder.value,
      requiredStatus: requiredType.value,
      validationType: validationType.value
    };
    if (editIndex > -1) {  
          if (label.value === "") {
        setLabel({
          ...label,
          error: "Please select a Label!",
        });
        return
      }
      const temp = [...editFormData];
      temp[editIndex] = content;
      /* setCurrentItems(temp) */
      setEditFormData(temp);
      handleYes1(fieldId, content);

      clear();
    } else {
      /* setCurrentItems([...discountListArr, content]) */
      setEditFormData([...editFormData, content]);
      handleYes(content);

      /* console.log("added", editFormData); */
      clear();
    }
  };

  const onEdit = (e, item, index) => {
    e.preventDefault();
    setFieldId(item.ID)
    console.log(fieldId, 'FieldID')
    // setLoading(true);
    setEditData(item);
    setEditIndex(index);
    setFieldType({ ...fieldType, value: item.FieldType });
    setFieldInputType({ ...fieldInputType, value: item.FieldInputType });
    setFieldValue({ ...fieldValue, value: item.FieldValue });
    setLabel({ ...label, value: item.FieldLabel });
    setPlaceholder({ ...placeholder, value: item.PlaceHolder });
    setRequiredType({ ...requiredType, value: item.RequiredStatus ? 'True' : 'False', checked: item.RequiredStatus });
    setValidationType({ ...validationType, value: item.ValidationType });
    if (item.FieldType === 'Multi-Value') {
      setMultiValue(true)
      setMultiValueFields(true)
    }
    else if (item.FieldInputType === 'Select' | 'Radio' | 'Switch') {
      setMultiValue(true)
      setMultiValueFields(true)
    }
    else if (item.FieldInputType === 'Textbox' | 'Textarea') {
      setMultiValue(false)
      setMultiValueFields(false)
    }
    // setUrl({ ...url, value: item.url });
  };
  const clear = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      fieldType: { value: "", error: "" },
      fieldInputType: { value: "", error: "" },
      label: { value: "", error: "" },
      fieldValue: { value: "", error: "" },
      placeholder: { value: "", error: "" },
      requiredType: { value: "", error: "" },
      validationType: { value: "", error: "" },
    });
    setLabel({ value: "", error: "" });
    setFieldValue({ value: "", error: "" });
    setPlaceholder({ value: "", error: "" });
    setFieldType({ value: "", error: "" });
    setFieldInputType({ value: "", error: "" });
    setLabel({ value: "", error: "" });
    setValidationType({ value: "", error: "" });
    setEditIndex(-1);
    // handleDiscountSubmit()
  };
  const closeModal = () => {
    resetAll();
    setShowModal(false);
  };
  const resetAll = () => {
    // e.preventDefault();
    // setLoading(true);
    setEditData({
      tagTitle: { value: "", error: "" },
      topic: { value: "", error: "" },
    });
    setEditIndex(-1);
    setFieldType({ ...fieldType, value: "", error: "" });
    setFieldInputType({ ...fieldInputType, value: "", error: "" });
    setLabel({ ...label, value: "", error: "" });
    setFieldValue({ ...fieldValue, value: "", error: "" });
    setPlaceholder({ ...placeholder, value: "", error: "" });
    setRequiredType({ ...requiredType, value: "", error: "" });
    setValidationType({ ...validationType, value: "", error: "" });
  };
  const clearErrors = () => {
    setEditIndex(-1);
    setFieldType({ ...fieldType, value: "", error: "" });
    setFieldInputType({ ...fieldInputType, value: "", error: "" });
    setLabel({ ...label, value: "", error: "" });
    setFieldValue({ ...fieldValue, value: "", error: "" });
    setPlaceholder({ ...placeholder, value: "", error: "" });
    setRequiredType({ ...requiredType, value: "", error: "" });
    setValidationType({ ...validationType, value: "", error: "" });
  };
  // const onFieldDelete = (e, item, index) => {
  //   e.preventDefault()
  //   console.log( item.ID, 'id')
  //   if (window.confirm("Are You Sure to Delete?")) {
  //     // const temp = [...editFormData];
  //     // temp.splice(index, 1);
  //     // setEditFormData(temp);
  //     // handleYes(temp);
  //     deleteFormField(item.ID).then(res => {
  //       if (res.data.status) {
  //         getFormContent("StartAQuote").then((res) => {
  //           setFieldTypeArr(res.data.result);
  //         });
  //       }
  //      /*  let temp = [...editFormData];
  //       temp.splice(index, 1);
  //       setEditFormData(temp)
  //       handleYes(temp)
  //       if (res.data.status) {
  //         alert("Item has been deleted!");
  //       } */
  //     })
  //   }
  // };

  // const handleDynamicFormUpdate = (content) => {
  //   // setFieldTypeArr(content);
  //   console.log(content, "arr");
  //     updateFormContent({
  //     fieldType: content.fieldType,
  //     fieldInputType: content.fieldInputType,
  //     fieldLabel: content.fieldLabel,
  //     fieldValue: content.fieldValue,
  //     placeHolder: content.placeHolder,
  //     pageName: "StartAQuote",
  //     requiredStatus: content.requiredStatus == "True" ? 1 : 0,
  //   }).then((res) => {
  //     if (res.data.status) {
  //       getFormContent("StartAQuote").then((res) => {
  //         setFieldTypeArr(res.data.result);
  //       });
  //     }
  //   });
  // };
  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className="modal__container-dark">
          <div className="modal_heading__container-dark">
            <h1 className="modal_heading__text-dark">Add Field</h1>
            <button
              className="modal__heading--button-dark"
              onClick={() => closeModal()}
            >
              <svg className="modal_heading__icon-dark">
                <use xlinkHref="/sprite.svg#icon-cross" />
              </svg>
            </button>
          </div>
          <div className="modal__form-dark">
            <div className="modal_inner_content">
              <div className="modal_inner_content--left">
              <div className="inline-form-view">
                  <FormInputComponent
                    heading="Blog title:"
                    style={{ width: "70%" }}
                    required={true}
                    value={label.value}
                    onChange={(e) => {
                      setLabel({ value: e.target.value, error: "" });
                    }}
                    errorMessage={label.error}
                  />
                </div>
              <div className="inline-form-view">
              <FormInputComponent
                    heading="Blog link:"
                    style={{ width: "70%" }}
                    required={true}
                    value={label.value}
                    onChange={(e) => {
                      setLabel({ value: e.target.value, error: "" });
                    }}
                    errorMessage={label.error}
                  />
                </div>
              <div className="inline-form-view">
              <FormInputComponent
                    heading="Tags: "
                    style={{ width: "70%", height: '20%' }}
                    required={true}
                    type={'textarea'}
                    value={label.value}
                    onChange={(e) => {
                      setLabel({ value: e.target.value, error: "" });
                    }}
                    errorMessage={label.error}
                  />
                </div>
                {fieldType.error !== "" ? (
                  <label
                    style={{
                      fontSize: "1.2rem",
                      color: "#e02b21",
                      marginTop: "-.5rem",
                    }}
                  >
                    {fieldType.error}
                  </label>
                ) : null}

            
                {/* {multiValue ? 
                <div className="inline-form-view">
                  <FormInputComponent
                    type={"textarea"}
                    heading="Value:"
                    required={multiValue}
                    style={{ width: "70%" }}
                    value={fieldValue.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setFieldValue({ value: e.target.value, error: "" });
                    }}
                    errorMessage={fieldValue.error}
                  />
                </div> : null } */}

                  <div className="inline-form-view">
                  
                </div>
                {requiredType.error !== "" ? (
                  <label
                    style={{
                      fontSize: "1.2rem",
                      color: "#e02b21",
                      marginTop: "-.5rem",
                    }}
                  >
                    {requiredType.error}
                  </label>
                ) : null}
              </div>
              
              <div style={{ alignItems: 'end' }} className="modal_inner_content--right3">
                {/* <label>Upload the image</label>
                <div
                  className='upload_border'
                  {...getRootProps()}
                  style={{ marginTop: '-1rem' }}>
                  <input {...getInputProps()} />
                  <label>Click or Drag a image to this area to upload</label>
                  {uploadImagePreview == '' ? (
                    <svg className='modal_upload__icon-dark'>
                      <use xlinkHref='/sprite.svg#icon-cloud' />
                    </svg>
                  ) : (
                    <img
                      height={60}
                      width={60}
                      src={uploadImagePreview}
                      style={{ marginTop: '0rem', alignSelf: 'center' }}></img>
                  )}
                </div>
                {noImage ? (
                  <label style={{ fontSize: '1.2rem', color: '#e02b21' }}>
                    Please upload a image*
                  </label>
                ) : (
                  <></>
                )}
                 */}
                 
                <div
                  className="modal_dark_buttons"
                  style={{ padding: "0.5rem" }}
                ></div>
                <div style={{ display: "flex", gap: 12 }}>
                  <button
                    type="button"
                    className="modal__button-reset-dark"
                    onClick={resetAll}
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    className="modal__button-dark"
                    onClick={handleDiscountSubmit}
                  >
                    {loading ? "Saving.." : editIndex > -1 ? "Save" : "Add"}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='modal_dark_buttons' style={{ paddingTop: '0' }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={(e) => handleDiscountSubmit()}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal>
  );
};

export default AddNewBlogModal;
