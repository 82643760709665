import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import { addContent, imageUpload } from '../../../../redux/actions/contentAction'
import {
  setKey,
  fromAddress,
} from "react-geocode";
let timeout;
const EditInfo = ({ setShowModal, showModal, pageName, section1, setSection1 }) => {
  setKey("AIzaSyC2EnyDBamB9KpzatULxDK8NLEdByw8n5M")
  const [loading, setLoading] = useState(false)
  const [lat, setLat] = useState({ value: section1?.lat?.value, error: '' })
  const [lng, setLng] = useState({ value: section1?.lng?.value, error: '' })
  const [address, setAddress] = useState({ value: section1?.address?.value, error: '' })
 

  const submit = (e) => {
    e.preventDefault()
    let temp = { ...section1 };
    temp.address = {}
    if (section1?.address?.value === '') {
      temp.address.value = 'Please enter address!'
      setSection1(temp)
      return
    } else {
      if (temp.address)
        temp.address.error = ''
      setSection1(temp)
    }
    temp.address.value = address.value
    temp.lat.value = lat.value
    temp.lng.value = lng.value

    addContent({ pageName: pageName, section: "Section_1", content: temp })
      .then(result => {
        if (result.data.status) {
          setSection1(temp)
          alert("Content Saved")
        }
      })
      .catch(error => {
        console.error(error);
      })
  }
  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Section 1</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => setShowModal(false)}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Address:'
                    style={{ width: '50%' }}
                    required={true}
                    value={address.value}
                    onChange={(e) => {
                      // let temp = { ...section1 }
                      // temp.lat.value = e.target.value
                      // setSection1(temp)
                      setAddress({ error: '', value: e.target.value })
                      clearTimeout(timeout)
                      timeout = setTimeout(() => {
                        fromAddress(e.target.value)
                          .then(({ results }) => {
                            const { lat: lat1, lng: lng1 } = results[0].geometry.location;
                            setLat({ value: lat1 })
                            setLng({ value: lng1 })
                          })
                          .catch(console.error);
                      }, 2000);
                    }}
                    errorMessage={address.error}
                  />
                </div>
                <label style={{ fontSize: '1rem' }}>lat:{lat.value}<br /> lng:{lng.value}</label>

              </div>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditInfo
