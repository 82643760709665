import React from "react";
import Select from 'react-select';
import Styles from './MultiSelectDropdown.css'

const MultiSelectDropdown = (props) => {
    return (
        <Select
            placeholder={props.placeHolder ? props.placeHolder : 'Select'}
            key={props.key}
            isMulti={props.isMulti === false ? false : true}
            className={props.className ? props.className : Styles.multi_dropdown_start_a_quote}
            styles={props.styles}
            options={props.options}
            onChange={props.onChange}
            value={props.value}
        />
    )
}

export default MultiSelectDropdown;