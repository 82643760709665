import axios from 'axios';
import { USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGIN_FAIL, USER_LOGOUT } from '../constants/userConstants';
import { USER_AZURE_REQUEST, USER_AZURE_SUCCESS, USER_AZURE_FAIL, USER_AZURE_LOGOUT } from '../constants/userConstants';
import { BASE_URL } from './ip';

export const loginAction = (formData) => async (dispatch) => {
	try {
		dispatch({ type: USER_LOGIN_REQUEST });

		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.post(`${BASE_URL}/auth/signin`, formData, config);

		dispatch({
			type: USER_LOGIN_SUCCESS,
			payload: data
		});

		localStorage.setItem('userInfo', JSON.stringify(data));
	} catch (err) {
		dispatch({
			type: USER_LOGIN_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
export const loginAzureAction = () => {
	try {

		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};

		return axios.get(`${BASE_URL}/auth/azure`, config);

	} catch (err) {
		return Promise.reject(err)
	}
};
export const azureLogin = (formData) => async (dispatch) => {
	try {
		dispatch({ type: USER_AZURE_REQUEST });

		dispatch({
			type: USER_AZURE_SUCCESS,
			payload: formData
		});

		localStorage.setItem('az', JSON.stringify(formData));
	} catch (err) {
		dispatch({
			type: USER_AZURE_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
export const azureLogout = () => async (dispatch) => {
	try {
		localStorage.removeItem('az');
		dispatch({ type: USER_AZURE_LOGOUT });
	} catch (err) {
		dispatch({
			type: USER_AZURE_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
export const logout = () => (dispatch) => {
	localStorage.removeItem('userInfo');
	dispatch({ type: USER_LOGOUT });
};
