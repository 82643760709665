import { AGENT_LIST_REQUEST, AGENT_LIST_SUCCESS, AGENT_LIST_FAIL } from '../constants/userConstants';

export const agentReducer = (state = {}, action) => {
	switch (action.type) {
		case AGENT_LIST_REQUEST:
			return { loading: true };

		case AGENT_LIST_SUCCESS:
			return { agentList: action.payload, loading: false };

		case AGENT_LIST_FAIL:
			return { loading: false, error: action.payload };


		default:
			return state;
	}
};
