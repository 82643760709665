import React from 'react';
import { Link } from 'react-router-dom';

import './Header.css';
const SalesHeader = () => {
	return (
		<header className='header'>
			<img src='/fav.png' alt='Logo' className='logo' />
			<nav className='navigation'>
				<ul className='navigation__links'>
					<li>
						<Link to='/sales-marketing' className='navigation__link'>
							Home
						</Link>
					</li>
					<li>
						<Link to='/sales-marketing/sales-template' className='navigation__link'>
							Templates
						</Link>
					</li>
					<li>
						<Link to='/sales-marketing/brochures' className='navigation__link'>
							Brochures
						</Link>
					</li>
					<li>
						<Link to='/sales-marketing/radio-script' className='navigation__link'>
							Radio Scripts
						</Link>
					</li>
					<li>
						<Link to='/sales-marketing/ourteam' className='navigation__link'>
							Our Team
						</Link>
					</li>
					<li>
						<Link to='/sales-marketing/marketing-resources' className='navigation__link'>
							Resources
						</Link>
					</li>
					<li>
						<Link to='/sales-marketing/contact' className='navigation__link'>
							Contact Us
						</Link>
					</li>
				</ul>
				<ul className='navigation__links'>
					<li>
						<Link to='/' className='navigation__link'>
							<svg className='footer__social--icon'>
								<use xlinkHref='/sprite.svg#icon-user' />
							</svg>
						</Link>
					</li>
					<li>
						<Link to='/sales-marketing/cart' className='navigation__link'>
							<svg className='footer__social--icon'>
								<use xlinkHref='/sprite.svg#icon-cart' />
							</svg>
						</Link>
					</li>
				</ul>
			</nav>
		</header>
	);
};

export default SalesHeader;
