import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import ReactPaginate from 'react-paginate'
import { deleteContentItem, imageUpload, addContentItem } from '../../../../redux/actions/contentAction'

const itemsPerPage = 10

const EditCoveragePoints = ({ setShowModal, showModal, coverageTitle, handleYes1, setCoverageTitle, handleYes, section4, setSection4, coveragePointsListArr, setCoveragePointsListArr }) => {
  const [accountNumber, setAccountNumber] = useState({ value: '', error: '' })

  const [loading, setLoading] = useState(false)

/*   const [title, setTitle] = useState({ value: '', error: '' }) */
  const [point, setPoint] = useState({ value: '', error: '' })

  const [editIndex, setEditIndex] = useState(-1)
  const [editData, setEditData] = useState({
/*     title: { value: "", error: "" }, */
    point: { value: "", error: "" },
  })

  const onAdd = () => {
    let valid = true

    if (point.value === '' && point.value.trim() === '') {
      setPoint({
        ...point,
        error: 'Please enter point',
      })
      valid = false
    }

    if (valid) {
      let content = {
        point: { value: point.value, action: '', type: '' },
      }

      if (editIndex > -1) {
        const temp = [...section4]
        temp[editIndex] = content

        console.log('updated', temp)
        setSection4(temp)
        handleYes(temp)
      } else {
        const temp = [...section4]
        temp.push(content)
        setSection4(temp)
        handleYes(temp)

        console.log('added', temp)
      }
    }
  }

  const handleDiscountSubmit = (e) => {
    e.preventDefault()
    if (coverageTitle.title.value === '' && coverageTitle.title.value.trim() === '') {
      let temp = { ...coverageTitle }
      temp.title.error = 'Please enter title!'
      setCoverageTitle(temp)
      setShowModal(false)
      return
    } else {
      let temp = { ...coverageTitle }
      temp.title.error = ''
      setCoverageTitle(temp)
      setShowModal(false)
    }

    handleYes1();
    }
  
  console.log(section4)

  const onEditPoints = (item, index) => {
    // e.preventDefault();
    // setLoading(true);
    setEditIndex(index)
    setPoint({ ...point, value: item.point.value })
  }

  const closeModal = () => {
    resetAll();
    setShowModal(false)
  }

  const resetAll = () => {
    setEditIndex(-1)
    setPoint({ ...point, value: "", error: "" })
  }

  const onDelete = (item, index) => {
    if (window.confirm('Are You Sure to Delete?')) {
      let temp = [...section4]
      temp.splice(index, 1)
      setSection4(temp)
      handleYes(temp)
    }
  }

  return (
    <Modal show={showModal}>
      <form onSubmit={handleYes}>
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Coverage Points</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => closeModal()}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content' style={{ width: '100%' }}>
              <div className='modal_inner_content--left' style={{ width: '100%' }}>
                <div className='inline-form-view' style={{ }}>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '70%' }}
                    required={true}
                    value={coverageTitle?.title?.value}
                    onChange={(e) => {
                      let temp = { ...coverageTitle }
                      temp.title.value = e.target.value
                      setCoverageTitle(temp)
                    }}
                    errorMessage={coverageTitle.error}
                  />
                  {/* <div style={{ display: 'flex', gap: 12, alignItems: 'flex-end' }}>
                    <button style={{height: '4rem',width:'7rem', marginBottom: '2px'}} type='button' className='modal__button-reset-dark' onClick={resetTitle}>Reset</button>
                    <button style={{height: '4rem',width:'7rem', marginBottom: '2px'}} type='button' className='modal__button-dark' onClick={onEditTitle}>Save</button>
                  </div> */}
                </div>
                <div className='inline-form-view'>
                  <FormInputComponent
                    heading='Coverage Point:'
                    required={true}
                    style={{ width: '87%' }}
                    value={point.value}
                    onChange={(e) => {
                      // let temp = { ...editData }
                      // temp.phone.value = e.target.value
                      // setEditData(temp)
                      setPoint({ error: '', value: e.target.value })
                    }}
                    errorMessage={point.error}
                  />
                  <div style={{ display: 'flex', gap: 12,alignItems: 'flex-end' }}>
                    <button style={{height: '4rem',width:'7rem', marginBottom: '2px'}} type='button' className='modal__button-reset-dark' onClick={resetAll}>Reset</button>
                    <button style={{height: '4rem',width:'7rem', marginBottom: '2px'}} type='button' className='modal__button-dark' onClick={onAdd}>Add</button>
                  </div>
                </div>

              </div>
              <div className='modal_inner_content--right3'>
                <div
                  className='modal_dark_buttons'
                  style={{ padding: '0.5rem' }}>
                </div>
                {/* <div style={{ display: 'flex', gap: 12 }}>
                  <button type='button' className='modal__button-reset-dark' onClick={resetAll}>Reset</button>
                  <button type='button' className='modal__button-dark' onClick={handleDiscountSubmit}>{loading ? 'Saving..' : (editIndex > -1 ? 'Save' : 'Add')}</button>
                </div> */}
              </div>
            </div>
            <div className='modal_inner_content'>
              <div className='modal_form_container'>
                <table
                  className='modal__form-table'
                  cellSpacing='0'
                  cellPadding='0'>
                  <thead className='modal__form-sticky'>
                    <tr className='modal__form-tr' >
                      <th>No.</th>
                      <th style={{ textAlign: 'left' }}>Coverage Points</th>
                      <th style={{marginTop: '8px', display: 'block', marginLeft: '70px' }}>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {section4 && section4.length > 0 &&
                      section4.map((item, i) => {
                        return (
                          <tr style={{height:'40px'}} className='modal__form-tr2' key={i}>
                            <td>{i + 1}</td>
                            <td style={{textAlign: 'left'}}>{item?.point?.value}</td>
                            <td style={{marginTop: '8px'}} className='modal__form-buttons'>
                              <button
                                type='button'
                                className='modal__button-edit'
                                onClick={(e) => onEditPoints(item, i)}>
                                edit
                              </button>
                              <button
                                type='button'
                                className='modal__button-delete'
                                onClick={(e) => onDelete(item, i)}>
                                delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </div>
              <div
            className='modal_dark_buttons'
            style={{
              marginTop: '4px',
            }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={handleDiscountSubmit}>
                Submit
              </button>
            )}
          </div>
            {/* <div className='ms-pagination2'>
              <h1 className='paginator_head'>
                {' '}
                Showing {itemOffset + 1} - {itemOffset + currentItems?.length}{' '}
                of {coveragePointsListArr.length} results
              </h1>

              <ReactPaginate
                nextClassName={''}
                breakLabel='...'
                nextLabel='>>'
                pageCount={pageCount}
                onPageChange={onPageChange}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                previousLabel='<<'
                // renderOnZeroPageCount={null}
                containerClassName={'paginationBttns'}
                previousLinkClassName={'previousBttn'}
                nextLinkClassName={'nextBttn'}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginationActive'}
              />
            </div> */}
          </div>

          {/* <div className='modal_dark_buttons' style={{ paddingTop: '0' }}>
            <button
              type='button'
              className='modal__button-reset-dark'
              onClick={() => setShowModal(false)}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={(e) => handleDiscountSubmit()}>
                Submit
              </button>
            )}
          </div> */}
        </div>
      </form>
    </Modal>
  )
}

export default EditCoveragePoints
