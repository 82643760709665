import React, { Fragment, useState, useEffect, useCallback } from 'react'
// import { useHistory } from 'react-router-dom'
import Modal from '../../../../components/DarkModal/index'
import FormInputComponent from '../../../../components/FormInputComponent/FormInputComponent'
import { useDropzone } from 'react-dropzone'
import { Link } from 'react-router-dom'

const EditProduct = ({ setShowModal, showModal, handleYes, section2, setSection2 }) => {
  const [loading, setLoading] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [title, setTitle] = useState({ value: 'Choose a product', error: '' })
  const [subTitle, setSubTitle] = useState({
    value: `that's right for you`,
    error: '',
  })
  const [productTitle, setProductTitle] = useState({
    value: '',
    error: '',
  })

  const [productLink, setProductLink] = useState({
    value: '',
    error: '',
  })
  const [imageName, setImageName] = useState({
    value: '',
    error: '',
  })
  const [redirectTo, setRedirectTo] = useState({
    value: '',
    error: '',
  })
  const [editIndex, setEditIndex] = useState(null);

  const [productList, setProductList] = useState([])
  const resetHandler = () => {
    setProductTitle({
      value: '',
      error: '',
    })
    setProductLink({
      value: '',
      error: '',
    })
    setRedirectTo({
      value: '',
      error: '',
    })
    setImageName({
      value: '',
      error: '',
    })
    setCurrentIndex(-1)
    setIsUpdate(false)
  }

  const onAddhandler = () => {
    let validated = true

    if (productTitle.value === '' && productTitle.value.trim() === '') {
      setProductTitle({
        ...productTitle,
        error: 'Please enter a product title',
      })
      validated = false
    }
    if (imageName.value === '' && imageName.value.trim() === '') {
      setImageName({
        ...imageName,
        error: 'Please enter a icon name',
      })
      validated = false
    }
    if (redirectTo.value === '' && redirectTo.value.trim() === '') {
      setRedirectTo({
        ...productLink,
        error: 'Please enter a redirection url',
      })
      validated = false
    }

    if (validated) {
      let newArray = section2.productList

      newArray.push({
        title: productTitle.value,
        action: redirectTo.value,
        iconName: imageName.value,
      })
      setSection2({ ...section2, productList: newArray })
      resetHandler()
    }
  }
  const setTextData = (key, value) => {
    let temp = { ...section2 };
    temp[key].value = value
    setSection2(temp)
  }
  const deleteHandler = (index) => {
    // const filtered = productList.filter((item, i) => {
    //   return item.title !== data.title
    // })
    let temp = section2.productList;
    temp.splice(index, 1);

    setSection2({ ...section2, productList: temp })
    resetHandler()
  }
  const editHandler = (data, index) => {
    setProductTitle({
      value: data.title,
      error: '',
    })
    setProductLink({
      value: data.url,
      error: '',
    })
    setImageName({
      value: data.iconName,
      error: '',
    })
    setRedirectTo({
      value: data.action,
      error: '',
    })
    setIsUpdate(true)
    setCurrentIndex(index)
  }

  const saveHandler = () => {
    let validated = true

    if (productTitle.value === '' && productTitle.value.trim() === '') {
      setProductTitle({
        ...productTitle,
        error: 'Please enter a product title',
      })
      validated = false
    }
    if (imageName.value === '' && imageName.value.trim() === '') {
      setImageName({
        ...imageName,
        error: 'Please enter a icon name',
      })
      validated = false
    }
    if (redirectTo.value === '' && redirectTo.value.trim() === '') {
      setProductLink({
        ...redirectTo,
        error: 'Please enter a redirection url',
      })
      validated = false
    }

    if (validated) {
      let newArray = section2.productList
      if (currentIndex > -1) {
        newArray[currentIndex] = {
          title: productTitle.value,
          action: redirectTo.value,
          iconName: imageName.value,
        }
      } else {
        newArray.push({
          title: productTitle.value,
          action: redirectTo.value,
          iconName: imageName.value,
        })
      }
      setSection2({ ...section2, productList: newArray })
      resetHandler()
    }
  }
  const submit = (e) => {
    e.preventDefault();

    if (section2.title.value == '') {
      let temp = { ...section2 };
      temp.title.error = 'Please enter title!'
      setSection2(temp)
      return
    } else {
      let temp = { ...section2 };
      temp.title.error = ''
      setSection2(temp)
    }
    if (section2.title.value == '') {
      let temp = { ...section2 };
      temp.title.error = 'Please enter sub title!'
      setSection2(temp)
      return
    } else {
      let temp = { ...section2 };
      temp.title.error = ''
      setSection2(temp)
    }
    handleYes()
  }
  return (
    <Modal show={showModal}>
      <form >
        <div className='modal__container-dark'>
          <div className='modal_heading__container-dark'>
            <h1 className='modal_heading__text-dark'>Edit Product</h1>
            <button
              className='modal__heading--button-dark'
              onClick={() => {
                resetHandler()
                setShowModal(false)
              }}>
              <svg className='modal_heading__icon-dark'>
                <use xlinkHref='/sprite.svg#icon-cross' />
              </svg>
            </button>
          </div>
          <div className='modal__form-dark'>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Title:'
                    style={{ width: '80%' }}
                    value={section2.title.value}
                    errorMessage={section2.title.error}
                    required={true}
                    onChange={(e) => {
                      let temp = { ...section2 };
                      temp.title.value = e.target.value
                      setSection2(temp)
                      setTextData('title', e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right2'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Sub Title:'
                    style={{ width: '80%' }}
                    required={true}
                    value={section2.subTitle.value}
                    errorMessage={section2.subTitle.error}
                    onChange={(e) => {
                      let temp = { ...section2 };
                      temp.subTitle.value = e.target.value
                      setSection2(temp)
                      setTextData('subTitle', e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='modal__form_line'></div>
            <div className='modal_inner_content'>
              <div className='modal_inner_content--left'>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Product Title:'
                    style={{ width: '80%' }}
                    value={productTitle.value}
                    required={true}
                    onChange={(e) => {
                      setProductTitle({ error: '', value: e.target.value })
                    }}
                    errorMessage={productTitle.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Image Name:'
                    style={{ width: '80%' }}
                    value={imageName.value}
                    required={true}
                    onChange={(e) => {
                      setImageName({ error: '', value: e.target.value })
                    }}
                    errorMessage={imageName.error}
                  />
                </div>
                <div className='pre_heading'>
                  <FormInputComponent
                    heading='Redirect to:'
                    style={{ width: '80%' }}
                    value={redirectTo.value}
                    required={true}
                    onChange={(e) => {
                      setRedirectTo({ error: '', value: e.target.value })
                    }}
                    errorMessage={redirectTo.error}
                  />
                </div>
              </div>
              <div className='modal_inner_content--right3'>
                <div
                  className='mw_products__item'
                  style={{ marginTop: '5rem' }}>
                  <img
                    onLoad={(event) => (event.target.style.display = 'block')}
                    onError={(event) => (event.target.style.display = 'none')}
                    src={`${imageName.value}`}
                    className='mw_products__item--image'></img>
                  <p
                    className='mw_products__item--name'
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                      textAlign: 'center',
                    }}>
                    {productTitle.value}
                  </p>
                </div>

                <div
                  className='modal_dark_buttons'
                  style={{ paddingRight: '0rem' }}>
                  <button
                    className='modal__button-reset-dark'
                    onClick={resetHandler}>
                    Reset
                  </button>

                  {!isUpdate ? (
                    <button
                      type='button'
                      className='modal__button-dark'
                      onClick={onAddhandler}>
                      Add
                    </button>
                  ) : (
                    <button
                      type='button'
                      className='modal__button-dark'
                      onClick={saveHandler}>
                      save
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className='modal_inner_content'>
              <table
                className='modal__form-table'
                cellspacing='0'
                cellpadding='0'>
                <thead>
                  <tr className='modal__form-tr'>
                    <th>No.</th>
                    <th>Title</th>
                    <th>Icon Name</th>
                    <th>Redirect Link</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {section2.productList.map((item, i) => {
                    return (
                      <tr className='modal__form-tr2' key={i}>
                        <td>{i + 1}</td>
                        <td>{item.title}</td>
                        <td>{item.iconName}</td>
                        <td>{item.action}</td>
                        <td className='modal__form-buttons'>
                          <button
                            type='button'
                            className='modal__button-edit'
                            onClick={(e) => editHandler(item, i)}>
                            edit
                          </button>
                          <button
                            type='button'
                            className='modal__button-delete'
                            onClick={(e) => deleteHandler(i)}>
                            delete
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className='modal_dark_buttons'>
            <button
              className='modal__button-reset-dark'
              onClick={() => {
                setShowModal(false)
                resetHandler()
              }}>
              Cancel
            </button>
            {loading ? (
              <button className='modal__button-dark'>Please wait...</button>
            ) : (
              <button className='modal__button-dark' onClick={submit}>
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default EditProduct
