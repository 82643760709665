import React, { useState, useEffect } from "react";
import Banner from "../../../components/Banner/Banner";
import { Link } from "react-router-dom";

import Content from "../../../components/Content/Content";
import "./Farm&RanchInsurance.css";
import {
  addContent,
  getPageContent,
} from "../../../redux/actions/contentAction";
import EditFarmRanchHeader from "./edit/EditFarmRanchHeader";
import EditFarmRanchPoints from "./edit/EditFarmRanchPoints";
import EditFarmRanchOptions from "./edit/EditFarmRanchOptional";
import { validateJsonString } from "../../../redux/constants/utils";
import EditCoveragePoints from "./edit/EditCoveragePoints";
import EditFarmRanchDiscountTitle from "./edit/EditFarmRanchDiscountTitle";
import EditDiscountType from "./edit/EditDiscountType";
import { ADMIN_USERS } from "../../../redux/actions/ip";
import { useSelector } from "react-redux";

const PAGE_NAME = "Farm&Ranch";
const FarmRanchInsurance = () => {

  const azureLogin = useSelector((state) => state.azureLogin)
  const [checked, setChecked] = useState("checked1");
  const [showEdit, setShowEdit] = useState(false);
  const [showFarmRanchHeader, setShowFarmRanchHeader] = useState(false);
  const [showFarmRanchPoints, setShowFarmRanchPoints] = useState(false);
  const [showFarmRanchOptional, setShowFarmRanchOptional] = useState(false);
  const [showCoveragePointsModal, setShowCoveragePointsModal] = useState(false);
  const [showFarmRanchTitle, setShowFarmRanchTitle] = useState(false);
  const [coveragePointsListArr, setCoveragePointsListArr] = useState([]);
  const [showDiscountTypeModal, setShowDiscountTypeModal] = useState(false);
  const [discountListArr, setDiscountListArr] = useState([
    {
      title: "Retirement Funding ",
      description:
        "Individual Retirement Accounts (IRAs) can be a good option for individuals who do not	have access to an employer- sponsored retirement plan or who want an option in addition	to an employersponsored plan",
      src: "https://www.mwfbi.com/images/Graphics-Retirement-Funding.png",
    },
    {
      title: "Retirement Funding ",
      description:
        "Individual Retirement Accounts (IRAs) can be a good option for individuals who do not	have access to an employer- sponsored retirement plan or who want an option in addition	to an employersponsored plan",
      src: "https://www.mwfbi.com/images/Graphics-Mutual-Funds.png",
    },
    {
      title: "Retirement Funding ",
      description:
        "Individual Retirement Accounts (IRAs) can be a good option for individuals who do not	have access to an employer- sponsored retirement plan or who want an option in addition	to an employersponsored plan",
      src: "https://www.mwfbi.com/images/Graphics-College-Funding.png",
    },
  ]);

  const [bannerData, setBannerData] = useState({
    title: { value: "Farm & Ranch Insurance", error: "" },
    subTitle: {
      value: "We insure more than your stuff. We insure your lifestyle.",
      error: "",
    },
    tagline: {
      value: "Start a quote and protect your farm and ranch operation today.",
      error: "",
    },
    button1: { value: "START A QUOTE", error: "" },
    button1Link: { value: "/mwfbi/start-a-quote", error: "" },
    button1Status: { value: true, error: "" },
    button2: { value: "FIND AN AGENT", error: "" },
    button2Link: { value: "/mwfbi/find-an-agent", error: "" },
    button2Status: { value: true, error: "" },
    imageUrl: { value: "/images/cowboys&cattle.jpg", error: "" },
  });

  const [section1, setSection1] = useState({
    title: { value: "Farm & Ranch Coverage", action: "", type: "" },
    description: {
      value: `The Ag Advantage policy offers coverage for the property exposures of your farm and ranch, including your home, outbuildings, and farm and ranch personal property. 360 Insurance also offers a variety of optional coverage that can be added to your policy to meet your individual needs and tailor your policy to fit you.`,
    },
  });

  const [section2, setSection2] = useState([
    {
      title: {
        value: "Property Coverage",
        action: "",
        type: "",
      },
      description: {
        value:
          "Country Squire offers coverage for the property exposures of your farm and ranch including your home, farm structures, and farm and ranch personal property. Some optional coverages include: replacement cost coverage for household personal property, earthquake, backup of sewer or drains, and waiver of glass deductible.",
      },
      example: {
        value:
          "Lightning strikes your cattle, killing a few head. Your policy will reimburse you the current market value of the lost cattle up to your policy limits.",
      },
    },
    {
      title: {
        value: "Auto Coverage",
        action: "",
        type: "",
      },
      description: {
        value:
          "Our auto insurance provides coverage for comprehensive, collision, and liability exposures. In addition, various deductible options and discounts allow you to customize the policy to meet your needs. Some optional coverage include: theft rental reimbursement, special equipment or paint, rental expense, additional living expense, and accidental death or dismemberment.",
      },
      example: {
        value:
          "You’re driving your ranch truck full of beets. You swerve to miss a deer and roll your truck. Your collision coverage would apply to the accident",
      },
    },
    {
      title: {
        value: "Liability Insurance",
        action: "",
        type: "",
      },
      description: {
        value:
          "In addition to standard liability coverage, we offer farm liability coverage for the unique exposures of a farm or ranch. Standard coverage include bodily injury and property damage to protect you from damages to others for which you are legally obligated and medical payments to cover medical expenses of those who are injured on your property.",
      },
      example: {
        value:
          "You are driving your tractor down the highway early in the morning. You turn left in front of an oncoming vehicle causing damage and injury to that driver. Liability coverage will protect you.",
      },
    },
    {
      title: {
        value: "Inland Marine",
        action: "",
        type: "",
      },
      description: {
        value:
          "Enhanced coverage is available for your valuable personal property such as jewelry, furs, silverware, guns, coin and stamp collections, and fine arts. We can also insure recreational vehicles, truck cargo, saddles, tack, hay, grain, livestock, farm equipment, and irrigation systems.",
      },
      example: {
        value:
          "You own a high-valued bull who wanders out onto the highway and gets hit by a vehicle. Inland Marine can help replace the market value of the bull up to the policy limit.",
      },
    },
    {
      title: {
        value: "Umbrella",
        action: "",
        type: "",
      },
      description: {
        value:
          "If you add on an Umbrella policy, you can get additional liability coverage to protect you against catastrophic liability claims.",
      },
      example: {
        value:
          "If you employ ranch-hands and a job related accident occurs, you could be sued for medical expenses, lost wages and attorney fees. Umbrella coverage can increase your limits to help protect your high-valued property and assets.",
      },
    },
  ]);

  const [section3, setSection3] = useState({
    title: { value: "Optional Coverages", action: "", type: "" },
    description: {
      value: `Country Squire is a flexible policy that can be tailored to provide the coverage and limits you select-at a cost lower than buying them separately. You can cover the following under one comprehensive policy.`,
    },
  });

  const [coverageTitle, setCoverageTitle] = useState({
    title: {
      value: "COVER THE REPLACEMENT COST OF YOUR PERSONAL PROPERTY, SUCH AS:",
      action: "",
      type: "",
    },
  });

  const handleDiscountType = (content) => {
    addContent({
      pageName: PAGE_NAME,
      section: "Section_6",
      content: { list: { value: JSON.stringify(content) } },
    }).then((result) => {
      console.log(result.data);

      if (result.data.status) {
        getPageContent({ pageName: PAGE_NAME }).then((res) => {
          if (res.data.status) {
            if (res.data.result.Section_6) {
              setSection6({
                discountList: JSON.parse(res.data.result.Section_6.list.value),
              });
              setDiscountListArr(
                JSON.parse(res.data.result.Section_6.list.value)
              );
            }
          }
        });
        alert("Content Saved");
      }
    });
  };

  const [section4, setSection4] = useState([
    {
      point: {
        value: "Personal and Farm & Ranch Liability",
        action: "",
        type: "",
      },
    },
    {
      point: { value: "Farm Equipment", action: "", type: "" },
    },
    {
      point: {
        value: "Additional Living Expense",
        action: "",
        type: "",
      },
    },
    {
      point: {
        value: "Livestock, Hay, and Grain",
        action: "",
        type: "",
      },
    },
    {
      point: { value: "Dwellings", action: "", type: "" },
    },
    {
      point: {
        value: "Auto Liability & Physical Damage",
        action: "",
        type: "",
      },
    },
    {
      point: { value: "Farm Personal Property", action: "", type: "" },
    },
    {
      point: { value: "Additional Buildings", action: "", type: "" },
    },
    {
      point: { value: "Recreational Vehicles & Boats", action: "", type: "" },
    },
    {
      point: { value: "Sprinkler Systems", action: "", type: "" },
    },
    {
      point: {
        value: " Household Personal Property",
        action: "",
        type: "",
      },
    },
    {
      point: {
        value: "Umbrella Protection",
        action: "",
        type: "",
      },
    },
  ]);

  const [section5, setSection5] = useState({
    title: { value: "Farm & Ranch Discounts", action: "", type: "" },
    description: {
      value: `Are you looking for quality car insurance that you can afford without sacrifi cing coverage? Mountain West is proud of the high-quality products and services we offer. The following discounts refl ect our commitment to offer these products at an affordable rate without sacrifi cing coverage.`,
    },
  });

  const [section6, setSection6] = useState({
    discountList: [
      {
        title: "Federation Membership",
        description:
          "Becoming a Federation Member qualifi es you for many perks and discounts that will save you money.",
        src: "./images/Graphics-Federation-Membership.png",
      },
      {
        title: "Hay Credit",
        description:
          "If we insure your peak season hay for a full year, you will receive a credit for one half of your hay premium when your policy renews.",
        src: "./images/Graphics-Hay-Credit.png",
      },
      {
        title: "Farm Use",
        description:
          "Many discounts are available for farm trucks and farm vehicles with permanently mounted equipment.",
        src: "./images/Graphics-Farm-Use.png",
      },
      {
        title: "New Home",
        description:
          "Our premium automatically refl ects a discount if your home was built in the last 12 years..",
        src: "./images/Graphics-New-Home.png",
      },
      {
        title: "Home Alert",
        description:
          "A discount for homeowners who protect their homes with various qualifying alarm and alert systems",
        src: "./images/Graphics-Home-Alert.png",
      },
      {
        title: "Resistive Roofing",
        description:
          "A discount for roofs that are constructed of qualifying “wind resistive” or “hail resistive” materials.",
        src: "./images/Graphics-Resistive-Roofing.png",
      },
      {
        title: "Loyalty",
        description:
          "If you have been with your current insurance company for 3, 6, 10 years or longer, we want a client like you!",
        src: "./images/Graphics-Loyalty.png",
      },
      {
        title: "Package Policy",
        description:
          "If you choose to insure your home and autos with Mountain West, there could be a discount.",
        src: "./images/Graphics-Package-Policy.png",
      },
    ],
  });

  const handleFarmRanchHeaderSubmit = () => {
    addContent({
      pageName: PAGE_NAME,
      section: "Section_1",
      content: section1,
    }).then((result) => {
      console.log(result.data);
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const handleFarmRanchPointsSubmit = (section2Content) => {
    addContent({
      pageName: PAGE_NAME,
      section: "Section_2",
      content: {
        list: { value: validateJsonString(JSON.stringify(section2Content)) },
      },
    }).then((result) => {
      console.log(result.data);
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const handleFarmRanchHeaderOptionalSubmit = () => {
    addContent({
      pageName: PAGE_NAME,
      section: "Section_3",
      content: section3,
    }).then((result) => {
      console.log(result.data);
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const handleCoveragePoints = (content) => {
    addContent({
      pageName: PAGE_NAME,
      section: "Section_4",
      content: { list: { value: JSON.stringify(content) }, coverageTitle: coverageTitle },
    }).then((result) => {
      console.log(result.data);
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const handleCoveragePointsTitle = (content) => {
    addContent({
      pageName: PAGE_NAME,
      section: "Section_4",
      content: coverageTitle,
    }).then((result) => {
      console.log(result.data);
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const handleFarmRanchTitle = () => {
    addContent({
      pageName: PAGE_NAME,
      section: "Section_5",
      content: section5,
    }).then((result) => {
      console.log(result.data);
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const splitArray = (array, perChunk = 6) => {
    if (Array.isArray(array) && array.length) {
      return array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    }
    return [];
  };
  useEffect(() => {
    if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
      if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
        setShowEdit(true)
      } else setShowEdit(false)
    } else setShowEdit(false)
  }, [azureLogin]);

  useEffect(() => {
    getPageContent({ pageName: PAGE_NAME }).then((res) => {
      // debugger
      if (res.data.status) {
        if (res.data.result.Section_1) setSection1(res.data.result.Section_1)
        if (res.data.result.Section_2)
          setSection2(JSON.parse(res.data.result.Section_2.list.value))
        if (res.data.result.Section_3) setSection3(res.data.result.Section_3)
        if (res?.data?.result?.Section_4) {
          if (res?.data?.result?.Section_4.title)
            setCoverageTitle({ title: { ...res.data.result.Section_4.title } });
          if (res.data.result.Section_4 && res.data.result.Section_4.list)
            setSection4(JSON.parse(res.data.result.Section_4.list.value));
        }
        if (res.data.result.Section_5) setSection5(res.data.result.Section_5)
        if (res.data.result.Section_6) {
          setSection6({
            discountList: JSON.parse(res.data.result.Section_6.list.value),
          });
          setDiscountListArr(JSON.parse(res.data.result.Section_6.list.value));
        }
        if (res.data.result.TopBanner)
          setBannerData({
            ...res.data.result.TopBanner,
            button1Status: {
              value: res.data.result.TopBanner.button1Status.value === "true",
            },
            button2Status: {
              value: res.data.result.TopBanner.button2Status.value === "true",
            },
          });
      }
    })
  }, [])

  return (
    <Content headerFooterMenu="mountain-west">
      <Banner
        showEdit={showEdit}
        pageName={PAGE_NAME}
        bannerData={bannerData}
        setBannerData={setBannerData}
        backgroundImage="linear-gradient(rgba(47,35,98,0.4), rgba(47,35,98,0.4)),url(/images/cowboys&cattle.jpg)"
        title="Farm & Ranch Insurance"
        subTitle="We insure more than your stuff. We insure your lifestyle."
      />
      <div className='quote2'>
        <div className='flex2'>
          <h3 className='quote__text2'>
            {bannerData && bannerData.tagline
              ? bannerData.tagline.value
              : 'Start a business insurance quote and begin Working with your local agent.'}
          </h3>
        </div>
        <div className='header_buttons'>
          {bannerData && bannerData.button1 && bannerData.button1Status.value && (
            <Link to={bannerData.button1Link.value} className='mw_button'>
              {bannerData.button1.value}
            </Link>
          )}
          {bannerData && bannerData.button2 && bannerData.button2Status.value && (
            <Link to={bannerData.button2Link.value} className='mw_button'>
              {bannerData.button2.value}
            </Link>
          )}
        </div>
      </div>

      <div
        className="Farm_ranch_insurance"
        id="f&r_coverage"
        style={{ width: "100%", position: "relative" }}
      >
        <h1 className="phase2_heading">
          {section1?.title?.value ? section1.title.value : ""}
        </h1>
        <p className="phase2_subheading">
          {section1?.description?.value ? section1.description.value : ""}
        </p>
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "1rem" }}
            id="admin-edit"
            onClick={() => setShowFarmRanchHeader(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
        <EditFarmRanchHeader
          showModal={showFarmRanchHeader}
          setShowModal={setShowFarmRanchHeader}
          handleYes={handleFarmRanchHeaderSubmit}
          section1={section1}
          setSection1={setSection1}
        />
        <EditFarmRanchOptions
          showModal={showFarmRanchOptional}
          setShowModal={setShowFarmRanchOptional}
          handleYes={handleFarmRanchHeaderOptionalSubmit}
          section1={section3}
          setSection1={setSection3}
        />
        <EditCoveragePoints
          coveragePointsListArr={coveragePointsListArr}
          section4={section4}
          setSection4={setSection4}
          handleYes={handleCoveragePoints}
          handleYes1={handleCoveragePointsTitle}
          setCoveragePointsListArr={setCoveragePointsListArr}
          setShowModal={setShowCoveragePointsModal}
          coverageTitle={coverageTitle}
          setCoverageTitle={setCoverageTitle}
          showModal={showCoveragePointsModal}
        />
      </div>

      {section2 && section2.length && (
        <div
          className="annuities_tabset tabset"
          style={{
            width: "100%",
            position: "relative",
            padding: "3rem 33rem 0rem",
          }}
        >
          {section2.map((item, index) => (
            <React.Fragment key={`checked${index}`}>
              <input
                type="radio"
                name="tabset"
                id={`tab${index + 1}`}
                aria-controls={`tab-${item.title.value}`}
                checked={checked === `checked${index + 1}`}
                onChange={() => setChecked(`checked${index + 1}`)}
              />
              <label style={{ width: "200px" }} htmlFor={`tab${index + 1}`} key={`label${index}`}>
                {item.title.value}
              </label>
            </React.Fragment>
          ))}
          <div className="tab-panels">
            {section2.map((item, index) => (
              <section
                id={`tab-fixed-annuities${index}`}
                className="tab-panel"
                key={`tab-fixed-annuities${index}`}
              >
                <p className="phase2_subheading">{item.description.value}</p>
                {item.example != "" && (
                  <>
                    <br />
                    <br />
                  </>
                )}
                <p className="tab_about__data">
                  {item.example != "" && (
                    <div className="tab_comprehensive">
                      <p className="phase2_heading1">Example:</p>
                      <p className="phase2_subheading">{item.example.value}</p>
                    </div>
                  )}
                </p>
              </section>
            ))}
            {showEdit && (
              <div
                className="mwfbi_banner__edit"
                style={{ top: "1rem" }}
                id="admin-edit"
                onClick={() => setShowFarmRanchPoints(true)}
              >
                <svg>
                  <use xlinkHref="/sprite.svg#icon-edit" />
                </svg>
              </div>
            )}
          </div>
          <br />
          <br />
        </div>
      )}
      <EditFarmRanchPoints
        showModal={showFarmRanchPoints}
        setShowModal={setShowFarmRanchPoints}
        handleYes={handleFarmRanchPointsSubmit}
        section2={section2}
        setSection2={setSection2}
      />
      <EditFarmRanchDiscountTitle
        showModal={showFarmRanchTitle}
        setShowModal={setShowFarmRanchTitle}
        handleYes={handleFarmRanchTitle}
        section5={section5}
        setSection5={setSection5}
      />
      <EditDiscountType
        handleYes={handleDiscountType}
        showModal={showDiscountTypeModal}
        setShowModal={setShowDiscountTypeModal}
        discountListArr={discountListArr}
        setDiscountListArr={setDiscountListArr}
      />


      <div
        className="Farm_insurance_coverage"
        id="optional_coverages"
        style={{ width: "100%", position: "relative", marginTop: "2rem" }}
      >
        <h1 className="phase2_heading">
          {section3?.title?.value ? section3.title.value : ""}
        </h1>
        <p className="phase2_subheading">
          {section3?.description?.value ? section3.description.value : ""}

          {/* Country Squire is a flexible policy that can be tailored to provide
          the coverage and limits you select-at a cost lower than buying them
          separately. You can cover the following under one comprehensive
          policy. */}
        </p>
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "1rem" }}
            id="admin-edit"
            onClick={() => setShowFarmRanchOptional(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "12rem" }}
            id="admin-edit"
            onClick={() => setShowCoveragePointsModal(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}

        <div
          className="business_coverage_insurance"
          style={{ width: "100%", position: "relative" }}
        >
          <h1 className="phase2_heading">{coverageTitle.title.value}</h1>
          <div style={{ display: "flex", gap: "6rem" }}>
            {section4 && section4.length
              ? splitArray(section4, 6).map((elements, i) => (
                <div className="business_insurance-cover">
                  <div
                    className="homeowner-renter-insurance-list"
                    key={"parent" + i}
                  >
                    {elements.length &&
                      elements.map((item, i) => (
                        <h1 className="phase2_subheading">
                          &#10004; {item.point.value}
                        </h1>
                      ))}
                  </div>
                </div>
              ))
              : null}
          </div>
        </div>
      </div>
      <div
        className="auto_insurance_discounts"
        id="f&r_discounts"
        style={{ width: "100%", position: "relative" }}
      >
        <h1 className=" phase2_heading">{section5.title.value}</h1>
        <p className="phase2_subheading">{section5.description.value}</p>
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "3rem" }}
            id="admin-edit"
            onClick={() => setShowFarmRanchTitle(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}

        {/* <div className="auto_insurance_discounts-types">
          <div className="auto_insurance_discounts-grid">
            <div className="discounts-types">
              <img
                src="./images/Graphics-Federation-Membership.png"
                alt="federation"
                className="discounts_images_nails"
              />

              <div className="discount_image_text">
                <h1 className="phase2_heading1">Federation Membership</h1>
                <p className="phase2_subheading">
                  Becoming a Federation Member qualifies you for many perks and
                  discounts that will save you money.
                </p>
              </div>
              <div className="line" />
            </div>
            <div className="discounts-types">
              <img
                src="./images/Graphics-Hay-Credit.png"
                alt="hay credit"
                className="discounts_images_nails"
              />
              <div className="discount_image_text">
                <h1 className="phase2_heading1">Hay Credit</h1>
                <p className="phase2_subheading">
                  If we insure your peak season hay for a full year, you will
                  receive a credit for one half of your hay premium when your
                  policy renews.
                </p>
              </div>
              <div className="line" />
            </div>
            <div className="discounts-types">
              <img
                src="./images/Graphics-Farm-Use.png"
                alt="farm use"
                className="discounts_images_nails"
              />
              <div className="discount_image_text">
                <h1 className="phase2_heading1">Farm Use</h1>
                <p className="phase2_subheading">
                  Many discounts are available for farm trucks and farm vehicles
                  with permanently mounted equipment.
                </p>
              </div>
            </div>
          </div>
          <div className="auto_insurance_discounts-grid">
            <div className="discounts-types">
              <img
                src="./images/Graphics-New-Home.png"
                alt="new home"
                className="discounts_images_nails"
              />

              <div className="discount_image_text">
                <h1 className="phase2_heading1">New Home</h1>
                <p className="phase2_subheading">
                  Our premium automatically reflects a discount if your home was
                  built in the last 12 years..
                </p>
              </div>
              <div className="line" />
            </div>
            <div className="discounts-types">
              <img
                src="./images/Graphics-Home-Alert.png"
                alt="home alert"
                className="discounts_images_nails"
              />
              <div className="discount_image_text">
                <h1 className="phase2_heading1">Home Alert</h1>
                <p className="phase2_subheading">
                  A discount for homeowners who protect their homes with various
                  qualifying alarm and alert systems
                </p>
              </div>
              <div className="line" />
            </div>
            <div className="discounts-types">
              <img
                src="./images/Graphics-Resistive-Roofing.png"
                alt="resistive roofing"
                className="discounts_images_nails"
              />
              <div className="discount_image_text">
                <h1 className="phase2_heading1">Resistive Roofing</h1>

                <p className="phase2_subheading">
                  A discount for roofs that are constructed of qualifying “wind
                  resistive” or “hail resistive” materials.
                </p>
              </div>
            </div>
          </div>
          <div className="auto_insurance_discounts-grid">
            <div className="discounts-types">
              <img
                src="./images/Graphics-Loyalty.png"
                alt="loyalty"
                className="discounts_images_nails"
              />
              <div className="discount_image_text">
                <h1 className="phase2_heading1">Loyalty</h1>
                <p className="phase2_subheading">
                  If you have been with your current insurance company for 3, 6,
                  10 years or longer, we want a client like you!
                </p>
              </div>
              <div className="line" />
            </div>
            <div className="discounts-types">
              <img
                src="./images/Graphics-Package-Policy.png"
                alt="package policy"
                className="discounts_images_nails"
              />
              <div className="discount_image_text">
                <h1 className="phase2_heading1">Package Policy</h1>
                <p className="phase2_subheading">
                  If you choose to insure your home and autos with 360
                  Insurance, there could be a discount.
                </p>
              </div>
              <div className="line" />
            </div>
          </div>
        </div> */}
      </div>
      <div
        className="life_insurance_options"
        id="coverage_options"
        style={{ width: "100%", position: "relative", backgroundColor: '#ffffff', marginBottom: '0rem' }}
      >
        {discountListArr &&
          discountListArr.length &&
          splitArray(discountListArr, 3).map((elements, i) => (
            <div
              className="homeowner_insurance_discounts-grid"
              style={{ paddingTop: '0rem' }}
              key={"parent" + i}
            >
              {elements.length &&
                elements.map((item, i) => (
                  <div className="discounts-types">
                    <img
                      src={item.src}
                      alt={item.title}
                      className="discounts_images_nails"
                    />

                    <div className="discount_image_text">
                      <h1 className="phase2_heading1">{item.title}</h1>
                      <p className="phase2_subheading">{item.description}</p>
                    </div>
                    {i <= 1 ? <div className="line" /> : <div />}
                  </div>
                ))}
            </div>
          ))}
        <EditDiscountType
          handleYes={handleDiscountType}
          showModal={showDiscountTypeModal}
          setShowModal={setShowDiscountTypeModal}
          discountListArr={discountListArr}
          setDiscountListArr={setDiscountListArr}
        />
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "1rem" }}
            id="admin-edit"
            onClick={() => setShowDiscountTypeModal(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
      </div>
    </Content>
  );
};
export default FarmRanchInsurance;
