import React, { useEffect, useState } from "react";
import Banner from "../../../components/Banner/Banner";
import { Link } from "react-router-dom";

import Content from "../../../components/Content/Content";
import "./BusinessInsurance.css";

import {
  addContent,
  getPageContent,
} from "../../../redux/actions/contentAction";
import { useSelector } from "react-redux";
import EditCoverageInfo from "./edit/EditCoverageInfo";
import EditDiscountsInfo from "./edit/EditDiscountsInfo";
import EditCoveragePoints from "./edit/EditCoveragePoints";
import EditBusinessInfo from "./edit/EditBusinessInfo";
import EditBusinessPoints from "./edit/EditBusinessPoints";
import EditDiscountType from "./edit/EditDiscountType";
import { ADMIN_USERS } from "../../../redux/actions/ip";

const BusinessInsurance = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showCoverageInfoModal, setShowCoverageInfoModal] = useState(false);
  const [showDiscountsInfoModal, setShowDiscountsInfoModal] = useState(false);
  const [showBusinessInfoModal, setShowBusinessInfoModal] = useState(false);
  const [showCoveragePointsModal, setShowCoveragePointsModal] = useState(false);
  const [showBusinessPointsModal, setShowBusinessPointsModal] = useState(false);
  const [showDiscountTypeModal, setShowDiscountTypeModal] = useState(false);
  const azureLogin = useSelector((state) => state.azureLogin);
  const [checked, setChecked] = useState("checked1");
  const [discountListArr, setDiscountListArr] = useState([
    {
      title: "Package Policy",
      description:
        "If you choose to unsure your commercial property and/ or liability scheduled with commercial auto, there may be a discount for you!",
      src: "./images/Graphics-Package-Policy.png",
    },
    {
      title: "Claims Free Auto",
      description:
        "If you are claims free with your vehicle, a discount can be applied to your commercial auto insurance.",
      src: "./images/Graphics-Claims-Free-Auto.png",
    },
    {
      title: "Fleet",
      description:
        "If your business has five or more autos under your business policy, we have a discount for your fleet..",
      src: "./images/Graphics-Fleet.png",
    },
  ]);
  const [coveragePointsListArr, setCoveragePointsListArr] = useState([]);
  const [section1, setSection1] = useState({
    title: { value: "Business Insurance", action: "", type: "" },
    description: {
      value: `Commercial insurance from 360 Insurance includes a comprehensive package of coverage choices. You
			can choose the coverage options that provide solutions for your concerns and help give you financial
			peace of mind.`,
    },
  });
  const [section3, setSection3] = useState({
    title: { value: "Coverage Options", action: "", type: "" },
    description: {
      value: `Our standard business owners policy provides comprehensive coverage with the flexibility to adapt to your unique business needs. You can cover the following under one comprehensive policy:`,
    },
  });

  const [section6, setSection6] = useState({
    discountList: [
      {
        title: "Retirement Funding ",
        description:
          "Individual Retirement Accounts (IRAs) can be a good option for individuals who do not	have access to an employer- sponsored retirement plan or who want an option in addition	to an employersponsored plan",
        src: "https://www.mwfbi.com/images/Graphics-Retirement-Funding.png",
      },
      {
        title: "Retirement Funding ",
        description:
          "Individual Retirement Accounts (IRAs) can be a good option for individuals who do not	have access to an employer- sponsored retirement plan or who want an option in addition	to an employersponsored plan",
        src: "https://www.mwfbi.com/images/Graphics-Mutual-Funds.png",
      },
      {
        title: "Retirement Funding ",
        description:
          "Individual Retirement Accounts (IRAs) can be a good option for individuals who do not	have access to an employer- sponsored retirement plan or who want an option in addition	to an employersponsored plan",
        src: "https://www.mwfbi.com/images/Graphics-College-Funding.png",
      },
    ],
  });

  const [section2, setSection2] = useState([
    {
      title: "Property Coverage",
      description: `Business property insurance provides coverage for your buildings, business personal property, loss of business income, and other exposures.`,
      example:
        "A car accidentally runs into your building. Your business insurance will cover the building repairs, loss of product and business income. *depending on the coverage you purchase",
    },
    {
      title: "Auto Coverage",
      description: `Business vehicle insurance protects your business from potential financial losses involving a car, truck, or trailer used for business purposes. In addition, it provides coverage if you or your employees are involved in an accident and are held legally liable.`,
      example:
        "Your employee is out delivering flowers for your florist business and they are rear-ended by another vehicle. Standard auto coverage will apply for this accident.",
    },
    {
      title: "Liability Insurance",
      description: `Business liability insurance covers bodily injury, property damage, personal injury, and advertising injury that may occur at your business or as a result of your operations and products. It includes medical payments to cover medical expenses of those who are injured on your property.`,
      example:
        "A customer slips and falls on the sidewalk in front of your building. Liability will protect you for the injuries they sustain",
    },
    {
      title: "Inland Marine",
      description: `You may need extra protection for valuable business property that you take away from your principal business location.`,
      example:
        "Your tools are damaged at a work site away form your business location. Coverage can help pay for the loss up to the limit you placed on your policy.",
    },
    {
      title: "Umbrella",
      description: `Additional liability coverage is available to protect you against catastrophic liability claims.`,
      example: "",
    },
  ]);

  const [section4, setSection4] = useState([
    {
      point: { value: "Business Liability", action: "", type: "" },
    },
    {
      point: { value: "Equipment", action: "", type: "" },
    },
    {
      point: {
        value: "Business Personal Property/Inventory",
        action: "",
        type: "",
      },
    },
    {
      point: {
        value: "Business Vehicle Liability & Physical Damage",
        action: "",
        type: "",
      },
    },
    {
      point: { value: "Outdoor Signs", action: "", type: "" },
    },
    {
      point: { value: "Money and Securities", action: "", type: "" },
    },
    {
      point: { value: "Earthquake", action: "", type: "" },
    },
    {
      point: { value: "Condo Unit Owner’s Coverage", action: "", type: "" },
    },
    {
      point: { value: "Spoilage", action: "", type: "" },
    },
    {
      point: { value: "Umbrella Protection", action: "", type: "" },
    },
  ]);

  const [coverageTitle, setCoverageTitle] = useState({
    title: {
      value: "Special Equipment means any equipment or devices that alter the:",
      action: "",
      type: "",
    },
  });

  const [section5, setSection5] = useState({
    title: { value: "Business Insurance Discounts", action: "", type: "" },
    description: {
      value: `Are you looking for quality home insurance that you can afford without sacrificing coverage? 360 Insurance is proud of the high-quality products and services we offer. The following discounts reflect our commitment to offer these products at an affordable rate without sacrificing coverage.`,
    },
  });
  const [bannerData, setBannerData] = useState({
    title: { value: "Business Insurance", error: "" },
    subTitle: {
      value: "Providing peace of mind for your livelihood.",
      error: "",
    },
    tagline: {
      value:
        "Start a business insurance quote and begin Working with your local agent.",
      error: "",
    },
    button1: { value: "START A QUOTE", error: "" },
    button1Link: { value: "/360/start-a-quote", error: "" },
    button1Status: { value: true, error: "" },
    button2: { value: "FIND AN AGENT", error: "" },
    button2Link: { value: "/360/find-an-agent", error: "" },
    button2Status: { value: true, error: "" },
    imageUrl: { value: "/images/Business-Insurance.jpg", error: "" },
  });
  const handleCoverageInfoSubmit = () => {
    addContent({
      pageName: "Business",
      section: "Section_3",
      content: section3,
    }).then((result) => {
      console.log(result.data);
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const handleDiscountsInfoSubmit = () => {
    addContent({
      pageName: "Business",
      section: "Section_5",
      content: section5,
    }).then((result) => {
      console.log(result.data);
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const handleBusinessInfoSubmit = () => {
    addContent({
      pageName: "Business",
      section: "Section_1",
      content: section1,
    }).then((result) => {
      console.log(result.data);
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const handleBusinessPointsSubmit = () => {
    addContent({
      pageName: "Business",
      section: "Section_2_Update",
      content: { list: { value: JSON.stringify(section2) } },
    }).then((result) => {
      console.log(result.data);
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const handleCoveragePoints = (content) => {
    addContent({
      pageName: "Business",
      section: "Section_4",
      content: {
        list: { value: JSON.stringify(content) },
        coverageTitle: coverageTitle,
      },
    }).then((result) => {
      console.log(result.data);
      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const handleDiscountType = (content) => {
    addContent({
      pageName: "Business",
      section: "Section_6",
      content: { list: { value: JSON.stringify(content) } },
    }).then((result) => {
      console.log(result.data);

      if (result.data.status) {
        alert("Content Saved");
      }
    });
  };

  const splitArray = (array, perChunk = 3) => {
    if (Array.isArray(array) && array.length) {
      return array.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
    }
    return [];
  };

  useEffect(() => {
  	if (azureLogin && azureLogin.userInfo && azureLogin.userInfo.account) {
  		if (ADMIN_USERS.includes(azureLogin.userInfo.account.username)) {
  			setShowEdit(true)
  		} else setShowEdit(false)
  	} else setShowEdit(false)
  }, [azureLogin]);

  useEffect(() => {
    getPageContent({ pageName: "Business" }).then((res) => {
      if (res.data.status) {
        if (res.data.result.Section_1) setSection1(res.data.result.Section_1);
        if (res.data.result.Section_3) setSection3(res.data.result.Section_3);
        if (res?.data?.result?.Section_4) {
          if (res?.data?.result?.Section_4.title)
            setCoverageTitle({ title: { ...res.data.result.Section_4.title } });
          setSection4(JSON.parse(res.data.result.Section_4.list.value));
        }
        if (res.data.result.Section_5) setSection5(res.data.result.Section_5);
        if (res.data.result.Section_6) {
          /* setSection6({ discountList: JSON.parse(res.data.result.Section_6.list.value) }) */
          setDiscountListArr(JSON.parse(res.data.result.Section_6.list.value));
        }
        if (res.data.result.Section_2_Update) {
          /* setSection6({ discountList: JSON.parse(res.data.result.Section_2_Update.list.value) }) */
          setSection2(JSON.parse(res.data.result.Section_2_Update.list.value));
        }
        if (res.data.result.TopBanner)
          setBannerData({
            ...res.data.result.TopBanner,
            button1Status: {
              value: res.data.result.TopBanner.button1Status.value === "true",
            },
            button2Status: {
              value: res.data.result.TopBanner.button2Status.value === "true",
            },
          });
      }
    });
  }, []);

  return (
    <Content headerFooterMenu="mountain-west">
      <Banner
        backgroundImage="linear-gradient(rgba(47,35,98,0.4), rgba(47,35,98,0.4)),url(/images/Business-Insurance.jpg)"
        title="Business Insurance"
        backgroundPosition={"10% 71%"}
        subTitle="Providing peace of mind for your livelihood."
        bannerData={bannerData}
        setBannerData={setBannerData}
        showEdit={showEdit}
        pageName={"Business"}
      />
      <div className="quote2">
        <div className="flex2">
          <h3 className="quote__text2">
            {bannerData && bannerData.tagline
              ? bannerData.tagline.value
              : "Start a business insurance quote and begin Working with your local agent."}
          </h3>
        </div>
        <div className="header_buttons">
          {bannerData &&
            bannerData.button1 &&
            bannerData.button1Status.value && (
              <Link to={bannerData.button1Link.value} className="mw_button">
                {bannerData.button1.value}
              </Link>
            )}
          {bannerData &&
            bannerData.button2 &&
            bannerData.button2Status.value && (
              <Link to={bannerData.button2Link.value} className="mw_button">
                {bannerData.button2.value}
              </Link>
            )}
        </div>
      </div>
      <div
        className="business_insurance"
        id="business_coverage"
        style={{ width: "100%", position: "relative" }}
      >
        <h1 className="phase2_heading">{section1.title.value}</h1>
        <p className="phase2_subheading">{section1.description.value}</p>
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "1rem" }}
            id="admin-edit"
            onClick={() => setShowBusinessInfoModal(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
      </div>
      {section2 && section2.length && (
        <div
          className="annuities_tabset tabset"
          style={{
            width: "100%",
            position: "relative",
            padding: "3rem 33rem 0rem",
          }}
        >
          {section2.map((item, index) => (
            <React.Fragment key={`checked${index}`}>
              <input
                type="radio"
                name="tabset"
                id={`tab${index + 1}`}
                aria-controls={`tab-${item.title}`}
                checked={checked === `checked${index + 1}`}
                onChange={() => setChecked(`checked${index + 1}`)}
              />
              <label style={{ width: "200px" }} htmlFor={`tab${index + 1}`}>
                {item.title}
              </label>
            </React.Fragment>
          ))}
          <div className="tab-panels">
            {section2.map((item, index) => (
              <section
                id={`tab-fixed-annuities${index}`}
                className="tab-panel"
                key={`tab-fixed-annuities${index}`}
              >
                <p className="phase2_subheading">{item.description}</p>
                {item.example != "" && (
                  <>
                    <br />
                    <br />
                  </>
                )}
                <p className="tab_about__data">
                  {item.example != "" && (
                    <div className="tab_comprehensive">
                      <p className="phase2_heading1">Example:</p>
                      <p className="phase2_subheading">{item.example}</p>
                    </div>
                  )}
                </p>
              </section>
            ))}
            {showEdit && (
              <div
                className="mwfbi_banner__edit"
                style={{ top: "1rem" }}
                id="admin-edit"
                onClick={() => setShowBusinessPointsModal(true)}
              >
                <svg>
                  <use xlinkHref="/sprite.svg#icon-edit" />
                </svg>
              </div>
            )}
          </div>
          <br />
          <br />
        </div>
      )}

      <div
        className="business_insurance_coverage"
        id="optional_coverage"
        style={{ width: "100%", position: "relative" }}
      >
        <h1 className="phase2_heading">{section3.title.value}</h1>
        <p className="phase2_subheading">{section3.description.value}</p>

        <div className="business_coverage_insurance">
          <h1 className="phase2_heading">
            COVER THE REPLACEMENT COST OF YOUR PERSONAL PROPERTY, SUCH AS:
          </h1>
          <div className="business_insurance-cover">
            {section4 &&
              section4.length &&
              splitArray(section4, 5).map((item, index) => (
                <div
                  className="homeowner-renter-insurance-list"
                  key={index + "cover"}
                >
                  {item.map((elem) => (
                    <h1 className="phase2_subheading">
                      &#10004; {elem.point.value}
                    </h1>
                  ))}
                </div>
              ))}
          </div>
        </div>
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "1rem" }}
            id="admin-edit"
            onClick={() => setShowCoverageInfoModal(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "12rem" }}
            id="admin-edit"
            onClick={() => setShowCoveragePointsModal(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
      </div>
      <div
        className="business_insurance_discounts"
        id="business_discounts"
        style={{ width: "100%", position: "relative" }}
      >
        <div className="">
          <h1 className="phase2_heading">{section5.title.value}</h1>
          <p className="phase2_subheading">{section5.description.value}</p>
          {showEdit && (
            <div
              className="mwfbi_banner__edit"
              style={{ top: "1rem" }}
              id="admin-edit"
              onClick={() => setShowDiscountsInfoModal(true)}
            >
              <svg>
                <use xlinkHref="/sprite.svg#icon-edit" />
              </svg>
            </div>
          )}
        </div>

        {discountListArr &&
          discountListArr.length &&
          splitArray(discountListArr, 3).map((elements, i) => (
            <div className="homeowner_insurance_discounts-grid">
              {elements.map((item, subIndex) => (
                <div className="discounts-types" key={item.title}>
                  <img src={item.src} className="discounts_images_nails" />
                  <div className="discount_image_text">
                    <h1 className="phase2_heading1">{item.title}</h1>
                    <p className="phase2_subheading">{item.description}</p>
                  </div>
                  {subIndex < 2 && <div className="line" />}
                </div>
              ))}
            </div>
          ))}

        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "1rem" }}
            id="admin-edit"
            onClick={() => setShowDiscountTypeModal(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
        {showEdit && (
          <div
            className="mwfbi_banner__edit"
            style={{ top: "10rem" }}
            id="admin-edit"
            onClick={() => setShowDiscountTypeModal(true)}
          >
            <svg>
              <use xlinkHref="/sprite.svg#icon-edit" />
            </svg>
          </div>
        )}
      </div>
      <EditDiscountType
        handleYes={handleDiscountType}
        showModal={showDiscountTypeModal}
        setShowModal={setShowDiscountTypeModal}
        discountListArr={discountListArr}
        setDiscountListArr={setDiscountListArr}
      />
      <EditCoverageInfo
        handleYes={handleCoverageInfoSubmit}
        section3={section3}
        setSection3={setSection3}
        setShowModal={setShowCoverageInfoModal}
        showModal={showCoverageInfoModal}
      />
      <EditDiscountsInfo
        handleYes={handleDiscountsInfoSubmit}
        section5={section5}
        setSection5={setSection5}
        setShowModal={setShowDiscountsInfoModal}
        showModal={showDiscountsInfoModal}
      />
      <EditCoveragePoints
        coveragePointsListArr={coveragePointsListArr}
        section4={section4}
        setSection4={setSection4}
        handleYes={handleCoveragePoints}
        setCoveragePointsListArr={setCoveragePointsListArr}
        setShowModal={setShowCoveragePointsModal}
        coverageTitle={coverageTitle}
        setCoverageTitle={setCoverageTitle}
        showModal={showCoveragePointsModal}
      />
      <EditBusinessPoints
        showModal={showBusinessPointsModal}
        setShowModal={setShowBusinessPointsModal}
        handleYes={handleBusinessPointsSubmit}
        section2={section2}
        setSection2={setSection2}
      />
      <EditBusinessInfo
        handleYes={handleBusinessInfoSubmit}
        section1={section1}
        setSection1={setSection1}
        setShowModal={setShowBusinessInfoModal}
        showModal={showBusinessInfoModal}
      />
    </Content>
  );
};
export default BusinessInsurance;
