import { USER_AZURE_REQUEST, USER_AZURE_SUCCESS, USER_AZURE_FAIL, USER_AZURE_LOGOUT } from '../constants/userConstants';

export const userAzureReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_AZURE_REQUEST:
			return { loading: true };

		case USER_AZURE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case USER_AZURE_FAIL:
			return { loading: false, error: action.payload };

		case USER_AZURE_LOGOUT:
			return {};

		default:
			return state;
	}
};
